import axios from "axios";
import { axiosMyProperty } from "./apis";
import { isIOS } from "react-device-detect";
import moment from "moment";

export const FormatDate = (dateString, dateFormat = "MMMM DD, YYYY") => {
	var day = new Date(dateString).getDate();
	var month = new Date(dateString).getMonth();
	var year = new Date(dateString).getFullYear();
	year = Math.abs(year);

	var finalDate = new Date(year, month, day);
	return moment(new Date(finalDate)).format(dateFormat);
};

export const getCurrentDateTime = (dateFormat = "MMMM DD, YYYY hh:mm A") => {
	return moment(new Date()).format(dateFormat);
};

export const FormatCurrency = (num, decimals = 0, dollarSign = true) => {
	let value =
		num || num === 0
			? num
					.toLocaleString("en-US", {
						minimumFractionDigits: decimals,
						maximumFractionDigits: decimals,
					})
					.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
			: "";
	return dollarSign ? "$" + value : value;
};

export const FormatPercent = (num, decimals = 0, percentSign = true, initialValueInPercent = false) => {
	let value =
		num || num === 0
			? (num * (initialValueInPercent ? 1 : 100)).toLocaleString("en-US", {
					minimumFractionDigits: decimals,
					maximumFractionDigits: decimals,
			  })
			: "0.0";
	return percentSign ? value + "%" : value;
};

export const FormatPhoneNumber = (phoneNumber) => {
	var cleaned = ("" + phoneNumber).replace(/\D/g, "");
	var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		return "" + match[1] + "-" + match[2] + "-" + match[3];
	}
	return null;
};

//attempt to get ip client side causes other erros if geolocation api timesout, so returning empty always since IP will be retrived server side if empty
export const getIP = async () => {
	// const ip = await axios
	// 	.get("https://geolocation-db.com/json/")
	// 	.then((res) => {
	// 		return res.data.IPv4 ?? "";
	// 	})
	// 	.catch((e) => {
	// 		//ignore
	// 		return "";
	// 	});
	// return ip;
	return "";
};

export const AddPropertyTypeEnum = {
	None: "None",
	Add: "Add",
	Verify: "Verify",
};

export const pdfDownload = (base64Data, fileName) => {
	let linkSource = ``;
	let pdfDownload = null;

	const downloadLink = document.createElement("a");

	if (isIOS) {
		// iOS has issues with downloading PDFs, use octet-stream
		linkSource = `data:application/octet-stream;base64,${base64Data}`;
		pdfDownload = fileName + ".pdf";
	} else {
		// otherwise use pdf
		linkSource = `data:application/pdf;base64,${base64Data}`;
		pdfDownload = fileName;
	}

	downloadLink.href = linkSource;
	downloadLink.download = pdfDownload;

	document.body.appendChild(downloadLink);
	setTimeout(() => {
		downloadLink.click();
		document.body.removeChild(downloadLink);
	}, 500);
};

export const errorInfo = (message) => {
	let url = "common/errorinfo";

	let payload = {
		message: message,
	};

	return axiosMyProperty.post(url, payload);
};
