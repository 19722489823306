import "./Report.css";

import * as CommonFns from "../../../../../../../common";
import * as toastMessages from "../../../../../../../toastMessages";

import {
	Box,
	Button,
	Flex,
	FormControl,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Radio,
	RadioGroup,
	Spacer,
	chakra,
	useBreakpointValue,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import { AuditContext } from "../../../../../../../contexts/AuditContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner } from "@chakra-ui/react";
import { axiosWebLogic } from "../../../../../../../apis";

const Report = ({ accId }) => {
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [unit, setUnit] = useState("metric");
	const [payload, setPayload] = useState({
		accountNumber: accId.toString(),
		ipAddress: "",
		unitOfMeasure: unit,
	});
	const [processing, setProcessing] = useState(false);

	const { auditActivity } = useContext(AuditContext);

	const toast = useToast();
	const assessmentReportToastErrorId = "assessment-report-toast-error";

	const formLabelMinWidth = 200;
	const formControlMinWidth = 405;

	useEffect(() => {
		//get the client IP address for sign in
		CommonFns.getIP().then((res) => setPayload({ ...payload, ipAddress: res }));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const handleChangeUnit = (selectedUnit) => {
		setUnit(selectedUnit);
		setPayload({ ...payload, unitOfMeasure: selectedUnit });
	};

	const handleReportDownload = (e) => {
		e.preventDefault();
		setProcessing(true);

		let url = "report/assessment";
		axiosWebLogic
			.post(url, payload)
			.then((resp) => {
				let returnObj = resp.data;

				//audit log
				let activity = `Download Property Assessment Detail - ${payload.unitOfMeasure}`;
				auditActivity(activity, accId);

				//download report
				CommonFns.pdfDownload(returnObj.report, returnObj.reportName);

				//close modal
				onClose();
			})
			.catch((e) => {
				// error
				if (!toast.isActive(assessmentReportToastErrorId)) {
					toast(toastMessages.getToastError(assessmentReportToastErrorId));
				}
			})
			.finally(() => {
				setProcessing(false);
			});
	};

	return (
		<Box
			p={3}
			mt={1}
			className='report-container'>
			<React.Fragment>
				<Flex>
					<Box pt={1}>
						<chakra.span fontWeight={700}>Property assessment detail report</chakra.span>
					</Box>
					<Spacer />
					<Box>
						<Button
							colorScheme='lightBlue'
							as='button'
							minW={"5rem"}
							size={"sm"}
							onClick={onOpen}>
							Download
						</Button>
					</Box>
				</Flex>

				<Modal
					closeOnOverlayClick={false}
					isOpen={isOpen}
					onClose={onClose}>
					<ModalOverlay />
					<ModalContent maxW={"38rem"}>
						<ModalHeader className={"modal-header"}>{`Property Assessment Detail Report`}</ModalHeader>
						<ModalCloseButton className='modal-close-button'>
							<FontAwesomeIcon
								size={"2xl"}
								icon='fa-solid fa-xmark'
							/>
						</ModalCloseButton>
						<ModalBody>
							<Box
								mt={2}
								mb={2}>
								<FormControl mt={2}>
									<Flex direction={["column", "column", "row"]}>
										<Box
											minW={formLabelMinWidth}
											mr={isSmallerScreen ? 0 : 3}>
											<FormLabel
												textAlign={isSmallerScreen ? "" : "end"}
												className=''>
												Unit of Measurement
											</FormLabel>
										</Box>
										<Box
											minW={formControlMinWidth}
											fontWeight={700}
											mt={isSmallerScreen ? 2 : 0}>
											<RadioGroup
												onChange={handleChangeUnit}
												value={unit}>
												<Flex direction={["column"]}>
													<Radio
														size='md'
														value='metric'
														colorScheme='lightBlue'
														defaultChecked
														pb={3}>
														<chakra.span fontSize={"15px"}>
															Metric (metres, square metres)
														</chakra.span>
													</Radio>
													<Radio
														size='md'
														value='imperial'
														colorScheme='lightBlue'>
														<chakra.span fontSize={"15px"}>
															Imperial (feet, square feet)
														</chakra.span>
													</Radio>
												</Flex>
											</RadioGroup>
										</Box>
									</Flex>
								</FormControl>

								{/* <FormControl mt={isSmallerScreen ? 6 : 4}>
									<Flex direction={["column", "column", "row"]}>
										<Box minW={formLabelMinWidth} display={isSmallerScreen ? "none" : "normal"}>
											<FormLabel>&nbsp;</FormLabel>
										</Box>
										<Box ml={isSmallerScreen ? 0 : 3}>
											<Flex direction={["column", "column", "row"]}>
												<Button
													size='sm'
													colorScheme='lightBlue'
													mr={isSmallerScreen ? 0 : 4}
													isDisabled={processing}
													onClick={(e) => {
														handleReportDownload(e);
													}}>
													Download
												</Button>
												<Box
													display={processing ? "normal" : "none"}
													mt={isSmallerScreen ? 2 : "7px"}>
													<Flex direction={["row"]}>
														<Spinner
															size='sm'
															speed='0.90s'
															emptyColor='gray.200'
															color='blue.500'
														/>
														<Box
															className='sales-transfers-fade'
															ml={2}
															display={isSmallerScreen ? "normal" : "none"}>
															Processing...
														</Box>
													</Flex>
												</Box>
											</Flex>
										</Box>
									</Flex>
								</FormControl> */}
							</Box>
						</ModalBody>
						<ModalFooter className={"modal-footer"}>
							<Flex
								direction={["column", "column", "row"]}
								w={["100%", "100%", ""]}
								justifyContent={"flex-end"}>
								<Button
									size='md'
									colorScheme='lightBlue'
									//mr={[0, 0, 2]}
									isDisabled={processing}
									onClick={(e) => {
										handleReportDownload(e);
									}}>
									{processing ? (
										<>
											<Spinner
												size='sm'
												mr={2}
												speed='0.90s'
												emptyColor='gray.200'
												color='blue.500'
											/>{" "}
											Processing...
										</>
									) : (
										<>Download</>
									)}
								</Button>
								{/* <Button
									colorScheme='grey'
									variant={"outline"}
									mt={[2, 2, 0]}
									minW={["100%", "100%", 100]}
									onClick={() => onClose()}>
									Cancel
								</Button> */}
							</Flex>
							{/* <Button colorScheme='blue' size={"sm"} onClick={onClose}>
								Close
							</Button> */}
						</ModalFooter>
					</ModalContent>
				</Modal>
			</React.Fragment>
		</Box>
	);
};

export default Report;
