import "./PropertyMap.css";

import * as CommonFns from "../../../../../../common";
import * as toastMessages from "../../../../../../toastMessages";

import { AspectRatio, Box, Button, Flex, Image, Skeleton, Spacer, Spinner, chakra, useToast } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { axiosMaps, axiosMyPropertyAuth } from "../../../../../../apis";

import { AuditContext } from "../../../../../../contexts/AuditContext";

const PropertyMap = ({ accId }) => {
	const [mapDetail, setMapDetail] = useState();
	const [mapUrl, setMapUrl] = useState();
	const [mapImageSource, setMapImageSource] = useState();
	const [mapImageLoading, setMapImageLoading] = useState(true);
	const mapImageDefault = require("./Images/map_default.png");

	const { auditActivity } = useContext(AuditContext);

	const toast = useToast();
	const mapDetailToastErrorId = "map-detail-toast-error";
	const mapDetailToastWarningId = "map-detail-toast-warning";

	useEffect(() => {
		getMapDetail();
		getMapUrl();
		getMapImage();
	}, []);

	const getMapDetail = () => {
		let url = "assessment/map";

		axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					setMapDetail(returnObj);
				} else {
					if (!toast.isActive(mapDetailToastWarningId)) {
						toast(
							toastMessages.getToastWarning(
								mapDetailToastWarningId,
								returnObj.errorMsg,
								toastMessages.propertyMessages
							)
						);
					}
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(mapDetailToastErrorId)) {
					toast(toastMessages.getToastError(mapDetailToastErrorId));
				}
			});
	};

	const getMapImage = () => {
		let url = `maps/${accId}`;
		axiosMaps
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.base64.length > 0) {
					setMapImageSource(`data:image/png;base64,` + returnObj.base64);
				} else {
					// default image will display
					setMapImageSource(mapImageDefault);
				}
				setMapImageLoading(false);
			})
			.catch((error) => {
				// error
				setMapImageSource(mapImageDefault);
				setMapImageLoading(false);
				if (!toast.isActive(mapDetailToastErrorId)) {
					toast(toastMessages.getToastError(mapDetailToastErrorId));
				}
			});
	};

	const getMapUrl = () => {
		// get the ip address and then generate the map url
		CommonFns.getIP().then((resp) => {
			// ip response
			let ip = resp;
			let payload = {
				accId: accId,
				ipAddress: ip,
			};

			// get the map url with token
			let url = "maps";
			axiosMaps
				.post(url, payload)
				.then((resp) => {
					let returnObj = resp.data;
					setMapUrl(returnObj.link);
				})
				.catch((error) => {
					// error
					setMapUrl();
				});
		});
	};

	const handleViewMap = (e) => {
		e.preventDefault();
		if (mapUrl !== undefined) {
			window.open(mapUrl, "_blank", "noopener");
		}
	};

	const handleMapImageOnError = () => {
		// make map image source undefied, to display the default image
		setMapImageSource();
	};

	return (
		<React.Fragment>
			{mapDetail !== undefined ? (
				<React.Fragment>
					<Box
						mb={2}
						pt={1}
						pl={3}
						pr={3}
						maxW={1050}>
						<chakra.span fontSize={"xl"}>{mapDetail.linkMsg}</chakra.span>
					</Box>
					<Box display={{ md: "flex" }}>
						<Box minW={"33%"}>
							<AspectRatio
								width={"100%"}
								ratio={16 / 9}>
								<iframe
									width={415}
									height={233}
									title='How to navigate secure assessment maps'
									src={mapDetail.mapTutorialLink}
									allowFullScreen
								/>
							</AspectRatio>
						</Box>
						<Box>
							{!mapImageLoading ? (
								<Image
									src={mapImageSource}
									alt='Map'
									className={mapUrl !== undefined ? "map-image-hover" : ""}
									width={"100%"}
									onError={handleMapImageOnError}
									onClick={(e) => {
										auditActivity("View Property Maps Image", accId);
										handleViewMap(e);
									}}></Image>
							) : (
								<Spinner
									size='sm'
									ml={1}
								/>
							)}
						</Box>
					</Box>

					{mapUrl !== undefined ? (
						<Box p={3}>
							<Flex>
								<Box pt={1}>
									<chakra.span fontWeight={700}>Detailed assessment maps</chakra.span>
								</Box>
								<Spacer />
								<Box>
									<Button
										colorScheme='lightBlue'
										as='button'
										minW={"5rem"}
										size={"sm"}
										onClick={(e) => {
											auditActivity("View Property Maps Button", accId);
											handleViewMap(e);
										}}>
										View
									</Button>
								</Box>
							</Flex>
						</Box>
					) : null}
				</React.Fragment>
			) : (
				<Box>
					<Skeleton
						startColor='#e2e2e2'
						endColor='#bbbbbb'
						size='8'
						width={540}
						height={38}></Skeleton>
					<Skeleton
						mt={4}
						height={380}
						startColor='#e2e2e2'
						endColor='#bbbbbb'></Skeleton>
				</Box>
			)}
		</React.Fragment>
	);
};

export default PropertyMap;
