import "./Header.css";

import { Box, Image, Link } from "@chakra-ui/react";
import React, { useContext, useState } from "react";

import { AuditContext } from "../../../contexts/AuditContext";

const Header = () => {
	const { auditActivity } = useContext(AuditContext);
	const [faqUrl] = useState(process.env.REACT_APP_FAQ_URL);
	const [edmontonUrl] = useState(process.env.REACT_APP_EDMONTON_URL);
	const [env] = useState(process.env.REACT_APP_ENVIRONMENT);

	const ImageEdmontonLogo_Stamp = require("./Images/coe-logo-stamp.png");
	const ImageEdmontonLogo = require("./Images/coe-logo.svg").default;

	return (
		<React.Fragment>
			<Box className="header-container">
				<Box className="logo-container">
					<Link href={edmontonUrl} rel={"noopener"}>
						<Image
							src={env !== "production" ? ImageEdmontonLogo_Stamp : ImageEdmontonLogo}
							alt="City of Edmonton"
							className="logo"
						></Image>
					</Link>
				</Box>
				<Box className="link-container">
					<Link
						href={faqUrl}
						rel={"noopener"}
						target={"_blank"}
						onClick={() => {
							auditActivity("FAQ");
						}}
					>
						FAQs
					</Link>
					<Link href={edmontonUrl} rel={"noopener"} target={"_blank"}>
						Edmonton.ca
					</Link>
				</Box>
			</Box>
		</React.Fragment>
	);
};

export default Header;
