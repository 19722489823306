import { Box, Button, Flex, useBreakpointValue } from "@chakra-ui/react";
import React, { useContext, useState } from "react";

import { AuditContext } from "../../../contexts/AuditContext";

const Agent = () => {
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });
	const { auditActivity } = useContext(AuditContext);
	const [agentAccessUrl] = useState(process.env.REACT_APP_AGENT_ACCESS_URL);

	const handleAgentAccess = (e) => {
		e.preventDefault();
		auditActivity("Agent Sign In");
		window.open(agentAccessUrl, "_self", "noopener");
	};
	return (
		<React.Fragment>
			<Box className='sign-in-container'>
				<Flex direction={["column", "column", "row", "row"]}>
					<Box w={isSmallerScreen ? "100%" : 550}>
						<Flex direction={["column"]}>
							<Box className='sign-in-title'>One-time Access</Box>
							<Box className='sign-in-text'>
								Access limited assessment and tax information using your access code, without creating
								an account.
							</Box>
						</Flex>
					</Box>
					<Box
						w={isSmallerScreen ? "100%" : 235}
						ml={isSmallerScreen ? 0 : 10}
						mt={isSmallerScreen ? 2 : 12}
						mb={isSmallerScreen ? 2 : 0}
						mr={4}>
						<Box>
							<Button
								colorScheme='blue'
								className='sign-in-button'
								onClick={(e) => handleAgentAccess(e)}>
								SIGN IN
							</Button>
						</Box>
					</Box>
				</Flex>
			</Box>
		</React.Fragment>
	);
};

export default Agent;
