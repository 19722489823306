import "./RenewDetails.css";

import {
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Badge,
	Box,
	Checkbox,
	Flex,
	Spacer,
	Table,
	Tbody,
	Td,
	Text,
	Tr,
} from "@chakra-ui/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const RenewDetails = ({ displayProperties, selectedProperties, setSelectedProperties, isReadOnly = true }) => {
	const handleSelectPropertyClick = (e, emailAgent, accId) => {
		let isChecked = e.target.checked;
		let currentSelectedProperties = [...selectedProperties];

		//if email exists in selected array, get index
		var emailIndex = currentSelectedProperties.findIndex((x) => x.emailAddress === emailAgent.emailAddress);

		if (isChecked) {
			//if email does not already exist in selected properties add email and selected account
			if (emailIndex === -1) {
				currentSelectedProperties.push({
					emailAddress: emailAgent.emailAddress,
					accountsCol: emailAgent.accountsCol.filter((x) => x.accId === accId),
				});
			} else {
				//if email already exists in selected properties, add selected account to that object
				var selectedAccountCols = currentSelectedProperties[emailIndex].accountsCol;
				selectedAccountCols.push(emailAgent.accountsCol.find((x) => x.accId === accId));
			}
		} else {
			//remove from selected list
			var selectedAccountCols = currentSelectedProperties[emailIndex].accountsCol;

			//if email only has one account, delete the whole array element
			if (selectedAccountCols.length === 1) {
				currentSelectedProperties.splice(emailIndex, 1);
			} else {
				//if email has more than one account selected, only remove the one account
				var accountIndex = selectedAccountCols.findIndex((x) => x.accId === accId);
				selectedAccountCols.splice(accountIndex, 1);
			}
		}
		setSelectedProperties(currentSelectedProperties);
	};

	return (
		<>
			<Accordion
				allowMultiple
				defaultIndex={[displayProperties?.length === 1 ? 0 : ""]}>
				{displayProperties
					?.sort((a, b) => a.emailAddress.localeCompare(b.emailAddress))
					.map((emailAgent, index) => (
						<AccordionItem
							key={index}
							className='accordion-item'
							mb={3}>
							{({ isExpanded }) => (
								<React.Fragment>
									<AccordionButton className={`accordion-heading ${isExpanded ? "expanded" : ""}`}>
										<Flex w={"100%"}>
											<Box w={"100%"}>
												<Flex>
													<Box mr={2}>
														{isExpanded ? (
															<Box className='accordion-expand-icon-container expanded'>
																<FontAwesomeIcon icon='fa-solid fa-minus' />
															</Box>
														) : (
															<Box className='accordion-expand-icon-container collapsed'>
																<FontAwesomeIcon icon='fa-solid fa-plus' />
															</Box>
														)}
													</Box>
													<Box mr={2}>
														<Text>{emailAgent.emailAddress}</Text>
													</Box>
												</Flex>
											</Box>
											<Spacer></Spacer>
											<Box
												w={"50%"}
												mr={2}
												fontSize={".8em"}
												position={"relative"}
												top={"3px"}>
												<Flex>
													<Box mr={2}>
														<Badge
															background={isExpanded ? "#e9eaea" : "#004060"}
															color={isExpanded ? "#004060" : "#FFF"}>
															{emailAgent?.accountsCol.length.toString()}
														</Badge>
													</Box>
													<Box>
														<Text
															w={"100px"}
															textAlign={"left"}>
															{emailAgent?.accountsCol.length > 1
																? "Properties"
																: "Property"}
														</Text>
													</Box>
												</Flex>
											</Box>
										</Flex>
									</AccordionButton>
									<AccordionPanel
										pb={4}
										className='accordion-panel'>
										<Box>
											<Table className='prop-auth'>
												<Tbody>
													{emailAgent?.accountsCol
														?.sort((a, b) => a.sortOrder - b.sortOrder)
														.map((account, index) => (
															<Tr key={index}>
																{!isReadOnly ? (
																	<>
																		<Td>
																			<Checkbox
																				onChange={(e) => {
																					handleSelectPropertyClick(
																						e,
																						emailAgent,
																						account.accId
																					);
																				}}
																				className='renew-details form-check-input'
																				isChecked={
																					selectedProperties.findIndex(
																						(x) =>
																							x.emailAddress ===
																								emailAgent.emailAddress &&
																							x.accountsCol.some(
																								(y) =>
																									y.accId ===
																									account.accId
																							)
																					) >= 0
																				}></Checkbox>
																		</Td>
																	</>
																) : null}
																<Td>
																	<Box w={"400px"}>{account.propAddress}</Box>
																</Td>
																<Td textAlign={"center"}>{account.accId}</Td>
																<Td textAlign={"center"}>
																	{account.representativeFlag === "Y" ? (
																		<Box w={"114px"}>Representative</Box>
																	) : account.representativeFlag === "N" ? (
																		<Box w={"114px"}></Box>
																	) : (
																		<Box w={"114px"}></Box>
																	)}
																</Td>
															</Tr>
														))}
												</Tbody>
											</Table>
										</Box>
									</AccordionPanel>
								</React.Fragment>
							)}
						</AccordionItem>
					))}
			</Accordion>
		</>
	);
};
export default RenewDetails;
