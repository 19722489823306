import "./CEIP.css";

import * as CommonFns from "../../../../../../common";
import * as toastMessages from "../../../../../../toastMessages";

import {
	Box,
	Button,
	Flex,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	chakra,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import { AuditContext } from "../../../../../../contexts/AuditContext";
import CEIPPayOut from "./PayOut/CEIPPayOut";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Markdown from "../../../../../Global/Markdown/Markdown";
import { axiosMyPropertyAuth } from "../../../../../../apis";

const CEIP = ({ accId }) => {
	const [ceip, setCeip] = useState();
	const [isDisplayPayout, setIsDisplayPayout] = useState(false);

	useEffect(() => {
		getCeip();
	}, []);

	const { isOpen: isOpenPayOut, onOpen: onOpenPayOut, onClose: onClosePayOut } = useDisclosure();
	const { isOpen: isOpenPayableWith, onToggle: onTogglePayableWith, onClose: onClosePayableWith } = useDisclosure();

	const { auditActivity } = useContext(AuditContext);

	const toast = useToast();
	const ceipErrorId = "ceip-toast-error";

	const getCeip = () => {
		let url = `taxes/ceip/${accId}`;
		axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;

				if (returnObj.retVal === "true") {
					setCeip(returnObj);
					setIsDisplayPayout(
						returnObj.displayPayoutFlag !== null
							? returnObj.displayPayoutFlag === "Y"
								? true
								: false
							: false
					);
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(ceipErrorId)) {
					toast(toastMessages.getToastError(ceipErrorId));
				}
			});
	};

	// const handlePayOutModalClose = () => {
	// 	onClosePayOut();
	// };

	const handleCeipLearnMore = (e, url) => {
		e.preventDefault();
		window.open(url, "_blank", "noopener");
	};

	return (
		<React.Fragment>
			{ceip !== undefined ? (
				<React.Fragment>
					<Box maxW={1050}>
						<Box>
							<Text fontSize={"xl"}>{ceip.cpExplanationMessage}</Text>
						</Box>
						<Box mt={4}>
							<Text>{ceip.cpExplanation2Message}</Text>
						</Box>
						{ceip.cpPayoutMessage !== null ? (
							<React.Fragment>
								<Box mt={4}>
									<Markdown text={ceip.cpBreakdownMessage}></Markdown>
								</Box>
								<Box>
									<TableContainer>
										<Box
											maxW={1050}
											minW={750}>
											<Table className='ceip-table'>
												<Thead>
													<Tr>
														<Th className='border-none'></Th>
														<Th>
															First
															<br />
															year
														</Th>
														<Th>
															Expiry
															<br />
															year
														</Th>
														<Th>
															Interest
															<br />
															rate
														</Th>
														<Th>
															Payable with
															<br />
															{ceip.taxYear}
															<br />
															property taxes
															<Link
																className='ceip-payable-with-info'
																ml={1}
																onClick={onTogglePayableWith}>
																<FontAwesomeIcon icon='fa-solid fa-circle-info' />
															</Link>
															<Popover
																returnFocusOnClose={false}
																isOpen={isOpenPayableWith}
																onClose={onClosePayableWith}
																placement='right'
																closeOnBlur={true}>
																<PopoverTrigger>
																	<chakra.span></chakra.span>
																</PopoverTrigger>
																<PopoverContent minW={{ base: "100%", lg: 500 }}>
																	<PopoverArrow />
																	<PopoverBody className='popover-shadow'>
																		<Box textAlign={"left"}>
																			<chakra.span
																				className='ceip-payable-with-text'
																				whiteSpace={"break-spaces"}>
																				{ceip.cpInfoMessage}
																			</chakra.span>
																		</Box>
																	</PopoverBody>
																</PopoverContent>
															</Popover>
														</Th>
														{isDisplayPayout ? (
															<Th className='ceip-payout'>
																Optional
																<br />
																payout amount
																<br />
																(if available)
															</Th>
														) : null}
													</Tr>
												</Thead>
												<Tbody>
													{ceip.cpGroupCol.map((group, index) => (
														<React.Fragment key={index}>
															<Tr>
																<Td
																	className='text-left'
																	fontWeight={700}>
																	{group.displayHeading}
																</Td>
																<Td colSpan={4}>&nbsp;</Td>
																{isDisplayPayout ? (
																	<Td className='ceip-payout'></Td>
																) : null}
															</Tr>
															{group.cpGroupDtls.map((groupDetail, index) => (
																<React.Fragment key={index}>
																	<Tr>
																		<Td
																			w={"30%"}
																			className='text-left pad-left'>
																			{groupDetail.cpType}
																		</Td>
																		<Td w={"10%"}>
																			{groupDetail.firstYear !== null
																				? groupDetail.firstYear !== "0"
																					? groupDetail.firstYear
																					: ""
																				: ""}
																		</Td>
																		<Td w={"10%"}>
																			{groupDetail.expiryYear !== null
																				? groupDetail.expiryYear !== "0"
																					? groupDetail.expiryYear
																					: ""
																				: ""}
																		</Td>
																		<Td w={"10%"}>
																			<Flex justifyContent='center'>
																				{groupDetail.interestRate !== null
																					? groupDetail.interestRate + "%"
																					: ""}
																			</Flex>
																		</Td>
																		<Td w={"15%"}>
																			<Flex justifyContent='center'>
																				{groupDetail.annualAmount !== null
																					? CommonFns.FormatCurrency(
																							groupDetail.annualAmount,
																							2,
																							true
																					  )
																					: ""}
																			</Flex>
																		</Td>
																		{isDisplayPayout ? (
																			<Td
																				w={"10%"}
																				className='ceip-payout'>
																				<Flex justifyContent='center'>
																					{groupDetail.payoutAmount !== null
																						? CommonFns.FormatCurrency(
																								groupDetail.payoutAmount,
																								2,
																								true
																						  )
																						: ""}
																				</Flex>
																			</Td>
																		) : null}
																	</Tr>
																</React.Fragment>
															))}
														</React.Fragment>
													))}
													{isDisplayPayout ? (
														<Tr>
															<Td colSpan={5}></Td>
															<Td className='ceip-payout'>
																<Box
																	pl={"2px"}
																	pr={"2px"}
																	pb={"2px"}>
																	<Button
																		className='ceip-payout-button'
																		size={"sm"}
																		onClick={() => {
																			auditActivity(
																				"Clean Energy Improvement Program - Pay out Click",
																				accId
																			);
																			onOpenPayOut();
																		}}>
																		Pay out
																	</Button>
																</Box>
															</Td>
														</Tr>
													) : null}
												</Tbody>
											</Table>
										</Box>
									</TableContainer>
								</Box>
							</React.Fragment>
						) : (
							<React.Fragment>
								<Box
									mt={4}
									mb={2}>
									<Button
										colorScheme='lightBlue'
										size={"xs"}
										fontSize={".9em"}
										mr={1}
										width={95}
										onClick={(e) => {
											auditActivity("View Learn More CEIP", accId);
											handleCeipLearnMore(e, ceip.cpLearnMoreLinkMessage);
										}}>
										Learn More
									</Button>
									about the CEIP program and how you can apply.{" "}
								</Box>
							</React.Fragment>
						)}
					</Box>
				</React.Fragment>
			) : null}

			{/* Pay Out Modal */}
			<Modal
				size={"5xl"}
				closeOnOverlayClick={false}
				isOpen={isOpenPayOut}
				onClose={onClosePayOut}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className={"modal-header"}>If You Wish to Pay Out CEIP Charges</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					<ModalBody>
						<CEIPPayOut ceip={ceip}></CEIPPayOut>
					</ModalBody>
					<ModalFooter className={"modal-footer"}>
						{/* <Button colorScheme='blue' size={"sm"} onClick={() => handlePayOutModalClose()}>
							Close
						</Button> */}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</React.Fragment>
	);
};

export default CEIP;
