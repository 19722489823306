import "./Calculator.css";

import * as CommonFns from "../../../../../../../common";

import {
	Box,
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	InputGroup,
	InputLeftAddon,
	InputRightElement,
	Link,
	Spacer,
	chakra,
	useBreakpointValue,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import { AuditContext } from "../../../../../../../contexts/AuditContext";
import { AuthContext } from "../../../../../../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MultiUseCalculator from "./MultiUseCalculator";
import { useForm } from "react-hook-form";

const Calculator = ({
	estimatorType,
	accountDetails,
	taxRates,
	taxYear,
	multiUseTaxRates,
	hideSingleEstimatorTypes,
}) => {
	const { authDetails } = useContext(AuthContext);
	const { auditActivity } = useContext(AuditContext);

	const isSmallerScreen = useBreakpointValue({ base: true, md: false });

	const [showCalculation, setShowCalculation] = useState(false);
	const [municipalTotal, setMunicipalTotal] = useState(0);
	const [provincialTotal, setProvincialTotal] = useState(0);
	const [total, setTotal] = useState(0);
	const [monthlyTotal, setMonthlyTotal] = useState(0);
	const [multiUse100Error, setMulti100Error] = useState(false);
	const [historyMuniChangesUrl] = useState(process.env.REACT_APP_HISTORY_MUNI_CHANGES_URL);

	const formLabelMinWidth = 270;

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		defaultValues: {
			assessedValue: "",
			municipalTaxIncrease: "",
			provincialTaxIncrease: "",
			resPropertyUse: "",
			resTaxStatusMuni: "",
			resTaxStatusSchl: "",
			nonPropertyUse: "",
			nonTaxStatusMuni: "",
			nonTaxStatusSchl: "",
			othPropertyUse: "",
			othTaxStatusMuni: "",
			othTaxStatusSchl: "",
			farPropertyUse: "",
			farTaxStatusMuni: "",
			farTaxStatusSchl: "",
		},
	});

	useEffect(() => {
		if (
			accountDetails !== undefined &&
			(estimatorType?.estimatorTaxClass === accountDetails?.estimatorTaxClass?.toLowerCase() ||
				(estimatorType?.id === "mu" && hideSingleEstimatorTypes))
		) {
			setValue("assessedValue", parseFloat(accountDetails.currAsmt));
		}
	}, [accountDetails, estimatorType, setValue, hideSingleEstimatorTypes]);

	const onSubmit = (data) => {
		setShowCalculation(true);
		let municipalTaxIncrease = 0;
		let provincialTaxIncrease = 0;
		let assessedValue = 0;

		if (data.municipalTaxIncrease.trim() !== "") {
			municipalTaxIncrease = parseFloat(data.municipalTaxIncrease.trim());
		}

		if (data.provincialTaxIncrease.trim() !== "") {
			provincialTaxIncrease = parseFloat(data.provincialTaxIncrease.trim());
		}

		if (data.assessedValue !== "") {
			assessedValue = parseFloat(data.assessedValue);
		}

		let municipalTaxTotal = 0;
		let provincialTaxTotal = 0;

		if (estimatorType.id !== "mu") {
			let municipalRate = parseFloat(
				taxRates.filter((x) => x.millClass?.toLowerCase() === estimatorType?.id && x.levyType === "MUNI")[0]
					?.rate
			);
			let provincialRate = parseFloat(
				taxRates.filter((x) => x.millClass?.toLowerCase() === estimatorType?.id && x.levyType === "SCHOOL")[0]
					?.rate
			);

			municipalTaxTotal = parseFloat(
				assessedValue * ((municipalRate / 1000) * (1 + municipalTaxIncrease / 100))
			).toFixed(2);

			provincialTaxTotal = parseFloat(
				assessedValue * ((provincialRate / 1000) * (1 + provincialTaxIncrease / 100))
			).toFixed(2);
		} else {
			//Get property Use input values
			let resPropertyUse = data.resPropertyUse.trim() !== "" ? parseFloat(data.resPropertyUse.trim()) : 0;
			let nonPropertyUse = data.nonPropertyUse.trim() !== "" ? parseFloat(data.nonPropertyUse.trim()) : 0;
			let othPropertyUse = data.othPropertyUse.trim() !== "" ? parseFloat(data.othPropertyUse.trim()) : 0;
			let farPropertyUse = data.farPropertyUse.trim() !== "" ? parseFloat(data.farPropertyUse.trim()) : 0;

			let totalPropertyUse = resPropertyUse + nonPropertyUse + othPropertyUse + farPropertyUse;
			validateMultiUsePercent(totalPropertyUse);

			//get municipal taxable status values
			let resTaxStatusMuni = data.resTaxStatusMuni.trim() !== "" ? parseFloat(data.resTaxStatusMuni.trim()) : 0;
			let nonTaxStatusMuni = data.nonTaxStatusMuni.trim() !== "" ? parseFloat(data.nonTaxStatusMuni.trim()) : 0;
			let othTaxStatusMuni = data.othTaxStatusMuni.trim() !== "" ? parseFloat(data.othTaxStatusMuni.trim()) : 0;
			let farTaxStatusMuni = data.farTaxStatusMuni.trim() !== "" ? parseFloat(data.farTaxStatusMuni.trim()) : 0;

			//get provincial taxable status values
			let resTaxStatusSchl = data.resTaxStatusSchl.trim() !== "" ? parseFloat(data.resTaxStatusSchl.trim()) : 0;
			let nonTaxStatusSchl = data.nonTaxStatusSchl.trim() !== "" ? parseFloat(data.nonTaxStatusSchl.trim()) : 0;
			let othTaxStatusSchl = data.othTaxStatusSchl.trim() !== "" ? parseFloat(data.othTaxStatusSchl.trim()) : 0;
			let farTaxStatusSchl = data.farTaxStatusSchl.trim() !== "" ? parseFloat(data.farTaxStatusSchl.trim()) : 0;

			//MUNICIPAL RATES
			let resMuniRate = parseFloat(
				taxRates.filter((x) => x.millClass === "SF" && x.levyType === "MUNI")[0]?.rate
			);
			let nonMuniRate = parseFloat(
				taxRates.filter((x) => x.millClass === "COM" && x.levyType === "MUNI")[0]?.rate
			);
			let othMuniRate = parseFloat(
				taxRates.filter((x) => x.millClass === "OR" && x.levyType === "MUNI")[0]?.rate
			);
			let farMuniRate = parseFloat(
				taxRates.filter((x) => x.millClass === "FL" && x.levyType === "MUNI")[0]?.rate
			);

			//SCHOOL/PROVINCIAL RATES
			let resProvincialRate = parseFloat(
				taxRates.filter((x) => x.millClass === "SF" && x.levyType === "SCHOOL")[0]?.rate
			);
			let nonProvincialRate = parseFloat(
				taxRates.filter((x) => x.millClass === "COM" && x.levyType === "SCHOOL")[0]?.rate
			);
			let othProvincialRate = parseFloat(
				taxRates.filter((x) => x.millClass === "OR" && x.levyType === "SCHOOL")[0]?.rate
			);
			let farProvincialRate = parseFloat(
				taxRates.filter((x) => x.millClass === "FL" && x.levyType === "SCHOOL")[0]?.rate
			);

			//calculate municipal totals for each type
			let resMuniTotal = parseFloat(
				(resPropertyUse / 100) * assessedValue * (resTaxStatusMuni / 100) * (resMuniRate / 1000)
			);
			let nonMuniTotal = parseFloat(
				(nonPropertyUse / 100) * assessedValue * (nonTaxStatusMuni / 100) * (nonMuniRate / 1000)
			);
			let othMuniTotal = parseFloat(
				(othPropertyUse / 100) * assessedValue * (othTaxStatusMuni / 100) * (othMuniRate / 1000)
			);
			let farMuniTotal = parseFloat(
				(farPropertyUse / 100) * assessedValue * (farTaxStatusMuni / 100) * (farMuniRate / 1000)
			);

			//calculate provincal totals for each type
			let resProvincialTotal = parseFloat(
				(resPropertyUse / 100) * assessedValue * (resTaxStatusSchl / 100) * (resProvincialRate / 1000)
			);
			let nonProvincialTotal = parseFloat(
				(nonPropertyUse / 100) * assessedValue * (nonTaxStatusSchl / 100) * (nonProvincialRate / 1000)
			);
			let othProvincialTotal = parseFloat(
				(othPropertyUse / 100) * assessedValue * (othTaxStatusSchl / 100) * (othProvincialRate / 1000)
			);
			let farProvincialTotal = parseFloat(
				(farPropertyUse / 100) * assessedValue * (farTaxStatusSchl / 100) * (farProvincialRate / 1000)
			);

			let municipalSubTotal =
				parseFloat(resMuniTotal) +
				parseFloat(nonMuniTotal) +
				parseFloat(othMuniTotal) +
				parseFloat(farMuniTotal);
			let provincialSubTotal =
				parseFloat(resProvincialTotal) +
				parseFloat(nonProvincialTotal) +
				parseFloat(othProvincialTotal) +
				parseFloat(farProvincialTotal);

			municipalTaxTotal = parseFloat(municipalSubTotal * (1 + municipalTaxIncrease / 100)).toFixed(2);
			provincialTaxTotal = parseFloat(provincialSubTotal * (1 + provincialTaxIncrease / 100)).toFixed(2);
		}

		setMunicipalTotal(municipalTaxTotal);
		setProvincialTotal(provincialTaxTotal);
		setTotal(parseFloat(municipalTaxTotal) + parseFloat(provincialTaxTotal));
		setMonthlyTotal((parseFloat(municipalTaxTotal) + parseFloat(provincialTaxTotal)) / 12);

		// audit
		auditActivity(`Estimate Tax ${estimatorType.id.toUpperCase()}`, authDetails.accId);
	};

	const validateMultiUsePercent = (totalPropertyUse) => {
		if (totalPropertyUse !== 100) {
			setMulti100Error(true);
		} else {
			setMulti100Error(false);
		}
	};

	return (
		<React.Fragment>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Box
					mt={1}
					className='estimator-content-container'>
					<Box pb={estimatorType?.id === "mu" ? 3 : 0}>
						<FormControl isInvalid={errors?.assessedValue}>
							<Flex direction={["column", "column", "row"]}>
								<Box
									minW={formLabelMinWidth}
									pt={1.5}>
									<FormLabel htmlFor='assessedValue'>Assessed Property Value</FormLabel>
								</Box>
								<Box>
									<InputGroup>
										<InputLeftAddon
											children={
												<chakra.span>
													<FontAwesomeIcon icon='fas fa-dollar-sign' />
												</chakra.span>
											}
										/>
										<Input
											w={isSmallerScreen ? "100%" : 218}
											id='assessedValue'
											type='number'
											autoComplete='off'
											{...register("assessedValue", {
												required: "Assessed Property Value is required",
											})}
										/>
									</InputGroup>
								</Box>
								<Box ml={isSmallerScreen ? 0 : 3}>
									<FormErrorMessage
										colorScheme={"red"}
										className={"text-error pos"}>
										{errors.assessedValue && errors.assessedValue.message}
									</FormErrorMessage>
								</Box>
							</Flex>
						</FormControl>
					</Box>

					{estimatorType?.id === "mu" ? (
						<>
							<Box className='estimator-multi-container'>
								<MultiUseCalculator
									register={register}
									setValue={setValue}
									multiUsetaxRates={multiUseTaxRates}
									multiUse100Error={multiUse100Error}></MultiUseCalculator>
							</Box>
						</>
					) : null}

					<FormControl mt={2}>
						<Flex direction={["column", "column", "row"]}>
							<Box minW={formLabelMinWidth}>
								<FormLabel
									pt={1.5}
									htmlFor='municipalTaxIncrease'>
									Municipal Tax Change
								</FormLabel>
							</Box>
							<Box>
								<InputGroup mr={isSmallerScreen ? 0 : 1}>
									<Input
										id='municipalTaxIncrease'
										type='number'
										min='0'
										step='any'
										placeholder='0.0'
										autoComplete='off'
										{...register("municipalTaxIncrease")}
									/>
									<InputRightElement
										children={
											<span>
												<FontAwesomeIcon icon='fas fa-percent' />
											</span>
										}
									/>
								</InputGroup>
							</Box>
						</Flex>
					</FormControl>

					<FormControl mt={2}>
						<Flex direction={["column", "column", "row"]}>
							<Box minW={formLabelMinWidth}>
								<FormLabel
									htmlFor='provincialTaxIncrease'
									pt={1.5}>
									Provincial Educational Tax Change
								</FormLabel>
							</Box>
							<Box>
								<InputGroup mr={isSmallerScreen ? 0 : 1}>
									<Input
										id='provincialTaxIncrease'
										type='number'
										min='0'
										step='any'
										placeholder='0.0'
										autoComplete='off'
										{...register("provincialTaxIncrease")}
									/>
									<InputRightElement
										children={
											<span>
												<FontAwesomeIcon icon='fas fa-percent' />
											</span>
										}
									/>
								</InputGroup>
							</Box>
						</Flex>
					</FormControl>

					<FormControl mt={isSmallerScreen ? 6 : 3}>
						<Flex direction={["column", "column", "row"]}>
							<Box
								minW={formLabelMinWidth}
								display={isSmallerScreen ? "none" : "normal"}>
								<FormLabel>&nbsp;</FormLabel>
							</Box>
							<Box>
								<Flex direction={["column", "column", "row"]}>
									<Button
										colorScheme='lightBlue'
										type={"submit"}>
										Estimate Tax
									</Button>
								</Flex>
							</Box>
						</Flex>
					</FormControl>

					{showCalculation ? (
						<Box
							mt={4}
							maxW={isSmallerScreen ? "100%" : 538}>
							<Box className={"estimator-total-border"}>
								<Flex direction={["row"]}>
									<Box pt={2}>Municipal Tax</Box>
									<Spacer></Spacer>
									<Box
										pt={2}
										textAlign={["right"]}>
										<Box fontWeight={700}>{CommonFns.FormatCurrency(municipalTotal, 2)}</Box>
									</Box>
								</Flex>
								<Flex direction={["row"]}>
									<Box pt={2}>Provincial Educational Tax</Box>
									<Spacer></Spacer>
									<Box
										pt={2}
										textAlign={["right"]}>
										<Box fontWeight={700}>{CommonFns.FormatCurrency(provincialTotal, 2)}</Box>
									</Box>
								</Flex>
							</Box>
							<Box className={"estimator-total-border"}>
								<Flex direction={["row"]}>
									<Box pt={2}>Estimated Property Taxes for {taxYear}</Box>
									<Spacer></Spacer>
									<Box
										pt={2}
										textAlign={["right"]}>
										<Box fontWeight={700}>{CommonFns.FormatCurrency(total, 2)}</Box>
									</Box>
								</Flex>
								<Flex direction={["row"]}>
									<Box pt={2}>Estimated Monthly Payment for {taxYear}</Box>
									<Spacer></Spacer>
									<Box
										pt={2}
										textAlign={["right"]}>
										<Box fontWeight={700}>{CommonFns.FormatCurrency(monthlyTotal, 2)}</Box>
									</Box>
								</Flex>
							</Box>
						</Box>
					) : null}
				</Box>

				<Box maxW={1050}>
					<Box
						w={"100%"}
						p={3}
						mt={1}>
						<Box w={"100%"}>
							<chakra.span fontWeight={700}>Step 1: </chakra.span>
							Confirm or adjust the assessment value of your property.
						</Box>
						<Box
							pt={3}
							w={"100%"}>
							<chakra.span fontWeight={700}>Step 2: </chakra.span>
							Adjust the municipal tax change and provincial education tax change—according to your best
							estimate. If you wish, you can view the{" "}
							<Link
								href={historyMuniChangesUrl}
								rel={"noopener"}
								target={"_blank"}>
								history of municipal and provincial education tax changes
							</Link>{" "}
							for reference.
						</Box>
						<Box
							pt={3}
							w={"100%"}>
							<chakra.span fontWeight={700}>Step 3: </chakra.span>
							Click Estimate Tax and review the estimate of your annual property tax bill.
						</Box>
					</Box>
				</Box>
			</form>
		</React.Fragment>
	);
};
export default Calculator;
