import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, chakra } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import AssessedValue from "./AssessedValue/AssessedValue";
import { AuditContext } from "../../../../../contexts/AuditContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropertyMap from "./PropertyMap/PropertyMap";
import PropertyOverview from "./PropertyOverview/PropertyOverview";
import ReviewBoard from "./ReviewBoard/ReviewBoard";
import SalesTransfers from "./SalesTransfers/SalesTransfers";
import { axiosMyPropertyAuth } from "../../../../../apis";

const Assessment = ({ accId, openTaxEstimator, openHistory, accountDetails }) => {
	const [assessmentDetails, setAssessmentDetails] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);

	const { auditActivity } = useContext(AuditContext);

	useEffect(() => {
		if (accId !== undefined) {
			getAssessmentDetails();
		}
	}, [accId]);

	const handleAssessmentSectionClick = (isExpanded, sectionName) => {
		if (isExpanded) {
			let activity = "Expand " + sectionName + " Section";
			auditActivity(activity, accId);
		}
	};

	const getAssessmentDetails = () => {
		setIsLoading(true);
		setIsError(false);

		let url = `assessment/${accId}`;
		axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				let returnObj = resp.data[0];
				setAssessmentDetails(returnObj);

				setIsLoading(false);
				setIsError(false);
			})
			.catch((error) => {
				// error
				setIsLoading(false);
				setIsError(true);
			});
	};
	return (
		<React.Fragment>
			<Accordion
				allowMultiple
				defaultIndex={[0]}>
				<AccordionItem className='accordion-item'>
					{({ isExpanded }) => (
						<React.Fragment>
							<AccordionButton
								className={`accordion-heading ${isExpanded ? "expanded" : ""}`}
								onClick={() => handleAssessmentSectionClick(!isExpanded, "Property Overview")}>
								<Box
									flex='1'
									textAlign='left'>
									<chakra.span
										fontWeight={700}
										fontSize={".95em"}>
										Property Overview
									</chakra.span>
								</Box>
								{isExpanded ? (
									<Box className='accordion-expand-icon-container expanded'>
										<FontAwesomeIcon icon='fa-solid fa-minus' />
									</Box>
								) : (
									<Box className='accordion-expand-icon-container collapsed'>
										<FontAwesomeIcon icon='fa-solid fa-plus' />
									</Box>
								)}
							</AccordionButton>
							<AccordionPanel
								pb={4}
								className='accordion-panel'>
								<Box pt={2}>
									<PropertyOverview
										accId={accId}
										openTaxEstimator={openTaxEstimator}
										openHistory={openHistory}
										assessmentDetails={assessmentDetails}
										accountDetails={accountDetails}
										isLoading={isLoading}
										isError={isError}></PropertyOverview>
								</Box>
							</AccordionPanel>
						</React.Fragment>
					)}
				</AccordionItem>
				<AccordionItem className='accordion-item'>
					{({ isExpanded }) => (
						<React.Fragment>
							<AccordionButton
								className={`accordion-heading ${isExpanded ? "expanded" : ""}`}
								onClick={() => handleAssessmentSectionClick(!isExpanded, "Assessed Value")}>
								<Box
									flex='1'
									textAlign='left'>
									<chakra.span
										fontWeight={700}
										fontSize={".95em"}>
										What Makes Up Your Property's Assessed Value
									</chakra.span>
								</Box>
								{isExpanded ? (
									<Box className='accordion-expand-icon-container expanded'>
										<FontAwesomeIcon icon='fa-solid fa-minus' />
									</Box>
								) : (
									<Box className='accordion-expand-icon-container collapsed'>
										<FontAwesomeIcon icon='fa-solid fa-plus' />
									</Box>
								)}
							</AccordionButton>
							<AccordionPanel
								pb={4}
								className='accordion-panel'>
								<Box pt={2}>
									<AssessedValue
										accId={accId}
										assessmentDetails={assessmentDetails}
										isLoading={isLoading}
										isError={isError}></AssessedValue>
								</Box>
							</AccordionPanel>
						</React.Fragment>
					)}
				</AccordionItem>
				<AccordionItem className='accordion-item'>
					{({ isExpanded }) => (
						<React.Fragment>
							<AccordionButton
								className={`accordion-heading ${isExpanded ? "expanded" : ""}`}
								onClick={() => handleAssessmentSectionClick(!isExpanded, "Maps")}>
								<Box
									flex='1'
									textAlign='left'>
									<chakra.span
										fontWeight={700}
										fontSize={".95em"}>
										Maps
									</chakra.span>
								</Box>
								{isExpanded ? (
									<Box className='accordion-expand-icon-container expanded'>
										<FontAwesomeIcon icon='fa-solid fa-minus' />
									</Box>
								) : (
									<Box className='accordion-expand-icon-container collapsed'>
										<FontAwesomeIcon icon='fa-solid fa-plus' />
									</Box>
								)}
							</AccordionButton>
							<AccordionPanel
								pb={4}
								className='accordion-panel grey'>
								<Box pt={2}>
									<PropertyMap accId={accId}></PropertyMap>
								</Box>
							</AccordionPanel>
						</React.Fragment>
					)}
				</AccordionItem>
				<AccordionItem className='accordion-item'>
					{({ isExpanded }) => (
						<React.Fragment>
							<AccordionButton
								className={`accordion-heading ${isExpanded ? "expanded" : ""}`}
								onClick={() => handleAssessmentSectionClick(!isExpanded, "Sales")}>
								<Box
									flex='1'
									textAlign='left'>
									<chakra.span
										fontWeight={700}
										fontSize={".95em"}>
										Property Sales and Transfers
									</chakra.span>
								</Box>
								{isExpanded ? (
									<Box className='accordion-expand-icon-container expanded'>
										<FontAwesomeIcon icon='fa-solid fa-minus' />
									</Box>
								) : (
									<Box className='accordion-expand-icon-container collapsed'>
										<FontAwesomeIcon icon='fa-solid fa-plus' />
									</Box>
								)}
							</AccordionButton>
							<AccordionPanel
								pb={4}
								className='accordion-panel grey'>
								<Box pt={2}>
									<SalesTransfers
										accId={accId}
										accountDetails={accountDetails}></SalesTransfers>
								</Box>
							</AccordionPanel>
						</React.Fragment>
					)}
				</AccordionItem>
				<AccordionItem className='accordion-item'>
					{({ isExpanded }) => (
						<React.Fragment>
							<AccordionButton
								className={`accordion-heading ${isExpanded ? "expanded" : ""}`}
								onClick={() => handleAssessmentSectionClick(!isExpanded, "Review Board")}>
								<Box
									flex='1'
									textAlign='left'>
									<chakra.span
										fontWeight={700}
										fontSize={".95em"}>
										Steps to Take if You Don't Agree With Your Assessment
									</chakra.span>
								</Box>
								{isExpanded ? (
									<Box className='accordion-expand-icon-container expanded'>
										<FontAwesomeIcon icon='fa-solid fa-minus' />
									</Box>
								) : (
									<Box className='accordion-expand-icon-container collapsed'>
										<FontAwesomeIcon icon='fa-solid fa-plus' />
									</Box>
								)}
							</AccordionButton>
							<AccordionPanel
								pb={4}
								className='accordion-panel blue'>
								<Box pt={2}>
									<ReviewBoard
										accId={accId}
										assessmentDetails={assessmentDetails}></ReviewBoard>
								</Box>
							</AccordionPanel>
						</React.Fragment>
					)}
				</AccordionItem>
			</Accordion>
		</React.Fragment>
	);
};

export default Assessment;
