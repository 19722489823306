import "./Home.css";

import { Box, Flex, Spacer } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";

import Agent from "./Agent/Agent";
import { AuthContext } from "../../contexts/AuthContext";
import Features from "./Features/Features";
import Markdown from "../Global/Markdown/Markdown";
import PropertyOwner from "./PropertyOwner/PropertyOwner";
import { useNavigate } from "react-router-dom";

const Home = ({ siteClosure, siteClosureMessage }) => {
	const { authDetails } = useContext(AuthContext);
	let navigate = useNavigate();

	useEffect(() => {
		if (authDetails.isAuthenticated) {
			navigate("/auth/property", { replace: true });
		}
	}, []);

	return (
		<React.Fragment>
			{siteClosure === "Y" ? (
				<Flex>
					<Spacer />
					<Box
						className='announcement announcement-light-blue'
						w={"75%"}>
						<Box
							maxW={1050}
							pb={5}>
							<Markdown text={siteClosureMessage}></Markdown>
						</Box>
					</Box>
					<Spacer />
				</Flex>
			) : (
				<Box
					maxWidth={826}
					margin={"0 auto"}>
					<PropertyOwner></PropertyOwner>
					<Agent></Agent>
					<Features></Features>
				</Box>
			)}
		</React.Fragment>
	);
};

export default Home;
