import "./Summary.css";

import * as CommonFns from "../../../../../../../common";

import { Box, Table, Tbody, Td, Th, Thead, Tr, useBreakpointValue } from "@chakra-ui/react";

import React from "react";

const YourPropertyTaxesSummary = ({
	notice,
	isAnnual,
	taxYear,
	annualTaxesTotal,
	supplementaryTaxesTotal,
	taxesTotal,
	accountDetails,
}) => {
	const isSmallerScreen = useBreakpointValue({ base: true, lg: false });
	return (
		<React.Fragment>
			{notice.taxGroupCol !== null ? (
				<Box
					w={isSmallerScreen ? "100%" : "60%"}
					pr={isSmallerScreen ? 3 : 0}>
					<Table className='your-property-taxes-table'>
						<Thead>
							<Tr>
								<Th>Description</Th>
								<Th></Th>
								<Th width={20}></Th>
								<Th isNumeric>Tax Rate</Th>
								<Th isNumeric>Amount</Th>
							</Tr>
						</Thead>
						<Tbody>
							{notice.taxGroupCol.map((group, index) => (
								<React.Fragment key={index}>
									{group.taxGroupItemCol.map((groupItem, index) => (
										<React.Fragment key={index}>
											<Tr>
												<Td
													colSpan={5}
													className='col-title'>
													{groupItem.itemTitle}
												</Td>
											</Tr>
											{groupItem.taxGroupItemDtlsCol.map((groupItemDetail, index) => (
												<React.Fragment key={index}>
													<Tr>
														<Td
															colSpan={3}
															className='data-title-pad'>
															{groupItemDetail.itemDesc}
														</Td>
														<Td isNumeric>
															{groupItemDetail.rate !== null
																? groupItemDetail.rate.toFixed(7)
																: null}
														</Td>
														<Td isNumeric>
															{CommonFns.FormatCurrency(
																groupItemDetail.transactionAmount,
																2
															)}
														</Td>
													</Tr>
												</React.Fragment>
											))}
										</React.Fragment>
									))}

									<Tr className='row-sub-total'>
										<Td></Td>
										<Td></Td>
										<Td
											isNumeric
											className='data-sub-total'>
											Total
										</Td>
										<Td
											isNumeric
											className='data-sub-total'></Td>
										<Td
											isNumeric
											className='data-sub-total'>
											{CommonFns.FormatCurrency(group.groupTotal, 2)}
										</Td>
									</Tr>
								</React.Fragment>
							))}
							{!isAnnual ? (
								<React.Fragment>
									<Tr className='row-spacer'>
										<Td colSpan={5}></Td>
									</Tr>
									<Tr className='row-total'>
										<Td></Td>
										<Td
											isNumeric
											colSpan={2}>
											Annual Property Taxes
										</Td>
										<Td
											isNumeric
											colSpan={4}>
											{CommonFns.FormatCurrency(annualTaxesTotal, 2)}
										</Td>
									</Tr>
									<Tr className='row-total'>
										<Td></Td>
										<Td
											isNumeric
											colSpan={2}>
											Supplementary Property Taxes
										</Td>
										<Td
											isNumeric
											colSpan={4}>
											{CommonFns.FormatCurrency(supplementaryTaxesTotal, 2)}
										</Td>
									</Tr>
								</React.Fragment>
							) : null}
							<Tr className='row-spacer'>
								<Td colSpan={5}></Td>
							</Tr>
							<Tr className='row-total'>
								<Td></Td>
								<Td
									isNumeric
									colSpan={2}>
									Total {taxYear} Property Taxes
								</Td>
								<Td
									isNumeric
									colSpan={2}>
									{CommonFns.FormatCurrency(isAnnual ? notice.transactionAmountTotal : taxesTotal, 2)}
								</Td>
							</Tr>
							{accountDetails.mppMessage !== null ? (
								accountDetails.mppMessage.trim() !== "" ? (
									<React.Fragment>
										<Tr className='row-spacer'>
											<Td colSpan={5}></Td>
										</Tr>
										<Tr className='row-mpp'>
											<Td colSpan={5}>{accountDetails.mppMessage}</Td>
										</Tr>
									</React.Fragment>
								) : null
							) : null}
						</Tbody>
					</Table>
				</Box>
			) : null}
		</React.Fragment>
	);
};

export default YourPropertyTaxesSummary;
