import "./Features.css";

import { Box, Table } from "@chakra-ui/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const Features = () => {
	return (
		<React.Fragment>
			<Box className='feature-container'>
				<Table className='features-table'>
					<thead>
						<tr>
							<th>What can I see?</th>
							<th className='center'>MyProperty Access</th>
							<th className='center'>One-Time Access</th>
							<th className='center'>Public Access</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Property assessed value</td>
							<td className='center icon'>
								<FontAwesomeIcon icon={faCheck} />
							</td>
							<td className='center icon'>
								<FontAwesomeIcon icon={faCheck} />
							</td>
							<td className='center icon'>
								<FontAwesomeIcon icon={faCheck} />
							</td>
						</tr>
						<tr>
							<td>Property assessment details</td>
							<td className='center icon'>
								<FontAwesomeIcon icon={faCheck} />
							</td>
							<td className='center icon'>
								<FontAwesomeIcon icon={faCheck} />
							</td>
							<td className='center'></td>
						</tr>
						<tr>
							<td>Comparable sales information</td>
							<td className='center icon'>
								<FontAwesomeIcon icon={faCheck} />
							</td>
							<td className='center icon'>
								<FontAwesomeIcon icon={faCheck} />
							</td>
							<td className='center'></td>
						</tr>
						<tr>
							<td>Assessment and tax notices</td>
							<td className='center icon'>
								<FontAwesomeIcon icon={faCheck} />
							</td>
							<td className='center icon'>Redacted Notices</td>
							<td className='center'></td>
						</tr>
						<tr>
							<td>Sign up for monthly payments</td>
							<td className='center icon'>
								<FontAwesomeIcon icon={faCheck} />
							</td>
							<td className='center'></td>
							<td className='center'></td>
						</tr>
						<tr>
							<td>Tax account balance</td>
							<td className='center icon'>
								<FontAwesomeIcon icon={faCheck} />
							</td>
							<td className='center'></td>
							<td className='center'></td>
						</tr>
						<tr>
							<td>Search for comparable accounts</td>
							<td className='center icon'>
								<FontAwesomeIcon icon={faCheck} />
							</td>
							<td className='center'></td>
							<td className='center'></td>
						</tr>
					</tbody>
				</Table>
			</Box>
		</React.Fragment>
	);
};

export default Features;
