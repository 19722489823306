import "./LegalText.css";

import * as toastMessages from "../../../toastMessages";

import { Box, Skeleton, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import Markdown from "../../Global/Markdown/Markdown";
import { axiosMyProperty } from "../../../apis";

const LegalText = ({ displayMessageTypes }) => {
	// displayMessageTypes - prop
	// provide any of the following in an array [] to be displayed, otherwise ignored
	// 'WEB PRIVACY TERMS',
	// 'WEB USE OF INFO DISCLAIMER',
	// 'WEB ELECTRONIC NOTIFICATION PREFS',
	// 'WEB USER AGREEMENT',
	// 'WEB USER AGREEMENT2',
	// 'WEB ELECTRONIC COMMUNICATIONS PRIVACY TERMS'
	// 'AGENT_REPRESENTATIVE_AUTHORIZATION_TERMS'

	const toast = useToast();
	const [legalText, setLegalText] = useState();
	const legalTextToastErrorId = "legal-text-toast-error";

	useEffect(() => {
		getLegalText();
	}, [displayMessageTypes]);

	const getLegalText = () => {
		let url = "legaltext";

		axiosMyProperty
			.get(url)
			.then((resp) => {
				filterLegalText(resp.data.LegalTextMessageDto);
			})
			.catch((error) => {
				// error
				if (!toast.isActive(legalTextToastErrorId)) {
					toast(toastMessages.getToastError(legalTextToastErrorId));
				}
			});
	};

	const filterLegalText = (messages) => {
		let filteredMessages = [""];

		if (displayMessageTypes !== undefined) {
			filteredMessages = messages.filter((msg) => displayMessageTypes.includes(msg.Type));
			setLegalText(filteredMessages);
		} else {
			setLegalText(messages);
		}
	};

	return (
		<React.Fragment>
			{legalText !== undefined ? (
				legalText.map((item, index) => (
					<Box key={index} className='legal-text-container'>
						<Box fontWeight={700} mb={1}>
							{item.Title}
						</Box>
						<Markdown text={item.Message}></Markdown>
					</Box>
				))
			) : (
				<React.Fragment>
					<Box>
						<Skeleton startColor='#e2e2e2' endColor='#bbbbbb' size='8' width={540} height={30}></Skeleton>
						<Skeleton mt={2} height={250} startColor='#e2e2e2' endColor='#bbbbbb'></Skeleton>
					</Box>
					<Box mt={4}>
						<Skeleton startColor='#e2e2e2' endColor='#bbbbbb' size='8' width={540} height={30}></Skeleton>
						<Skeleton mt={2} height={250} startColor='#e2e2e2' endColor='#bbbbbb'></Skeleton>
					</Box>
					<Box mt={4}>
						<Skeleton startColor='#e2e2e2' endColor='#bbbbbb' size='8' width={540} height={30}></Skeleton>
						<Skeleton mt={2} height={250} startColor='#e2e2e2' endColor='#bbbbbb'></Skeleton>
					</Box>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

export default LegalText;
