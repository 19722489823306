import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, chakra } from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";

import { AuditContext } from "../../../contexts/AuditContext";
import { AuthContext } from "../../../contexts/AuthContext";
import ChangePassword from "./ChangePassword/ChangePassword";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotificationPreferences from "./NotificationPreferences/NotificationPreferences";
import ProfileInformation from "./ProfileInformation/ProfileInformation";
import ShareYourInformation from "./ShareYourInformation/ShareYourInformation";
import { useLocation } from "react-router-dom";

const Profile = ({ userProfile, getUserProfile, managePropertyAndMail, handleLogout }) => {
	const { resetAuthAccountNumber, checkEmailUpdate } = useContext(AuthContext);
	const [selectedIndex, setSelectedIndex] = useState([0]);
	const [smsPhoneNum, setSmsPhoneNum] = useState();
	const { auditActivity } = useContext(AuditContext);

	const location = useLocation();

	const section = new URLSearchParams(location.search).get("sec");

	const npTop = useRef(null);

	useEffect(() => {
		resetAuthAccountNumber();
		if (userProfile === undefined) {
			getUserProfile();
		}

		if (section === "np") {
			//open Notification Preferences panel
			setSelectedIndex([2]);
			setTimeout(() => {
				npTop?.current.scrollIntoView({ behavior: "smooth" });
			}, 200);
		}
	}, []);

	useEffect(() => {
		//setUserProfile(resp.data);
		if (userProfile !== undefined) {
			setSmsPhoneNum(userProfile.smsPhoneNum);
		}
	}, [userProfile]);

	const onPhoneNumberModified = () => {
		getUserProfile();
	};

	const handleProfileSectionClick = (isExpanded, sectionName) => {
		checkEmailUpdate();
		if (isExpanded) {
			let activity = "Expand " + sectionName + " Section";
			auditActivity(activity);
		}
	};

	const onMailPreferencesSave = () => {
		getUserProfile();
	};

	const handlePanelChange = (index) => {
		setSelectedIndex(index);
	};

	return (
		<React.Fragment>
			<Accordion index={selectedIndex} onChange={handlePanelChange} allowMultiple>
				<AccordionItem className='accordion-item'>
					{({ isExpanded }) => (
						<React.Fragment>
							<AccordionButton
								className={`accordion-heading ${isExpanded ? "expanded" : ""}`}
								onClick={() => handleProfileSectionClick(!isExpanded, "Profile Information")}>
								<Box flex='1' textAlign='left'>
									<chakra.span fontWeight={700} fontSize={".95em"}>
										Profile Information
									</chakra.span>
								</Box>
								{isExpanded ? (
									<Box className='accordion-expand-icon-container expanded'>
										<FontAwesomeIcon icon='fa-solid fa-minus' />
									</Box>
								) : (
									<Box className='accordion-expand-icon-container collapsed'>
										<FontAwesomeIcon icon='fa-solid fa-plus' />
									</Box>
								)}
							</AccordionButton>
							<AccordionPanel pb={4} className='accordion-panel' borderTop={"solid 1px red"}>
								<ProfileInformation
									userProfile={userProfile}
									handleLogout={handleLogout}></ProfileInformation>
							</AccordionPanel>
						</React.Fragment>
					)}
				</AccordionItem>
				<AccordionItem className='accordion-item'>
					{({ isExpanded }) => (
						<React.Fragment>
							<AccordionButton
								className={`accordion-heading ${isExpanded ? "expanded" : ""}`}
								onClick={() => handleProfileSectionClick(!isExpanded, "Change Password")}>
								<Box flex='1' textAlign='left'>
									<chakra.span fontWeight={700} fontSize={".95em"}>
										Change Your Password
									</chakra.span>
								</Box>
								{isExpanded ? (
									<Box className='accordion-expand-icon-container expanded'>
										<FontAwesomeIcon icon='fa-solid fa-minus' />
									</Box>
								) : (
									<Box className='accordion-expand-icon-container collapsed'>
										<FontAwesomeIcon icon='fa-solid fa-plus' />
									</Box>
								)}
							</AccordionButton>
							<AccordionPanel pb={4} className='accordion-panel' borderTop={"solid 1px red"}>
								<ChangePassword></ChangePassword>
							</AccordionPanel>
						</React.Fragment>
					)}
				</AccordionItem>
				<AccordionItem className='accordion-item' ref={npTop}>
					{({ isExpanded }) => (
						<React.Fragment>
							<AccordionButton
								className={`accordion-heading ${isExpanded ? "expanded" : ""}`}
								onClick={() => handleProfileSectionClick(!isExpanded, "Notification Preferences")}>
								<Box flex='1' textAlign='left'>
									<chakra.span fontWeight={700} fontSize={".95em"}>
										Notification Preferences
									</chakra.span>
								</Box>
								{isExpanded ? (
									<Box className='accordion-expand-icon-container expanded'>
										<FontAwesomeIcon icon='fa-solid fa-minus' />
									</Box>
								) : (
									<Box className='accordion-expand-icon-container collapsed'>
										<FontAwesomeIcon icon='fa-solid fa-plus' />
									</Box>
								)}
							</AccordionButton>
							<AccordionPanel pb={4} className='accordion-panel' borderTop={"solid 1px red"}>
								<NotificationPreferences
									smsPhoneNum={smsPhoneNum}
									onPhoneNumberModified={onPhoneNumberModified}
									onMailPreferencesSave={onMailPreferencesSave}
									managePropertyAndMail={managePropertyAndMail}
									handleLogout={handleLogout}></NotificationPreferences>
							</AccordionPanel>
						</React.Fragment>
					)}
				</AccordionItem>
				<AccordionItem className='accordion-item'>
					{({ isExpanded }) => (
						<React.Fragment>
							<AccordionButton
								className={`accordion-heading ${isExpanded ? "expanded" : ""}`}
								onClick={() => handleProfileSectionClick(!isExpanded, "Share Your Information")}>
								<Box flex='1' textAlign='left'>
									<chakra.span fontWeight={700} fontSize={".95em"}>
										Share Your Information
									</chakra.span>
								</Box>
								{isExpanded ? (
									<Box className='accordion-expand-icon-container expanded'>
										<FontAwesomeIcon icon='fa-solid fa-minus' />
									</Box>
								) : (
									<Box className='accordion-expand-icon-container collapsed'>
										<FontAwesomeIcon icon='fa-solid fa-plus' />
									</Box>
								)}
							</AccordionButton>
							<AccordionPanel pb={4} className='accordion-panel' borderTop={"solid 1px red"}>
								<ShareYourInformation></ShareYourInformation>
							</AccordionPanel>
						</React.Fragment>
					)}
				</AccordionItem>
			</Accordion>
		</React.Fragment>
	);
};

export default Profile;
