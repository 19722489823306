import * as toastMessages from "../../../toastMessages";

import {
	Box,
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	chakra,
	useBreakpointValue,
	useMediaQuery,
	useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { axiosMyProperty } from "../../../apis";
import { useForm } from "react-hook-form";

const ForgotPasswordRequest = () => {
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });
	const [isLargerThan480] = useMediaQuery("(min-width: 480px)");
	const [showSuccess, setShowSuccess] = useState(false);

	const forgotPasswordRequestToastErrorId = "forgot-password-request-toast-error";

	let navigate = useNavigate();
	const location = useLocation();

	const toast = useToast();

	const formLabelMinWidth = 125;
	const formControlMinWidth = 405;

	const emailParam = new URLSearchParams(location.search).get("emailAddress");

	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = useForm({
		defaultValues: {
			emailAddress: emailParam ?? "",
		},
	});

	const onSubmit = async (data) => {
		let url = `account/password/request/${data.emailAddress}`;
		await axiosMyProperty
			.get(url)
			.then((resp) => {
				setShowSuccess(true);
			})
			.catch((error) => {
				// error
				if (!toast.isActive(forgotPasswordRequestToastErrorId)) {
					toast(toastMessages.getToastError(forgotPasswordRequestToastErrorId));
				}
			});
	};

	const handleSignIn = (event) => {
		event.preventDefault();
		navigate("/sign-in", { replace: true });
	};

	return (
		<React.Fragment>
			<FormControl>
				<Flex direction={["column", "column", "row"]}>
					<Box
						minW={formLabelMinWidth}
						display={isSmallerScreen ? "none" : "normal"}>
						<FormLabel>&nbsp;</FormLabel>
					</Box>
					<Box>
						<chakra.span
							fontSize={"1.45em"}
							fontWeight={400}>
							Forgot Password
						</chakra.span>
					</Box>
				</Flex>
			</FormControl>
			{!showSuccess ? (
				<form onSubmit={handleSubmit(async (data) => await onSubmit(data))}>
					<FormControl
						isInvalid={errors.emailAddress}
						mt={2}>
						<Flex direction={["column", "column", "row"]}>
							<Box minW={formLabelMinWidth}>
								<FormLabel
									htmlFor='emailAddress'
									textAlign={isSmallerScreen ? "" : "end"}
									className='form-label-fixed'>
									Your Email
								</FormLabel>
							</Box>
							<Box minW={!isLargerThan480 ? "" : formControlMinWidth}>
								<Input
									id='emailAddress'
									type='email'
									autoComplete='off'
									{...register("emailAddress", {
										required: "Your Email is required",
										pattern: {
											value: /^[\w.-]+@([\w-]+\.)+[\w-]{2,}$/g,
											message: "Your Email is invalid",
										},
									})}
								/>
							</Box>
							<Box ml={isSmallerScreen ? 0 : 3}>
								<FormErrorMessage
									colorScheme={"red"}
									className={"text-error pos"}>
									{errors.emailAddress && errors.emailAddress.message}
								</FormErrorMessage>
							</Box>
						</Flex>
					</FormControl>

					<FormControl mt={isSmallerScreen ? 6 : 3}>
						<Flex direction={["column", "column", "row"]}>
							<Box
								minW={formLabelMinWidth}
								display={isSmallerScreen ? "none" : "normal"}>
								<FormLabel>&nbsp;</FormLabel>
							</Box>
							<Box>
								<Flex direction={["column", "column", "row"]}>
									<Button
										isLoading={isSubmitting}
										loadingText={"SUBMIT"}
										colorScheme='blue'
										type={"submit"}
										width={isSmallerScreen ? "100%" : 100}
										mr={isSmallerScreen ? 0 : 4}>
										SUBMIT
									</Button>
									<Button
										colorScheme='grey'
										variant='ghost'
										mt={isSmallerScreen ? 4 : 0}
										onClick={handleSignIn}>
										Sign In
									</Button>
								</Flex>
							</Box>
						</Flex>
					</FormControl>
				</form>
			) : (
				<FormControl mt={isSmallerScreen ? 6 : 3}>
					<Flex direction={["column", "column", "row"]}>
						<Box
							minW={formLabelMinWidth}
							display={isSmallerScreen ? "none" : "normal"}>
							<FormLabel>&nbsp;</FormLabel>
						</Box>
						<Box>
							<Box mb={3}>We've just sent a password reset email to the email address you provided.</Box>
							<Box mb={3}>Please check your inbox to complete the reset process.</Box>
						</Box>
					</Flex>
				</FormControl>
			)}
		</React.Fragment>
	);
};

export default ForgotPasswordRequest;
