import "./Calculator.css";

import {
	Box,
	Flex,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MultiUseMunicipalLabel from "./MultiUseMunicipalLabel";
import MultiUsePropertyUseLabel from "./MultiUsePropertyUseLabel";
import MultiUseProvincialLabel from "./MultiUseProvincialLabel";

const MultiUseCalculator = ({ register, setValue, multiUsetaxRates, multiUse100Error }) => {
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });

	const assessmentClassHeadingWidth = 126;
	const assessmentClassLabelWidth = 270;
	const assessmentClassTitleMarginTopFirst = 4;
	const assessmentClassTitleMarginTopFirstResidential = 0;
	const assessmentClassTitleMarginTop = 4;
	const assessmentClassTitleFontSize = "1em";

	const assessmentClassValueMarginTopFirst = 0;
	const assessmentClassValueMarginTop = 2;
	const assessmentClassValueMarginRight = 3;

	useEffect(() => {
		if (multiUsetaxRates?.length > 0) {
			setValue("resPropertyUse", multiUsetaxRates.multiuseResAsmtPct);
			setValue("resTaxStatusMuni", multiUsetaxRates.multiuseResTaxSt);
			setValue("resTaxStatusSchl", multiUsetaxRates.multiuseResTaxStSchl);

			setValue("nonPropertyUse", multiUsetaxRates.multiuseNonresAsmtPct);
			setValue("nonTaxStatusMuni", multiUsetaxRates.multiuseNonresTaxSt);
			setValue("nonTaxStatusSchl", multiUsetaxRates.multiuseNonresTaxStSchl);

			setValue("othPropertyUse", multiUsetaxRates.multiuseOrAsmtPct);
			setValue("othTaxStatusMuni", multiUsetaxRates.multiuseOrTaxSt);
			setValue("othTaxStatusSchl", multiUsetaxRates.multiuseOrTaxStSchl);

			setValue("farPropertyUse", multiUsetaxRates.multiuseFlAsmtPct);
			setValue("farTaxStatusMuni", multiUsetaxRates.multiuseFlTaxSt);
			setValue("farTaxStatusSchl", multiUsetaxRates.multiuseFlTaxStSchl);
		}
	}, [multiUsetaxRates, setValue]);

	return (
		<React.Fragment>
			<Box>
				<FormControl pt={2}>
					<Flex direction={["column", "column", "row"]}>
						<Box minW={assessmentClassLabelWidth}>
							<FormLabel fontWeight={700}>Assessment Class</FormLabel>
						</Box>
						<Box
							display={isSmallerScreen ? "none" : "normal"}
							w={assessmentClassHeadingWidth}
							mr={assessmentClassValueMarginRight}>
							<FormLabel fontWeight={700}>Property Use</FormLabel>
						</Box>
						<Box
							display={isSmallerScreen ? "none" : "normal"}
							w={assessmentClassHeadingWidth}
							mr={assessmentClassValueMarginRight}>
							<FormLabel fontWeight={700}>
								<Box>Taxable Status</Box>
								<Box className={"estimator-sub-head"}>Municipal</Box>
							</FormLabel>
						</Box>
						<Box display={isSmallerScreen ? "none" : "normal"}>
							<FormLabel fontWeight={700}>
								<Box>&nbsp;</Box>
								<Box className={"estimator-sub-head"}>Provincial education</Box>
							</FormLabel>
						</Box>
					</Flex>
				</FormControl>
				<FormControl>
					<Flex direction={["column", "column", "row"]}>
						<Box minW={assessmentClassLabelWidth}>
							<FormLabel
								mt={
									isSmallerScreen
										? assessmentClassTitleMarginTopFirstResidential
										: assessmentClassTitleMarginTop
								}
								fontWeight={700}
								fontSize={assessmentClassTitleFontSize}
								htmlFor='resPropertyUse'>
								Residential
							</FormLabel>
						</Box>
						<Box
							mt={isSmallerScreen ? assessmentClassValueMarginTopFirst : assessmentClassValueMarginTop}
							mr={assessmentClassValueMarginRight}>
							<Flex direction={["row"]}>
								<Box>
									<InputGroup w={assessmentClassHeadingWidth}>
										<Input
											id='resPropertyUse'
											type='number'
											min='0'
											step='any'
											placeholder='0.0'
											autoComplete='off'
											{...register("resPropertyUse")}
										/>
										<InputRightElement
											children={
												<span>
													<FontAwesomeIcon icon='fas fa-percent' />
												</span>
											}
										/>
									</InputGroup>
								</Box>
								<Box display={{ sm: "normal", md: "none" }}>
									<MultiUsePropertyUseLabel></MultiUsePropertyUseLabel>
								</Box>
							</Flex>
						</Box>
						<Box
							mt={assessmentClassValueMarginTop}
							mr={assessmentClassValueMarginRight}>
							<Flex direction={["row"]}>
								<Box>
									<InputGroup w={assessmentClassHeadingWidth}>
										<Input
											id='resTaxStatusMuni'
											type='number'
											min='0'
											step='any'
											placeholder='0.0'
											autoComplete='off'
											{...register("resTaxStatusMuni")}
										/>
										<InputRightElement
											children={
												<span>
													<FontAwesomeIcon icon='fas fa-percent' />
												</span>
											}
										/>
									</InputGroup>
								</Box>
								<Box display={{ sm: "normal", md: "none" }}>
									<MultiUseMunicipalLabel></MultiUseMunicipalLabel>
								</Box>
							</Flex>
						</Box>
						<Box mt={assessmentClassValueMarginTop}>
							<Flex direction={["row"]}>
								<Box>
									<InputGroup w={assessmentClassHeadingWidth}>
										<Input
											id='resTaxStatusSchl'
											type='number'
											min='0'
											step='any'
											placeholder='0.0'
											autoComplete='off'
											{...register("resTaxStatusSchl")}
										/>
										<InputRightElement
											children={
												<span>
													<FontAwesomeIcon icon='fas fa-percent' />
												</span>
											}
										/>
									</InputGroup>
								</Box>
								<Box display={{ sm: "normal", md: "none" }}>
									<MultiUseProvincialLabel></MultiUseProvincialLabel>
								</Box>
							</Flex>
						</Box>
					</Flex>
				</FormControl>
				<FormControl>
					<Flex direction={["column", "column", "row"]}>
						<Box minW={assessmentClassLabelWidth}>
							<FormLabel
								mt={
									isSmallerScreen ? assessmentClassTitleMarginTopFirst : assessmentClassTitleMarginTop
								}
								fontWeight={700}
								fontSize={assessmentClassTitleFontSize}
								htmlFor='nonPropertyUse'>
								Non-residential
							</FormLabel>
						</Box>
						<Box
							mt={isSmallerScreen ? assessmentClassValueMarginTopFirst : assessmentClassValueMarginTop}
							mr={assessmentClassValueMarginRight}>
							<Flex direction={["row"]}>
								<Box>
									<InputGroup w={assessmentClassHeadingWidth}>
										<Input
											id='nonPropertyUse'
											type='number'
											min='0'
											step='any'
											placeholder='0.0'
											autoComplete='off'
											{...register("nonPropertyUse")}
										/>
										<InputRightElement
											children={
												<span>
													<FontAwesomeIcon icon='fas fa-percent' />
												</span>
											}
										/>
									</InputGroup>
								</Box>
								<Box display={{ sm: "normal", md: "none" }}>
									<MultiUsePropertyUseLabel></MultiUsePropertyUseLabel>
								</Box>
							</Flex>
						</Box>
						<Box
							mt={assessmentClassValueMarginTop}
							mr={assessmentClassValueMarginRight}>
							<Flex direction={["row"]}>
								<Box>
									<InputGroup w={assessmentClassHeadingWidth}>
										<Input
											id='nonresTaxStatusMuni'
											type='number'
											min='0'
											step='any'
											placeholder='0.0'
											autoComplete='off'
											{...register("nonTaxStatusMuni")}
										/>
										<InputRightElement
											children={
												<span>
													<FontAwesomeIcon icon='fas fa-percent' />
												</span>
											}
										/>
									</InputGroup>
								</Box>
								<Box display={{ sm: "normal", md: "none" }}>
									<MultiUseMunicipalLabel></MultiUseMunicipalLabel>
								</Box>
							</Flex>
						</Box>
						<Box mt={assessmentClassValueMarginTop}>
							<Flex direction={["row"]}>
								<Box>
									<InputGroup w={assessmentClassHeadingWidth}>
										<Input
											id='nonTaxStatusSchl'
											type='number'
											min='0'
											step='any'
											placeholder='0.0'
											autoComplete='off'
											{...register("nonTaxStatusSchl")}
										/>
										<InputRightElement
											children={
												<span>
													<FontAwesomeIcon icon='fas fa-percent' />
												</span>
											}
										/>
									</InputGroup>
								</Box>
								<Box display={{ sm: "normal", md: "none" }}>
									<MultiUseProvincialLabel></MultiUseProvincialLabel>
								</Box>
							</Flex>
						</Box>
					</Flex>
				</FormControl>
				<FormControl>
					<Flex direction={["column", "column", "row"]}>
						<Box minW={assessmentClassLabelWidth}>
							<FormLabel
								mt={
									isSmallerScreen ? assessmentClassTitleMarginTopFirst : assessmentClassTitleMarginTop
								}
								fontWeight={700}
								fontSize={assessmentClassTitleFontSize}
								htmlFor='othPropertyUse'>
								Other residential
							</FormLabel>
						</Box>
						<Box
							mt={isSmallerScreen ? assessmentClassValueMarginTopFirst : assessmentClassValueMarginTop}
							mr={assessmentClassValueMarginRight}>
							<Flex direction={["row"]}>
								<Box>
									<InputGroup w={assessmentClassHeadingWidth}>
										<Input
											id='othPropertyUse'
											type='number'
											min='0'
											step='any'
											placeholder='0.0'
											autoComplete='off'
											{...register("othPropertyUse")}
										/>
										<InputRightElement
											children={
												<span>
													<FontAwesomeIcon icon='fas fa-percent' />
												</span>
											}
										/>
									</InputGroup>
								</Box>
								<Box display={{ sm: "normal", md: "none" }}>
									<MultiUsePropertyUseLabel></MultiUsePropertyUseLabel>
								</Box>
							</Flex>
						</Box>
						<Box
							mt={assessmentClassValueMarginTop}
							mr={assessmentClassValueMarginRight}>
							<Flex direction={["row"]}>
								<Box>
									<InputGroup w={assessmentClassHeadingWidth}>
										<Input
											id='othTaxStatusMuni'
											type='number'
											min='0'
											step='any'
											placeholder='0.0'
											autoComplete='off'
											{...register("othTaxStatusMuni")}
										/>
										<InputRightElement
											children={
												<span>
													<FontAwesomeIcon icon='fas fa-percent' />
												</span>
											}
										/>
									</InputGroup>
								</Box>
								<Box display={{ sm: "normal", md: "none" }}>
									<MultiUseMunicipalLabel></MultiUseMunicipalLabel>
								</Box>
							</Flex>
						</Box>
						<Box mt={assessmentClassValueMarginTop}>
							<Flex direction={["row"]}>
								<Box>
									<InputGroup w={assessmentClassHeadingWidth}>
										<Input
											id='othTaxStatusSchl'
											type='number'
											min='0'
											step='any'
											placeholder='0.0'
											autoComplete='off'
											{...register("othTaxStatusSchl")}
										/>
										<InputRightElement
											children={
												<span>
													<FontAwesomeIcon icon='fas fa-percent' />
												</span>
											}
										/>
									</InputGroup>
								</Box>
								<Box display={{ sm: "normal", md: "none" }}>
									<MultiUseProvincialLabel></MultiUseProvincialLabel>
								</Box>
							</Flex>
						</Box>
					</Flex>
				</FormControl>
				<FormControl>
					<Flex direction={["column", "column", "row"]}>
						<Box minW={assessmentClassLabelWidth}>
							<FormLabel
								mt={
									isSmallerScreen ? assessmentClassTitleMarginTopFirst : assessmentClassTitleMarginTop
								}
								fontWeight={700}
								fontSize={assessmentClassTitleFontSize}
								htmlFor='farPropertyUse'>
								Farmland and agricultural
							</FormLabel>
						</Box>
						<Box
							mt={isSmallerScreen ? assessmentClassValueMarginTopFirst : assessmentClassValueMarginTop}
							mr={assessmentClassValueMarginRight}>
							<Flex direction={["row"]}>
								<Box>
									<InputGroup w={assessmentClassHeadingWidth}>
										<Input
											id='farPropertyUse'
											type='number'
											min='0'
											step='any'
											placeholder='0.0'
											autoComplete='off'
											{...register("farPropertyUse")}
										/>
										<InputRightElement
											children={
												<span>
													<FontAwesomeIcon icon='fas fa-percent' />
												</span>
											}
										/>
									</InputGroup>
								</Box>
								<Box display={{ sm: "normal", md: "none" }}>
									<MultiUsePropertyUseLabel></MultiUsePropertyUseLabel>
								</Box>
							</Flex>
						</Box>
						<Box
							mt={assessmentClassValueMarginTop}
							mr={assessmentClassValueMarginRight}>
							<Flex direction={["row"]}>
								<Box>
									<InputGroup w={assessmentClassHeadingWidth}>
										<Input
											id='farTaxStatusMuni'
											type='number'
											min='0'
											step='any'
											placeholder='0.0'
											autoComplete='off'
											{...register("farTaxStatusMuni")}
										/>
										<InputRightElement
											children={
												<span>
													<FontAwesomeIcon icon='fas fa-percent' />
												</span>
											}
										/>
									</InputGroup>
								</Box>
								<Box display={{ sm: "normal", md: "none" }}>
									<MultiUseMunicipalLabel></MultiUseMunicipalLabel>
								</Box>
							</Flex>
						</Box>
						<Box mt={assessmentClassValueMarginTop}>
							<Flex direction={["row"]}>
								<Box>
									<InputGroup w={assessmentClassHeadingWidth}>
										<Input
											id='farTaxStatusSchl'
											type='number'
											min='0'
											step='any'
											placeholder='0.0'
											autoComplete='off'
											{...register("farTaxStatusSchl")}
										/>
										<InputRightElement
											children={
												<span>
													<FontAwesomeIcon icon='fas fa-percent' />
												</span>
											}
										/>
									</InputGroup>
								</Box>
								<Box display={{ sm: "normal", md: "none" }}>
									<MultiUseProvincialLabel></MultiUseProvincialLabel>
								</Box>
							</Flex>
						</Box>
					</Flex>
				</FormControl>
				<FormControl>
					<Flex direction={["column", "column", "row"]}>
						<Box minW={assessmentClassLabelWidth}>
							<FormLabel
								pt={1.5}
								fontWeight={700}></FormLabel>
						</Box>
						<Box display={multiUse100Error ? "normal" : "none"}>
							<Box
								className={"estimator-multi-error"}
								mt={isSmallerScreen ? 0 : 2}
								mb={2}>
								Note: Property use does not equal 100%.
							</Box>
						</Box>
					</Flex>
				</FormControl>
			</Box>
		</React.Fragment>
	);
};
export default MultiUseCalculator;
