import { axiosLiveCycle, axiosMyPropertyAuth } from "./apis";

export const getMyMailDocument = async (mailDate, corrType, accId, xmlId, pageRange, isOwnerMail) => {
	// get XML
	let xml = await getMyMailXml(xmlId, isOwnerMail);

	if (xml !== null) {
		let pageRangeFormatted = pageRange === null ? "" : pageRange;

		let pdfPayload = {
			accId: accId,
			correspondence: corrType,
			correspondenceDate: mailDate,
			reprint: {
				XmlId: xmlId,
				Xml: xml.xml,
				AssessmentYear: xml.asmtYear,
				RasId: xml.rasId,
				CorrelationType: xml.corrType,
				PropertyAddress: xml.propertyAdd,
				TemplatePath: xml.templatePath,
				AllowChangeOwnership: xml.allowChangeOwnership,
				RecordLevel: xml.paramRecordLevel,
			},
			pageRange: pageRangeFormatted,
		};

		// get PDF
		let pdf = await getPdf(pdfPayload);

		if (pdf !== null) {
			return pdf;
		}

		return null;
	}

	return null;
};

async function getMyMailXml(xmlId, isOwnerMail) {
	let payload = {
		uId: "",
		xmlId: xmlId,
		calledFrom: isOwnerMail ? "WEB" : "AGENT",
	};

	let url = "mail/xml";
	return axiosMyPropertyAuth
		.put(url, payload)
		.then((resp) => {
			let returnObj = resp.data;
			if (returnObj.retVal === "true") {
				return returnObj;
			} else {
				return null;
			}
		})
		.catch(() => {
			return null;
		});
}

export const getMppXml = async (xmlId) => {
	// get XML
	let url = `taxes/mpp/xml/${xmlId}`;
	return axiosMyPropertyAuth
		.get(url)
		.then((resp) => {
			let returnObj = resp.data;
			if (returnObj.retVal === "true") {
				return returnObj;
			} else {
				return null;
			}
		})
		.catch(() => {
			return null;
		});
};

export const getMppPdf = async (xml, appDtls) => {
	let pageRangeFormatted = xml.pageRange === null ? "" : xml.pageRange;
	let pdfPayload = {
		accId: xml.accId,
		correspondence: appDtls.correspondenceType,
		correspondenceDate: appDtls.correspondenceDate,
		reprint: {
			XmlId: appDtls.mpfId,
			Xml: xml.xmlDocument,
			AssessmentYear: 0,
			RasId: 0,
			CorrelationType: "",
			PropertyAddress: "",
			TemplatePath: xml.templateName,
			AllowChangeOwnership: "",
			RecordLevel: String(xml.recordLevel),
		},
		pageRange: pageRangeFormatted,
	};

	// get PDF
	let pdf = await getPdf(pdfPayload);

	if (pdf !== null) {
		return pdf;
	}

	return null;
};

async function getPdf(payload) {
	let url = "reprintnotice";
	return axiosLiveCycle
		.post(url, payload)
		.then((resp) => {
			return resp.data;
		})
		.catch(() => {
			return null;
		});
}
