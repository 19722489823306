import * as CommonFns from "../../../common";
import * as toastMessages from "../../../toastMessages";

import {
	Box,
	Button,
	Checkbox,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Input,
	Link,
	LinkBox,
	LinkOverlay,
	Select,
	Text,
	Textarea,
	Tooltip,
	Wrap,
	WrapItem,
	chakra,
	useBreakpointValue,
	useMediaQuery,
	useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import React, { useContext, useEffect, useState } from "react";

import { AuditContext } from "../../../contexts/AuditContext";
import { AuthContext } from "../../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Markdown from "../../Global/Markdown/Markdown";
import { axiosMyPropertyAuth } from "../../../apis";
import { useLocation } from "react-router-dom";

const Feedback = () => {
	const { authDetails, resetAuthAccountNumber, checkEmailUpdate } = useContext(AuthContext);
	const { auditActivity } = useContext(AuditContext);
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });
	const [isLargerThan480] = useMediaQuery("(min-width: 480px)");

	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [allowedMimeTypes] = useState(process.env.REACT_APP_FILEUPLOAD_MIMETYPES);
	const [maxTotalUploadSize] = useState(process.env.REACT_APP_FILEUPLOAD_MAXSIZEBYTE);
	const [showUploadError, setShowUploadError] = useState(false);
	const [uploadErrorMessage, setUploadErrorMessage] = useState("");
	const [topics, setTopics] = useState([]);
	const [charCount, setCharCount] = useState(0);
	const [showThankYou, setShowThankYou] = useState(false);

	const toast = useToast();
	const feedbackToastErrorId = "feedback-toast-error";

	const location = useLocation();

	const formLabelMinWidth = 210;
	const formControlMinWidth = 405;

	const hiddenFileInput = React.useRef(null);
	const onlineSubmission = React.useRef(null);

	const {
		register,
		handleSubmit,
		control,
		reset: resetFeedbackForm,
		getValues,
		setValue,
		formState: { errors },
	} = useForm({
		defaultValues: {
			name: authDetails.profileName,
			emailAddress: authDetails.emailAddress,
			accId: "",
			phone: "",
			topic: "",
			topicEmail: "",
			inquiry: "",
			contactUser: false,
		},
	});

	useEffect(() => {
		setValue("emailAddress", authDetails.emailAddress);
		setValue("name", authDetails.profileName);
	}, [authDetails]);

	useEffect(() => {
		resetAuthAccountNumber();
		getTopics();
		checkEmailUpdate();

		//prepopulate accountId if passed in querystring
		let accId = new URLSearchParams(location.search).get("accId");
		if (accId != null) {
			setValue("accId", accId);
		}
	}, []);

	useEffect(() => {
		//prepopulate topic if passed in querystring
		let topicCode = new URLSearchParams(location.search).get("tc");
		if (topics.length > 0 && topicCode != null) {
			let topic = topics.filter((x) => x.topicCode.toLowerCase() === topicCode.toLowerCase())[0]?.topic ?? "";
			setValue("topic", topic);
			setValue("inquiry", localStorage.getItem("feedbackReason"));
		}
	}, [topics]);

	const getTopics = () => {
		let url = `feedback`;
		axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					let topicEmails = returnObj.emailAddressCol.sort((a, b) => a.sortOrder - b.sortOrder);
					setTopics(topicEmails);
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(feedbackToastErrorId)) {
					toast(toastMessages.getToastError(feedbackToastErrorId));
				}
			});
	};

	const handleClear = (e) => {
		e.preventDefault();

		setCharCount(0); //this needs to be before reset is called for some reason
		resetFeedbackForm();
		setUploadedFiles([]);
		setShowUploadError(false);
	};

	const handleFileUpload = (e) => {
		hiddenFileInput.current.click();
	};

	const onChangeHandler = (e) => {
		let isValid = false;
		let fileToUpload = e.target.files[0];

		//validate file type and size
		isValid = isFileContentTypeValid(fileToUpload) && isTotalFileSizeValid(fileToUpload);

		if (isValid) {
			setShowUploadError(false);

			let tempFiles = [...uploadedFiles];

			//check if uploaded file already exists, if so, overwrite it
			let duplicateIndex = uploadedFiles.map((x) => x.name).indexOf(fileToUpload.name);
			if (duplicateIndex === -1) {
				tempFiles.push(fileToUpload);
			} else {
				tempFiles[duplicateIndex] = fileToUpload;
			}

			setUploadedFiles(tempFiles);
		} else {
			setShowUploadError(true);
		}

		//clear hidden file upload control
		e.target.value = "";
	};

	const isFileContentTypeValid = (fileToUpload) => {
		let fileExtension = "." + fileToUpload.name.split(".").pop();

		let isValid = allowedMimeTypes
			.split(",")
			.map((x) => x.trim())
			.some((x) => x === fileExtension);

		if (!isValid) {
			setUploadErrorMessage(
				`Our appologies. We were unable to accept files of certain types.\r\n Ensure your files are one of the following formats: **${allowedMimeTypes}**`
			);
		}

		return isValid;
	};

	const isTotalFileSizeValid = (fileToUpload) => {
		let currentTotalSize = uploadedFiles.reduce((a, b) => {
			return a + b.size;
		}, 0);

		let isValid = currentTotalSize + fileToUpload.size < maxTotalUploadSize;

		if (!isValid) {
			setUploadErrorMessage(
				`The total combined size of all attachments must not exceed: **${maxTotalUploadSize / 1024 / 1024} MB**`
			);
		}

		return isValid;
	};

	const handleDeleteFile = (index) => {
		let tempUploadedFiles = [...uploadedFiles];

		tempUploadedFiles.splice(index, 1);
		setUploadedFiles(tempUploadedFiles);
	};

	const onSubmit = (data) => {
		const payload = new FormData();

		payload.append("accId", data.accId === "" ? "" : parseInt(data.accId));
		payload.append("contactUser", data.contactUser);
		payload.append("emailAddress", data.emailAddress);
		payload.append("inquiry", data.inquiry);
		payload.append("name", data.name);
		payload.append("phone", data.phone);
		payload.append("topic", data.topic);
		payload.append("submissionDate", CommonFns.getCurrentDateTime());

		//get topicEmail
		let email = topics.filter((x) => x.topic === data.topic)[0].emailAddress;
		payload.append("topicEmail", email);

		//add attachments
		if (uploadedFiles.length > 0) {
			uploadedFiles.forEach((x, index) => payload.append("attachments", x));
		} else {
			payload.append("attachments", "");
		}

		// for (var pair of payload.entries()) {
		// 	console.log(pair[0] + ", " + pair[1]);
		// }

		let url = "feedback";
		axiosMyPropertyAuth
			.post(url, payload)
			.then((resp) => {
				//display success panel
				setShowThankYou(true);

				// audit
				auditActivity("Contact Us Submit");

				onlineSubmission.current.scrollIntoView();
			})
			.catch((error) => {
				// error
				if (!toast.isActive(feedbackToastErrorId)) {
					toast(toastMessages.getToastError(feedbackToastErrorId));
				}
			});
	};

	const handlePageReload = (e) => {
		window.location.reload();
	};

	return (
		<React.Fragment>
			<Box fontSize={"2xl"}>Contact Us</Box>
			<Box
				fontSize={"lg"}
				mt={1}
				maxW={1050}>
				Property owners can contact the City 24 hours a day, every day of the year to access City of Edmonton
				information and services.
			</Box>
			<Box
				fontSize={"lg"}
				mt={2}
				maxW={1050}>
				When you have a question about your assessment or taxes, contact us as soon as possible. We strive to
				address your inquiry within three business days​​.
			</Box>
			<Box
				fontSize={"lg"}
				mt={2}
				maxW={1050}>
				Most assessment-related concerns can be resolved by speaking with a 311 agent or assessor - with no
				formal complaint fees required.
			</Box>
			<Box
				ref={onlineSubmission}
				fontSize={"2xl"}
				mt={5}>
				Online Submission
			</Box>

			{!showThankYou ? (
				<>
					<Box mt={5}>
						<form onSubmit={handleSubmit(onSubmit)}>
							<FormControl
								isInvalid={errors.name}
								mt={2}>
								<Flex direction={["column", "column", "row"]}>
									<Box minW={formLabelMinWidth}>
										<FormLabel
											htmlFor='name'
											textAlign={isSmallerScreen ? "" : "end"}
											className='form-label-fixed'>
											Name
										</FormLabel>
									</Box>
									<Box minW={!isLargerThan480 ? "" : formControlMinWidth}>
										<Input
											id='name'
											type='text'
											autoComplete='off'
											{...register("name")}
											readOnly={true}
										/>
									</Box>
								</Flex>
							</FormControl>

							<FormControl
								isInvalid={errors.emailAddress}
								mt={2}>
								<Flex direction={["column", "column", "row"]}>
									<Box minW={formLabelMinWidth}>
										<FormLabel
											htmlFor='emailAddress'
											textAlign={isSmallerScreen ? "" : "end"}
											className='form-label-fixed'>
											Email Address
										</FormLabel>
									</Box>
									<Box minW={!isLargerThan480 ? "" : formControlMinWidth}>
										<Input
											id='emailAddress'
											type='text'
											autoComplete='off'
											{...register("emailAddress")}
											readOnly={true}
										/>
									</Box>
								</Flex>
							</FormControl>
							<FormControl
								isInvalid={errors.accId}
								mt={2}>
								<Flex direction={["column", "column", "row"]}>
									<Box minW={formLabelMinWidth}>
										<FormLabel
											htmlFor='accId'
											textAlign={isSmallerScreen ? "" : "end"}
											className='form-label-fixed'>
											Account Number
										</FormLabel>
									</Box>
									<Box>
										<Input
											id='accId'
											type='number'
											autoComplete='off'
											{...register("accId")}
										/>
									</Box>
									<Box ml={isSmallerScreen ? 0 : 3}>
										<FormErrorMessage
											colorScheme={"red"}
											className={"text-error pos"}>
											{errors.accId && errors.accId.message}
										</FormErrorMessage>
									</Box>
								</Flex>
							</FormControl>
							<FormControl
								isInvalid={errors.phone}
								mt={2}>
								<Flex direction={["column", "column", "row"]}>
									<Box minW={formLabelMinWidth}>
										<FormLabel
											htmlFor='phone'
											textAlign={isSmallerScreen ? "" : "end"}
											className='form-label-fixed'>
											Daytime Phone Number
										</FormLabel>
									</Box>
									<Box>
										<Input
											id='phone'
											type='tel'
											autoComplete='off'
											placeholder='000-000-0000'
											{...register("phone", {
												pattern: {
													value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g,
													message: "Phone Number is formatted incorrectly.",
												},
											})}
										/>
									</Box>
									<Box ml={isSmallerScreen ? 0 : 3}>
										<FormErrorMessage
											colorScheme={"red"}
											className={"text-error pos"}>
											{errors.phone && errors.phone.message}
										</FormErrorMessage>
									</Box>
								</Flex>
							</FormControl>
							<FormControl
								isInvalid={errors.topic}
								mt={2}>
								<Flex direction={["column", "column", "row"]}>
									<Box minW={formLabelMinWidth}>
										<FormLabel
											htmlFor='topic'
											textAlign={isSmallerScreen ? "" : "end"}
											className='form-label-fixed'>
											<chakra.span color={"#c80000"}>*</chakra.span>Topic
										</FormLabel>
									</Box>
									<Box minW={!isLargerThan480 ? "" : formControlMinWidth}>
										<Select
											id='topic'
											name='topic'
											iconSize={"1em"}
											icon={<FontAwesomeIcon icon='fa-solid fa-caret-down' />}
											placeholder='Select...'
											{...register("topic", {
												required: "Please select a Topic.",
											})}>
											{topics.map((item, index) => (
												<option
													key={index}
													value={item.topic}>
													{item.topic.trim()}
												</option>
											))}
										</Select>
									</Box>
									<Box ml={isSmallerScreen ? 0 : 3}>
										<FormErrorMessage
											colorScheme={"red"}
											className={"text-error pos"}>
											{errors.topic && errors.topic.message}
										</FormErrorMessage>
									</Box>
								</Flex>
							</FormControl>
							<FormControl
								isInvalid={errors.inquiry}
								mt={2}>
								<Flex direction={["column", "column", "row"]}>
									<Box minW={formLabelMinWidth}>
										<FormLabel
											htmlFor='inquiry'
											textAlign={isSmallerScreen ? "" : "end"}
											className='form-label-fixed'>
											<chakra.span color={"#c80000"}>*</chakra.span>Inquiry / Feedback
										</FormLabel>
									</Box>
									<Box>
										<Textarea
											id='inquiry'
											type='text'
											autoComplete='off'
											{...register("inquiry", {
												required: "Inquiry / Feedback is required.",
												minLength: {
													value: 10,
													message:
														"Inquiry / Feedback must be between 10 and 3000 characters.",
												},
												onChange: (e) => {
													setCharCount(e.target.value.length);
												},
											})}
											cols='50'
											rows='6'
											resize='none'
											maxLength='3000'
										/>
										<chakra.span fontSize={"xs"}>
											[{3000 - charCount} characters remaining]
										</chakra.span>
									</Box>
									<Box ml={isSmallerScreen ? 0 : 3}>
										<FormErrorMessage
											colorScheme={"red"}
											className={"text-error pos"}>
											{errors.inquiry && errors.inquiry.message}
										</FormErrorMessage>
									</Box>
								</Flex>
							</FormControl>

							<FormControl mt={6}>
								<Flex direction={["column", "column", "row"]}>
									<Box minW={formLabelMinWidth}>
										<FormLabel textAlign={isSmallerScreen ? "" : "end"}>Attachments</FormLabel>
									</Box>
									<Box>
										<Box mb={uploadedFiles.length > 0 ? 2 : 0}>
											<Wrap
												spacing={2}
												maxWidth='800px'>
												{uploadedFiles.map((x, index) => (
													<WrapItem key={index}>
														<LinkBox>
															<HStack spacing={0}>
																<Tooltip label={`Remove ${x.name}`}>
																	<LinkOverlay
																		as='button'
																		onClick={(e) => handleDeleteFile(index)}>
																		<Box
																			p={1}
																			borderRightWidth={1}
																			borderRightColor='#000'
																			bgColor='#dcddde'
																			width='30px'>
																			<FontAwesomeIcon icon='fa-solid fa-circle-xmark' />
																		</Box>
																	</LinkOverlay>
																</Tooltip>
																<Box
																	p={1}
																	pl={2}
																	bgColor='#dcddde'
																	width='125px'
																	whiteSpace='nowrap'>
																	<Tooltip label={x.name}>
																		<Text
																			noOfLines={1}
																			display='block'>
																			{x.name}
																		</Text>
																	</Tooltip>
																</Box>
															</HStack>
														</LinkBox>
													</WrapItem>
												))}
											</Wrap>
										</Box>
										<HStack mt={2}>
											<Box>
												<Button
													colorScheme='lightBlue'
													size={"xs"}
													fontSize={".9em"}
													width={95}
													onClick={(e) => {
														handleFileUpload(e);
													}}>
													Attach Files
												</Button>
											</Box>
											<Box pl={2}>
												Total size remaining:{" "}
												<chakra.span fontWeight={700}>
													{15 -
														Math.ceil(
															uploadedFiles.reduce((a, b) => {
																return a + b.size;
															}, 0) /
																1024 /
																1024
														)}{" "}
													MB
												</chakra.span>
											</Box>
										</HStack>
										<Box
											display={showUploadError ? "normal" : "none"}
											className={"text-error"}
											mt={2}>
											<Markdown text={uploadErrorMessage}></Markdown>
										</Box>
									</Box>
								</Flex>
								<Box display={"none"}>
									<input
										ref={hiddenFileInput}
										type='file'
										className='form-control'
										onChange={onChangeHandler}
									/>
								</Box>
							</FormControl>

							<FormControl mt={6}>
								<Flex direction={["column", "column", "row"]}>
									<Box minW={formLabelMinWidth}></Box>
									<Box maxW={isSmallerScreen ? "100%" : "45%"}>
										<Controller
											control={control}
											name='contactUser'
											defaultValue={false}
											render={({ field: { onChange, value, ref } }) => (
												<Checkbox
													size={"lg"}
													onChange={onChange}
													ref={ref}
													isChecked={value}>
													I would like to be contacted about my inquiry.
												</Checkbox>
											)}
										/>
									</Box>
								</Flex>
							</FormControl>

							<FormControl mt={6}>
								<Flex direction={["column", "column", "row"]}>
									<Box
										minW={formLabelMinWidth}
										display={isSmallerScreen ? "none" : "normal"}>
										<FormLabel>&nbsp;</FormLabel>
									</Box>
									<Box>
										<Flex direction={["column", "column", "row"]}>
											<Button
												colorScheme='blue'
												width={isSmallerScreen ? "100%" : 100}
												mr={isSmallerScreen ? 0 : 4}
												type='submit'>
												SUBMIT
											</Button>
											<Button
												type='reset'
												colorScheme='grey'
												variant={"outline"}
												width={isSmallerScreen ? "100%" : 100}
												mr={isSmallerScreen ? 0 : 2}
												mt={isSmallerScreen ? 6 : 0}
												onClick={(e) => handleClear(e)}>
												CLEAR
											</Button>
										</Flex>
									</Box>
								</Flex>
							</FormControl>
						</form>
					</Box>
				</>
			) : (
				<>
					<Box
						fontWeight={700}
						mt={1}>
						Thank you for your inquiry.
					</Box>
					{getValues("contactUser") ? (
						<>
							<Box my={2}>
								We will do our best to get back to you within ​​
								<chakra.span fontWeight={700}>three (3) business days</chakra.span>.
							</Box>
						</>
					) : (
						<></>
					)}
					<Box mt={3}>
						<Button
							colorScheme='lightBlue'
							size={"sm"}
							px={5}
							onClick={(e) => {
								handlePageReload(e);
							}}>
							SUBMIT ANOTHER
						</Button>
					</Box>
				</>
			)}

			<Box
				fontSize={"2xl"}
				mt={8}>
				Email
			</Box>
			<Box
				fontSize={"lg"}
				mt={1}
				maxW={1050}>
				In your email, please mention your name and account number and describe your inquiry in as much detail
				as you can.
			</Box>
			<Box mt={2}>
				<Link
					href={"mailto:assessment@edmonton.ca"}
					rel={"noopener"}
					target={"_blank"}>
					assessment@edmonton.ca
				</Link>{" "}
				for inquiries related to your assessment.
			</Box>
			<Box>
				<Link
					href={"mailto:taxes@edmonton.ca"}
					rel={"noopener"}
					target={"_blank"}>
					taxes@edmonton.ca
				</Link>{" "}
				for inquiries related to your property taxes.
			</Box>

			<Box
				fontSize={"2xl"}
				mt={5}>
				Phone
			</Box>
			<Box
				fontSize={"lg"}
				mt={1}
				maxW={1050}>
				Before you call us, have your latest assessment notice in front of you with the "account" number and
				"valuation group" information ready - it will help us address your concerns more effectively.
			</Box>

			<Box mt={2}>
				Within Edmonton: <chakra.span fontWeight={700}>311</chakra.span>
			</Box>
			<Box>
				Outside Edmonton: <chakra.span fontWeight={700}>780-442-5311</chakra.span>
			</Box>
			<Box>
				TTY: <chakra.span fontWeight={700}>780-944-5555</chakra.span>
			</Box>
		</React.Fragment>
	);
};

export default Feedback;
