import React from "react";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";

const Markdown = ({ text }) => {
	function LinkRenderer(props) {
		// match for fqdn of current environment
		// if found, make target _self (internal)
		// if not found, make target _blank (external)
		let host = window.location.hostname;
		let hostPort = window.location.host;

		let regexHost = new RegExp("^(https?:)?//" + host);
		let regexHostPort = new RegExp("^(https?:)?//" + hostPort);

		//return props.href.match(/^(https?:)?\/\/.*myproperty.edmonton.ca/) ? (
		return props.href.startsWith("/") || props.href.match(regexHost) || props.href.match(regexHostPort) ? (
			<a href={props.href} target='_self'>
				{props.children}
			</a>
		) : (
			<a href={props.href} target='_blank' rel='noreferrer'>
				{props.children}
			</a>
		);
	}

	return (
		<React.Fragment>
			<ReactMarkdown
				remarkPlugins={[remarkBreaks]}
				components={{ a: LinkRenderer }}
				children={text}></ReactMarkdown>
		</React.Fragment>
	);
};

export default Markdown;
