import "../History.css";
import "chart.js/auto";

import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, chakra } from "@chakra-ui/react";
import React, { useContext, useState } from "react";

import { AuditContext } from "../../../../../../contexts/AuditContext";
import DataTable from "./DataTable/DataTable";
import Markdown from "../../../../../Global/Markdown/Markdown";
import ValueChange from "./ValueChange/ValueChange";

const AssessmentChange = ({ accId, historyDetails, scenarioMessage, scenarioAttnMessage, chartMessage }) => {
	const [historyTabIndex, setHistoryTabIndex] = useState(0);

	const { auditActivity } = useContext(AuditContext);

	const handleHistoryTabChange = (index) => {
		setHistoryTabIndex(index);
	};

	const handleAuditAssessmentChange = (activity) => {
		auditActivity(activity, accId);
	};

	return (
		<React.Fragment>
			<Box mb={3} mt={3} maxW={1050}>
				<chakra.span fontSize={"2xl"}>Property Assessment Change in the Last Five Years</chakra.span>
			</Box>
			<Box maxW={1050} pb={2} fontSize={"xl"}>
				<Markdown text={scenarioMessage}></Markdown>
			</Box>
			<Box maxW={1050} pb={5} fontSize={"xl"}>
				<Markdown text={scenarioAttnMessage}></Markdown>
			</Box>
			<Box>
				<Tabs
					className="history tabs"
					variant="unstyled"
					index={historyTabIndex}
					onChange={handleHistoryTabChange}
				>
					<Box className="history-display">
						<TabList mb="1em" className="history tab-list">
							<Tab
								key={0}
								value={0}
								_selected={{ color: "white", bg: "blue.500" }}
								_hover={{ color: "white", bg: "blue.500" }}
								className="history tab"
								onClick={() => handleAuditAssessmentChange("View History Assessment Value Change")}
							>
								Value Change
							</Tab>
							<Tab
								key={1}
								value={1}
								_selected={{ color: "white", bg: "blue.500" }}
								_hover={{ color: "white", bg: "blue.500" }}
								className="history tab"
								onClick={() => handleAuditAssessmentChange("View History Assessment Data Table")}
							>
								Data Table
							</Tab>
						</TabList>
					</Box>
					{/* </Stack> */}
					<TabPanels>
						<TabPanel className="history tab-panel" key={0}>
							<Box mt={3} ml={5} maxWidth={"650px"}>
								<ValueChange historyDetails={historyDetails}></ValueChange>
							</Box>
						</TabPanel>
						<TabPanel className="history tab-panel" key={1}>
							<Box mt={1} maxWidth={"650px"}>
								<DataTable historyDetails={historyDetails}></DataTable>
							</Box>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>
			<Box maxW={1050} pt={5}>
				<Markdown text={chartMessage}></Markdown>
			</Box>
		</React.Fragment>
	);
};
export default AssessmentChange;
