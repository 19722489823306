import "./YourPropertyTaxes.css";

import { Box, Flex, HStack, Skeleton, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import { AuditContext } from "../../../../../../contexts/AuditContext";
import DataUnavailable from "../../../../../Global/DataUnavailable/DataUnavailable";
import YourPropertyTaxesHeader from "../YourPropertyTaxes/Header/Header";
import YourPropertyTaxesSummary from "./Summary/Summary";
import { axiosMyPropertyAuth } from "../../../../../../apis";

const YourPropertyTaxes = ({ accId, accountDetails, updateHasTaxNotice }) => {
	const [taxNotice, setTaxNotice] = useState();

	const [annualTaxesTotal, setAnnualTaxesTotal] = useState(0);
	const [supplementaryTaxesTotal, setSupplementaryTaxesTotal] = useState(0);
	const [taxesTotal, setTaxesTotal] = useState(0);

	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);

	useEffect(() => {
		getTaxNotice();
	}, []);

	const { auditActivity } = useContext(AuditContext);

	const formLabelMinWidth = 430;
	const formControlMinWidth = 360;

	const getTaxNotice = () => {
		setIsLoading(true);
		setIsError(false);

		let url = `taxes/notice/${accId}`;
		axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					if (returnObj.webTaxNoticeCol !== null) {
						// set show/hide value for accordion section on taxes.js
						updateHasTaxNotice(returnObj.webTaxNoticeCol.length > 0 ? true : false);

						setTaxNotice(returnObj);

						if (returnObj.webTaxNoticeCol !== null) {
							let annualTaxes = getTotal(returnObj, "ANN");
							let supplementaryTaxes = getTotal(returnObj, "SUPP");

							setAnnualTaxesTotal(annualTaxes);
							setSupplementaryTaxesTotal(supplementaryTaxes);
							setTaxesTotal(annualTaxes + supplementaryTaxes);
						}

						setIsLoading(false);
						setIsError(false);
					} else {
						updateHasTaxNotice(false);

						setIsLoading(false);
						setIsError(false);
					}
				}
			})
			.catch((error) => {
				// error
				setIsLoading(false);
				setIsError(true);
			});
	};

	const getTotal = (returnObj, type) => {
		if (returnObj.webTaxNoticeCol.filter((x) => x.assessmentType === type).length > 0) {
			return returnObj.webTaxNoticeCol.filter((x) => x.assessmentType === type)[0].transactionAmountTotal;
		}
		return 0;
	};

	return (
		<React.Fragment>
			{isLoading ? (
				<Box>
					<Flex>
						<Skeleton
							height='32px'
							width='80px'
							mb={2}
							mr={2}
						/>
						<Skeleton
							height='32px'
							width='80px'
							mb={2}
							mr={2}
						/>
						<Skeleton
							height='32px'
							width='80px'
							mb={2}
							mr={2}
						/>
					</Flex>
					<Box
						ml={3}
						mt={2}
						mb={4}>
						<Flex direction={["column", "column", "row"]}>
							<Box minW={formLabelMinWidth}>
								<Skeleton
									height='24px'
									width='30%'
									mb={2}
								/>
								<Skeleton
									height='24px'
									width='20%'
									mb={2}
								/>
								<Skeleton
									height='24px'
									width='35%'
									mb={2}
								/>
							</Box>
							<Box minW={formControlMinWidth}>
								<Skeleton
									height='24px'
									width='40%'
									mb={2}
								/>
								<Skeleton
									height='24px'
									width='40%'
									mb={2}
								/>
								<Skeleton
									height='24px'
									width='40%'
									mb={2}
								/>
							</Box>
						</Flex>
					</Box>
					<Skeleton
						height='300px'
						width='60%'
						mb={2}
					/>
				</Box>
			) : !isError ? (
				<Box ml={3}>
					{taxNotice !== undefined ? (
						<Box
							pt={1}
							pb={2}>
							<Tabs
								className='your-property-taxes tabs'
								variant='unstyled'>
								<HStack>
									<Box className='assessed-value-display'>
										<TabList
											mb='1em'
											className='your-property-taxes tab-list'>
											{taxNotice.webTaxNoticeCol.length > 0
												? taxNotice.webTaxNoticeCol.map((item, index) => (
														<Tab
															className='your-property-taxes tab'
															key={index}
															value={index}
															onClick={() => {
																item.assessmentType === "ANN"
																	? auditActivity(
																			"View Your Property Taxes Annual",
																			accId
																	  )
																	: auditActivity(
																			"View Your Property Taxes Supplementary",
																			accId
																	  );
															}}>
															{item.assessmentType === "ANN"
																? item.reasonCode === "NEW"
																	? "Annual"
																	: "Amended Annual"
																: item.reasonCode === "NEW"
																? "Supplementary"
																: "Amended Supplementary"}
														</Tab>
												  ))
												: null}
										</TabList>
									</Box>
								</HStack>
								<TabPanels>
									{taxNotice.webTaxNoticeCol.map((item, index) => (
										<TabPanel
											key={index}
											className='your-property-taxes tab-panel'>
											<Box mt={3}>
												<YourPropertyTaxesHeader
													accId={accId}
													notice={item}
													isAnnual={item.assessmentType === "ANN" ? true : false}
													isAmended={
														item.reasonCode !== "NEW" ? true : false
													}></YourPropertyTaxesHeader>
											</Box>
											<Box mt={5}>
												<YourPropertyTaxesSummary
													notice={item}
													isAnnual={item.assessmentType === "ANN" ? true : false}
													taxYear={item.taxYear}
													annualTaxesTotal={annualTaxesTotal}
													supplementaryTaxesTotal={supplementaryTaxesTotal}
													taxesTotal={taxesTotal}
													accountDetails={accountDetails}></YourPropertyTaxesSummary>
											</Box>
										</TabPanel>
									))}
								</TabPanels>
							</Tabs>
						</Box>
					) : (
						<DataUnavailable></DataUnavailable>
					)}
				</Box>
			) : (
				<DataUnavailable></DataUnavailable>
			)}
		</React.Fragment>
	);
};

export default YourPropertyTaxes;
