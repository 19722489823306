import * as toastMessages from "../../../toastMessages";

import {
	Box,
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	Link,
	chakra,
	useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Complexity from "../../Global/Password/Complexity";
import PasswordStrengthBar from "react-password-strength-bar";
import { axiosMyProperty } from "../../../apis";
import { useForm } from "react-hook-form";
import { useToast } from "@chakra-ui/react";

const ForgotPasswordReset = () => {
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });
	const toast = useToast();
	let navigate = useNavigate();
	const location = useLocation();

	const [showResetForm, setShowResetForm] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);

	const [passwordShow, setPasswordShow] = useState(false);
	const handleShowHidePassword = () => setPasswordShow(!passwordShow);

	const [passwordConfirmShow, setPasswordConfirmShow] = useState(false);
	const handleShowHidePasswordConfirm = () => setPasswordConfirmShow(!passwordConfirmShow);

	const [hasError, setHasError] = useState(false);
	const [hasErrorExpired, setHasErrorExpired] = useState(false);
	const [hasGenericError, setHasGenericError] = useState(false);

	const resetPasswordToastErrorId = "reset-password-toast-error";

	const formLabelMinWidth = 190;
	const formControlMinWidth = 405;

	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		reset: resetPassword,
		watch,
		formState: { errors, isSubmitting },
	} = useForm({
		defaultValues: {
			oldUId: "",
			newUId: "",
			emailAddress: "",
			password: "",
			passwordConfirm: "",
		},
	});

	useEffect(() => {
		let uId = new URLSearchParams(location.search).get("id");
		let emailAddress = new URLSearchParams(location.search).get("e");

		if ((uId === null) | (emailAddress === null)) {
			setHasError(true);
			setHasGenericError(true);
		} else {
			//check if link has expired
			verifyLink(uId, emailAddress);
		}

		setValue("emailAddress", emailAddress);
		setValue("oldUId", uId);
	}, []);

	const watchPassword = watch("password", "");

	const verifyLink = (uId, emailAddress) => {
		let url = "account/password/verify";
		let payload = { uId: uId, emailAddress: emailAddress };
		axiosMyProperty
			.put(url, payload)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					if (returnObj.isExpired === "Y") {
						setHasError(true);
						setHasErrorExpired(true);
						setHasGenericError(false);
					} else {
						// verified
						setShowResetForm(true);
						setHasError(false);
					}
				} else {
					setHasError(true);
					setHasErrorExpired(false);
					setHasGenericError(true);
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(resetPasswordToastErrorId)) {
					toast(toastMessages.getToastError(resetPasswordToastErrorId));
				}
			});
	};

	const passwordMatch = (value) => {
		return value === watchPassword ? true : false;
	};

	const handleClearResetPassword = (e) => {
		e.preventDefault();

		// form
		resetPassword();
	};

	const onSubmit = async (data) => {
		let url = "account/password/reset";
		// let {passwordConfirm, ...payload} = data; //remove passwordConfirm from payload
		let payload = data;

		await axiosMyProperty
			.put(url, payload)
			.then((resp) => {
				// request succeeded
				setShowSuccess(true);
				setShowResetForm(false);
			})
			.catch((error) => {
				// error
				if (!toast.isActive(resetPasswordToastErrorId)) {
					toast(toastMessages.getToastError(resetPasswordToastErrorId));
				}
			});
	};

	const handleSignIn = (e) => {
		e.preventDefault();
		let emailAddress = getValues("emailAddress");
		navigate(`/sign-in?emailAddress=${emailAddress}`, { replace: true });
	};

	const handleForgotPassword = (e) => {
		e.preventDefault();
		navigate("/forgot-password", { replace: true });
	};

	return (
		<React.Fragment>
			{showResetForm || showSuccess ? (
				<>
					<FormControl>
						<Flex direction={["column", "column", "row"]}>
							<Box
								minW={formLabelMinWidth}
								display={isSmallerScreen ? "none" : "normal"}>
								<FormLabel>&nbsp;</FormLabel>
							</Box>
							<Box mb={1}>
								<chakra.span
									fontSize={"1.45em"}
									fontWeight={400}>
									Forgot Password
								</chakra.span>
							</Box>
						</Flex>
					</FormControl>
				</>
			) : (
				<></>
			)}
			{showResetForm ? (
				<>
					<form onSubmit={handleSubmit(async (data) => await onSubmit(data))}>
						<FormControl
							isInvalid={errors.password}
							mt={2}>
							<Flex direction={["column", "column", "row"]}>
								<Box minW={formLabelMinWidth}>
									<FormLabel
										htmlFor='password'
										textAlign={isSmallerScreen ? "" : "end"}
										className='form-label-fixed'>
										<Complexity></Complexity>
										New Password
									</FormLabel>
								</Box>
								<Box minW={formControlMinWidth}>
									<InputGroup size='md'>
										<Input
											id='password'
											type={passwordShow ? "text" : "password"}
											autoComplete='off'
											{...register("password", {
												required: "New Password is required",
												pattern: {
													value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/,
													message: "Your Password does not meet the requirements",
												},
											})}
										/>
										<InputRightElement width='4.5rem'>
											<Button
												h='1.75rem'
												w={"56px"}
												size='sm'
												colorScheme={"lightBlue"}
												onClick={handleShowHidePassword}
												tabIndex={-1}>
												{passwordShow ? "Hide" : "Show"}
											</Button>
										</InputRightElement>
									</InputGroup>
									<PasswordStrengthBar
										password={watchPassword}
										minLength={8}
										shortScoreWord={"Too Short"}
										scoreWords={["Very Weak", "Weak", "Medium", "Strong", "Very Strong"]}
									/>
								</Box>
								<Box ml={isSmallerScreen ? 0 : 3}>
									<FormErrorMessage
										colorScheme={"red"}
										className={"text-error"}>
										{errors.password && errors.password.message}
									</FormErrorMessage>
								</Box>
							</Flex>
						</FormControl>

						<FormControl
							isInvalid={errors.passwordConfirm}
							mt={2}>
							<Flex direction={["column", "column", "row"]}>
								<Box minW={formLabelMinWidth}>
									<FormLabel
										htmlFor='passwordConfirm'
										textAlign={isSmallerScreen ? "" : "end"}
										className='form-label-fixed'>
										Retype New Password
									</FormLabel>
								</Box>
								<Box minW={formControlMinWidth}>
									<InputGroup size='md'>
										<Input
											id='passwordConfirm'
											type={passwordConfirmShow ? "text" : "password"}
											autoComplete='off'
											{...register("passwordConfirm", {
												required: "Retype New Password is required",
												validate: passwordMatch,
											})}
										/>
										<InputRightElement width='4.5rem'>
											<Button
												h='1.75rem'
												w={"56px"}
												size='sm'
												colorScheme={"lightBlue"}
												onClick={handleShowHidePasswordConfirm}
												tabIndex={-2}>
												{passwordConfirmShow ? "Hide" : "Show"}
											</Button>
										</InputRightElement>
									</InputGroup>
								</Box>
								<Box ml={isSmallerScreen ? 0 : 3}>
									<FormErrorMessage
										colorScheme={"red"}
										className={"text-error"}>
										{errors.passwordConfirm && errors.passwordConfirm.message}
										{errors.passwordConfirm?.type === "validate" && (
											<span>Passwords do not match</span>
										)}
									</FormErrorMessage>
								</Box>
							</Flex>
						</FormControl>

						<FormControl mt={isSmallerScreen ? 6 : 3}>
							<Flex direction={["column", "column", "row"]}>
								<Box
									minW={formLabelMinWidth}
									display={isSmallerScreen ? "none" : "normal"}>
									<FormLabel>&nbsp;</FormLabel>
								</Box>
								<Box>
									<Flex direction={["column", "column", "row"]}>
										<Button
											isLoading={isSubmitting}
											loadingText={"UPDATE"}
											colorScheme='blue'
											type={"submit"}
											width={isSmallerScreen ? "100%" : 100}
											mr={isSmallerScreen ? 0 : 4}>
											UPDATE
										</Button>
										<Button
											colorScheme='grey'
											type='reset'
											variant={"outline"}
											width={isSmallerScreen ? "100%" : 100}
											mt={isSmallerScreen ? 4 : 0}
											onClick={(e) => handleClearResetPassword(e)}>
											CLEAR
										</Button>
									</Flex>
								</Box>
							</Flex>
						</FormControl>
					</form>
				</>
			) : (
				<></>
			)}
			{showSuccess ? (
				<Box mt={1}>
					<Flex direction={["column", "column", "row"]}>
						<Box
							minW={formLabelMinWidth}
							display={isSmallerScreen ? "none" : "normal"}>
							<FormLabel>&nbsp;</FormLabel>
						</Box>
						<Box>
							<chakra.span>
								Your password has been updated successfully. Please{" "}
								<Link onClick={(e) => handleSignIn(e, true)}>sign in</Link>.
							</chakra.span>
						</Box>
					</Flex>
				</Box>
			) : (
				<></>
			)}
			{hasError ? (
				<>
					<Box mb={1}>
						<chakra.span
							fontSize={"1.45em"}
							fontWeight={400}>
							Reset Your Password
						</chakra.span>
					</Box>

					{hasErrorExpired ? (
						<>
							<Box>
								Unfortunately this password reset link has expired. Please complete the{" "}
								<Link onClick={(e) => handleForgotPassword(e)}>forgot password</Link> process again.
							</Box>
						</>
					) : (
						<></>
					)}
					{hasGenericError ? (
						<>
							<Box>
								Something went wrong while trying to process the forgot password request, please try
								again later.
							</Box>
						</>
					) : (
						<></>
					)}
				</>
			) : (
				<></>
			)}
		</React.Fragment>
	);
};
export default ForgotPasswordReset;
