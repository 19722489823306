import "./Header.css";

import * as CommonFns from "../../../../../../../common";

import {
	AspectRatio,
	Box,
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	chakra,
	useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import { AuditContext } from "../../../../../../../contexts/AuditContext";

const AssessedValueHeader = ({ accId, assessment, assessmentSummary }) => {
	useEffect(() => {
		let tabLabel = assessmentSummary.tabLabel.trim().toLowerCase();
		setupDisplay(tabLabel);
	}, []);

	const { auditActivity } = useContext(AuditContext);

	const { isOpen: isOpenSupVideo, onOpen: onOpenSupVideo, onClose: onCloseSupVideo } = useDisclosure();

	const [assessedValueLabel, setAssessedValueLabel] = useState();
	const [displaySupplementary, setDisplaySupplementary] = useState(false);
	const [supplementaryVideoUrl, setSupplementaryVideoUrl] = useState("");

	const formLabelMinWidth = 430;
	const formControlMinWidth = 360;

	const setupDisplay = (tabLabel) => {
		let assessedValueLabel = "";
		setDisplaySupplementary(false);
		setSupplementaryVideoUrl("");

		switch (tabLabel) {
			case "annual":
				break;
			case "amended supplementary":
				assessedValueLabel = "amended supplementary ";
				setDisplaySupplementary(true);
				setSupplementaryVideoUrl(assessment.suppAsmtTutorialLink);
				break;
			case "amended":
				assessedValueLabel = "amended ";
				break;
			case "supplementary":
				assessedValueLabel = "supplementary ";
				setDisplaySupplementary(true);
				setSupplementaryVideoUrl(assessment.suppAsmtTutorialLink);
				break;
			//no default
		}
		setAssessedValueLabel(assessedValueLabel);
	};

	return (
		<React.Fragment>
			<Box mb={2}>
				<Box mt={1}>
					<Flex direction={["column", "column", "row"]}>
						<Box minW={formLabelMinWidth}>
							{assessment.asmtYear} {assessedValueLabel}assessed value
						</Box>
						<Box minW={formControlMinWidth}>
							{" "}
							<chakra.span fontWeight={700}>
								{CommonFns.FormatCurrency(assessmentSummary.totalAsmtAmt)}
							</chakra.span>
						</Box>
					</Flex>
				</Box>

				{displaySupplementary ? (
					<Box mt={1}>
						<Flex direction={["column", "column", "row"]}>
							<Box minW={formLabelMinWidth}>Supplementary months</Box>
							<Box minW={formControlMinWidth}>
								<chakra.span fontWeight={700}>{assessmentSummary.suppMonths}</chakra.span>
							</Box>
						</Flex>
					</Box>
				) : null}

				<Box mt={2}>
					<Flex direction={["column", "column", "row"]}>
						<Box minW={formLabelMinWidth}>Date of issue</Box>
						<Box minW={formControlMinWidth}>
							<chakra.span fontWeight={700}>
								{CommonFns.FormatDate(new Date(assessmentSummary.mailDate))}
							</chakra.span>
						</Box>
					</Flex>
				</Box>

				{displaySupplementary ? (
					<Box mt={2}>
						<Flex direction={["column", "column", "row"]}>
							<Box minW={formLabelMinWidth}>What is a supplementary assessment?</Box>
							<Box minW={formControlMinWidth}>
								<Button
									onClick={() => {
										onOpenSupVideo();
										auditActivity("Supplementary A&T Video - Watch", accId);
									}}
									colorScheme="lightBlue"
									minW={"5rem"}
									size={"xs"}
								>
									Watch
								</Button>
							</Box>
						</Flex>
					</Box>
				) : null}
			</Box>

			<Modal size={"2xl"} isOpen={isOpenSupVideo} onClose={onCloseSupVideo}>
				<ModalOverlay />
				<ModalContent maxW={"57rem"}>
					<ModalBody className="assessed-value-modal-no-padding">
						<AspectRatio ratio={16 / 9}>
							<iframe
								id="supplementaryVideo"
								src={supplementaryVideoUrl}
								title="Supplementary Assessment Video"
								data-video="supplementaryvideo"
								frameborder="0"
								allow="autoplay; encrypted-media"
								allowfullscreen
							></iframe>
						</AspectRatio>
					</ModalBody>
				</ModalContent>
			</Modal>
		</React.Fragment>
	);
};

export default AssessedValueHeader;
