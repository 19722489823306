import "./Breakdown.css";

import * as CommonFns from "../../../../../../../common";
import * as toastMessages from "../../../../../../../toastMessages";

import { Box, Table, Tbody, Td, Text, Tfoot, Tr, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { axiosMyPropertyAuth } from "../../../../../../../apis";

const Breakdown = ({ accId }) => {
	const [breakdown, setBreakdown] = useState();

	useEffect(() => {
		getMunicipalTaxBreakdown();
	}, []);

	const toast = useToast();
	const taxBreakdownToastErrorId = "tax-breakdown-toast-error";

	const getMunicipalTaxBreakdown = () => {
		let url = `taxes/breakdown/${accId}`;
		axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;

				if (returnObj.retVal === "true") {
					setBreakdown(returnObj);
				} else {
					setBreakdown();
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(taxBreakdownToastErrorId)) {
					toast(toastMessages.getToastError(taxBreakdownToastErrorId));
				}
			});
	};
	return (
		<React.Fragment>
			{breakdown !== undefined && breakdown.webTaxBreakdownCol !== null ? (
				breakdown.webTaxBreakdownCol?.length > 0 ? (
					<React.Fragment>
						<Box
							maxW={700}
							mb={2}>
							<Text fontSize={"xl"}>
								Here's where your municipal property taxes go&mdash;and the services you get for your
								money, every day.
							</Text>
						</Box>
						<Box
							maxW={700}
							mb={3}>
							<Text fontSize={"lg"}>Please note that the percentages below are rounded values.</Text>
						</Box>
						<Table
							width={"100%"}
							cellPadding={0}
							className='breakdown-table'>
							<Tbody>
								{breakdown.webTaxBreakdownCol.map((item, index) => (
									<React.Fragment key={index}>
										<Tr>
											<Td w={330}>{item.displayLabel}</Td>
											<Td isNumeric>{CommonFns.FormatPercent(item.percent, 1)}</Td>
											<Td isNumeric>{CommonFns.FormatCurrency(item.totalMuniTax, 2)}</Td>
										</Tr>
									</React.Fragment>
								))}
							</Tbody>
							<Tfoot>
								<Tr>
									<Td colSpan={2}>Your Municipal Property Taxes</Td>
									<Td isNumeric>{CommonFns.FormatCurrency(breakdown.totalMuniTax, 2)}</Td>
								</Tr>
							</Tfoot>
						</Table>
					</React.Fragment>
				) : (
					<React.Fragment>Details are unavailable at this time.</React.Fragment>
				)
			) : (
				<React.Fragment>Details are unavailable at this time.</React.Fragment>
			)}
		</React.Fragment>
	);
};

export default Breakdown;
