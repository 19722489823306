import * as CommonFns from "../../../common";
import * as toastMessages from "../../../toastMessages";

import {
	Box,
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	chakra,
	useBreakpointValue,
	useDisclosure,
	useMediaQuery,
	useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AuditContext } from "../../../contexts/AuditContext";
import { AuthContext } from "../../../contexts/AuthContext";
import Complexity from "../../Global/Password/Complexity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { axiosMyProperty } from "../../../apis";
import { useForm } from "react-hook-form";

const SignIn = () => {
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });
	const [isLargerThan480] = useMediaQuery("(min-width: 480px)");
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { authDetails, setAuthDetails } = useContext(AuthContext);
	const { auditActivity } = useContext(AuditContext);

	let navigate = useNavigate();
	const location = useLocation();

	const toast = useToast();
	const signInToastErrorId = "sign-in-toast-error";
	const signInToastWarningId = "sign-in-toast-warning";

	const [passwordShow, setPasswordShow] = useState(false);
	const [isResendEmail, setIsResendEmail] = useState(false);

	const formLabelMinWidth = 145;
	const formControlMinWidth = 405;

	const handleShowHidePassword = () => setPasswordShow(!passwordShow);

	const emailParam = new URLSearchParams(location.search).get("emailAddress");

	useEffect(() => {
		//get the client IP address for sign in
		CommonFns.getIP().then((res) => setValue("ipAddress", res));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// auto focus field on render
	const autoFocus = useRef(null);
	useEffect(() => {
		autoFocus.current.focus();
	}, []);

	const {
		register,
		handleSubmit,
		setValue,
		watch,
		formState: { errors, isSubmitting },
	} = useForm({
		defaultValues: {
			emailAddress: emailParam ?? "",
			ipAddress: "",
		},
	});

	const watchEmailAddress = watch("emailAddress", "");

	const onSubmit = async (data) => {
		localStorage.setItem("token", "");
		localStorage.setItem("feedbackReason", "");
		let payload = data;
		let url_signin = "account/signin";
		await axiosMyProperty
			.post(url_signin, payload)
			.then((resp) => {
				let returnObj = resp.data;
				let result = JSON.parse(returnObj.result);

				if (result.successfulLogin === "Y" && result.isActive === "Y" && result.emailVerified === "Y") {
					// get the token from the response
					// set this in local storage
					localStorage.setItem("token", returnObj.token);

					let profile = JSON.parse(result.profile);

					setAuthDetails({
						...authDetails,
						isAuthenticated: true,
						emailAddress: data.emailAddress,
						profileName: result.name,
						uId: result.profileUid,
						isAdmin: result.adminLogin === "Y" ? true : false,
						unreadMailCount: result.newMyMailOwnerCount,
						unreadSharedMailCount: result.newMyMailViewerCount,
						inUpdateEmailProcess: result.inNewEmailVerifyProcess === "Y" ? true : false,
						displayPaperlessPromo: profile.displayPaperlessPromo,
						profileIsNew: profile.profileIsNew,
						mobilePhoneNumberAttempts: 0,
						displayPendingAuthorizedProperties: "Y",
					});

					// audit
					auditActivity("Sign In", 0, result.profileUid);

					// redirect
					navigate("/auth/property", { replace: true });
				} else if (result.successfulLogin === "N" && result.emailVerified === "N") {
					handleResendModalOpen();
					setAuthDetails({
						...authDetails,
						profileName: result.name,
						uId: result.profileUid,
					});

					localStorage.setItem("token", "");
				} else {
					if (!toast.isActive(signInToastWarningId)) {
						toast(
							toastMessages.getToastWarning(
								signInToastWarningId,
								result.errorMsg,
								toastMessages.signInMessages
							)
						);
					}
				}
			})
			.catch((e) => {
				// error
				if (!toast.isActive(signInToastErrorId)) {
					toast(toastMessages.getToastError(signInToastErrorId));
				}
			});
	};

	const handleCreateProfile = (event) => {
		event.preventDefault();
		navigate("/create-profile", { replace: true });
	};

	const handleForgotPassword = (event) => {
		event.preventDefault();
		auditActivity("Forgot Password");
		navigate("/forgot-password", { replace: true });
	};

	const handleResendModalOpen = () => {
		setIsResendEmail(false);
		onOpen();
	};

	const handleResendModalClose = () => {
		//e.preventDefault();
		setIsResendEmail(false);
		onClose();
	};

	const handleResendVerificationEmail = (e) => {
		let url = "account/verify";

		let payload = {
			uId: authDetails.uId,
			toAddress: watchEmailAddress,
			profileName: authDetails.profileName,
		};

		axiosMyProperty
			.post(url, payload)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.isSuccess) {
					setIsResendEmail(true);
					payload = null;
				} else {
					payload = null;
					if (!toast.isActive(signInToastWarningId)) {
						toast(
							toastMessages.getToastWarning(
								signInToastWarningId,
								returnObj.errorMsg,
								toastMessages.signInMessages
							)
						);
					}
				}
			})
			.catch((e) => {
				if (!toast.isActive(signInToastErrorId)) {
					toast(toastMessages.getToastError(signInToastErrorId));
				}
			});
	};

	return (
		<React.Fragment>
			<form onSubmit={handleSubmit(async (data) => await onSubmit(data))}>
				<FormControl>
					<Flex direction={["column", "column", "row"]}>
						<Box
							minW={formLabelMinWidth}
							display={isSmallerScreen ? "none" : "normal"}>
							<FormLabel>&nbsp;</FormLabel>
						</Box>
						<Box>
							<chakra.span
								fontSize={"1.45em"}
								fontWeight={400}>
								Sign In
							</chakra.span>
						</Box>
					</Flex>
				</FormControl>
				<FormControl
					isInvalid={errors.emailAddress}
					mt={2}>
					<Flex direction={["column", "column", "row"]}>
						<Box minW={formLabelMinWidth}>
							<FormLabel
								htmlFor='emailAddress'
								textAlign={isSmallerScreen ? "" : "end"}
								className='form-label-fixed'
								ref={autoFocus}>
								Your Email
							</FormLabel>
						</Box>
						<Box minW={!isLargerThan480 ? "" : formControlMinWidth}>
							<Input
								id='emailAddress'
								type='email'
								autoComplete='off'
								{...register("emailAddress", {
									required: "Your Email is required",
									pattern: {
										value: /^[\w.-]+@([\w-]+\.)+[\w-]{2,}$/g,
										message: "Your Email is invalid",
									},
								})}
							/>
						</Box>
						<Box ml={isSmallerScreen ? 0 : 3}>
							<FormErrorMessage
								colorScheme={"red"}
								className={"text-error pos"}>
								{errors.emailAddress && errors.emailAddress.message}
							</FormErrorMessage>
						</Box>
					</Flex>
				</FormControl>
				<FormControl
					isInvalid={errors.password}
					mt={3}>
					<Flex direction={["column", "column", "row"]}>
						<Box minW={formLabelMinWidth}>
							<FormLabel
								htmlFor='password'
								textAlign={isSmallerScreen ? "" : "end"}
								className='form-label-fixed'>
								<Complexity></Complexity>
								Your Password
							</FormLabel>
						</Box>
						<Box minW={!isLargerThan480 ? "" : formControlMinWidth}>
							<InputGroup size='md'>
								<Input
									id='password'
									type={passwordShow ? "text" : "password"}
									autoComplete='off'
									{...register("password", {
										required: "Your Password is required",
									})}
								/>
								<InputRightElement width='4.5rem'>
									<Button
										h='1.75rem'
										w={"56px"}
										size='sm'
										colorScheme={"lightBlue"}
										onClick={handleShowHidePassword}
										tabIndex={-1}>
										{passwordShow ? "Hide" : "Show"}
									</Button>
								</InputRightElement>
							</InputGroup>
						</Box>
						<Box ml={isSmallerScreen ? 0 : 3}>
							<FormErrorMessage
								colorScheme={"red"}
								className={"text-error pos"}>
								{errors.password && errors.password.message}
							</FormErrorMessage>
						</Box>
					</Flex>
				</FormControl>
				<FormControl mt={isSmallerScreen ? 6 : 3}>
					<Flex direction={["column", "column", "row"]}>
						<Box
							minW={formLabelMinWidth}
							display={isSmallerScreen ? "none" : "normal"}>
							<FormLabel>&nbsp;</FormLabel>
						</Box>
						<Box>
							<Flex direction={["column", "column", "row"]}>
								<Button
									isLoading={isSubmitting}
									loadingText={"SUBMIT"}
									colorScheme='blue'
									type={"submit"}
									mr={isSmallerScreen ? 0 : 4}>
									SUBMIT
								</Button>
								<Button
									colorScheme='grey'
									variant='ghost'
									mt={isSmallerScreen ? 6 : 0}
									onClick={handleCreateProfile}>
									Create Profile
								</Button>
								<Button
									colorScheme='grey'
									variant='ghost'
									mt={isSmallerScreen ? 4 : 0}
									onClick={handleForgotPassword}>
									Forgot Password?
								</Button>
							</Flex>
						</Box>
					</Flex>
				</FormControl>
			</form>

			<Modal
				closeOnOverlayClick={false}
				isOpen={isOpen}
				onClose={handleResendModalClose}>
				<ModalOverlay />
				<ModalContent maxW={"57rem"}>
					<ModalHeader className={"modal-header"}>Profile Not Verified</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					<ModalBody>
						{!isResendEmail ? (
							<React.Fragment>
								<Box>
									It looks like your profile has yet to be verified. Please check your email for a
									verification link.
								</Box>
								<Box mt={2}>
									If this link has expired or you no longer have the link, please{" "}
									<Link onClick={(e) => handleResendVerificationEmail(e)}>
										resend a verification email
									</Link>
									.
								</Box>
							</React.Fragment>
						) : (
							<React.Fragment>
								<Box>Thank you.</Box>
								<Box mt={2}>
									{" "}
									A new verification email has been sent to{" "}
									<chakra.span fontWeight={700}>{watchEmailAddress}</chakra.span>.
								</Box>
							</React.Fragment>
						)}
					</ModalBody>
					<ModalFooter className={"modal-footer"}>
						<Flex w={["100%", "100%", "auto"]}>
							<Button
								colorScheme='blue'
								minW={["100%", "100%", 100]}
								onClick={(e) => handleResendModalClose(e)}>
								OK
							</Button>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</React.Fragment>
	);
};

export default SignIn;
