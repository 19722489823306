// error handling
// messages
export const profileMessages = [
	{
		sourceMessage: "password incorrect",
		friendlyMessage: "Password is incorrect.",
	},
	{
		sourceMessage: "profile_uid does not exist",
		friendlyMessage: "The provided information could not be validated.",
	},
];

export const listMailMessages = [];

export const createProfileMessages = [
	{
		sourceMessage: "p_email_address exists",
		friendlyMessage: "The provided email has already been registered. Please sign-in.",
	},
	{
		sourceMessage: "isEmailPatternError",
		friendlyMessage: "Email Address is invalid, please review.",
	},
	{
		sourceMessage: "isEmailMatchError",
		friendlyMessage: "Email Addresses do not match, please review.",
	},
	{
		sourceMessage: "isPasswordComplexityError",
		friendlyMessage: "Password does not meet requirements, please review.",
	},
	{
		sourceMessage: "isPasswordMatchError",
		friendlyMessage: "Passwords do not match, please review.",
	},
	{
		sourceMessage: "isTermsSelectedError",
		friendlyMessage: "Please agree to the terms and conditions.",
	},
];

export const deleteProfileMessages = [
	{
		sourceMessage: "password incorrect",
		friendlyMessage: "Password is incorrect.",
	},
];

export const changeEmailMessages = [
	{
		sourceMessage: "password incorrect",
		friendlyMessage: "Password is incorrect.",
	},
];

export const crmTicketMessages = [
	{
		sourceMessage: "cannot sent email",
		friendlyMessage: "There was an issue sending your application request. Please try again later.",
	},
];

export const changePasswordMessages = [
	{
		sourceMessage: "password incorrect",
		friendlyMessage: "Current password is incorrect.",
	},
	{
		sourceMessage: "password_complexity",
		friendlyMessage:
			"Your New Password must be at least 8 characters in length. Your New Password must contain a lower and uppercase character. Your New Password must contain a numeric character.",
	},
	{
		sourceMessage: "password matches current password",
		friendlyMessage: "Your new password cannot be the same as your current password.",
	},
];

export const propertyMessages = [
	{
		sourceMessage: "The account number or access code is incorrect.",
		friendlyMessage: "Account Number or Access Code is incorrect.",
	},
	{
		sourceMessage: "This account is already in your profile.",
		friendlyMessage: "The account is already in your profile.",
	},
	{
		sourceMessage: "Profile UID does not exist",
		friendlyMessage: "Unable to retrieve properties at this time.",
	},
	{
		sourceMessage:
			"It looks like this account is not currently available on our secure site. We apologize for the inconvenience.",
		friendlyMessage:
			"It looks like this account is not currently available on our secure site. We apologize for the inconvenience.",
	},
];

export const propertyAuthorizationMessages = [
	{
		sourceMessage: "owner detail unavailable",
		friendlyMessage: "Authorized property information is unavailable at this time.",
	},
	{
		sourceMessage: "shared with me unavailable",
		friendlyMessage: "Shared with me property information is unavailable at this time.",
	},
	{
		sourceMessage: "issue removing property",
		friendlyMessage: "Unable to remove the authorized property at this time.",
	},
	{
		sourceMessage: "issue sorting property",
		friendlyMessage: "Unable to sort authorized property at this time.",
	},
];

export const shareYourInfoMessages = [
	{
		sourceMessage: "share your info unavailable",
		friendlyMessage: "Share your information is unavailable at this time.",
	},
];

export const signInMessages = [
	{
		sourceMessage: "Password incorrect",
		friendlyMessage: "Email address or password is incorrect.",
	},
	{
		sourceMessage: "Account does not exist",
		friendlyMessage: "Email address or password is incorrect.",
	},
];

export const mobilePhoneNumberMessages = [
	{
		sourceMessage: "aws validation failed",
		friendlyMessage: "The provided phone number must be a mobile phone number.",
	},
	{
		sourceMessage: "regex validation failed",
		friendlyMessage: "The provided phone number is formatted incorrectly.",
	},
	{
		sourceMessage: "set phone number failed",
		friendlyMessage: "Unable to set phone number for profile.",
	},
	{
		sourceMessage: "phone number match fail",
		friendlyMessage: "Could not re-validate the provided phone number.",
	},
	{
		sourceMessage: "verification code match fail",
		friendlyMessage: "The verification code is incorrect. Please check and retype.",
	},
	{
		sourceMessage: "too many attempts",
		friendlyMessage: "Too many attempts to validate a phone number, please try again later.",
	},
];

// gloabls
const warningTitle = "Oops!";
const exceptionTitle = "Error";
const successTitle = "Success!";
const infoTitle = "Info";
const genericMessage = "Unable to process your request at this time, please try again.";

// message gets
const getClientMessage = (message, messageArray) => {
	let friendlyMessage = messageArray.find(
		(msg) => msg.sourceMessage.toLowerCase() === message.toLowerCase()
	)?.friendlyMessage;
	return friendlyMessage ?? genericMessage;
};

// toasts
export const getToastWarning = (id, message, messageArray) => {
	if (message.toLowerCase() === "fatal") {
		getToastError(id);
	} else {
		return {
			id: id,
			title: warningTitle,
			description: getClientMessage(message, messageArray),
			status: "warning",
			position: "top-right",
			duration: 6000,
			isClosable: true,
		};
	}
};

export const getToastError = (id, msg = "") => {
	return {
		id: id,
		title: exceptionTitle,
		description: genericMessage,
		//description: msg,
		status: "error",
		position: "top-right",
		duration: 6000,
		isClosable: true,
	};
};

export const getToastSuccess = (id, message) => {
	return {
		id: id,
		title: successTitle,
		description: message, // ... has been...
		status: "success",
		position: "top-right",
		duration: 3000,
		isClosable: true,
	};
};

export const getToastInfo = (id, message) => {
	return {
		id: id,
		title: infoTitle,
		description: message,
		status: "info",
		position: "top-right",
		duration: 9000,
		isClosable: true,
		variant: "subtle",
	};
};

// ./error handling
