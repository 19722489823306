import "../../History.css";
import "chart.js/auto";

import { Box, HStack, chakra } from "@chakra-ui/react";

import { Line } from "react-chartjs-2";
import React from "react";

const ValueChange = ({ historyDetails }) => {
	return (
		<React.Fragment>
			<Line
				datasetIdKey="id"
				options={{
					//maintainAspectRatio: false,
					responsive: true,
					scales: {
						x: {
							title: {
								display: true,
								text: "Year",
								font: {
									size: "16",
								},
							},
							offset: true,
							grid: {
								borderColor: "#949494",
								borderWidth: 2,
							},
						},
						y: {
							title: {
								display: true,
								text: "Property Value",
								font: {
									size: "16",
								},
							},
							ticks: {
								// Include a dollar sign in the ticks
								callback: function (value, index, ticks) {
									return value.toLocaleString("en-CA", {
										style: "currency",
										currency: "CAD",
									});
								},
							},
							grid: {
								borderColor: "#949494",
								borderWidth: 2,
							},
							grace: "25%",
						},
					},
					plugins: {
						legend: {
							display: false,
						},
					},
				}}
				data={{
					labels: historyDetails.webAsmtHistCol.map((x) => x.asmtYear),
					datasets: [
						{
							id: 1,
							//label: "Your Property - Actual",
							data: historyDetails.webAsmtHistCol.map((x) => x.myPropAsmt),
							fill: false,
							borderColor: "#92d050",
							backgroundColor: "#92d050",
							tension: 0,
							pointStyle: "rect",
							pointRadius: 7,
							pointHoverRadius: 10,
						},
						{
							id: 2,
							//label: "Your Property - Had it followed the average",
							data: historyDetails.webAsmtHistCol.map((x) => x.cityAsmt),
							fill: false,
							borderColor: "#004060",
							backgroundColor: "#004060",
							tension: 0,
							pointStyle: "rect",
							pointRadius: 7,
							pointHoverRadius: 10,
						},
					],
				}}
			></Line>
			<Box className="history-legend-container-tax  alt" mt={5}>
				<HStack>
					<Box className="history-legend-alt-first"></Box>
					<Box className="history-legend-font">
						<chakra.span>Your property - Actual</chakra.span>
					</Box>
				</HStack>
			</Box>
			<Box className="history-legend-container-tax alt following">
				<HStack>
					<Box className="history-legend-alt-last"></Box>
					<Box className="history-legend-font">
						<chakra.span>Your property - Had it followed the average change</chakra.span>
					</Box>
				</HStack>
			</Box>
		</React.Fragment>
	);
};
export default ValueChange;
