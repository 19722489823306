import "./ProfileInformation.css";

import * as CommonFns from "../../../../common";
import * as toastMessages from "../../../../toastMessages";

import {
	Box,
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Link,
	Modal,
	ModalContent,
	ModalOverlay,
	Spacer,
	useBreakpointValue,
	useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";

import { AuthContext } from "../../../../contexts/AuthContext";
import ChangeEmail from "../ChangeEmail/ChangeEmail";
import DeleteProfile from "../DeleteProfile/DeleteProfile";
import { axiosMyPropertyAuth } from "../../../../apis";
import { useForm } from "react-hook-form";
import { useToast } from "@chakra-ui/react";

const ProfileInformation = ({ userProfile, handleLogout }) => {
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });
	const isNotLgScreen = useBreakpointValue({ base: true, lg: false });

	const { authDetails, setAuthDetails } = useContext(AuthContext);

	const { isOpen: isOpenDeleteProfile, onOpen: onOpenDeleteProfile, onClose: onCloseDeleteProfile } = useDisclosure();
	const { isOpen: isOpenChangeEmail, onOpen: onOpenChangeEmail, onClose: onCloseChangeEmail } = useDisclosure();

	const toast = useToast();
	const profileInformationToastErrorId = "profile-information-toast-error";
	const profileInformationToastSuccessId = "profile-information-toast-success";

	const formLabelMinWidth = 210;
	const formControlMinWidth = 350;

	const formInfoLabelMinWidth = 250;

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		defaultValues: {
			profileName: "",
			emailAddress: authDetails.emailAddress ?? "",
			setAgreeToFoipDate: "N",
		},
	});

	useEffect(() => {
		setValue("profileName", authDetails.profileName);
	}, [authDetails.profileName]);

	useEffect(() => {
		setValue("emailAddress", authDetails.emailAddress);
	}, [authDetails]);

	const onProfileUpdate = (data) => {
		let url = "profile";
		let payload = data;

		// where is uId set originally?
		// its set in data prior to being converted to payload
		payload.uId = "";

		axiosMyPropertyAuth
			.put(url, payload)
			.then((resp) => {
				//update profile name in context
				setAuthDetails({
					...authDetails,
					profileName: data.profileName,
				});

				if (!toast.isActive(profileInformationToastSuccessId)) {
					toast(toastMessages.getToastSuccess(profileInformationToastSuccessId, "Profile has been updated!"));
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(profileInformationToastErrorId)) {
					toast(toastMessages.getToastError(profileInformationToastErrorId));
				}
			});
	};

	// CHANGE EMAIL
	const handleChangeEmailModalOpen = (e) => {
		e.preventDefault();
		onOpenChangeEmail();
	};

	const handleChangeEmailModalClose = (e) => {
		e.preventDefault();
		onCloseChangeEmail();
	};
	// ./CHANGE EMAIL

	// DELETE PROFILE
	const handleDeleteProfileModalOpen = (e) => {
		e.preventDefault();
		onOpenDeleteProfile();
	};

	const handleDeleteProfileModalClose = (e) => {
		e.preventDefault();
		onCloseDeleteProfile();
	};
	// ./DELETE PROFILE

	return (
		<React.Fragment>
			<Box
				mt={3}
				mb={6}>
				<Flex direction={["column", "column", "column", "row"]}>
					<Box>
						<form onSubmit={handleSubmit(onProfileUpdate)}>
							<FormControl
								isInvalid={errors.profileName}
								mt={2}>
								<Flex direction={["column", "column", "row"]}>
									<Box
										minW={formLabelMinWidth}
										mr={3}>
										<FormLabel
											htmlFor='profileName'
											textAlign={isSmallerScreen ? "" : "end"}
											className='form-label-fixed'>
											How should we greet you?
										</FormLabel>
									</Box>
									<Box minW={formControlMinWidth}>
										<Input
											id='profileName'
											type='text'
											autoComplete='off'
											{...register("profileName", {
												required: "How should we greet you? is required.",
											})}
										/>
									</Box>
									<Box
										ml={isSmallerScreen ? 0 : 3}
										display={isSmallerScreen ? "normal" : "none"}>
										<FormErrorMessage
											colorScheme={"red"}
											className={"text-error pos"}>
											{errors.profileName && errors.profileName.message}
										</FormErrorMessage>
									</Box>
									<Box
										mt={isSmallerScreen ? 3 : 0}
										ml={isSmallerScreen ? 0 : 3}>
										<Button
											colorScheme='blue'
											type={"submit"}
											w={isSmallerScreen ? "100%" : 100}
											mr={isSmallerScreen ? 0 : 4}>
											UPDATE
										</Button>
									</Box>
								</Flex>
								<Flex
									display={isSmallerScreen ? "none" : "flex"}
									direction={["column", "column", "row"]}>
									<Box minW={formLabelMinWidth}>
										<FormLabel></FormLabel>
									</Box>
									<Box>
										<FormErrorMessage
											colorScheme={"red"}
											className={"text-error pos"}>
											{errors.profileName && errors.profileName.message}
										</FormErrorMessage>
									</Box>
								</Flex>
							</FormControl>

							<FormControl
								isInvalid={errors.emailAddress}
								mt={2}>
								<Flex direction={["column", "column", "row"]}>
									<Box
										minW={formLabelMinWidth}
										mr={3}>
										<FormLabel
											htmlFor='emailAddress'
											textAlign={isSmallerScreen ? "" : "end"}
											className='form-label-fixed'>
											Your email
										</FormLabel>
									</Box>
									<Box minW={formControlMinWidth}>
										<Input
											id='emailAddress'
											type='email'
											autoComplete='off'
											readOnly={true}
											{...register("emailAddress")}
										/>
									</Box>
									<Box mt={2}>
										<Link
											colorScheme='grey'
											variant='ghost'
											pl={isSmallerScreen ? 0 : 3}
											ml={isSmallerScreen ? 0 : 0}
											fontSize={"1.05em"}
											onClick={(e) => handleChangeEmailModalOpen(e)}>
											Change Email
										</Link>
									</Box>
								</Flex>
							</FormControl>

							<FormControl mt={isSmallerScreen ? 0 : 3}>
								<Flex direction={["column", "column", "row"]}>
									<Box
										minW={formLabelMinWidth}
										display={isSmallerScreen ? "none" : "normal"}
										mr={3}>
										<FormLabel>&nbsp;</FormLabel>
									</Box>
									<Box mt={isSmallerScreen ? 3 : 1}>
										<Link
											colorScheme='grey'
											variant='ghost'
											pl={0}
											fontSize={"1.05em"}
											onClick={(e) => handleDeleteProfileModalOpen(e)}>
											Delete Profile
										</Link>
									</Box>
								</Flex>
							</FormControl>
						</form>

						{/* CHANGE EMAIL MODAL */}
						<Modal
							size={"5xl"}
							closeOnOverlayClick={false}
							closeOnEsc={false}
							isOpen={isOpenChangeEmail}
							onClose={onCloseChangeEmail}>
							<ModalOverlay />
							<ModalContent>
								<ChangeEmail
									userProfile={userProfile}
									closeChangeEmailModal={handleChangeEmailModalClose}></ChangeEmail>
							</ModalContent>
						</Modal>
						{/* ./CHANGE EMAIL MODAL */}

						{/* DELETE PROFILE MODAL */}
						<Modal
							size={"xl"}
							closeOnOverlayClick={false}
							isOpen={isOpenDeleteProfile}
							onClose={onCloseDeleteProfile}>
							<ModalOverlay />
							<ModalContent>
								<DeleteProfile
									emailAddress={authDetails.emailAddress}
									handleLogout={handleLogout}
									handleDeleteProfileModalClose={handleDeleteProfileModalClose}></DeleteProfile>
							</ModalContent>
						</Modal>
						{/* ./DELETE PROFILE MODAL */}
					</Box>
					<Spacer></Spacer>
					<Box
						mt={isNotLgScreen ? 4 : 0}
						w={isNotLgScreen ? "100%" : "35%"}>
						<Box
							className='profile-information-container'
							maxH={"180px"}
							mt={2}>
							<Box>
								<Flex direction={["row", "row", "row"]}>
									<Box minW={isSmallerScreen ? "145px" : formInfoLabelMinWidth}>
										<FormLabel
											textAlign={isSmallerScreen ? "left" : "end"}
											ml={isSmallerScreen ? 4 : 0}
											className='form-label-fixed'>
											Agreed to FOIP
										</FormLabel>
									</Box>
									<Box minW={isSmallerScreen ? "" : formInfoLabelMinWidth}>
										<FormLabel
											className='form-label-fixed'
											fontWeight={700}>
											{CommonFns.FormatDate(userProfile?.agreeToFoidDate)}
										</FormLabel>
									</Box>
								</Flex>
							</Box>

							<Box>
								<Flex direction={["row", "row", "row"]}>
									<Box minW={isSmallerScreen ? "145px" : formInfoLabelMinWidth}>
										<FormLabel
											textAlign={isSmallerScreen ? "left" : "end"}
											ml={isSmallerScreen ? 4 : 0}
											className='form-label-fixed'>
											Email Verified
										</FormLabel>
									</Box>
									<Box minW={isSmallerScreen ? "" : formInfoLabelMinWidth}>
										<FormLabel
											className='form-label-fixed'
											fontWeight={700}>
											{CommonFns.FormatDate(userProfile?.emailVerifiedDate)}
										</FormLabel>
									</Box>
								</Flex>
							</Box>

							<Box>
								<Flex direction={["row", "row", "row"]}>
									<Box minW={isSmallerScreen ? "145px" : formInfoLabelMinWidth}>
										<FormLabel
											textAlign={isSmallerScreen ? "left" : "end"}
											ml={isSmallerScreen ? 4 : 0}
											className='form-label-fixed'>
											Notification Preferences Set
										</FormLabel>
									</Box>
									<Box minW={isSmallerScreen ? "" : formInfoLabelMinWidth}>
										<FormLabel
											className='form-label-fixed'
											fontWeight={700}>
											{userProfile?.electNotifnConsentDate == null
												? "N/A"
												: CommonFns.FormatDate(userProfile?.electNotifnConsentDate)}
										</FormLabel>
									</Box>
								</Flex>
							</Box>
						</Box>
					</Box>
				</Flex>
			</Box>
		</React.Fragment>
	);
};
export default ProfileInformation;
