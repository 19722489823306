import { Box, Text, chakra, useMediaQuery } from "@chakra-ui/react";

import React from "react";

const WaysToPay = () => {
	const [isLargerThan992] = useMediaQuery("(min-width: 992px)");

	return (
		<Box
			px={3}
			pt={1}
			pb={3}>
			<Box display={{ lg: "flex" }}>
				<Box
					minW={"33.3%"}
					pr={4}
					borderRight={isLargerThan992 ? "1px solid #fff" : "none"}
					borderBottom={isLargerThan992 ? "none" : "1px solid #fff"}
					pb={isLargerThan992 ? 0 : 4}>
					<Box
						fontWeight={700}
						textTransform={"uppercase"}>
						Through Your Bank
					</Box>
					<Box fontSize={".9em"}>
						<Text mt={2}>
							You can pay property taxes through your bank: online banking, telephone banking or in
							person.
						</Text>
						<Text mt={3}>
							When you are paying online through your bank, ensure you link your payment to the account
							number indicated on your property tax notice, particularly if you have purchased a new home.
						</Text>
					</Box>
				</Box>
				<Box
					minW={"33.3%"}
					pr={isLargerThan992 ? 4 : 0}
					pl={isLargerThan992 ? 4 : 0}
					borderRight={isLargerThan992 ? "1px solid #fff" : "none"}
					borderBottom={isLargerThan992 ? "none" : "1px solid #fff"}
					pb={isLargerThan992 ? 0 : 4}
					pt={isLargerThan992 ? 0 : 4}>
					<Box
						fontWeight={700}
						textTransform={"uppercase"}>
						Monthly
					</Box>
					<Box fontSize={".9em"}>
						<Text mt={2}>
							The City's property tax <chakra.span fontWeight={700}>monthly payment plan</chakra.span>{" "}
							allows you to pay property taxes by pre-authorized automatic bank withdrawal and spread your
							property tax payments over the year.
						</Text>
						<Text mt={3}>
							You can also pay property taxes through your{" "}
							<chakra.span fontWeight={700}>mortgage</chakra.span>. Talk to your bank if you are
							interested to set up this option.
						</Text>
					</Box>
				</Box>
				<Box
					minW={"33.3%"}
					pl={isLargerThan992 ? 4 : 0}
					pt={isLargerThan992 ? 0 : 4}>
					<Box
						fontWeight={700}
						textTransform={"uppercase"}>
						By Mail
					</Box>
					<Box fontSize={".9em"}>
						<Text mt={2}>
							If you wish to pay taxes by mail, send a cheque or money order and the remittance portion of
							your tax notice to the following address:
						</Text>
						<Box
							mt={3}
							ml={3}>
							<Box mt={0}>City of Edmonton</Box>
							<Box mt={0}>PO Box 1982</Box>
							<Box mt={0}>Edmonton, AB T5J 3X5</Box>
						</Box>
						<Text mt={3}>
							If you are paying by mail, allow ample time for the payment to arrive prior to June 30.
						</Text>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default WaysToPay;
