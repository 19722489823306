import * as CommonFns from "../common";

import { createContext, useContext } from "react";

import { AuthContext } from "../contexts/AuthContext";
import { axiosMyProperty } from "../apis";

export const AuditContext = createContext();

const AuditContextProvider = ({ children }) => {
	const { authDetails } = useContext(AuthContext);

	const auditActivity = (activity, accId = 0, uId = "") => {
		CommonFns.getIP().then((resp) => {
			// ipAddress
			let ip = resp;

			// accId
			let accountNumber;
			if (accId !== 0) {
				accountNumber = accId;
			} else {
				if ((authDetails.accId === null) | (authDetails.accId === "null")) {
					accountNumber = 0;
				} else {
					accountNumber = authDetails.accId;
				}
			}

			// uId
			let userProfileId;
			if (uId !== "") {
				userProfileId = uId;
			} else {
				if ((authDetails.uId === null) | (authDetails.uId === "null")) {
					userProfileId = "";
				} else {
					userProfileId = authDetails.uId;
				}
			}

			let url = "common/activitylog";
			let payload = {
				uId: userProfileId,
				ipAddress: ip,
				accId: accountNumber,
				activity: activity,
			};

			axiosMyProperty.post(url, payload);
		});
	};

	const auditActivityAsync = async (activity, accId = 0, uId = "") => {
		await CommonFns.getIP().then((resp) => {
			// ipAddress
			let ip = resp;

			// accId
			let accountNumber;
			if (accId !== 0) {
				accountNumber = accId;
			} else {
				if ((authDetails.accId === null) | (authDetails.accId === "null")) {
					accountNumber = 0;
				} else {
					accountNumber = authDetails.accId;
				}
			}

			// uId
			let userProfileId;
			if (uId !== "") {
				userProfileId = uId;
			} else {
				if ((authDetails.uId === null) | (authDetails.uId === "null")) {
					userProfileId = "";
				} else {
					userProfileId = authDetails.uId;
				}
			}

			let url = "common/activitylog";
			let payload = {
				uId: userProfileId,
				ipAddress: ip,
				accId: accountNumber,
				activity: activity,
			};

			axiosMyProperty.post(url, payload);
		});
	};

	return <AuditContext.Provider value={{ auditActivity, auditActivityAsync }}>{children}</AuditContext.Provider>;
};

export default AuditContextProvider;
