import React, { useEffect } from 'react';

const SignOut = ({ handleLogout }) => {
	useEffect(() => {
		handleLogout('expired');
	}, []);

	return <></>;
};

export default SignOut;
