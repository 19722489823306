import "./App.css";
import "../src/components/Global/Styles/Tabs.css";

import * as toastMessages from "./toastMessages";

import {
	Box,
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { axiosMyProperty, axiosMyPropertyAuth } from "./apis";
import { faCalendar, faCircle, faClock, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
	faCaretDown,
	faCheck,
	faCheckCircle,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faChevronUp,
	faCircleCheck,
	faCircleInfo,
	faCircleXmark,
	faDollarSign,
	faEquals,
	faExclamationCircle,
	faEye,
	faFilter,
	faHome,
	faMinus,
	faPercent,
	faPlus,
	faQuestionCircle,
	faSearch,
	faSquareFull,
	faSquareXmark,
	faSyncAlt,
	faTimes,
	faTimesCircle,
	faUsers,
	faXmark,
} from "@fortawesome/free-solid-svg-icons";

import { AuthContext } from "./contexts/AuthContext";
import Banner from "./components/Authentication/Banner/Banner";
import { BannerContext } from "./contexts/BannerContext";
import CreateAccountVerify from "./components/Account/Create/Verify/Verify";
import CreateProfile from "./components/Account/Create/CreateProfile/CreateProfile";
import Detail from "./components/Authentication/Property/Detail/Detail";
import Feedback from "./components/Authentication/Feedback/Feedback";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "./components/Global/Footer/Footer";
import ForgotPasswordRequest from "./components/Account/Password/ForgotPasswordRequest";
import ForgotPasswordReset from "./components/Account/Password/ForgotPasswordReset";
import Header from "./components/Global/Header/Header";
import Home from "./components/Home/Home";
import Layout from "./components/Global/Layout";
import LegalText from "./components/Global/LegalText/LegalText";
import MyMail from "./components/Authentication/MyMail/MyMail";
import Navigation from "./components/Global/Navigation/Navigation";
import NavigationAuth from "./components/Authentication/Navigation/Navigation";
import NotFound from "./components/Global/NotFound/NotFound";
import Paperless from "./components/Authentication/Splash/Campaign/Paperless/Paperless";
import Profile from "./components/Authentication/Profile/Profile";
import Property from "./components/Authentication/Property/Property";
import ReactGA from "react-ga4";
import ScrollToTop from "./components/Global/ScrollToTop/ScrollToTop";
import SignIn from "./components/Account/SignIn/SignIn";
import SignOut from "./components/Account/SignOut/SignOut";
import ThankYou from "./components/Account/Create/ThankYou/ThankYou";
import Title from "./components/Global/Title/Title";
import UpdateEmailVerify from "./components/Account/ChangeEmail/Verify";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useIdleTimer } from "react-idle-timer";

library.add(
	faUsers,
	faSquareFull,
	faEnvelope,
	faDollarSign,
	faEquals,
	faHome,
	faCalendar,
	faClock,
	faCircle,
	faCheckCircle,
	faPlus,
	faMinus,
	faChevronUp,
	faExclamationCircle,
	faTimesCircle,
	faQuestionCircle,
	faTimes,
	faChevronDown,
	faChevronRight,
	faChevronLeft,
	faEye,
	faCircleCheck,
	faCircleInfo,
	faSquareXmark,
	faCheck,
	faCaretDown,
	faPercent,
	faCircleXmark,
	faFilter,
	faSyncAlt,
	faSearch,
	faXmark
);

const App = () => {
	const location = useLocation();
	const propertyRoute = "/auth/property";

	const [env] = useState(process.env.REACT_APP_ENVIRONMENT);
	const [gaMeasurementId] = useState(process.env.REACT_APP_GA_MEASUREMENT_ID);

	const { authDetails, setAuthDetails, resetAuthDetails } = useContext(AuthContext);
	const [isAuthenticated, _setIsAuthenticated] = React.useState(authDetails.isAuthenticated);

	const isAuthenticatedRef = React.useRef(isAuthenticated);
	const setIsAuthenticated = (isAuth) => {
		isAuthenticatedRef.current = isAuth;
		_setIsAuthenticated(isAuth);
	};

	const { resetBannerItems } = useContext(BannerContext);

	const [unreadMailCount, setUnreadMailCount] = useState();
	const [propertyCount, setPropertyCount] = useState();
	const [addRemovePropertyToggle, setAddRemovePropertyToggle] = useState(false);

	const [userProfile, setUserProfile] = useState();

	const [siteClosure, setSiteClosure] = useState("N");
	const [siteClosureMessage, setSiteClosureMessage] = useState("");

	const toast = useToast();
	const profileToastErrorId = "profile-toast-error";

	const { isOpen, onOpen, onClose } = useDisclosure();
	const [appStatusTitle, setAppStatusTitle] = useState("");
	const [appStatusMessage, setAppStatusMessage] = useState("");

	let navigate = useNavigate();
	const timeout = process.env.REACT_APP_SESSION_TIMEOUT;

	if (env === "production") {
		ReactGA.initialize([
			{
				trackingId: gaMeasurementId,
				gaOptions: {
					standardImplementation: true,
				},
			},
		]);
	}

	useEffect(() => {
		if (env === "production") {
			ReactGA.send({ hitType: "pageview", page: location.pathname, title: location.pathname });
		}
	}, [location.pathname]);

	useEffect(() => {
		propertyCount === undefined ? setPropertyCount(authDetails.propertyCount) : setPropertyCount(propertyCount);

		checkSiteClosure();
		document.addEventListener("click", checkSiteClosure, false);
		document.addEventListener("submit", checkSiteClosure, false);
	}, []);

	useEffect(() => {
		if (Number(unreadMailCount) !== Number(authDetails.unreadMailCount)) {
			setUnreadMailCount(Number(authDetails.unreadMailCount));
		}
	}, [authDetails.unreadMailCount]);

	useEffect(() => {
		setIsAuthenticated(authDetails.isAuthenticated);
	}, [authDetails.isAuthenticated]);

	const checkSiteClosure = () => {
		if (siteClosure === "Y") {
			//check if app config to see if site should be closed
			handleLogout();
		} else {
			//check tacs to see if site should be closed
			getTacsSiteClosure();
		}
	};

	const getTacsSiteClosure = async () => {
		let url = "closure";
		let axiosInstance = isAuthenticatedRef.current ? axiosMyPropertyAuth : axiosMyProperty;

		return await axiosInstance
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;
				let isClosed = resp.data.isClosed;

				if (isClosed === "Y") {
					handleLogout();
				}

				setSiteClosure(isClosed);
				setSiteClosureMessage(returnObj.closureMsg);
			})
			.catch((error) => {
				// error
				// not handling errors
				// no need to display an error/popup in the case this fails
			});
	};

	const handleOnIdle = () => {
		if (isAuthenticated) {
			handleLogout("expired");
			onOpen();
		}
	};

	useIdleTimer({
		timeout,
		onIdle: handleOnIdle,
	});

	function handleLogout(arg) {
		//e.preventDefault();

		if (arg !== undefined) {
			switch (arg.toLowerCase()) {
				case "deleted":
					setAppStatusTitle("Profile Deleted");
					setAppStatusMessage("Your profile has been deleted. We are sorry to see you go.");
					onOpen();
					break;
				case "expired":
					setAppStatusTitle("Session Expired");
					setAppStatusMessage("Your session has expired, please sign in again.");
					onOpen();
				// no default
			}
		} else {
			setAppStatusTitle("");
			setAppStatusMessage("");
		}

		resetAuthDetails();
		resetBannerItems();
		localStorage.setItem("token", "");
		localStorage.setItem("feedbackReason", "");
		navigate("/", { replace: true });
	}

	const managePropertyAndMail = (propertyAction, accId, listedPropCount, newMyMailCount) => {
		if (isAuthenticated) {
			let propCount = Number(authDetails.propertyCount);

			// "verify" does not affect propertyCount
			switch (propertyAction) {
				case "add":
					propCount += 1;
					setAddRemovePropertyToggle(!addRemovePropertyToggle);
					break;

				case "remove":
					propCount -= 1;
					setAddRemovePropertyToggle(!addRemovePropertyToggle);
					break;

				case "listed":
					propCount = Number(listedPropCount);
					break;
				// no default
			}

			setPropertyCount(propCount);

			if (newMyMailCount !== undefined) {
				setUnreadMailCount(newMyMailCount);
			} else {
				newMyMailCount = authDetails.unreadMailCount;
			}

			if (propCount === 0) {
				setAuthDetails({
					...authDetails,
					propertyCount: propCount,
					addPropertyModal: false,
					unreadMailCount: 0,
					accId: accId,
				});
			} else {
				setAuthDetails({
					...authDetails,
					propertyCount: propCount,
					addPropertyModal: false,
					unreadMailCount: Number(newMyMailCount),
					accId: accId,
				});
			}
		}
	};

	async function getUserProfile() {
		let url = `profile`;
		return await axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				setUserProfile(resp.data);
				return resp.data;
			})
			.catch((error) => {
				// error
				if (!toast.isActive(profileToastErrorId)) {
					toast(toastMessages.getToastError(profileToastErrorId));
				}
			});
	}

	return (
		<React.Fragment>
			<Layout>
				<Header></Header>
				{isAuthenticated ? (
					<>
						<NavigationAuth
							handleLogout={handleLogout}
							unreadMailCount={unreadMailCount}></NavigationAuth>
					</>
				) : (
					<Navigation></Navigation>
				)}
				{location.pathname === propertyRoute ? <Banner></Banner> : null}
				<Box
					pl={15}
					pr={15}>
					<Title></Title>
					<ScrollToTop>
						<Routes>
							<Route
								path='*'
								element={<NotFound />}
							/>
							<Route
								path='/'
								element={
									<Home
										siteClosure={siteClosure}
										siteClosureMessage={siteClosureMessage}
									/>
								}
							/>
							<Route
								path='/sign-in'
								element={
									siteClosure === "N" ? (
										<SignIn />
									) : (
										<Navigate
											to='/'
											replace
										/>
									)
								}
							/>
							<Route
								path='/create-profile'
								element={
									siteClosure === "N" ? (
										<CreateProfile />
									) : (
										<Navigate
											to='/'
											replace
										/>
									)
								}
							/>
							<Route
								path='/create-profile/thank-you'
								element={
									siteClosure === "N" ? (
										<ThankYou />
									) : (
										<Navigate
											to='/'
											replace
										/>
									)
								}
							/>
							<Route
								path='/create-profile/verify'
								element={
									siteClosure === "N" ? (
										<CreateAccountVerify />
									) : (
										<Navigate
											to='/'
											replace
										/>
									)
								}
							/>
							<Route
								path='/forgot-password'
								element={
									siteClosure === "N" ? (
										<ForgotPasswordRequest />
									) : (
										<Navigate
											to='/'
											replace
										/>
									)
								}
							/>
							<Route
								path='/forgot-password/reset'
								element={
									siteClosure === "N" ? (
										<ForgotPasswordReset />
									) : (
										<Navigate
											to='/'
											replace
										/>
									)
								}
							/>
							<Route
								path='/update-email/verify'
								element={
									siteClosure === "N" ? (
										<UpdateEmailVerify />
									) : (
										<Navigate
											to='/'
											replace
										/>
									)
								}
							/>
							<Route
								path='/legal'
								element={
									siteClosure === "N" ? (
										<LegalText />
									) : (
										<Navigate
											to='/'
											replace
										/>
									)
								}
							/>
							<Route
								path='/sign-out'
								element={<SignOut handleLogout={handleLogout} />}
							/>

							<Route
								path='/auth/campaign/paperless'
								element={
									isAuthenticated ? (
										<Paperless />
									) : (
										<Navigate
											to='/'
											replace
										/>
									)
								}
							/>

							<Route
								path='/auth/property'
								element={
									isAuthenticated & (siteClosure === "N") ? (
										<Property
											managePropertyAndMail={managePropertyAndMail}
											propertyCount={propertyCount}
											addRemovePropertyToggle={addRemovePropertyToggle}
										/>
									) : (
										<Navigate
											to='/'
											replace
										/>
									)
								}
							/>
							<Route
								path='/auth/property-detail'
								element={
									isAuthenticated & (siteClosure === "N") ? (
										<Detail />
									) : (
										<Navigate
											to='/'
											replace
										/>
									)
								}
							/>
							<Route
								path='/auth/my-mail'
								element={
									isAuthenticated & (siteClosure === "N") ? (
										<MyMail
											propertyCount={propertyCount}
											addRemovePropertyToggle={addRemovePropertyToggle}
										/>
									) : (
										<Navigate
											to='/'
											replace
										/>
									)
								}
							/>
							<Route
								path='/auth/feedback'
								element={
									isAuthenticated & (siteClosure === "N") ? (
										<Feedback />
									) : (
										<Navigate
											to='/'
											replace
										/>
									)
								}
							/>
							<Route
								path='/auth/profile'
								element={
									isAuthenticated & (siteClosure === "N") ? (
										<Profile
											userProfile={userProfile}
											getUserProfile={getUserProfile}
											managePropertyAndMail={managePropertyAndMail}
											handleLogout={handleLogout}
										/>
									) : (
										<Navigate
											to='/'
											replace
										/>
									)
								}
							/>
						</Routes>
					</ScrollToTop>
				</Box>
			</Layout>
			<Footer></Footer>

			<Modal
				closeOnOverlayClick={false}
				isOpen={isOpen}
				onClose={onClose}>
				<ModalOverlay />
				<ModalContent maxW={"35rem"}>
					<ModalHeader className={"modal-header"}>{appStatusTitle}</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					<ModalBody>
						<Box
							pt={3}
							pb={5}>
							{appStatusMessage}
						</Box>
					</ModalBody>
					<ModalFooter className={"modal-footer"}>
						<Flex w={["100%", "100%", "auto"]}>
							<Button
								colorScheme='blue'
								size={"md"}
								minW={["100%", "100%", 100]}
								onClick={() => onClose()}>
								OK
							</Button>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</React.Fragment>
	);
};

export default App;
