import "../../History.css";
import "chart.js/auto";

import { Box, HStack, LinkBox, LinkOverlay, chakra } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { Bar } from "react-chartjs-2";

const Breakdown = ({ historyDetails, totalGreaterThan15000, totalGreaterThan60000 }) => {
	const [breakdownData, setBreakdownData] = useState([]);
	const [propMunicipalData, setPropMunicipalData] = useState([]);
	const [propProvincialData, setPropProvincialData] = useState([]);
	const [cityMunicipalData, setCityMunicipalData] = useState([]);
	const [cityProvincialData, setCityProvincialData] = useState([]);

	const [showPropMunicipal, setShowPropMunicipal] = useState(true);
	const [showPropProvincial, setShowPropProvincial] = useState(true);
	const [showCityMunicipal, setShowCityMunicipal] = useState(true);
	const [showCityProvincial, setShowCityProvincial] = useState(true);

	useEffect(() => {
		if (historyDetails !== undefined) {
			let propMuni = [
				{
					//Your Property - Muni
					label: historyDetails.webTaxHistCol.map((x) => x.asmtYear),
					type: "bar",
					stack: "Prop/Actual",
					backgroundColor: historyDetails.webTaxHistCol.map((x) =>
						x.baselineRecordFlag === "Y" ? "#b5b5b5" : "#607a19"
					),
					data: historyDetails.webTaxHistCol.map((x) => x.myPropTaxMuni),
				},
			];

			let propProv = [
				{
					//Your Property - Provincial
					label: historyDetails.webTaxHistCol.map((x) => x.asmtYear),
					type: "bar",
					stack: "Prop/Actual",
					backgroundColor: historyDetails.webTaxHistCol.map((x) =>
						x.baselineRecordFlag === "Y" ? "#e2e2e2" : "#b2c435"
					),
					data: historyDetails.webTaxHistCol.map((x) => x.myPropTaxProv),
				},
			];

			let cityMuni = [
				{
					//City - Muni
					label: historyDetails.webTaxHistCol.map((x) => x.asmtYear),
					type: "bar",
					stack: "Prop/Avg",
					backgroundColor: historyDetails.webTaxHistCol.map((x) =>
						x.baselineRecordFlag === "Y" ? "#b5b5b5" : "#003f60"
					),
					data: historyDetails.webTaxHistCol.map((x) => x.cityTaxMuni),
				},
			];

			let cityProv = [
				{
					//City - Provincial
					label: historyDetails.webTaxHistCol.map((x) => x.asmtYear),
					type: "bar",
					stack: "Prop/Avg",
					backgroundColor: historyDetails.webTaxHistCol.map((x) =>
						x.baselineRecordFlag === "Y" ? "#e2e2e2" : "#61bcdd"
					),
					data: historyDetails.webTaxHistCol.map((x) => x.cityTaxProv),
				},
			];

			setPropMunicipalData(propMuni);
			setPropProvincialData(propProv);
			setCityMunicipalData(cityMuni);
			setCityProvincialData(cityProv);
			setBreakdownData(propMuni.concat(propProv, cityMuni, cityProv));
		}
	}, [historyDetails]);

	useEffect(() => {
		let filteredData = [];

		if (showPropMunicipal) {
			filteredData = filteredData.concat(propMunicipalData);
		}

		if (showPropProvincial) {
			filteredData = filteredData.concat(propProvincialData);
		}

		if (showCityMunicipal) {
			filteredData = filteredData.concat(cityMunicipalData);
		}

		if (showCityProvincial) {
			filteredData = filteredData.concat(cityProvincialData);
		}

		setBreakdownData(filteredData);
	}, [
		showPropMunicipal,
		showPropProvincial,
		showCityMunicipal,
		showCityProvincial,
		propMunicipalData,
		propProvincialData,
		cityMunicipalData,
		cityProvincialData,
	]);

	const toggleShowPropMunicipal = () => {
		setShowPropMunicipal(!showPropMunicipal);
	};

	const toggleShowPropProvincial = () => {
		setShowPropProvincial(!showPropProvincial);
	};

	const toggleShowCityMunicipal = () => {
		setShowCityMunicipal(!showCityMunicipal);
	};

	const toggleShowCityProvincial = () => {
		setShowCityProvincial(!showCityProvincial);
	};

	return (
		<React.Fragment>
			<Bar
				options={{
					responsive: true,
					scales: {
						y: {
							title: {
								display: true,
								text: "Amount",
								font: {
									size: "16",
								},
							},
							ticks: {
								stepSize: totalGreaterThan60000 ? 10000 : totalGreaterThan15000 ? 5000 : 1000,
								// Include a dollar sign in the ticks
								callback: function (value, index, ticks) {
									return value.toLocaleString("en-CA", {
										style: "currency",
										currency: "CAD",
									});
								},
							},
							grid: {
								borderColor: "#949494",
								borderWidth: 2,
							},
						},
						x: {
							title: {
								display: true,
								text: "Year",
								font: {
									size: "16",
								},
							},
							offset: true,
							grid: {
								borderColor: "#949494",
								borderWidth: 2,
							},
						},
					},
					plugins: {
						legend: {
							display: false,
						},
						tooltip: {
							callbacks: {
								label: function (tooltipItem) {
									return tooltipItem.dataset.data[tooltipItem.dataIndex];
								},
								title: function (tooltipItem) {
									return "";
								},
							},
						},
					},
				}}
				data={{
					labels: historyDetails.webTaxHistCol.map((x) => x.asmtYear),
					datasets: breakdownData,
				}}
			/>
			<Box className="history-legend-container-tax alt" mt={5}>
				<LinkBox>
					<LinkOverlay as="button" onClick={(e) => toggleShowPropMunicipal()}>
						<HStack>
							<Box
								className={`history-legend-breakdown first ${showPropMunicipal ? "" : "lighter"}`}
							></Box>
							<Box className={`history-legend-font ${showPropMunicipal ? "" : "lighter"}`}>
								<chakra.span>Your property - Actual - Municipal taxes</chakra.span>
							</Box>
						</HStack>
					</LinkOverlay>
				</LinkBox>
			</Box>
			<Box className="history-legend-container-tax alt following">
				<LinkBox>
					<LinkOverlay as="button" onClick={(e) => toggleShowPropProvincial()}>
						<HStack>
							<Box
								className={`history-legend-breakdown second ${showPropProvincial ? "" : "lighter"}`}
							></Box>
							<Box className={`history-legend-font ${showPropProvincial ? "" : "lighter"}`}>
								<chakra.span>Your property - Actual - Provincial educational taxes</chakra.span>
							</Box>
						</HStack>
					</LinkOverlay>
				</LinkBox>
			</Box>
			<Box className="history-legend-container-tax alt following">
				<LinkBox>
					<LinkOverlay as="button" onClick={(e) => toggleShowCityMunicipal()}>
						<HStack>
							<Box
								className={`history-legend-breakdown third ${showCityMunicipal ? "" : "lighter"}`}
							></Box>
							<Box className={`history-legend-font ${showCityMunicipal ? "" : "lighter"}`}>
								<chakra.span>Your property - Had it followed the average - Municipal taxes</chakra.span>
							</Box>
						</HStack>
					</LinkOverlay>
				</LinkBox>
			</Box>
			<Box className="history-legend-container-tax alt following">
				<LinkBox>
					<LinkOverlay as="button" onClick={(e) => toggleShowCityProvincial()}>
						<HStack>
							<Box
								className={`history-legend-breakdown last ${showCityProvincial ? "" : "lighter"}`}
							></Box>
							<Box className={`history-legend-font  ${showCityProvincial ? "" : "lighter"}`}>
								<chakra.span>
									Your property - Had it followed the average - Provincial educational taxes
								</chakra.span>
							</Box>
						</HStack>
					</LinkOverlay>
				</LinkBox>
			</Box>
		</React.Fragment>
	);
};
export default Breakdown;
