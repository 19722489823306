import "./Title.css";

import { Box, useMediaQuery } from "@chakra-ui/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faHome } from "@fortawesome/free-solid-svg-icons";

const Title = () => {
	const [isLargerThan480] = useMediaQuery("(min-width: 480px)");

	return (
		<Box className='title-container'>
			<Box
				className='title'
				fontSize={!isLargerThan480 ? "1.9em" : ""}>
				MyProperty
				<FontAwesomeIcon
					icon={faHome}
					className='icon'
				/>
				Edmonton
			</Box>
			<Box
				className='title-sub-text'
				fontSize={!isLargerThan480 ? ".9em" : ""}>
				Your trusted, secure source for property assessment and tax information.
			</Box>
		</Box>
	);
};

export default Title;
