import "./PropertyOverview.css";

import * as CommonFns from "../../../../../../common";

import { Box, Button, Flex, Skeleton, Spacer, VStack, chakra } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import { AuditContext } from "../../../../../../contexts/AuditContext";
import DataUnavailable from "../../../../../Global/DataUnavailable/DataUnavailable";

const PropertyOverview = ({
	accId,
	openTaxEstimator,
	openHistory,
	assessmentDetails,
	accountDetails,
	isLoading,
	isError,
}) => {
	const [property, setProperty] = useState({
		currentAssessment: "",
		assessmentYear: "",
		address: "",
		legalDescription: "",
		assessedParcel: "",
		neighbourhood: "",
		zoning: "",
		effectiveZoning: "",
		lucDetailsCol: [],
		hasSuppDetail: false,
		showEstimate: "",
	});

	const { auditActivity } = useContext(AuditContext);

	const formLabelMinWidth = 430;
	const formControlMinWidth = 360;

	useEffect(() => {
		if (assessmentDetails !== undefined && accountDetails !== undefined) {
			getPropertyOverview();
		}
	}, [assessmentDetails, accountDetails]);

	const getPropertyOverview = () => {
		let tempProperty = { ...property };
		tempProperty.currentAssessment = CommonFns.FormatCurrency(assessmentDetails.currentAsmt);
		tempProperty.assessmentYear = assessmentDetails.asmtYear;
		tempProperty.address = assessmentDetails.address;
		tempProperty.legalDescription =
			assessmentDetails.assessedParcel == null
				? assessmentDetails.shortLegal
				: assessmentDetails.shortLegal + " " + assessmentDetails.assessedParcel;
		tempProperty.neighbourhood = assessmentDetails.neighbourhood;
		tempProperty.zoning = assessmentDetails.actZone;
		tempProperty.effectiveZoning = assessmentDetails.effZone;
		tempProperty.lucDetailsCol = assessmentDetails.lucDetailsCol;
		tempProperty.hasSuppDetail = assessmentDetails.assessmentSummaryCol?.some((x) => x.assessmentType === "SUPP")
			? true
			: false;

		tempProperty.showEstimate = accountDetails.mayDecTaxEstimator !== "Y";

		setProperty(tempProperty);
	};

	const handleFiveYearAssessment = (e) => {
		e.preventDefault();
		openHistory();
	};

	const handleEstimate = (e, showEstimate) => {
		e.preventDefault();
		openTaxEstimator(showEstimate);
	};

	return (
		<React.Fragment>
			{isLoading ? (
				<Flex>
					<Box ml={3} mt={1} mb={2}>
						<Flex direction={["column", "column", "row"]}>
							<Box minW={formLabelMinWidth}>
								<Skeleton height='24px' width='75%' mb={2} />
								<Skeleton height='24px' width='60%' mb={2} />
								<Skeleton height='24px' width='50%' mb={2} />
								<Skeleton height='24px' width='40%' mb={2} />
								<Skeleton height='24px' width='50%' mb={2} />
								<Skeleton height='24px' width='60%' mb={2} />
								<Skeleton height='24px' width='75%' mb={4} />
								<Skeleton height='24px' width='40%' mb={2} />
								<Skeleton height='24px' width='35%' mb={2} />
								<Skeleton height='24px' width='40%' mb={2} />
								<Skeleton height='24px' width='50%' />
							</Box>
							<Box minW={formControlMinWidth}>
								<Skeleton height='24px' width='25%' mb={2} />
								<Skeleton height='24px' width='30%' mb={2} />
								<Skeleton height='24px' width='50%' mb={2} />
								<Skeleton height='24px' width='45%' mb={2} />
								<Skeleton height='24px' width='60%' mb={2} />
								<Skeleton height='24px' width='50%' mb={2} />
								<Skeleton height='24px' width='35%' mb={4} />
								<Skeleton height='24px' width='30%' mb={2} />
								<Skeleton height='24px' width='45%' mb={2} />
								<Skeleton height='24px' width='60%' mb={2} />
								<Skeleton height='24px' width='30%' />
							</Box>
							<Spacer></Spacer>
							<Box minW={formControlMinWidth}>
								<Skeleton height='120px' width='100%' ml={14} />
							</Box>
						</Flex>
					</Box>
				</Flex>
			) : !isError ? (
				<Box ml={3}>
					<Flex>
						<Box>
							<Box mt={1}>
								<Flex direction={["column", "column", "row"]}>
									<Box minW={formLabelMinWidth}>
										Your property's {property.hasSuppDetail ? "total" : ""}{" "}
										{property.assessmentYear} assessed value
									</Box>
									<Box minW={formControlMinWidth}>
										<chakra.span fontWeight={700}>{property.currentAssessment}</chakra.span>
									</Box>
								</Flex>
							</Box>
							<Box mt={2}>
								<Flex direction={["column", "column", "row"]}>
									<Box minW={formLabelMinWidth}>Assessed value over five years</Box>
									<Box minW={formControlMinWidth}>
										<Button
											colorScheme='lightBlue'
											size={"xs"}
											fontSize={".9em"}
											mr={2}
											width={95}
											onClick={(e) => {
												auditActivity("View Assessed Value Five Years", accId);
												handleFiveYearAssessment(e);
											}}>
											View
										</Button>
									</Box>
								</Flex>
							</Box>

							{property.address != null ? (
								<Box mt={2}>
									<Flex direction={["column", "column", "row"]}>
										<Box minW={formLabelMinWidth}>Municipal address</Box>
										<Box minW={formControlMinWidth}>
											<chakra.span fontWeight={700}>{property.address}</chakra.span>
										</Box>
									</Flex>
								</Box>
							) : null}

							<Box mt={2}>
								<Flex direction={["column", "column", "row"]}>
									<Box minW={formLabelMinWidth}>Legal description</Box>
									<Box minW={formControlMinWidth}>
										<chakra.span fontWeight={700}>{property.legalDescription}</chakra.span>
									</Box>
								</Flex>
							</Box>

							<Box mt={2}>
								<Flex direction={["column", "column", "row"]}>
									<Box minW={formLabelMinWidth}>Zoning</Box>
									<Box minW={formControlMinWidth}>
										<chakra.span fontWeight={700}>{property.zoning}</chakra.span>
									</Box>
								</Flex>
							</Box>

							{assessmentDetails.displayEffZone === "Y" ? (
								<>
									<Box mt={2}>
										<Flex direction={["column", "column", "row"]}>
											<Box minW={formLabelMinWidth}>Effective Zoning</Box>
											<Box minW={formControlMinWidth}>
												<chakra.span fontWeight={700}>{property.effectiveZoning}</chakra.span>
											</Box>
										</Flex>
									</Box>
								</>
							) : (
								<></>
							)}

							<Box mt={2}>
								<Flex direction={["column", "column", "row"]}>
									<Box minW={formLabelMinWidth}>Neighbourhood</Box>
									<Box minW={formControlMinWidth}>
										<chakra.span fontWeight={700}>{property.neighbourhood}</chakra.span>
									</Box>
								</Flex>
							</Box>

							<Box pt={2} pb={2}></Box>

							{property.lucDetailsCol.length > 0 ? (
								<React.Fragment>
									<Box mt={2}>
										<Flex direction={["column", "column", "row"]}>
											<Box minW={formLabelMinWidth}>Assessment class</Box>
											<Box minW={formControlMinWidth}>
												<chakra.span fontWeight={700}>
													{property.lucDetailsCol[0].assessmentClass}
												</chakra.span>
											</Box>
										</Flex>
									</Box>

									<Box mt={2}>
										<Flex direction={["column", "column", "row"]}>
											<Box minW={formLabelMinWidth}>Property use</Box>
											<Box minW={formControlMinWidth}>
												<chakra.span fontWeight={700}>
													{property.lucDetailsCol[0].propertyUse}
												</chakra.span>
											</Box>
										</Flex>
									</Box>

									<Box mt={2}>
										<Flex direction={["column", "column", "row"]}>
											<Box minW={formLabelMinWidth}>Taxable status</Box>
											<Box minW={formControlMinWidth}>
												<chakra.span fontWeight={700}>
													{property.lucDetailsCol[0].taxableStatus}
												</chakra.span>
											</Box>
										</Flex>
									</Box>
								</React.Fragment>
							) : null}

							<Box mt={2}>
								<Flex direction={["column", "column", "row"]}>
									<Box minW={formLabelMinWidth}>Property taxes for {property.assessmentYear}</Box>
									<Box minW={formControlMinWidth}>
										<Button
											colorScheme='lightBlue'
											size={"xs"}
											fontSize={".9em"}
											width={95}
											onClick={(e) => {
												auditActivity("View Estimate Property Taxes", accId);
												handleEstimate(e, property.showEstimate);
											}}>
											{property.showEstimate ? "Estimate" : "View"}
										</Button>
									</Box>
								</Flex>
							</Box>
						</Box>
						<Spacer></Spacer>
						<Box className='property-overview-value-box'>
							<Box mt={2} bg='#00a5d4' w='300px' p={3} color='white' fontWeight={700} fontSize={"1.3em"}>
								<VStack>
									<Box lineHeight={"1.2em"}>
										Your property's {property.hasSuppDetail ? "total" : ""}
									</Box>
									<Box lineHeight={"1.2em"}>
										<chakra.span> {property.assessmentYear} assessed value</chakra.span>
									</Box>
									<Box lineHeight={"1.2em"}>{property.currentAssessment}</Box>
								</VStack>
							</Box>
						</Box>
					</Flex>
				</Box>
			) : (
				<DataUnavailable></DataUnavailable>
			)}
		</React.Fragment>
	);
};
export default PropertyOverview;
