import "./CreateProfile.css";

import * as toastMessages from "../../../../toastMessages";

import {
	Box,
	Button,
	Checkbox,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Image,
	Input,
	InputGroup,
	InputRightElement,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	chakra,
	useBreakpointValue,
	useDisclosure,
	useMediaQuery,
	useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import React, { useContext, useState } from "react";

import { AuthContext } from "../../../../contexts/AuthContext";
import Complexity from "../../../Global/Password/Complexity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LegalText from "../../../Global/LegalText/LegalText";
import PasswordStrengthBar from "react-password-strength-bar";
import { axiosMyProperty } from "../../../../apis";
import { useNavigate } from "react-router-dom";

const ImageStep1 = require("../Images/step_01.png");

const CreateProfile = () => {
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });
	const [isLargerThan480] = useMediaQuery("(min-width: 480px)");
	const { isOpen, onOpen, onClose } = useDisclosure();

	const { authDetails, setAuthDetails } = useContext(AuthContext);

	const formLabelMinWidth = 210;
	const formControlMinWidth = 405;

	let navigate = useNavigate();

	const toast = useToast();
	const createProfileToastErrorId = "create-profile-toast-error";
	const createProfileToastWarningId = "create-profile-toast-error";

	const {
		register: registerProfile,
		handleSubmit: handleSubmitProfile,
		control: controlProfile,
		reset: resetProfile,
		watch: watchProfile,
		formState: { errors: errorsProfile },
	} = useForm({
		defaultValues: {
			electronicNotification: false,
			email: "",
			emailConfirm: "",
			name: "",
			password: "",
			passwordConfirm: "",
		},
	});

	const {
		register: registerTerms,
		handleSubmit,
		control: controlTerms,
		reset: resetTerms,
		formState: { errors: errorsTerms, isSubmitting },
	} = useForm({
		defaultValues: {
			terms: false,
		},
	});

	const [passwordShow, setPasswordShow] = useState(false);
	const handleShowHidePassword = () => setPasswordShow(!passwordShow);

	const [passwordConfirmShow, setPasswordConfirmShow] = useState(false);
	const handleShowHidePasswordConfirm = () => setPasswordConfirmShow(!passwordConfirmShow);

	const [isStepOne, setIsStepOne] = useState(true);
	const [isStepTwo, setIsStepTwo] = useState(false);

	const [profile, setProfile] = useState({
		profileName: "",
		emailAddress: "",
		emailAddressConfirm: "",
		password: "",
		passwordConfirm: "",
		isNotificationPreference: false,
		isTerms: false,
	});

	const watchEmail = watchProfile("email", "");
	const watchPassword = watchProfile("password", "");

	const handleCreateProfileNext = (data) => {
		setProfile((prevState) => ({
			...prevState,
			profileName: data.name,
			emailAddress: data.email,
			emailAddressConfirm: data.emailConfirm,
			password: data.password,
			passwordConfirm: data.passwordConfirm,
			isNotificationPreference: data.electronicNotification,
		}));

		setIsStepOne(false);
		setIsStepTwo(true);
	};

	const handleCreateProfile = async (data) => {
		// get profile detail for submission
		let payload = profile;

		// update terms prop for payload
		payload.isTerms = data.terms;

		// update the terms prop
		setProfile((prevState) => ({
			...prevState,
			isTerms: data.terms,
		}));

		let url = "account";
		await axiosMyProperty
			.post(url, payload)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					// successful creation of account
					setAuthDetails({ ...authDetails, emailAddress: payload.emailAddress, uId: returnObj.uId });

					if (returnObj.emailSent) {
						// redirect
						navigate(`/create-profile/thank-you`, { replace: true });
					} else {
						// redirect, email send issue
						// TODO - do we want to deal with this in some way
						// Users that try to login without a verified email will
						// be prompted to re-send a verification email if needed
						navigate(`/create-profile/thank-you`, { replace: true });
					}
				} else {
					// service validation failed
					if (returnObj.errorMsg === "p_email_address exists") {
						toast(
							toastMessages.getToastWarning(
								createProfileToastWarningId,
								returnObj.errorMsg,
								toastMessages.createProfileMessages
							)
						);
					}

					// email pattern
					if (returnObj.isEmailPatternError) {
						toast(
							toastMessages.getToastWarning(
								createProfileToastWarningId,
								"isEmailPatternError",
								toastMessages.createProfileMessages
							)
						);
					}

					// email match
					if (returnObj.isEmailMatchError) {
						toast(
							toastMessages.getToastWarning(
								createProfileToastWarningId,
								"isEmailMatchError",
								toastMessages.createProfileMessages
							)
						);
					}

					// password pattern
					if (returnObj.isPasswordComplexityError) {
						toast(
							toastMessages.getToastWarning(
								createProfileToastWarningId,
								"isPasswordComplexityError",
								toastMessages.createProfileMessages
							)
						);
					}

					// password match
					if (returnObj.isPasswordMatchError) {
						toast(
							toastMessages.getToastWarning(
								createProfileToastWarningId,
								"isPasswordMatchError",
								toastMessages.createProfileMessages
							)
						);
					}

					// terms not selected
					if (returnObj.isTermsSelectedError) {
						toast(
							toastMessages.getToastWarning(
								createProfileToastWarningId,
								"isTermsSelectedError",
								toastMessages.createProfileMessages
							)
						);
					}
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(createProfileToastErrorId)) {
					toast(toastMessages.getToastError(createProfileToastErrorId));
				}
			});
	};

	const handleClearCreateProfile = (e) => {
		e.preventDefault();
		resetProfile();
	};

	const handleClearLegal = (e) => {
		e.preventDefault();

		// form
		resetTerms();

		// steps
		setIsStepOne(true);
		setIsStepTwo(false);
	};

	const handleElecNotifModalOpen = (e) => {
		e.preventDefault();
		onOpen();
	};

	const handleElecNotifModalClose = (e) => {
		e.preventDefault();
		onClose();
	};

	const handleSignIn = (e, isEmailExists = false) => {
		e.preventDefault();
		isEmailExists
			? navigate(`/sign-in?emailAddress=${watchEmail}`, { replace: true })
			: navigate(`/sign-in`, { replace: true });
	};

	const handlePassword = (e) => {
		e.preventDefault();
		navigate(`/forgot-password`, { replace: true });
	};

	const emailExists = (value) => {
		let url = `profile/exists/${value}`;
		return axiosMyProperty
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;
				// special case, retVal is inccorect on return
				// do not check retVal
				if (returnObj.isRegistered === "Y") {
					// email is already registered
					// return false, validation message will be displayed
					return false;
				} else {
					// email is not registered
					// return true, validation message will not be displayed
					return true;
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(createProfileToastErrorId)) {
					toast(toastMessages.getToastError(createProfileToastErrorId));
				}
			});
	};

	const emailMatch = (value) => {
		return value === watchEmail ? true : false;
	};

	const passwordMatch = (value) => {
		return value === watchPassword ? true : false;
	};

	return (
		<React.Fragment>
			<form>
				<FormControl>
					<Flex direction={["column", "column", "row"]}>
						<Box
							minW={formLabelMinWidth}
							display={isSmallerScreen ? "none" : "normal"}>
							<FormLabel>&nbsp;</FormLabel>
						</Box>
						<Box mb={8}>
							<Image
								src={ImageStep1}
								alt={"Step 1 - Create Profile"}></Image>
						</Box>
					</Flex>
				</FormControl>
				{isStepOne ? (
					<React.Fragment>
						<FormControl>
							<Flex direction={["column", "column", "row"]}>
								<Box
									minW={formLabelMinWidth}
									display={isSmallerScreen ? "none" : "normal"}>
									<FormLabel>&nbsp;</FormLabel>
								</Box>
								<Box>
									<chakra.span
										fontSize={"1.45em"}
										fontWeight={400}>
										Tell Us About Yourself
									</chakra.span>
								</Box>
							</Flex>
						</FormControl>
						<FormControl
							isInvalid={errorsProfile.name}
							mt={2}>
							<Flex direction={["column", "column", "row"]}>
								<Box minW={formLabelMinWidth}>
									<FormLabel
										htmlFor='name'
										textAlign={isSmallerScreen ? "" : "end"}
										className='form-label-fixed'>
										How should we greet you?
									</FormLabel>
								</Box>
								<Box minW={!isLargerThan480 ? "" : formControlMinWidth}>
									<Input
										id='name'
										type='text'
										placeholder='for example, your first name'
										autoComplete='off'
										{...registerProfile("name", {
											required: "How should we greet you? is required",
										})}
									/>
								</Box>
								<Box ml={isSmallerScreen ? 0 : 3}>
									<FormErrorMessage
										colorScheme={"red"}
										className={"text-error pos"}>
										{errorsProfile.name && errorsProfile.name.message}
									</FormErrorMessage>
								</Box>
							</Flex>
						</FormControl>

						<FormControl
							isInvalid={errorsProfile.email}
							mt={6}>
							<Flex direction={["column", "column", "row"]}>
								<Box minW={formLabelMinWidth}>
									<FormLabel
										htmlFor='email'
										textAlign={isSmallerScreen ? "" : "end"}
										className='form-label-fixed'>
										Your Email
									</FormLabel>
								</Box>
								<Box minW={!isLargerThan480 ? "" : formControlMinWidth}>
									<Input
										id='email'
										type='email'
										autoComplete='off'
										{...registerProfile("email", {
											required: "Your Email is required",
											pattern: {
												value: /^[\w.-]+@([\w-]+\.)+[\w-]{2,}$/g,
												message: "Your Email is invalid",
											},
											validate: emailExists,
										})}
									/>
								</Box>
								<Box ml={isSmallerScreen ? 0 : 3}>
									<FormErrorMessage
										colorScheme={"red"}
										className={"text-error pos"}>
										{errorsProfile.email && errorsProfile.email.message}
										{errorsProfile.email?.type === "validate" && (
											<span>
												The provided email has already been registered.
												<br />
												Want to <Link onClick={(e) => handleSignIn(e, true)}>
													sign in
												</Link> or{" "}
												<Link onClick={(e) => handlePassword(e)}>recover your password</Link>?
											</span>
										)}
									</FormErrorMessage>
								</Box>
							</Flex>
						</FormControl>

						<FormControl
							isInvalid={errorsProfile.emailConfirm}
							mt={2}>
							<Flex direction={["column", "column", "row"]}>
								<Box minW={formLabelMinWidth}>
									<FormLabel
										htmlFor='emailConfirm'
										textAlign={isSmallerScreen ? "" : "end"}
										className='form-label-fixed'>
										Retype Your Email
									</FormLabel>
								</Box>
								<Box minW={!isLargerThan480 ? "" : formControlMinWidth}>
									<Input
										id='emailConfirm'
										type='text'
										autoComplete='off'
										{...registerProfile("emailConfirm", {
											required: "Retype Your Email is required",
											validate: emailMatch,
										})}
									/>
								</Box>
								<Box ml={isSmallerScreen ? 0 : 3}>
									<FormErrorMessage
										colorScheme={"red"}
										className={"text-error pos"}>
										{errorsProfile.emailConfirm && errorsProfile.emailConfirm.message}
										{errorsProfile.emailConfirm?.type === "validate" && (
											<span>Emails do not match</span>
										)}
									</FormErrorMessage>
								</Box>
							</Flex>
						</FormControl>

						<FormControl
							isInvalid={errorsProfile.password}
							mt={6}>
							<Flex direction={["column", "column", "row"]}>
								<Box minW={formLabelMinWidth}>
									<FormLabel
										htmlFor='password'
										textAlign={isSmallerScreen ? "" : "end"}
										className='form-label-fixed'>
										<Complexity></Complexity>
										Your Password
									</FormLabel>
								</Box>
								<Box minW={!isLargerThan480 ? "" : formControlMinWidth}>
									<InputGroup size='md'>
										<Input
											id='password'
											type={passwordShow ? "text" : "password"}
											autoComplete='off'
											{...registerProfile("password", {
												required: "Your Password is required",
												pattern: {
													value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/,
													message: "Your Password does not meet the requirements",
												},
											})}
										/>
										<InputRightElement width='4.5rem'>
											<Button
												h='1.75rem'
												w={"56px"}
												size='sm'
												colorScheme={"lightBlue"}
												onClick={handleShowHidePassword}
												tabIndex={-1}>
												{passwordShow ? "Hide" : "Show"}
											</Button>
										</InputRightElement>
									</InputGroup>
									<PasswordStrengthBar
										password={watchPassword}
										minLength={8}
										shortScoreWord={"Too Short"}
										scoreWords={["Very Weak", "Weak", "Medium", "Strong", "Very Strong"]}
									/>
								</Box>
								<Box ml={isSmallerScreen ? 0 : 3}>
									<FormErrorMessage
										colorScheme={"red"}
										className={"text-error"}>
										{errorsProfile.password && errorsProfile.password.message}
									</FormErrorMessage>
								</Box>
							</Flex>
						</FormControl>

						<FormControl
							isInvalid={errorsProfile.passwordConfirm}
							mt={2}>
							<Flex direction={["column", "column", "row"]}>
								<Box minW={formLabelMinWidth}>
									<FormLabel
										htmlFor='passwordConfirm'
										textAlign={isSmallerScreen ? "" : "end"}
										className='form-label-fixed'>
										Retype Your Password
									</FormLabel>
								</Box>
								<Box minW={!isLargerThan480 ? "" : formControlMinWidth}>
									<InputGroup size='md'>
										<Input
											id='passwordConfirm'
											type={passwordConfirmShow ? "text" : "password"}
											autoComplete='off'
											{...registerProfile("passwordConfirm", {
												required: "Retype Your Password is required",
												validate: passwordMatch,
											})}
										/>
										<InputRightElement width='4.5rem'>
											<Button
												h='1.75rem'
												w={"56px"}
												size='sm'
												colorScheme={"lightBlue"}
												onClick={handleShowHidePasswordConfirm}
												tabIndex={-2}>
												{passwordConfirmShow ? "Hide" : "Show"}
											</Button>
										</InputRightElement>
									</InputGroup>
								</Box>
								<Box ml={isSmallerScreen ? 0 : 3}>
									<FormErrorMessage
										colorScheme={"red"}
										className={"text-error pos"}>
										{errorsProfile.passwordConfirm && errorsProfile.passwordConfirm.message}
										{errorsProfile.passwordConfirm?.type === "validate" && (
											<span>Passwords do not match</span>
										)}
									</FormErrorMessage>
								</Box>
							</Flex>
						</FormControl>

						<FormControl
							isInvalid={errorsProfile.electronicNotification}
							mt={6}>
							<Flex direction={["column", "column", "row"]}>
								<Box minW={formLabelMinWidth}></Box>
								<Box maxW={isSmallerScreen ? "100%" : "45%"}>
									<Controller
										control={controlProfile}
										name='electronicNotification'
										defaultValue={false}
										render={({ field: { onChange, value, ref } }) => (
											<Checkbox
												size={"lg"}
												onChange={onChange}
												ref={ref}
												isChecked={value}>
												<chakra.span
													fontWeight={700}
													fontSize={".85em"}>
													Yes, I would like to receive updates, reminders and other extras and
													agree to{" "}
													<Link
														className='elec-notif-link'
														onClick={(e) => handleElecNotifModalOpen(e)}>
														Electronic Notification Preferences
													</Link>{" "}
													Terms. <chakra.span color={"#00a5d4"}>(optional)</chakra.span>
												</chakra.span>
											</Checkbox>
										)}
									/>
								</Box>
							</Flex>
						</FormControl>

						<FormControl mt={6}>
							<Flex direction={["column", "column", "row"]}>
								<Box
									minW={formLabelMinWidth}
									display={isSmallerScreen ? "none" : "normal"}>
									<FormLabel>&nbsp;</FormLabel>
								</Box>
								<Box>
									<Flex direction={["column", "column", "row"]}>
										<Button
											colorScheme='blue'
											width={isSmallerScreen ? "100%" : 100}
											mr={isSmallerScreen ? 0 : 4}
											onClick={handleSubmitProfile((data) => handleCreateProfileNext(data))}>
											NEXT
										</Button>
										<Button
											type='reset'
											colorScheme='grey'
											variant={"outline"}
											width={isSmallerScreen ? "100%" : 100}
											mr={isSmallerScreen ? 0 : 2}
											mt={isSmallerScreen ? 6 : 0}
											onClick={(e) => handleClearCreateProfile(e)}>
											CLEAR
										</Button>
										<Button
											colorScheme='grey'
											variant={"ghost"}
											mt={isSmallerScreen ? 4 : 0}
											onClick={(e) => handleSignIn(e)}>
											Sign In
										</Button>
									</Flex>
								</Box>
							</Flex>
						</FormControl>

						<Modal
							closeOnOverlayClick={true}
							isOpen={isOpen}
							onClose={onClose}>
							<ModalOverlay />
							<ModalContent maxW={"57rem"}>
								<ModalHeader className={"modal-header"}>
									Electronic Notification Preferences - Terms
								</ModalHeader>
								<ModalCloseButton className='modal-close-button'>
									<FontAwesomeIcon
										size={"2xl"}
										icon='fa-solid fa-xmark'
									/>
								</ModalCloseButton>
								<ModalBody>
									<LegalText displayMessageTypes={["WEB ELECTRONIC NOTIFICATION PREFS"]}></LegalText>
								</ModalBody>
								<ModalFooter className={"modal-footer"}>
									{/* <Button
										colorScheme='blue'
										size={"sm"}
										onClick={(e) => handleElecNotifModalClose(e)}>
										Close
									</Button> */}
								</ModalFooter>
							</ModalContent>
						</Modal>
					</React.Fragment>
				) : null}

				{isStepTwo ? (
					<React.Fragment>
						<FormControl>
							<Flex direction={["column", "column", "row"]}>
								<Box
									minW={formLabelMinWidth}
									display={isSmallerScreen ? "none" : "normal"}>
									<FormLabel>&nbsp;</FormLabel>
								</Box>
								<Box>
									<chakra.span
										fontSize={"1.45em"}
										fontWeight={400}>
										Now, review the legal conditions of MyProperty.
									</chakra.span>
								</Box>
							</Flex>
						</FormControl>

						<FormControl mt={2}>
							<Flex direction={["column", "column", "row"]}>
								<Box minW={formLabelMinWidth}></Box>
								<Box
									maxW={isSmallerScreen ? "100%" : "65%"}
									className='create-profile-legal-text'>
									<LegalText
										displayMessageTypes={[
											"WEB PRIVACY TERMS",
											"WEB USE OF INFO DISCLAIMER",
										]}></LegalText>
								</Box>
							</Flex>
						</FormControl>

						<FormControl
							isInvalid={errorsTerms.terms}
							mt={8}>
							<Flex direction={["column", "column", "row"]}>
								<Box minW={formLabelMinWidth}></Box>
								<Box maxW={isSmallerScreen ? "100%" : "65%"}>
									<Box>
										<Controller
											control={controlTerms}
											name='terms'
											defaultValue={false}
											render={({ field: { onChange, value, ref } }) => (
												<Checkbox
													size={"lg"}
													onChange={onChange}
													ref={ref}
													isChecked={value}
													{...registerTerms("terms", {
														required:
															"You must accept the MyProperty Privacy Terms and MyProperty Use of Information Disclaimer",
													})}>
													<chakra.span
														fontWeight={700}
														fontSize={".85em"}>
														I've read and agree to MyProperty Privacy Terms and MyProperty
														Use of Information Disclaimer.{" "}
														<chakra.span
															color={"#00a5d4"}
															fontStyle={"italic"}>
															(Mandatory)
														</chakra.span>
													</chakra.span>
												</Checkbox>
											)}
										/>
									</Box>
									<Box>
										<FormErrorMessage
											colorScheme={"red"}
											className={"text-error"}>
											{errorsTerms.terms && errorsTerms.terms.message}
										</FormErrorMessage>
									</Box>
								</Box>
								<Box ml={isSmallerScreen ? 0 : 3}></Box>
							</Flex>
						</FormControl>

						<FormControl mt={6}>
							<Flex direction={["column", "column", "row"]}>
								<Box
									minW={formLabelMinWidth}
									display={isSmallerScreen ? "none" : "normal"}>
									<FormLabel>&nbsp;</FormLabel>
								</Box>
								<Box>
									<Flex direction={["column", "column", "row"]}>
										<Button
											colorScheme='blue'
											width={isSmallerScreen ? "100%" : 170}
											mr={isSmallerScreen ? 0 : 4}
											isLoading={isSubmitting}
											loadingText={"CREATE PROFILE"}
											type={"submit"}
											onClick={handleSubmit(async (data) => await handleCreateProfile(data))}>
											CREATE PROFILE
										</Button>
										<Button
											type='reset'
											colorScheme='grey'
											width={isSmallerScreen ? "100%" : 100}
											mr={isSmallerScreen ? 0 : 2}
											mt={isSmallerScreen ? 6 : 0}
											variant={"outline"}
											onClick={(e) => handleClearLegal(e)}>
											BACK
										</Button>
										<Button
											colorScheme='grey'
											variant='ghost'
											mt={isSmallerScreen ? 4 : 0}
											onClick={(e) => handleSignIn(e)}>
											Sign In
										</Button>
									</Flex>
								</Box>
							</Flex>
						</FormControl>
					</React.Fragment>
				) : null}
			</form>
		</React.Fragment>
	);
};

export default CreateProfile;
