import "../../History.css";
import "chart.js/auto";

import * as CommonFns from "../../../../../../../common";

import { Box, HStack, chakra } from "@chakra-ui/react";

import React from "react";

const DataTable = ({ historyDetails, propertyTotal, municipalTotal }) => {
	return (
		<React.Fragment>
			<table className="history-table" border="0" cellSpacing="0" cellPadding="0">
				<thead>
					<tr>
						<td></td>
						<td colSpan="3" className="history-text-center history-table-border-all">
							<div className="history-text-heading">ACTUAL</div>
						</td>
						<td
							rowSpan="3"
							width={"165px"}
							className="history-text-left history-light-grey-bg history-table-border-all"
						>
							<div className="history-text-heading">WHAT IF...</div>
							<div className="history-text-small">
								Your property taxes experienced the Edmonton average % change
							</div>
						</td>
					</tr>
					<tr className="history-text-small">
						<td></td>
						<td colSpan="2" className="history-text-center history-table-border-left">
							Your Property <br />
							(municipal and education)
						</td>
						<td className="history-text-center history-table-border-right">
							Edmonton
							<br />
							average
						</td>
					</tr>
					<tr className="history-text-small">
						<td></td>
						<td className="history-text-center history-table-border-left history-table-border-bottom">
							Taxes
						</td>
						<td className="history-text-center history-table-border-bottom">% change</td>
						<td className="history-text-center history-table-border-bottom history-table-border-right">
							% change
						</td>
					</tr>
				</thead>
				<tbody>
					{historyDetails.webTaxHistCol.map((tax, index) => (
						<tr key={index} className="history-text-small">
							<td
								className={
									tax.baselineRecordFlag === "Y" ? "history-year history-baseline-bg" : "history-year"
								}
							>
								{tax.asmtYear}
							</td>
							<td
								className={
									tax.baselineRecordFlag === "Y"
										? "history-amount history-table-border-left history-text-left history-baseline-bg"
										: "history-amount history-table-border-left history-text-left"
								}
							>
								{CommonFns.FormatCurrency(tax.myPropTaxTotal ?? 0, 2)}
							</td>
							<td
								className={
									tax.baselineRecordFlag === "Y"
										? "history-percent history-baseline-bg"
										: tax.perChangeMyPropTax > tax.perChangeCityTax
										? "history-percent history-higher-average"
										: tax.perChangeMyPropTax < tax.perChangeCityTax
										? "history-percent history-lower-average"
										: "history-percent"
								}
							>
								{CommonFns.FormatPercent(tax.perChangeMyPropTax ?? 0, 1, true, true)}
							</td>
							<td
								className={
									tax.baselineRecordFlag === "Y"
										? "history-percent history-table-border-right history-baseline-bg"
										: "history-percent history-table-border-right"
								}
							>
								{CommonFns.FormatPercent(tax.perChangeCityTax ?? 0, 1, true, true)}
							</td>
							<td
								className={
									tax.baselineRecordFlag === "Y"
										? "history-light-grey-bg history-text-left history-table-border-right history-baseline-bg"
										: "history-light-grey-bg history-text-left history-table-border-right"
								}
							>
								{CommonFns.FormatCurrency(tax.cityTaxTotal ?? 0, 2)}
							</td>
						</tr>
					))}
					<tr className="history-text-small">
						<td className="history-year">TOTAL</td>
						<td className="history-amount history-text-bold history-table-border-top history-table-border-left history-table-border-right">
							{CommonFns.FormatCurrency(propertyTotal, 2)}
						</td>
						<td className="history-table-border-top history-table-border-left"></td>
						<td className="history-table-border-top history-table-border-right"></td>
						<td className="history-light-grey-bg history-text-bold history-text-left history-table-border-top history-table-border-right">
							{CommonFns.FormatCurrency(municipalTotal, 2)}
						</td>
					</tr>
					<tr>
						<td></td>
						<td className="history-table-border-top"></td>
						<td colSpan="2"></td>
						<td className="history-table-border-top"></td>
					</tr>
				</tbody>
			</table>
			<Box className="history-legend-container-tax">
				<HStack>
					<Box className="history-legend-lower-average"></Box>
					<Box>
						<chakra.span className="history-legend-font">Lower than the average change</chakra.span>
					</Box>
				</HStack>
			</Box>
			<Box className="history-legend-container-tax">
				<HStack>
					<Box className="history-legend-higher-average"></Box>
					<Box>
						<chakra.span className="history-legend-font">Higher than the average change</chakra.span>
					</Box>
				</HStack>
			</Box>
		</React.Fragment>
	);
};
export default DataTable;
