import './Header.css';

import * as CommonFns from '../../../../../../../common';

import { AspectRatio, Box, Button, Flex, Modal, ModalBody, ModalContent, ModalOverlay, chakra, useDisclosure } from '@chakra-ui/react';
import React, { useContext } from 'react';

import { AuditContext } from '../../../../../../../contexts/AuditContext';

const YourPropertyTaxesHeader = ({ accId, notice, isAnnual, isAmended }) => {
	const { isOpen: isOpenSupVideo, onOpen: onOpenSupVideo, onClose: onCloseSupVideo } = useDisclosure();

	const formLabelMinWidth = 430;
	const formControlMinWidth = 360;

	const { auditActivity } = useContext(AuditContext);

	return (
		<React.Fragment>
			<Box mt={1}>
				<Flex direction={['column', 'column', 'row']}>
					<Box minW={formLabelMinWidth}>Date of issue</Box>
					<Box minW={formControlMinWidth}>
						<chakra.span fontWeight={700}>{CommonFns.FormatDate(new Date(notice.mailingDate))}</chakra.span>
					</Box>
				</Flex>
			</Box>

			{notice.paymentDate !== null ? (
				notice.paymentDate.length > 0 ? (
					<Box mt={2}>
						<Flex direction={['column', 'column', 'row']}>
							<Box minW={formLabelMinWidth}>Payment due date</Box>
							<Box minW={formControlMinWidth}>
								<chakra.span fontWeight={700}>
									{CommonFns.FormatDate(notice.paymentDate)} {notice.paymentMsg ? ' - ' + notice.paymentMsg : null}
								</chakra.span>
							</Box>
						</Flex>
					</Box>
				) : null
			) : null}

			<Box mt={2}>
				<Flex direction={['column', 'column', 'row']}>
					<Box minW={formLabelMinWidth}>
						{notice.taxYear} {isAmended ? ' amended ' : ' '} {isAnnual ? ' ' : ' supplementary '} assessed value
					</Box>
					<Box minW={formControlMinWidth}>
						<chakra.span fontWeight={700}>{CommonFns.FormatCurrency(notice.assessment)}</chakra.span>
					</Box>
				</Flex>
			</Box>

			{!isAnnual ? (
				<React.Fragment>
					<Box mt={2}>
						<Flex direction={['column', 'column', 'row']}>
							<Box minW={formLabelMinWidth}>Supplementary months</Box>
							<Box minW={formControlMinWidth}>
								<chakra.span fontWeight={700}>{notice.suppMonths}</chakra.span>
							</Box>
						</Flex>
					</Box>

					<Box mt={2}>
						<Flex direction={['column', 'column', 'row']}>
							<Box minW={formLabelMinWidth}>What is a supplementary assessment?</Box>
							<Box minW={formControlMinWidth}>
								<Button
									onClick={() => {
										onOpenSupVideo();
										auditActivity('Supplementary A&T Video - Watch', accId);
									}}
									colorScheme="lightBlue"
									minW={'5rem'}
									size={'xs'}
								>
									Watch
								</Button>
							</Box>
						</Flex>
					</Box>
				</React.Fragment>
			) : null}

			<Modal size={'2xl'} isOpen={isOpenSupVideo} onClose={onCloseSupVideo}>
				<ModalOverlay />
				<ModalContent maxW={'57rem'}>
					<ModalBody className="assessed-value-modal-no-padding">
						<AspectRatio ratio={16 / 9}>
							<iframe id="supplementaryVideo" src={notice.suppAsmtTutorialLink} title="Supplementary Assessment Video" data-video="supplementaryvideo" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
						</AspectRatio>
					</ModalBody>
				</ModalContent>
			</Modal>
		</React.Fragment>
	);
};

export default YourPropertyTaxesHeader;
