import {
	Box,
	Button,
	Link,
	ListItem,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	OrderedList,
	UnorderedList,
	useDisclosure,
} from "@chakra-ui/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Complexity = () => {
	const { isOpen: isOpenPassword, onOpen: onOpenPassword, onClose: onClosePassword } = useDisclosure();

	const handlePasswordModalOpen = (e) => {
		e.preventDefault();
		onOpenPassword();
	};

	// const handlePasswordModalClose = (e) => {
	// 	e.preventDefault();
	// 	onClosePassword();
	// };

	return (
		<React.Fragment>
			<Link
				className='create-profile-password-info'
				mr={1}>
				<FontAwesomeIcon
					icon='fa-solid fa-circle-info'
					onClick={(e) => handlePasswordModalOpen(e)}
				/>
			</Link>

			<Modal
				closeOnOverlayClick={true}
				isOpen={isOpenPassword}
				onClose={onClosePassword}
				size={"lg"}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className={"modal-header"}>Password Complexity Requirements</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					<ModalBody>
						<Box
							mt={2}
							mb={2}>
							<OrderedList>
								<ListItem>At least 8 characters in length</ListItem>
								<ListItem>
									Contain at least one...
									<UnorderedList>
										<ListItem>lowercase character (a-z)</ListItem>
										<ListItem>uppercase character (A-Z)</ListItem>
										<ListItem>numeric character (0-9)</ListItem>
									</UnorderedList>
								</ListItem>
							</OrderedList>
						</Box>
					</ModalBody>
					<ModalFooter className={"modal-footer"}>
						{/* <Button colorScheme="blue" size={"sm"} onClick={(e) => handlePasswordModalClose(e)}>
							Close
						</Button> */}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</React.Fragment>
	);
};

export default Complexity;
