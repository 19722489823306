import * as CommonFns from "../../../common";

import { Box, Center, Flex, Spacer, chakra } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import AddProperty from "./AddProperty/AddProperty";
import { AuthContext } from "../../../contexts/AuthContext";
import FirstSignIn from "../Splash/FirstSignIn/FirstSignIn";
import ListProperty from "./ListProperty/ListProperty";
import MyMail from "../MyMail/MyMail";
import Paperless from "../Splash/Campaign/Paperless/Paperless";
import PropertyAuthorization from "./PropertyAuthorization/PropertyAuthorization";
import TimeLine from "./Timeline/Timeline";
import WelcomeMessage from "./WelcomeMessage/WelcomeMessage";

const Property = ({ managePropertyAndMail, propertyCount, addRemovePropertyToggle }) => {
	const { authDetails, resetAuthAccountNumber, setAuthDetails } = useContext(AuthContext);

	const [addPropertyType, setAddPropertyType] = useState(CommonFns.AddPropertyTypeEnum.None);
	const [loadAuthorizedPropertiesToggle, setLoadAuthorizedPropertiesToggle] = useState(false);

	const handleOnPropertyAdded = (newMyMailCount) => {
		//manage the property and mail items
		managePropertyAndMail("add", 0, 0, newMyMailCount);
		setLoadAuthorizedPropertiesToggle(!loadAuthorizedPropertiesToggle);
	};

	const handleOnPropertyVerified = (accId, newMyMailCount) => {
		//manage the property and mail items
		managePropertyAndMail("verify", accId, 0, newMyMailCount);
	};

	const handleOnPropertyRemoved = (newMyMailCount) => {
		//manage the property and mail items
		managePropertyAndMail("remove", 0, 0, newMyMailCount);
	};

	const handleOnPropertyListed = (listedPropertyCount) => {
		//manage the property and mail items
		managePropertyAndMail("listed", 0, listedPropertyCount);
	};

	const handleOnAddPropertyLink = () => {
		setAddPropertyType(CommonFns.AddPropertyTypeEnum.Add);
		setAuthDetails({
			...authDetails,
			addPropertyModal: true,
		});
	};

	const handleAddPropertyClose = () => {
		setAddPropertyType(CommonFns.AddPropertyTypeEnum.None);
	};

	useEffect(() => {
		if (authDetails.addPropertyModal === true) {
			setAddPropertyType(CommonFns.AddPropertyTypeEnum.Add);
		}
	}, [authDetails.addPropertyModal]);

	useEffect(() => {
		resetAuthAccountNumber();
	}, []);

	return (
		<React.Fragment>
			<Box>
				<WelcomeMessage />
			</Box>
			<Box mt={5}>
				<Flex>
					<Center>
						<chakra.span fontSize={"1.5em"}>My Properties</chakra.span>
					</Center>
					<Spacer></Spacer>
					<Box>
						<AddProperty
							onPropertyAdded={handleOnPropertyAdded}
							addPropertyType={addPropertyType}
							handleAddPropertyClose={handleAddPropertyClose}></AddProperty>
					</Box>
				</Flex>
				<ListProperty
					onPropertyAdded={handleOnPropertyAdded}
					onPropertyRemoved={handleOnPropertyRemoved}
					onPropertyVerified={handleOnPropertyVerified}
					onPropertyListed={handleOnPropertyListed}
					propertyCount={propertyCount}
					addRemovePropertyToggle={addRemovePropertyToggle}></ListProperty>
			</Box>
			<Box mt={2}>
				<PropertyAuthorization
					loadAuthorizedPropertiesToggle={loadAuthorizedPropertiesToggle}></PropertyAuthorization>
			</Box>
			<Box mt={6}>
				<TimeLine></TimeLine>
			</Box>
			<Box mt={6}>
				<MyMail
					propertyCount={propertyCount}
					addRemovePropertyToggle={addRemovePropertyToggle}
					handleAddPropertyLink={handleOnAddPropertyLink}
					isPropertyView={true}></MyMail>
			</Box>
			<FirstSignIn></FirstSignIn>
			<Paperless></Paperless>
		</React.Fragment>
	);
};

export default Property;
