import { Box, useBreakpointValue } from "@chakra-ui/react";

import MailPreferences from "./MailPreferences/MailPreferences";
import MobilePhoneNumber from "./MobilePhoneNumber/MobilePhoneNumber";
import React from "react";

const NotificationPreferences = ({
	smsPhoneNum,
	onPhoneNumberModified,
	onMailPreferencesSave,
	managePropertyAndMail,
	handleLogout,
}) => {
	const isSmallerScreen = useBreakpointValue({ base: true, md: true, lg: false });

	return (
		<React.Fragment>
			<MobilePhoneNumber
				smsPhoneNum={smsPhoneNum}
				onPhoneNumberModified={onPhoneNumberModified}
				handleLogout={handleLogout}
			></MobilePhoneNumber>
			<Box w={isSmallerScreen ? "100%" : "80%"}>
				<MailPreferences
					onMailPreferencesSave={onMailPreferencesSave}
					smsPhoneNum={smsPhoneNum}
					managePropertyAndMail={managePropertyAndMail}
				></MailPreferences>
			</Box>
		</React.Fragment>
	);
};
export default NotificationPreferences;
