import "./Footer.css";

import { Box, Flex, Image, Link, Spacer, chakra, useBreakpointValue, useMediaQuery } from "@chakra-ui/react";
import React, { useContext, useState } from "react";

import { AuditContext } from "../../../contexts/AuditContext";
import { useNavigate } from "react-router-dom";

const Footer = () => {
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });
	const [isLargerThan480] = useMediaQuery("(min-width: 480px)");

	const { auditActivity } = useContext(AuditContext);

	let currentYear = new Date().getFullYear();
	const [edmontonUrl] = useState(process.env.REACT_APP_EDMONTON_URL);
	const [conditionsUrl] = useState(process.env.REACT_APP_CONDITIONS_URL);
	const [privacyUrl] = useState(process.env.REACT_APP_PRIVACY_URL);
	const [env] = useState(process.env.REACT_APP_ENVIRONMENT);

	const ImageEdmontonLogo_Stamp = require("./Images/edmonton-logo-stamp.png");
	const ImageEdmontonLogo = require("./Images/edmonton-logo.svg").default;

	let navigate = useNavigate();

	const handleLegal = (e) => {
		e.preventDefault();
		navigate("/legal", { replace: false });
	};

	return (
		<footer className={"footer"}>
			<Box
				className={"footer-container"}
				h={"100%"}
				pb={4}>
				<Box
					maxWidth={1280}
					margin={"0 auto"}>
					<Box
						mt={4}
						ml={isSmallerScreen ? 4 : 2}>
						<Flex direction={["column", "column", "row"]}>
							<Flex direction={["column", "column", "row"]}>
								<Box
									className={isSmallerScreen ? "" : "footer-logo-container"}
									pr={isSmallerScreen ? 0 : 2}
									mr={isSmallerScreen ? 0 : 2}
									mb={isSmallerScreen ? 3 : 0}>
									<Link
										href={edmontonUrl}
										rel={"noopener"}>
										<Image
											h={"28px"}
											w={"120px"}
											src={env !== "production" ? ImageEdmontonLogo_Stamp : ImageEdmontonLogo}
											alt='City of Edmonton'></Image>
									</Link>
								</Box>
								<Box className={"footer-link-container"}>
									<Flex direction={["column", "row", "row"]}>
										<Box>
											<Link
												href={conditionsUrl}
												rel={"noopener"}
												target={"_blank"}
												onClick={() => {
													auditActivity("Conditions of Use");
												}}>
												Conditions of Use
											</Link>
										</Box>
										<Box ml={!isLargerThan480 ? 0 : 3}>
											<Link
												href={privacyUrl}
												rel={"noopener"}
												target={"_blank"}
												onClick={() => {
													auditActivity("Privacy");
												}}>
												Privacy
											</Link>
										</Box>
										<Box ml={!isLargerThan480 ? 0 : 3}>
											<Link onClick={(e) => handleLegal(e)}>Legal Notices</Link>
										</Box>
									</Flex>
								</Box>
							</Flex>
							<Spacer />
							<Box
								mr={2}
								mt={isSmallerScreen ? 2 : 0}>
								<Flex
									direction={["column"]}
									alignItems={isSmallerScreen ? "flex-start" : "flex-end"}>
									<Box className={"footer-copy-container"}>
										<chakra.span className={"footer-copy-text"}>
											&copy; {currentYear} City of Edmonton. All rights reserved.
										</chakra.span>
									</Box>
								</Flex>
							</Box>
						</Flex>
					</Box>
				</Box>
			</Box>
		</footer>
	);
};

export default Footer;
