import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, chakra } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import { AuditContext } from "../../../../../contexts/AuditContext";
import CEIP from "./CEIP/CEIP";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LocalImprovement from "./LocalImprovement/LocalImprovement";
import MonthlyPaymentPlan from "./MonthlyPaymentPlan/MonthlyPaymentPlan";
import MunicipalTaxes from "./MunicipalTaxes/MunicipalTaxes";
import PropertyOverview from "./PropertyOverview/PropertyOverview";
import TaxEstimator from "./TaxEstimator/TaxEstimator";
import WaysToPay from "./WaysToPay/WaysToPay";
import YourPropertyTaxes from "./YourPropertyTaxes/YourPropertyTaxes";

const Taxes = ({ accId, toggleTaxEstimator, toggleYourPropertyTaxes, accountDetails, isLoading, isError }) => {
	const [sectionIndex, setSectionIndex] = React.useState([0]);
	const [taxYear, setTaxYear] = useState();
	const { auditActivity } = useContext(AuditContext);

	const handleSectionChange = (index) => {
		setSectionIndex(index);
	};

	const handleTaxesSectionClick = (isExpanded, sectionName) => {
		if (isExpanded) {
			let activity = "Expand " + sectionName + " Section";
			auditActivity(activity, accId);
		}
	};

	const [isTaxNotice, setIsTaxNotice] = useState(true);
	const [isLocalImprovement, setIsLocalImprovement] = useState(true);
	const [localImprovementTaxYear, setLocalImprovementTaxYear] = useState(true);

	const yourPropertyTaxesTop = React.useRef(null);
	const taxEstimatorTop = React.useRef(null);

	useEffect(() => {
		let estimatorIndex;
		if (toggleTaxEstimator) {
			estimatorIndex = isTaxNotice ? 2 : 1;
			if (toggleTaxEstimator && !sectionIndex.includes(estimatorIndex)) {
				setSectionIndex([...sectionIndex, estimatorIndex]);
			}

			taxEstimatorTop?.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [toggleTaxEstimator]);

	useEffect(() => {
		let yourPropertyTaxesIndex;
		if (toggleYourPropertyTaxes && isTaxNotice) {
			yourPropertyTaxesIndex = 1;
			if (toggleYourPropertyTaxes && !sectionIndex.includes(yourPropertyTaxesIndex)) {
				setSectionIndex([...sectionIndex, yourPropertyTaxesIndex]);
			}
			yourPropertyTaxesTop?.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [toggleYourPropertyTaxes]);

	useEffect(() => {
		if (accountDetails !== undefined) {
			setTaxYear(accountDetails.taxYear);
		}
	}, [accountDetails]);

	return (
		<React.Fragment>
			<Accordion
				allowMultiple
				index={sectionIndex}
				onChange={handleSectionChange}>
				<AccordionItem className='accordion-item'>
					{({ isExpanded }) => (
						<React.Fragment>
							<AccordionButton
								className={`accordion-heading ${isExpanded ? "expanded" : ""}`}
								onClick={() => handleTaxesSectionClick(!isExpanded, "Property Overview")}>
								<Box
									flex='1'
									textAlign='left'>
									<chakra.span
										fontWeight={700}
										fontSize={".95em"}>
										Property Overview
									</chakra.span>
								</Box>
								{isExpanded ? (
									<Box className='accordion-expand-icon-container expanded'>
										<FontAwesomeIcon icon='fa-solid fa-minus' />
									</Box>
								) : (
									<Box className='accordion-expand-icon-container collapsed'>
										<FontAwesomeIcon icon='fa-solid fa-plus' />
									</Box>
								)}
							</AccordionButton>
							<AccordionPanel
								pb={4}
								className='accordion-panel'>
								<Box pt={2}>
									<PropertyOverview
										accId={accId}
										accountDetails={accountDetails}
										isLoading={isLoading}
										isError={isError}></PropertyOverview>
								</Box>
							</AccordionPanel>
						</React.Fragment>
					)}
				</AccordionItem>
				{isTaxNotice ? (
					<AccordionItem
						className='accordion-item'
						ref={yourPropertyTaxesTop}>
						{({ isExpanded }) => (
							<React.Fragment>
								<AccordionButton
									className={`accordion-heading ${isExpanded ? "expanded" : ""}`}
									onClick={() => handleTaxesSectionClick(!isExpanded, "Your Property Taxes")}>
									<Box
										flex='1'
										textAlign='left'>
										<chakra.span
											fontWeight={700}
											fontSize={".95em"}>
											Your {accountDetails !== undefined ? accountDetails.taxYear : null} Property
											Taxes
										</chakra.span>
									</Box>
									{isExpanded ? (
										<Box className='accordion-expand-icon-container expanded'>
											<FontAwesomeIcon icon='fa-solid fa-minus' />
										</Box>
									) : (
										<Box className='accordion-expand-icon-container collapsed'>
											<FontAwesomeIcon icon='fa-solid fa-plus' />
										</Box>
									)}
								</AccordionButton>
								<AccordionPanel
									pb={4}
									className='accordion-panel'>
									<Box pt={2}>
										<YourPropertyTaxes
											accId={accId}
											accountDetails={accountDetails}
											updateHasTaxNotice={setIsTaxNotice}></YourPropertyTaxes>
									</Box>
								</AccordionPanel>
							</React.Fragment>
						)}
					</AccordionItem>
				) : null}
				<AccordionItem
					className='accordion-item'
					ref={taxEstimatorTop}>
					{({ isExpanded }) => (
						<React.Fragment>
							<AccordionButton
								className={`accordion-heading ${isExpanded ? "expanded" : ""}`}
								onClick={() => handleTaxesSectionClick(!isExpanded, "Tax Estimator")}>
								<Box
									flex='1'
									textAlign='left'>
									<chakra.span
										fontWeight={700}
										fontSize={".95em"}>
										Tax Estimator
									</chakra.span>
								</Box>
								{isExpanded ? (
									<Box className='accordion-expand-icon-container expanded'>
										<FontAwesomeIcon icon='fa-solid fa-minus' />
									</Box>
								) : (
									<Box className='accordion-expand-icon-container collapsed'>
										<FontAwesomeIcon icon='fa-solid fa-plus' />
									</Box>
								)}
							</AccordionButton>
							<AccordionPanel
								pb={4}
								className='accordion-panel'>
								<Box pt={2}>
									<TaxEstimator
										accountDetails={accountDetails}
										accId={accId}></TaxEstimator>
								</Box>
							</AccordionPanel>
						</React.Fragment>
					)}
				</AccordionItem>
				{isLocalImprovement ? (
					<AccordionItem className='accordion-item'>
						{({ isExpanded }) => (
							<React.Fragment>
								<AccordionButton
									className={`accordion-heading ${isExpanded ? "expanded" : ""}`}
									onClick={() =>
										handleTaxesSectionClick(
											!isExpanded,
											"Local Improvement Charges and Special Taxes"
										)
									}>
									<Box
										flex='1'
										textAlign='left'>
										<chakra.span
											fontWeight={700}
											fontSize={".95em"}>
											Local Improvement Charges and Special Taxes: {localImprovementTaxYear}{" "}
											Breakdown
										</chakra.span>
									</Box>
									{isExpanded ? (
										<Box className='accordion-expand-icon-container expanded'>
											<FontAwesomeIcon icon='fa-solid fa-minus' />
										</Box>
									) : (
										<Box className='accordion-expand-icon-container collapsed'>
											<FontAwesomeIcon icon='fa-solid fa-plus' />
										</Box>
									)}
								</AccordionButton>
								<AccordionPanel
									pb={4}
									className='accordion-panel'>
									<Box pt={2}>
										<LocalImprovement
											accId={accId}
											updateHasLocalImprovement={setIsLocalImprovement}
											updateLocalImprovementTaxYear={
												setLocalImprovementTaxYear
											}></LocalImprovement>
									</Box>
								</AccordionPanel>
							</React.Fragment>
						)}
					</AccordionItem>
				) : null}
				<AccordionItem className='accordion-item'>
					{({ isExpanded }) => (
						<React.Fragment>
							<AccordionButton
								className={`accordion-heading ${isExpanded ? "expanded" : ""}`}
								onClick={() =>
									handleTaxesSectionClick(!isExpanded, "Clean Energy Improvement Program")
								}>
								<Box
									flex='1'
									textAlign='left'>
									<chakra.span
										fontWeight={700}
										fontSize={".95em"}>
										Clean Energy Improvement Program
									</chakra.span>
								</Box>
								{isExpanded ? (
									<Box className='accordion-expand-icon-container expanded'>
										<FontAwesomeIcon icon='fa-solid fa-minus' />
									</Box>
								) : (
									<Box className='accordion-expand-icon-container collapsed'>
										<FontAwesomeIcon icon='fa-solid fa-plus' />
									</Box>
								)}
							</AccordionButton>
							<AccordionPanel
								pb={4}
								className='accordion-panel'>
								<Box pt={2}>
									<CEIP accId={accId}></CEIP>
								</Box>
							</AccordionPanel>
						</React.Fragment>
					)}
				</AccordionItem>
				<AccordionItem className='accordion-item'>
					{({ isExpanded }) => (
						<React.Fragment>
							<AccordionButton
								className={`accordion-heading ${isExpanded ? "expanded" : ""}`}
								onClick={() => handleTaxesSectionClick(!isExpanded, "Ways to Pay")}>
								<Box
									flex='1'
									textAlign='left'>
									<chakra.span
										fontWeight={700}
										fontSize={".95em"}>
										Ways to Pay
									</chakra.span>
								</Box>
								{isExpanded ? (
									<Box className='accordion-expand-icon-container expanded'>
										<FontAwesomeIcon icon='fa-solid fa-minus' />
									</Box>
								) : (
									<Box className='accordion-expand-icon-container collapsed'>
										<FontAwesomeIcon icon='fa-solid fa-plus' />
									</Box>
								)}
							</AccordionButton>
							<AccordionPanel
								pb={4}
								className='accordion-panel blue'>
								<Box pt={2}>
									<WaysToPay accId={accId}></WaysToPay>
								</Box>
							</AccordionPanel>
						</React.Fragment>
					)}
				</AccordionItem>
				{isTaxNotice ? (
					<AccordionItem className='accordion-item'>
						{({ isExpanded }) => (
							<React.Fragment>
								<AccordionButton
									className={`accordion-heading ${isExpanded ? "expanded" : ""}`}
									onClick={() => handleTaxesSectionClick(!isExpanded, "Municipal Property Taxes")}>
									<Box
										flex='1'
										textAlign='left'>
										<chakra.span
											fontWeight={700}
											fontSize={".95em"}>
											Municipal Property Taxes
										</chakra.span>
									</Box>
									{isExpanded ? (
										<Box className='accordion-expand-icon-container expanded'>
											<FontAwesomeIcon icon='fa-solid fa-minus' />
										</Box>
									) : (
										<Box className='accordion-expand-icon-container collapsed'>
											<FontAwesomeIcon icon='fa-solid fa-plus' />
										</Box>
									)}
								</AccordionButton>
								<AccordionPanel
									pb={4}
									className='accordion-panel'>
									<Box pt={2}>
										<MunicipalTaxes
											accId={accId}
											taxYear={taxYear}></MunicipalTaxes>
									</Box>
								</AccordionPanel>
							</React.Fragment>
						)}
					</AccordionItem>
				) : null}
				<AccordionItem className='accordion-item'>
					{({ isExpanded }) => (
						<React.Fragment>
							<AccordionButton
								className={`accordion-heading ${isExpanded ? "expanded" : ""}`}
								onClick={() => handleTaxesSectionClick(!isExpanded, "Monthly Payment Plan")}>
								<Box
									flex='1'
									textAlign='left'>
									<chakra.span
										fontWeight={700}
										fontSize={".95em"}>
										Monthly Payment Plan
									</chakra.span>
								</Box>
								{isExpanded ? (
									<Box className='accordion-expand-icon-container expanded'>
										<FontAwesomeIcon icon='fa-solid fa-minus' />
									</Box>
								) : (
									<Box className='accordion-expand-icon-container collapsed'>
										<FontAwesomeIcon icon='fa-solid fa-plus' />
									</Box>
								)}
							</AccordionButton>
							<AccordionPanel
								pb={4}
								className='accordion-panel grey'>
								<Box pt={2}>
									<MonthlyPaymentPlan
										accId={accId}
										mppDetails={accountDetails.mppDetails}></MonthlyPaymentPlan>
								</Box>
							</AccordionPanel>
						</React.Fragment>
					)}
				</AccordionItem>
			</Accordion>
		</React.Fragment>
	);
};
export default Taxes;
