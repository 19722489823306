import "./AssessmentTaxRelationship.css";

import { Box, Flex, chakra, useBreakpointValue } from "@chakra-ui/react";

import Markdown from "../../../../../Global/Markdown/Markdown";
import React from "react";

const AssessmentTaxRelationship = ({ historyMessages }) => {
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });

	return (
		<React.Fragment>
			<Box>
				<Box mt={3}>
					<chakra.span fontSize={"2xl"}>Assessment to Tax Relationship</chakra.span>
				</Box>

				<Box mt={3} maxW={950}>
					<Box fontSize={"xl"}>
						<Markdown text={historyMessages.web5yr3relationshipIntro}></Markdown>
					</Box>
				</Box>

				<Box mt={3} maxW={950}>
					<Box className="assessment-tax-rel-message-container">
						<Markdown text={historyMessages.web5yr4RelationshipFactor}></Markdown>
					</Box>
				</Box>

				<Box maxW={950}>
					<Box mt={3}>
						<Flex direction={["column", "column", "row"]}>
							<Box className="assessment-tax-rel-blue-box" minW={isSmallerScreen ? 0 : 150}>
								<chakra.span className="text">Equal to</chakra.span>
							</Box>
							<Box mt={3} pl={isSmallerScreen ? 1 : 5} w={isSmallerScreen ? "100%" : 780}>
								<Markdown text={historyMessages.web5yr5RelationshipEqual}></Markdown>
							</Box>
						</Flex>
					</Box>
					<Box mt={3}>
						<Flex direction={["column", "column", "row"]}>
							<Box className="assessment-tax-rel-blue-box" minW={isSmallerScreen ? 0 : 150}>
								<chakra.span className="text">Higher</chakra.span>
							</Box>
							<Box mt={3} pl={isSmallerScreen ? 1 : 5} w={isSmallerScreen ? "100%" : 780}>
								<Markdown text={historyMessages.web5yr6RelationshipHigher}></Markdown>
							</Box>
						</Flex>
					</Box>
					<Box mt={3}>
						<Flex direction={["column", "column", "row"]}>
							<Box className="assessment-tax-rel-blue-box" minW={isSmallerScreen ? 0 : 150}>
								<chakra.span className="text">Lower</chakra.span>
							</Box>
							<Box mt={3} pl={isSmallerScreen ? 1 : 5} w={isSmallerScreen ? "100%" : 780}>
								<Markdown text={historyMessages.web5yr7RelationshipLower}></Markdown>
							</Box>
						</Flex>
					</Box>
				</Box>
			</Box>
		</React.Fragment>
	);
};

export default AssessmentTaxRelationship;
