import "./AssessedValue.css";

import * as toastMessages from "../../../../../../toastMessages";

import {
	Box,
	Flex,
	HStack,
	Select,
	Skeleton,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	useBreakpointValue,
	useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import { AuditContext } from "../../../../../../contexts/AuditContext";
import DataUnavailable from "../../../../../Global/DataUnavailable/DataUnavailable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "./Header/Header";
import MethodologyGuide from "./MethodologyGuide/MethodologyGuide";
import Report from "./Report/Report";
import Summary from "./Summary/Summary";

const AssessedValue = ({ accId, assessmentDetails, isLoading, isError }) => {
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });

	const { auditActivity } = useContext(AuditContext);

	const [assessment, setAssessment] = useState();
	const [assessmentSummary, setAssessmentSummary] = useState();
	const [assessedValueTabIndex, setAssessedValueTabIndex] = useState(0);
	const [assessedValueThreshold] = useState(process.env.REACT_APP_MYPROPERTY_ASSESSED_VALUE_THRESHOLD);

	const toast = useToast();
	const assessedValueToastWarningId = "assessed-value-toast-warning";

	const formLabelMinWidth = 430;
	const formControlMinWidth = 360;

	useEffect(() => {
		if (assessmentDetails !== undefined) {
			getAssessedValue();
		}
	}, [assessmentDetails]);

	const handleAssessedValueTabChange = (index) => {
		setAssessedValueTabIndex(index);
	};

	const handleCustomAssessedValueTabAudit = (e) => {
		let index = e.target.options.selectedIndex;
		let label = e.target[index].text;

		if (index > -1) {
			// audit
			auditActivity("View Asmt Details " + label + " Tab");
		}
	};

	const handleCustomAssessedValueTabChange = (e) => {
		e.preventDefault();
		//let index = parseInt(e.target.options.selectedIndex);
		let value = parseInt(e.target.value);
		if (value === -1) {
			// set state and mobile to index 0
			setAssessedValueTabIndex(0);
			document.getElementById("assessmentTypeMobile").value = 0;
		} else {
			// set state to the selected index
			setAssessedValueTabIndex(value);

			let assessmentTypeThreshold = document.getElementById("assessmentTypeThreshold");

			// check for threshold surpass
			if (assessmentSummary.length > assessedValueThreshold && value + 1 > assessedValueThreshold) {
				// surpassed threshold
				// set index in threshold dropdown
				if (assessmentTypeThreshold !== null) {
					assessmentTypeThreshold.value = value;
				}
			} else {
				// set index in threshold dropdown to -1 (More...)
				if (assessmentTypeThreshold !== null) {
					assessmentTypeThreshold.value = -1;
				}
			}
		}
	};

	const updateMobileDropdownSelection = (e) => {
		// called from a tab selection
		// set the mobile dropdown to the selected index
		e.preventDefault();
		let value = parseInt(e.target.value);
		let assessmentTypeMobile = document.getElementById("assessmentTypeMobile");

		if (value === -1) {
			assessmentTypeMobile.value = 0;
		} else {
			assessmentTypeMobile.value = value;
		}
	};

	const updateThresholdDropdownSelection = (e) => {
		// called from a tab selection
		// reset threshold dropdown to -1 (More...)
		e.preventDefault();

		let assessmentTypeThreshold = document.getElementById("assessmentTypeThreshold");
		if (assessmentTypeThreshold !== null) {
			assessmentTypeThreshold.value = -1;
		}
	};

	const getAssessedValue = () => {
		if (assessmentDetails.retVal === "true") {
			setAssessment(assessmentDetails);
			let assessmentSummaryCol = assessmentDetails.assessmentSummaryCol || [];
			setAssessmentSummary(assessmentSummaryCol);
		} else {
			if (!toast.isActive(assessedValueToastWarningId)) {
				toast(
					toastMessages.getToastWarning(
						assessedValueToastWarningId,
						assessmentDetails.errorMsg,
						toastMessages.propertyMessages
					)
				);
			}
		}
	};

	return (
		<React.Fragment>
			{isLoading ? (
				<Box>
					<Flex>
						<Skeleton
							height='32px'
							width='80px'
							mb={2}
							mr={2}
						/>
						<Skeleton
							height='32px'
							width='80px'
							mb={2}
							mr={2}
						/>
						<Skeleton
							height='32px'
							width='80px'
							mb={2}
							mr={2}
						/>
					</Flex>
					<Box
						ml={3}
						mt={2}
						mb={6}>
						<Flex direction={["column", "column", "row"]}>
							<Box minW={formLabelMinWidth}>
								<Skeleton
									height='24px'
									width='30%'
									mb={2}
								/>
								<Skeleton
									height='24px'
									width='20%'
									mb={2}
								/>
								<Skeleton
									height='24px'
									width='35%'
									mb={2}
								/>
							</Box>
							<Box minW={formControlMinWidth}>
								<Skeleton
									height='24px'
									width='40%'
									mb={2}
								/>
								<Skeleton
									height='24px'
									width='40%'
									mb={2}
								/>
								<Skeleton
									height='24px'
									width='40%'
									mb={2}
								/>
							</Box>
						</Flex>
					</Box>
					<Skeleton
						height='400px'
						width='100%'
						mb={2}
					/>
					<Skeleton
						height='60px'
						width='100%'
						mb={2}
					/>
					<Skeleton
						height='160px'
						width='100%'
						mb={2}
					/>
				</Box>
			) : !isError ? (
				<Box>
					{assessment !== undefined ? (
						<Box mt={1}>
							<Box display={isSmallerScreen ? "" : "none"}>
								<Box className='assessed-value-select-container'>
									<Select
										id='assessmentTypeMobile'
										name='assessmentTypeMobile'
										className='assessed-value-select'
										onChange={(e) => {
											handleCustomAssessedValueTabAudit(e);
											handleCustomAssessedValueTabChange(e);
											updateMobileDropdownSelection(e);
										}}
										iconSize={"1em"}
										icon={<FontAwesomeIcon icon='fa-solid fa-caret-down' />}>
										{assessmentSummary.map((item, index) => (
											<option
												key={index}
												value={index}>
												{item.tabLabel.trim()}
											</option>
										))}
									</Select>
								</Box>
							</Box>
							<Tabs
								className='assessed-value tabs'
								variant='unstyled'
								index={assessedValueTabIndex}
								onChange={handleAssessedValueTabChange}>
								<HStack>
									<Box className='assessed-value-display'>
										<TabList
											mb='1em'
											className='assessed-value tab-list'>
											{assessmentSummary.map((item, index) => (
												<Tab
													key={index}
													value={index}
													className={`assessed-value tab ${
														assessmentSummary.length > assessedValueThreshold &&
														index + 1 > assessedValueThreshold
															? "hidden"
															: ""
													}`}
													_selected={{ color: "white", bg: "blue.500" }}
													_hover={{ color: "white", bg: "blue.500" }}
													onClick={(e) => {
														// audit
														auditActivity(
															"View Asmt Details " + item.tabLabel.trim() + " Tab"
														);

														updateThresholdDropdownSelection(e);
														updateMobileDropdownSelection(e);
													}}>
													{item.tabLabel.trim()}
												</Tab>
											))}
										</TabList>
									</Box>

									<Box
										display={assessmentSummary.length > assessedValueThreshold ? "" : "none"}
										className={"assessed-value-select-threshold-pos"}>
										<Box
											className={`assessed-value-display assessed-value-select-threshold-container ${
												assessmentSummary.length > assessedValueThreshold &&
												assessedValueTabIndex + 1 > assessedValueThreshold
													? "selected"
													: ""
											}`}>
											<Select
												id='assessmentTypeThreshold'
												name='assessmentTypeThreshold'
												className={`assessed-value-threshold-select ${
													assessmentSummary.length > assessedValueThreshold &&
													assessedValueTabIndex + 1 > assessedValueThreshold
														? "selected"
														: ""
												}`}
												iconSize={"1em"}
												icon={<FontAwesomeIcon icon='fa-solid fa-caret-down' />}
												onChange={(e) => {
													handleCustomAssessedValueTabAudit(e);
													handleCustomAssessedValueTabChange(e);
													updateMobileDropdownSelection(e);
												}}>
												<option value={-1}>More...</option>
												{assessmentSummary.map((item, index) => (
													<option
														key={index}
														value={index}
														hidden={
															assessmentSummary.length > assessedValueThreshold &&
															index + 1 > assessedValueThreshold
																? false
																: true
														}>
														{item.tabLabel.trim()}
													</option>
												))}
											</Select>
										</Box>
									</Box>
								</HStack>
								<TabPanels>
									{assessmentSummary.map((item, index) => (
										<TabPanel
											className='assessed-value tab-panel'
											key={index}>
											<Box mt={3}>
												<Box ml={3}>
													<Header
														accId={accId}
														assessment={assessment}
														assessmentSummary={item}></Header>
												</Box>
												<Summary
													accId={accId}
													assessment={assessment}
													assessmentSummary={item}></Summary>
											</Box>
										</TabPanel>
									))}
									<Report accId={accId}></Report>
									<MethodologyGuide accId={accId}></MethodologyGuide>
								</TabPanels>
							</Tabs>
						</Box>
					) : (
						<Box>
							<Skeleton
								startColor='#e2e2e2'
								endColor='#bbbbbb'
								size='8'
								width={540}
								height={38}></Skeleton>
							<Skeleton
								mt={4}
								height={380}
								startColor='#e2e2e2'
								endColor='#bbbbbb'></Skeleton>
						</Box>
					)}
				</Box>
			) : (
				<DataUnavailable></DataUnavailable>
			)}
		</React.Fragment>
	);
};

export default AssessedValue;
