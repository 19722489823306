import "./PropertyOverview.css";

import * as CommonFns from "../../../../../../common";
import * as toastMessages from "../../../../../../toastMessages";

import {
	Box,
	Button,
	Flex,
	HStack,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Skeleton,
	Spinner,
	chakra,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { axiosMyPropertyAuth, axiosWebLogic } from "../../../../../../apis";

import { AuditContext } from "../../../../../../contexts/AuditContext";
import DataUnavailable from "../../../../../Global/DataUnavailable/DataUnavailable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Markdown from "../../../../../Global/Markdown/Markdown";

const PropertyOverview = ({ accId, accountDetails, isLoading, isError }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenExclusion, onOpen: onOpenExclusion, onClose: onCloseExclusion } = useDisclosure();
	const [exclusionTitle, setExclusionTitle] = useState("");
	const [exclusionMessage, setExclusionMessage] = useState("");
	const [processingBalance, setProcessingBalance] = useState(false);
	const [processingHistory, setProcessingHistory] = useState(false);
	const [payload, setPayload] = useState({});
	const [property, setProperty] = useState({
		accountBalanceAsOfDate: "",
		accountBalance: "",
		taxationYear: "",
		address: "",
		legalDescription: "",
		zoning: "",
		effectiveZoning: "",
		displayEffZoningTaxes: "",
		assessmentClass: "",
		propertyUse: "",
		taxableStatus: "",
		schoolSupportDeclaration: "",
		isExcluded: "N",
		balanceExclusionMessage: "",
		historyExclusionMessage: "",
	});
	const toast = useToast();
	const propertyOverviewToastErrorId = "property-overview-toast-error";
	const taxesReportToastErrorId = "taxes-report-toast-error";

	const { auditActivity } = useContext(AuditContext);

	useEffect(() => {
		if (accountDetails !== undefined) {
			getLucDetails();
		}

		getClientIP();
	}, [accId, accountDetails]);

	const formLabelMinWidth = 430;
	const formControlMinWidth = 360;

	const getLucDetails = () => {
		let tempProperty = { ...property };

		let url = `taxes/luc/${accId}`;
		axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;
				let luc = returnObj.webLucDetailsCol[0];

				tempProperty.accountBalance = CommonFns.FormatCurrency(accountDetails.acctBalance, 2);
				tempProperty.accountBalanceAsOfDate = accountDetails.acctBalanceAsOfDate;
				tempProperty.taxationYear = accountDetails.taxationYear;
				tempProperty.address = accountDetails.address;
				tempProperty.legalDescription =
					accountDetails.assessedParcel == null
						? accountDetails.shortLegal
						: accountDetails.shortLegal + " " + accountDetails.assessedParcel;
				tempProperty.zoning = accountDetails.actZone;
				tempProperty.effectiveZoning = accountDetails.effZone;
				tempProperty.displayEffZoningTaxes = accountDetails.displayEffZoningTaxes;
				tempProperty.schoolSupportDeclaration = accountDetails.schoolSupportDeclr;
				tempProperty.assessmentClass = luc.assessmentClass;
				tempProperty.propertyUse = luc.propertyUse;
				tempProperty.taxableStatus = luc.taxableStatus;
			})
			.then(() => {
				checkReportExclusion(tempProperty);
			})
			.catch((error) => {
				// error
				if (!toast.isActive(propertyOverviewToastErrorId)) {
					toast(toastMessages.getToastError(propertyOverviewToastErrorId));
				}
			});
	};

	const checkReportExclusion = (tempProperty) => {
		let url = `taxes/reportexclusion/${accId}`;
		axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;

				tempProperty.isExcluded = returnObj.isExcluded;
				tempProperty.balanceExclusionMessage = returnObj.balanceExclusionMessage;
				tempProperty.historyExclusionMessage = returnObj.historyExclusionMessage;

				setProperty(tempProperty);
			})
			.catch((error) => {
				// error
				if (!toast.isActive(propertyOverviewToastErrorId)) {
					toast(toastMessages.getToastError(propertyOverviewToastErrorId));
				}
			});
	};

	const getClientIP = () => {
		CommonFns.getIP().then((res) => setPayload({ ...payload, accountNumber: accId.toString(), ipAddress: res }));
	};

	const handleBalanceDownload = (e) => {
		e.preventDefault();

		if (property.isExcluded === "Y") {
			//show exclusion modal
			setExclusionTitle("Current Tax Account Balance");
			setExclusionMessage(property.balanceExclusionMessage);
			onOpenExclusion();
		} else {
			//audit activity
			auditActivity("Download Account Balance", accId);

			//download report
			setProcessingBalance(true);

			let url = "report/accountbalance";
			axiosWebLogic
				.post(url, payload)
				.then((resp) => {
					let returnObj = resp.data;

					//download report
					CommonFns.pdfDownload(returnObj.report, returnObj.reportName);

					//close modal
					onCloseExclusion();
				})
				.catch((e) => {
					// error
					if (!toast.isActive(taxesReportToastErrorId)) {
						toast(toastMessages.getToastError(taxesReportToastErrorId));
					}
				})
				.finally(() => {
					setProcessingBalance(false);
				});
		}
	};

	const handleTransactionHistoryDownload = (e) => {
		e.preventDefault();

		// make sure the accessType is OWNER no matter what
		// this button shouldnt be avaliable for "VIEWER" types anyway
		if (accountDetails.accessType === "OWNER") {
			if (property.isExcluded === "Y") {
				//show exclusion modal
				setExclusionTitle("Transaction History");
				setExclusionMessage(property.historyExclusionMessage);
				onOpenExclusion();
			} else {
				//audit activity
				auditActivity("Download Transaction History Report", accId);

				//download report
				setProcessingHistory(true);

				let url = "report/history";
				axiosWebLogic
					.post(url, payload)
					.then((resp) => {
						let returnObj = resp.data;

						//download report
						CommonFns.pdfDownload(returnObj.report, returnObj.reportName);

						//close modal
						onCloseExclusion();
					})
					.catch((e) => {
						// error
						if (!toast.isActive(taxesReportToastErrorId)) {
							toast(toastMessages.getToastError(taxesReportToastErrorId));
						}
					})
					.finally(() => {
						setProcessingHistory(false);
					});
			}
		}
	};

	return (
		<React.Fragment>
			{isLoading ? (
				<Flex>
					<Box
						ml={3}
						mt={1}
						mb={2}>
						<Flex direction={["column", "column", "row"]}>
							<Box minW={formLabelMinWidth}>
								<Skeleton
									height='24px'
									width='50%'
									mb={2}
								/>
								<Skeleton
									height='24px'
									width='55%'
									mb={2}
								/>
								<Skeleton
									height='24px'
									width='55%'
									mb={4}
								/>
								<Skeleton
									height='24px'
									width='30%'
									mb={2}
								/>
								<Skeleton
									height='24px'
									width='38%'
									mb={2}
								/>
								<Skeleton
									height='24px'
									width='35%'
									mb={2}
								/>
								<Skeleton
									height='24px'
									width='25%'
									mb={2}
								/>
								<Skeleton
									height='24px'
									width='35%'
									mb={5}
								/>
								<Skeleton
									height='24px'
									width='35%'
									mb={2}
								/>
								<Skeleton
									height='24px'
									width='40%'
									mb={2}
								/>
								<Skeleton
									height='24px'
									width='40%'
									mb={4}
								/>
								<Skeleton
									height='24px'
									width='65%'
								/>
							</Box>
							<Box minW={formControlMinWidth}>
								<Skeleton
									height='24px'
									width='40%'
									mb={2}
								/>
								<Skeleton
									height='24px'
									width='30%'
									mb={2}
								/>
								<Skeleton
									height='24px'
									width='30%'
									mb={4}
								/>
								<Skeleton
									height='24px'
									width='60%'
									mb={2}
								/>
								<Skeleton
									height='24px'
									width='65%'
									mb={2}
								/>
								<Skeleton
									height='24px'
									width='60%'
									mb={2}
								/>
								<Skeleton
									height='24px'
									width='70%'
									mb={2}
								/>
								<Skeleton
									height='24px'
									width='70%'
									mb={4}
								/>
								<Skeleton
									height='24px'
									width='40%'
									mb={2}
								/>
								<Skeleton
									height='24px'
									width='55%'
									mb={2}
								/>
								<Skeleton
									height='24px'
									width='65%'
									mb={4}
								/>
								<Skeleton
									height='24px'
									width='65%'
								/>
							</Box>
						</Flex>
					</Box>
				</Flex>
			) : !isError ? (
				<React.Fragment>
					<Box ml={3}>
						<Box mt={1}>
							<Flex direction={["column", "column", "row"]}>
								<Box minW={formLabelMinWidth}>
									Property tax account balance{" "}
									{property.accountBalanceAsOfDate !== null
										? "as of " + property.accountBalanceAsOfDate
										: ""}
								</Box>
								<Box minW={formControlMinWidth}>
									<chakra.span fontWeight={700}>{property.accountBalance}</chakra.span>
									<Link
										className='note-info'
										ml={2}
										onClick={onOpen}>
										<FontAwesomeIcon
											icon='fa-solid fa-circle-info'
											className='taxes-overview-i-icon'
										/>
									</Link>
									<Popover
										returnFocusOnClose={false}
										isOpen={isOpen}
										onClose={onClose}
										placement='right'
										closeOnBlur={true}>
										<PopoverTrigger>
											<chakra.span></chakra.span>
										</PopoverTrigger>
										<PopoverContent minW={{ base: "100%", lg: 500 }}>
											<PopoverArrow />
											<PopoverBody className='popover-shadow'>
												For full details of your current tax account balance, please refer to
												the{" "}
												<chakra.span fontWeight={700}>
													tax account balance statement{" "}
												</chakra.span>
												or the{" "}
												<chakra.span fontWeight={700}>
													tax account transaction history
												</chakra.span>
												.
											</PopoverBody>
										</PopoverContent>
									</Popover>
								</Box>
							</Flex>
						</Box>

						<Box mt={2}>
							<Flex direction={["column", "column", "row"]}>
								<Box minW={formLabelMinWidth}>Tax account balance statement</Box>
								<Box minW={formControlMinWidth}>
									<HStack>
										<Box>
											<Button
												colorScheme='lightBlue'
												size={"xs"}
												fontSize={".9em"}
												mr={1}
												width={95}
												onClick={(e) => {
													handleBalanceDownload(e);
												}}
												isDisabled={processingBalance}>
												Download
											</Button>
										</Box>
										<Box
											display={processingBalance ? "" : "none"}
											className='sales-transfers-fade'>
											<Spinner
												size='sm'
												speed='0.90s'
												emptyColor='gray.200'
												color='blue.500'
											/>
										</Box>
									</HStack>
								</Box>
							</Flex>
						</Box>

						{accountDetails?.accessType === "OWNER" ? (
							<Box mt={2}>
								<Flex direction={["column", "column", "row"]}>
									<Box minW={formLabelMinWidth}>Tax account transaction history</Box>
									<Box minW={formControlMinWidth}>
										<HStack>
											<Box>
												<Button
													colorScheme='lightBlue'
													size={"xs"}
													fontSize={".9em"}
													mr={1}
													width={95}
													onClick={(e) => {
														handleTransactionHistoryDownload(e);
													}}
													isDisabled={processingHistory}>
													Download
												</Button>
											</Box>
											<Box
												display={processingHistory ? "" : "none"}
												className='sales-transfers-fade'>
												<Spinner
													size='sm'
													speed='0.90s'
													emptyColor='gray.200'
													color='blue.500'
												/>
											</Box>
										</HStack>
									</Box>
								</Flex>
							</Box>
						) : null}

						<Box
							pt={2}
							pb={2}></Box>

						<Box mt={2}>
							<Flex direction={["column", "column", "row"]}>
								<Box minW={formLabelMinWidth}>Taxation year</Box>
								<Box minW={formControlMinWidth}>
									<chakra.span fontWeight={700}>{property.taxationYear}</chakra.span>
								</Box>
							</Flex>
						</Box>

						{property.address != null ? (
							<Box mt={2}>
								<Flex direction={["column", "column", "row"]}>
									<Box minW={formLabelMinWidth}>Municipal address</Box>
									<Box minW={formControlMinWidth}>
										<chakra.span fontWeight={700}>{property.address}</chakra.span>
									</Box>
								</Flex>
							</Box>
						) : null}

						<Box mt={2}>
							<Flex direction={["column", "column", "row"]}>
								<Box minW={formLabelMinWidth}>Legal description</Box>
								<Box minW={formControlMinWidth}>
									<chakra.span fontWeight={700}>{property.legalDescription}</chakra.span>
								</Box>
							</Flex>
						</Box>

						<Box mt={2}>
							<Flex direction={["column", "column", "row"]}>
								<Box minW={formLabelMinWidth}>Zoning</Box>
								<Box minW={formControlMinWidth}>
									<chakra.span fontWeight={700}>{property.zoning}</chakra.span>
								</Box>
							</Flex>
						</Box>

						{property.displayEffZoningTaxes === "Y" ? (
							<Box mt={2}>
								<Flex direction={["column", "column", "row"]}>
									<Box minW={formLabelMinWidth}>Effective Zoning</Box>
									<Box minW={formControlMinWidth}>
										<chakra.span fontWeight={700}>{property.effectiveZoning}</chakra.span>
									</Box>
								</Flex>
							</Box>
						) : null}

						<Box
							pt={2}
							pb={2}></Box>

						<Box mt={2}>
							<Flex direction={["column", "column", "row"]}>
								<Box minW={formLabelMinWidth}>Assessment class</Box>
								<Box minW={formControlMinWidth}>
									<chakra.span fontWeight={700}>{property.assessmentClass}</chakra.span>
								</Box>
							</Flex>
						</Box>

						<Box mt={2}>
							<Flex direction={["column", "column", "row"]}>
								<Box minW={formLabelMinWidth}>Property use</Box>
								<Box minW={formControlMinWidth}>
									<chakra.span fontWeight={700}>{property.propertyUse}</chakra.span>
								</Box>
							</Flex>
						</Box>

						<Box mt={2}>
							<Flex direction={["column", "column", "row"]}>
								<Box minW={formLabelMinWidth}>Taxable status</Box>
								<Box minW={formControlMinWidth}>
									<chakra.span fontWeight={700}>{property.taxableStatus}</chakra.span>
								</Box>
							</Flex>
						</Box>

						{accountDetails.accessType === "OWNER" ? (
							<>
								<Box
									pt={2}
									pb={2}></Box>

								<Box mt={2}>
									<Flex direction={["column", "column", "row"]}>
										<Box minW={formLabelMinWidth}>Current school support declaration</Box>
										<Box minW={formControlMinWidth}>
											<chakra.span fontWeight={700}>
												{property.schoolSupportDeclaration}
											</chakra.span>
										</Box>
									</Flex>
								</Box>
							</>
						) : null}
					</Box>
					<Modal
						closeOnOverlayClick={false}
						isOpen={isOpenExclusion}
						onClose={onCloseExclusion}>
						<ModalOverlay />
						<ModalContent maxW={"38rem"}>
							<ModalHeader className={"modal-header"}>{exclusionTitle} - Not Available</ModalHeader>
							<ModalCloseButton className='modal-close-button'>
								<FontAwesomeIcon
									size={"2xl"}
									icon='fa-solid fa-xmark'
								/>
							</ModalCloseButton>
							<ModalBody>
								<Box py='5'>
									<Markdown text={exclusionMessage}></Markdown>
								</Box>
							</ModalBody>
							<ModalFooter className={"modal-footer"}>
								{/* <Button
									colorScheme='blue'
									size={"sm"}
									onClick={onCloseExclusion}>
									Close
								</Button> */}
							</ModalFooter>
						</ModalContent>
					</Modal>
				</React.Fragment>
			) : (
				<DataUnavailable></DataUnavailable>
			)}
		</React.Fragment>
	);
};
export default PropertyOverview;
