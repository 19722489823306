import "../History.css";
import "chart.js/auto";

import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, chakra } from "@chakra-ui/react";
import React, { useContext, useState } from "react";

import { AuditContext } from "../../../../../../contexts/AuditContext";
import Breakdown from "./Breakdown/Breakdown";
import DataTable from "./DataTable/DataTable";
import FiveYearAmount from "./FiveYearAmount/FiveYearAmount";
import Markdown from "../../../../../Global/Markdown/Markdown";

const TaxesPaid = ({
	accId,
	historyDetails,
	scenarioMessage,
	taxIntroMessage,
	chartMessage,
	propertyTotal,
	municipalTotal,
	totalGreaterThan15000,
	totalGreaterThan60000,
}) => {
	const [historyTabIndex, setHistoryTabIndex] = useState(0);

	const { auditActivity } = useContext(AuditContext);

	const handleHistoryTabChange = (index) => {
		setHistoryTabIndex(index);
	};

	const handleAuditTaxesPaid = (activity) => {
		auditActivity(activity, accId);
	};

	return (
		<React.Fragment>
			<Box mb={3} mt={3} maxW={1050}>
				<chakra.span fontSize={"2xl"}>Property Taxes Paid in the Last Five Years</chakra.span>
			</Box>
			<Box maxW={1050} pb={2} fontSize={"xl"}>
				<Markdown text={taxIntroMessage}></Markdown>
			</Box>
			<Box maxW={1050} pb={5} fontSize={"lg"}>
				<Markdown text={scenarioMessage}></Markdown>
			</Box>
			<Box>
				<Tabs
					className="history tabs"
					variant="unstyled"
					index={historyTabIndex}
					onChange={handleHistoryTabChange}
				>
					<Box className="history-display">
						<TabList mb="1em" className="history tab-list">
							<Tab
								key={0}
								value={0}
								_selected={{ color: "white", bg: "blue.500" }}
								_hover={{ color: "white", bg: "blue.500" }}
								className="history tab"
								onClick={() => handleAuditTaxesPaid("View History Tax Breakdown")}
							>
								Breakdown
							</Tab>
							<Tab
								key={1}
								value={1}
								_selected={{ color: "white", bg: "blue.500" }}
								_hover={{ color: "white", bg: "blue.500" }}
								className="history tab"
								onClick={() => handleAuditTaxesPaid("View History Tax Five-year Amount")}
							>
								Five-Year Amount
							</Tab>
							<Tab
								key={2}
								value={2}
								_selected={{ color: "white", bg: "blue.500" }}
								_hover={{ color: "white", bg: "blue.500" }}
								className="history tab"
								onClick={() => handleAuditTaxesPaid("View History Tax Data Table")}
							>
								Data Table
							</Tab>
						</TabList>
					</Box>
					{/* </Stack> */}
					<TabPanels>
						<TabPanel className="history tab-panel" key={0}>
							<Box mt={3} ml={5} maxWidth={"650px"}>
								<Breakdown
									historyDetails={historyDetails}
									totalGreaterThan15000={totalGreaterThan15000}
									totalGreaterThan60000={totalGreaterThan60000}
								></Breakdown>
							</Box>
						</TabPanel>
						<TabPanel className="history tab-panel" key={1}>
							<Box mt={3} ml={5} maxWidth={"650px"}>
								<FiveYearAmount historyDetails={historyDetails}></FiveYearAmount>
							</Box>
						</TabPanel>
						<TabPanel className="history tab-panel" key={2}>
							<Box mt={1} maxWidth={"650px"}>
								<DataTable
									historyDetails={historyDetails}
									propertyTotal={propertyTotal}
									municipalTotal={municipalTotal}
								></DataTable>
							</Box>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>
			<Box maxW={1050} pt={5}>
				<Markdown text={chartMessage}></Markdown>
			</Box>
		</React.Fragment>
	);
};
export default TaxesPaid;
