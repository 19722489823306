import React from "react";
import { Spinner } from "@chakra-ui/react";

const LoadingSpinner = () => {
	return (
		<Spinner
			size='sm'
			speed='0.90s'
			emptyColor='gray.200'
			color='blue.500'
		/>
	);
};

export default LoadingSpinner;
