import "./Summary.css";

import { Box, Flex, HStack, Spacer, Switch, Table, TableContainer, Tbody, chakra } from "@chakra-ui/react";
import React, { useContext, useState } from "react";

import { AuditContext } from "../../../../../../../contexts/AuditContext";
import DetailRow from "./TableDetails/DetailRow";
import MarketApproachRow from "./TableDetails/MarketApproachRow";

const Summary = ({ accId, assessmentSummary }) => {
	const [metricSummary] = useState(assessmentSummary.metricSpssOutputCol);
	const [imperialSummary] = useState(assessmentSummary.impSpssOutputCol);
	const [displayMetric, setDisplayMetric] = useState(true);
	const [displayUnitToggle] = useState(
		assessmentSummary.impSpssOutputCol !== null && assessmentSummary.metricSpssOutputCol !== null ? true : false
	);
	const { auditActivity } = useContext(AuditContext);

	const handleUnitToggle = (e) => {
		// false = m/m2
		// true = ft/ft2
		let isChecked = e.target.checked;
		setDisplayMetric(!isChecked);

		let metric = isChecked ? "ft/ft2" : "m/m2";
		let activity = `Asmt Details - ${metric} - Toggle`;
		auditActivity(activity, accId);
	};

	return (
		<React.Fragment>
			{displayUnitToggle ? (
				<Box mb={2}>
					<Flex>
						<Spacer />
						<HStack>
							<chakra.span
								className={
									displayMetric ? "assessed-value-switch-label" : "assessed-value-switch-label-fade"
								}
							>
								m/m<sup>2</sup>
							</chakra.span>
							<Switch
								colorScheme="grey"
								size="lg"
								className="assessed-value-switch"
								onChange={(e) => handleUnitToggle(e)}
							/>
							<chakra.span
								className={
									displayMetric ? "assessed-value-switch-label-fade" : "assessed-value-switch-label"
								}
							>
								ft/ft<sup>2</sup>
							</chakra.span>
						</HStack>
					</Flex>
				</Box>
			) : null}
			<Box>
				{displayMetric ? (
					metricSummary !== null ? (
						<React.Fragment>
							<TableContainer>
								<Table variant="simple" className="table-assessed-value">
									<Tbody>
										<MarketApproachRow
											key={"tr_met_market_approach"}
											summary={assessmentSummary}
										></MarketApproachRow>
										{metricSummary.map((item, index) => (
											<DetailRow key={"tr_met_" + index} summary={item}></DetailRow>
										))}
									</Tbody>
								</Table>
							</TableContainer>
						</React.Fragment>
					) : null
				) : imperialSummary !== null ? (
					<React.Fragment>
						<TableContainer>
							<Table variant="simple" className="table-assessed-value">
								<Tbody>
									<MarketApproachRow
										key={"tr_met_market_approach"}
										summary={assessmentSummary}
									></MarketApproachRow>
									{imperialSummary.map((item, index) => (
										<DetailRow key={"tr_imp_" + index} summary={item}></DetailRow>
									))}
								</Tbody>
							</Table>
						</TableContainer>
					</React.Fragment>
				) : null}
			</Box>
		</React.Fragment>
	);
};

export default Summary;
