import "./LocalImprovement.css";

import * as CommonFns from "../../../../../../common";
import * as toastMessages from "../../../../../../toastMessages";

import {
	Box,
	Button,
	Flex,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	chakra,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import { AuditContext } from "../../../../../../contexts/AuditContext";
import FileComplaint from "./FileComplaint/FileComplaint";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LIPayOut from "./PayOut/LIPayOut";
import Markdown from "../../../../../Global/Markdown/Markdown";
import { axiosMyPropertyAuth } from "../../../../../../apis";

const LocalImprovement = ({ accId, updateHasLocalImprovement, updateLocalImprovementTaxYear }) => {
	const [localImprovement, setIsLocalImprovement] = useState();
	const [isDisplayPayout, setIsDisplayPayout] = useState(false);

	useEffect(() => {
		getLocalImprovement();
	}, []);

	const { isOpen: isOpenPayOut, onOpen: onOpenPayOut, onClose: onClosePayOut } = useDisclosure();
	const { isOpen: isOpenPayableWith, onToggle: onTogglePayableWith, onClose: onClosePayableWith } = useDisclosure();
	const { isOpen: isOpenComplaint, onToggle: onToggleComplaint, onClose: onCloseComplaint } = useDisclosure();

	const { auditActivity } = useContext(AuditContext);

	const toast = useToast();
	const localImprovementErrorId = "local-improvement-toast-error";

	const getLocalImprovement = () => {
		let url = `taxes/localimprovement/${accId}`;
		axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					setIsLocalImprovement(returnObj);

					setIsDisplayPayout(
						returnObj.displayPayoutFlag !== null
							? returnObj.displayPayoutFlag === "Y"
								? true
								: false
							: false
					);

					// set show/hide value for accordion section on taxes.js
					updateHasLocalImprovement(returnObj.liPayoutMessage !== null ? true : false);

					// tax year to display on accordion
					updateLocalImprovementTaxYear(returnObj.taxYear !== null ? returnObj.taxYear : "");
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(localImprovementErrorId)) {
					toast(toastMessages.getToastError(localImprovementErrorId));
				}
			});
	};

	// const handlePayOutModalClose = () => {
	// 	onClosePayOut();
	// };

	// const handleComplaintModalClose = () => {
	// 	onCloseComplaint();
	// };

	return (
		<React.Fragment>
			{localImprovement !== undefined ? (
				<React.Fragment>
					<Box maxW={1050}>
						<Text fontSize={"xl"}>{localImprovement.liExplanationMessage}</Text>
						<Box mt={4}>
							{" "}
							<Markdown text={localImprovement.liBreakdwonMessage}></Markdown>
						</Box>
					</Box>
					<Box>
						<TableContainer>
							<Box
								maxW={1050}
								minW={750}>
								<Table className='local-improvement-table'>
									<Thead>
										<Tr>
											<Th className='border-none'></Th>
											<Th>
												First
												<br />
												year
											</Th>
											<Th>
												Expiry
												<br />
												year
											</Th>
											<Th>
												Interest
												<br />
												rate
											</Th>
											<Th>
												Payable with
												<br />
												{localImprovement.taxYear}
												<br />
												property taxes
												<Link
													className='local-improvement-payable-with-info'
													ml={1}
													onClick={onTogglePayableWith}>
													<FontAwesomeIcon icon='fa-solid fa-circle-info' />
												</Link>
												<Popover
													returnFocusOnClose={false}
													isOpen={isOpenPayableWith}
													onClose={onClosePayableWith}
													placement='right'
													closeOnBlur={true}>
													<PopoverTrigger>
														<chakra.span></chakra.span>
													</PopoverTrigger>
													<PopoverContent minW={{ base: "100%", lg: 500 }}>
														<PopoverArrow />
														<PopoverBody className='popover-shadow'>
															<Box textAlign={"left"}>
																<chakra.span
																	className='local-improvement-payable-with-text'
																	whiteSpace={"break-spaces"}>
																	{localImprovement.liInfoMessage}
																</chakra.span>
															</Box>
														</PopoverBody>
													</PopoverContent>
												</Popover>
											</Th>
											{isDisplayPayout ? (
												<Th className='local-improvement-payout'>
													Optional
													<br />
													payout amount
													<br />
													(if available)
												</Th>
											) : null}
										</Tr>
									</Thead>
									<Tbody>
										{localImprovement.liGroupCol.map((group, index) => (
											<React.Fragment key={index}>
												<Tr>
													<Td
														className='text-left'
														fontWeight={700}>
														{group.displayHeading}
													</Td>
													<Td colSpan={4}>&nbsp;</Td>
													{isDisplayPayout ? (
														<Td className='local-improvement-payout'></Td>
													) : null}
												</Tr>
												{group.liGroupDtls.map((groupDetail, index) => (
													<React.Fragment key={index}>
														<Tr>
															<Td
																w={"30%"}
																className='text-left pad-left'>
																{groupDetail.liType}
															</Td>
															<Td w={"10%"}>
																{groupDetail.firstYear !== null
																	? groupDetail.firstYear !== "0"
																		? groupDetail.firstYear
																		: ""
																	: ""}
															</Td>
															<Td w={"10%"}>
																{groupDetail.expiryYear !== null
																	? groupDetail.expiryYear !== "0"
																		? groupDetail.expiryYear
																		: ""
																	: ""}
															</Td>
															<Td w={"10%"}>
																<Flex justifyContent='center'>
																	{groupDetail.interestRate !== null
																		? groupDetail.interestRate + "%"
																		: ""}
																</Flex>
															</Td>
															<Td w={"15%"}>
																<Flex justifyContent='center'>
																	{groupDetail.annualAmount !== null
																		? CommonFns.FormatCurrency(
																				groupDetail.annualAmount,
																				2,
																				true
																		  )
																		: ""}
																</Flex>
															</Td>
															{isDisplayPayout ? (
																<Td
																	w={"10%"}
																	className='local-improvement-payout'>
																	<Flex justifyContent='center'>
																		{groupDetail.payoutAmount !== null
																			? CommonFns.FormatCurrency(
																					groupDetail.payoutAmount,
																					2,
																					true
																			  )
																			: ""}
																	</Flex>
																</Td>
															) : null}
															{groupDetail.fileComplaintFlag === "Y" ? (
																<Td>
																	<Link
																		fontSize={".9em"}
																		onClick={() => {
																			auditActivity(
																				"Local Improvement Charges - File Complaint Click",
																				accId
																			);
																			onToggleComplaint();
																		}}>
																		File complaint
																	</Link>
																</Td>
															) : null}
														</Tr>
													</React.Fragment>
												))}
											</React.Fragment>
										))}
										{isDisplayPayout ? (
											<Tr>
												<Td colSpan={5}></Td>
												<Td className='local-improvement-payout'>
													<Box
														pl={"2px"}
														pr={"2px"}
														pb={"2px"}>
														<Button
															className='local-improvement-payout-button'
															size={"sm"}
															onClick={() => {
																auditActivity(
																	"Local Improvement Charges - Pay out Click",
																	accId
																);
																onOpenPayOut();
															}}>
															Pay out
														</Button>
													</Box>
												</Td>
											</Tr>
										) : null}
									</Tbody>
								</Table>
							</Box>
						</TableContainer>
					</Box>
				</React.Fragment>
			) : null}

			{/* Pay Out Modal */}
			<Modal
				size={"5xl"}
				closeOnOverlayClick={false}
				isOpen={isOpenPayOut}
				onClose={onClosePayOut}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className={"modal-header"}>
						If You Wish to Pay Out Local Improvement Charges
					</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					<ModalBody>
						<LIPayOut localImprovement={localImprovement}></LIPayOut>
					</ModalBody>
					<ModalFooter className={"modal-footer"}>
						{/* <Button
							colorScheme='blue'
							size={"sm"}
							onClick={() => handlePayOutModalClose()}>
							Close
						</Button> */}
					</ModalFooter>
				</ModalContent>
			</Modal>

			{/* File Complaint Modal */}
			<Modal
				size={"2xl"}
				closeOnOverlayClick={false}
				isOpen={isOpenComplaint}
				onClose={onCloseComplaint}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className={"modal-header"}>Steps to Take if You Don't Agree</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					<ModalBody>
						<FileComplaint
							accId={accId}
							localImprovement={localImprovement}></FileComplaint>
					</ModalBody>
					<ModalFooter className={"modal-footer"}>
						{/* <Button colorScheme='blue' size={"sm"} onClick={() => handleComplaintModalClose()}>
							Close
						</Button> */}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</React.Fragment>
	);
};

export default LocalImprovement;
