import './Timeline.css';

import { Box, Skeleton, chakra } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';

import { AuditContext } from '../../../../contexts/AuditContext';
import { Chrono } from 'react-chrono';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Markdown from '../../../Global/Markdown/Markdown';
import { axiosMyPropertyAuth } from '../../../../apis';

const TimeLine = () => {
	const pageTitle = 'Property Assessment and Tax Timeline';

	const [timeline, setTimeline] = useState();
	const [defaultMonthIndex, setDefaultMonthIndex] = useState();
	const [selectedMonthId, setSelectedMonthId] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);

	const { auditActivity } = useContext(AuditContext);

	useEffect(() => {
		getTimeline();
	}, []);

	const getTimeline = () => {
		setIsLoading(true);
		setIsError(false);

		axiosMyPropertyAuth
			.get('timeline')
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.Months.length > 0) {
					let months = returnObj.Months;
					setTimeline(months);

					//find the month that should be selected by default
					months.forEach((element, index) => {
						if (element.DefaultEventStyle === 'selected') {
							setDefaultMonthIndex(index);
						}
					});

					setIsLoading(false);
					setIsError(false);
				}
			})
			.catch((error) => {
				// error
				setIsLoading(false);
				setIsError(true);
			});
	};

	const handleActivityLog = (e) => {
		// hacky method to only log the activity once.
		// react-chrono onItemSelected likes to fire twice on a click
		// log the first instance of the click, ignore the second
		// this also works with the arrow buttons, only one onItemSelected is triggered...
		let timelineId = e.id;

		setSelectedMonthId(timelineId);

		if (selectedMonthId !== undefined && timelineId !== selectedMonthId) {
			let activity = 'Timeline - ' + e.title.toLowerCase();
			auditActivity(activity);
		}
	};

	return (
		<React.Fragment>
			{!isError ? (
				<React.Fragment>
					{!isLoading ? (
						timeline !== undefined ? (
							<React.Fragment>
								<Box mb={3}>
									<chakra.span fontSize={'1.5em'}>{pageTitle}</chakra.span>
								</Box>
								<Box className="timeline-container">
									<Chrono
										mode="HORIZONTAL"
										items={timeline}
										borderLessCards={true}
										activeItemIndex={defaultMonthIndex}
										focusActiveItemOnLoad={false}
										theme={{ primary: '#004060' }}
										disableAutoScrollOnClick={true}
										scrollable
										onItemSelected={(e) => {
											handleActivityLog(e);
										}}
									>
										{timeline.map((item, index) => (
											<React.Fragment key={index}>
												<Box mt={1} maxW={865} pl={1}>
													{item.Detail.map((d, i) => (
														<Box fontSize={'0.95em'} key={i} className={`timeline-content ${d.MessageBorderColor}`}>
															<Markdown text={d.Message}></Markdown>
														</Box>
													))}
												</Box>
											</React.Fragment>
										))}

										<Box className="chrono-icons">
											{timeline.map((item, index) => (
												<FontAwesomeIcon key={index} icon={item.DisplayIcon} className={`icon timeline-icon ${item.DisplayIcon} ${item.DisplayIconColor}`} />
											))}
										</Box>
									</Chrono>
								</Box>
							</React.Fragment>
						) : null
					) : (
						<Box>
							<Skeleton mt={4} height={10} width={'500px'} startColor="#e2e2e2" endColor="#bbbbbb"></Skeleton>
							<Skeleton mt={4} height={370} startColor="#e2e2e2" endColor="#bbbbbb"></Skeleton>
						</Box>
					)}
				</React.Fragment>
			) : null}
		</React.Fragment>
	);
};

export default TimeLine;
