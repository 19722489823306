import "../../Global/Styles/Navigation.css";

import {
	Badge,
	Box,
	Button,
	IconButton,
	Menu,
	MenuButton,
	MenuDivider,
	MenuGroup,
	MenuItem,
	MenuList,
	Text,
	useBreakpointValue,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import {
	faAddressCard,
	faBars,
	faEnvelope,
	faHome,
	faQuestionCircle,
	faSortDown,
	faUser,
	faUserSlash,
} from "@fortawesome/free-solid-svg-icons";

import { AuditContext } from "../../../contexts/AuditContext";
import { AuthContext } from "../../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const Navigation = ({ handleLogout, unreadMailCount }) => {
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });
	const { authDetails, checkEmailUpdate } = useContext(AuthContext);
	const { auditActivity } = useContext(AuditContext);

	const [mailCount, setMailCount] = useState();

	useEffect(() => {
		setMailCount(unreadMailCount);
	}, [unreadMailCount]);

	let navigate = useNavigate();

	function handleHome(e) {
		e.preventDefault();
		checkEmailUpdate();
		navigate("/auth/property", { replace: true });
	}

	function handleMyMail(e) {
		e.preventDefault();
		checkEmailUpdate();
		auditActivity("Clicked My Mail");
		navigate("/auth/my-mail", { replace: true });
	}

	function handleContactUs(e) {
		e.preventDefault();
		checkEmailUpdate();
		navigate("/auth/feedback", { replace: true });
	}

	function handleProfile(e) {
		e.preventDefault();
		checkEmailUpdate();
		navigate("/auth/profile", { replace: true });
	}

	return (
		<React.Fragment>
			{!isSmallerScreen ? (
				<Box>
					<Box className='navigation-container'>
						<Box className='left-container'></Box>
						<Box className='right-container'>
							<Menu>
								<MenuButton></MenuButton>
								<Button
									colorScheme='nav'
									className='navigation-button'
									onClick={(e) => handleHome(e)}>
									<FontAwesomeIcon
										icon={faHome}
										className='icon'
									/>{" "}
									Home
								</Button>
								<Button
									colorScheme='nav'
									className='navigation-button'
									onClick={(e) => handleMyMail(e)}>
									<FontAwesomeIcon
										icon={faEnvelope}
										className='icon'
									/>{" "}
									My Mail
									{mailCount > 0 ? (
										<Box ml={1}>
											<Badge
												pt={0.5}
												pb={0.5}
												bg={"#004060"}
												color={"#e9eaea"}>
												<Text>{mailCount}</Text>
											</Badge>
										</Box>
									) : null}
								</Button>
								<Button
									colorScheme='nav'
									className='navigation-button'
									onClick={(e) => handleContactUs(e)}>
									<FontAwesomeIcon
										icon={faQuestionCircle}
										className='icon'
										fontSize={"1.22em"}
									/>
									Contact Us
								</Button>
								<MenuButton
									as={Button}
									_hover={{ bg: "#e2e2e2" }}
									_expanded={{ bg: "#e2e2e2" }}
									_focus={{ boxShadow: "none", bg: "#e2e2e2" }}
									colorScheme='nav'
									className='navigation-button'>
									<FontAwesomeIcon
										icon={faUser}
										className='icon'
									/>{" "}
									{authDetails.emailAddress}
									<FontAwesomeIcon
										icon={faSortDown}
										className='icon icon-right'
									/>
								</MenuButton>
								<MenuList
									left={139}
									className='menu-list'>
									<MenuGroup className='menu-group'>
										<MenuItem
											as={Button}
											colorScheme='nav'
											className='menu-item'
											onClick={(e) => handleProfile(e)}>
											<FontAwesomeIcon
												icon={faAddressCard}
												className='icon'
											/>{" "}
											Profile
										</MenuItem>
										<MenuItem
											as={Button}
											colorScheme='nav'
											className='menu-item'
											onClick={(e) => handleLogout()}>
											<FontAwesomeIcon
												icon={faUserSlash}
												className='icon'
											/>{" "}
											Logout
										</MenuItem>
									</MenuGroup>
								</MenuList>
							</Menu>
						</Box>
					</Box>
				</Box>
			) : null}

			{isSmallerScreen ? (
				<Box>
					<Box className='navigation-container'>
						<Box className='left-container'></Box>
						<Box className='right-container'>
							<Menu>
								<MenuButton
									as={IconButton}
									_hover={{ bg: "#e2e2e2" }}
									_expanded={{ bg: "#e2e2e2" }}
									_focus={{ boxShadow: "none", bg: "#e2e2e2" }}
									colorScheme='nav'
									className='navigation-button'
									icon={
										<FontAwesomeIcon
											icon={faBars}
											className='icon'
										/>
									}
								/>
								<MenuList className='menu-list'>
									<MenuGroup className='menu-group'>
										<MenuItem
											as={Button}
											colorScheme='nav'
											className='menu-item bar-menu-item'
											onClick={(e) => handleHome(e)}>
											<FontAwesomeIcon
												icon={faHome}
												className='icon'
											/>{" "}
											Home
										</MenuItem>
										<MenuItem
											as={Button}
											colorScheme='nav'
											className='menu-item bar-menu-item'
											onClick={(e) => handleMyMail(e)}>
											<FontAwesomeIcon
												icon={faEnvelope}
												className='icon'
											/>{" "}
											My Mail
											{mailCount > 0 ? (
												<Box
													className='navigation-mail-badge-pos'
													ml={1}>
													<Badge
														colorScheme='nav'
														className='navigation-mail-badge'>
														<Box className='navigation-mail-badge-text'>{mailCount}</Box>
													</Badge>
												</Box>
											) : null}
										</MenuItem>
										<MenuItem
											className='menu-item bar-menu-item'
											onClick={(e) => handleContactUs(e)}>
											<FontAwesomeIcon
												icon={faQuestionCircle}
												className='icon'
												fontSize={"1.15em"}
											/>
											Contact Us
										</MenuItem>
									</MenuGroup>
									<MenuDivider />
									<MenuGroup
										title={
											<React.Fragment>
												<FontAwesomeIcon
													icon={faUser}
													className='icon'
												/>
												{authDetails.emailAddress}
											</React.Fragment>
										}>
										<MenuItem
											as={Button}
											colorScheme='nav'
											className='menu-item bar-menu-item'
											onClick={(e) => handleProfile(e)}>
											<FontAwesomeIcon
												icon={faAddressCard}
												className='icon'
											/>{" "}
											Profile
										</MenuItem>
										<MenuItem
											as={Button}
											colorScheme='nav'
											className='menu-item bar-menu-item'
											onClick={(e) => handleLogout()}>
											<FontAwesomeIcon
												icon={faUserSlash}
												className='icon'
											/>{" "}
											Logout
										</MenuItem>
									</MenuGroup>
								</MenuList>
							</Menu>
						</Box>
					</Box>
				</Box>
			) : null}
		</React.Fragment>
	);
};

export default Navigation;
