import { Box, Flex, Link, chakra } from "@chakra-ui/react";
import React, { useContext } from "react";

import { AuthContext } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
	let navigate = useNavigate();
	const { authDetails } = useContext(AuthContext);

	function handleHome(e) {
		e.preventDefault();

		if (authDetails.isAuthenticated) {
			navigate("/auth/property", { replace: true });
		} else {
			navigate("/", { replace: true });
		}
	}

	return (
		<React.Fragment>
			<Flex direction={["column"]}>
				<Box>
					<chakra.span fontSize={"1.45em"}>Page Not Found</chakra.span>
				</Box>
				<Box mt={3}>
					<Box>Oops... The page you are looking for has moved or is no longer available.</Box>
					<Box>
						Head back{" "}
						<Link target={"_self"} onClick={(e) => handleHome(e)}>
							home
						</Link>{" "}
						and update any old bookmarks that may have brought you here.
					</Box>
				</Box>
			</Flex>
		</React.Fragment>
	);
};

export default NotFound;
