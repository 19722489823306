import "../Styles/Navigation.css";

import { Box, Button, Menu } from "@chakra-ui/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const Navigation = () => {
	let navigate = useNavigate();

	function handleHome(event) {
		event.preventDefault();
		navigate("/", { replace: true });
	}

	return (
		<React.Fragment>
			<Box className='navigation-container'>
				<Box className='left-container'></Box>
				<Box className='right-container'>
					<Menu>
						<Button
							colorScheme='nav'
							className='navigation-button'
							onClick={handleHome}>
							<FontAwesomeIcon
								icon={faHome}
								className='icon'
							/>{" "}
							Home
						</Button>
					</Menu>
				</Box>
			</Box>
		</React.Fragment>
	);
};

export default Navigation;
