import "./WelcomeMessage.css";

import * as toastMessages from "../../../../toastMessages";

import { Box, Skeleton, Text, chakra, useToast } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "../../../../contexts/AuthContext";
import Markdown from "../../../Global/Markdown/Markdown";
import { axiosMyProperty } from "../../../../apis";

const WelcomeMessage = () => {
	useEffect(() => {
		getWelcomeMessage();
	}, []);

	const { authDetails } = useContext(AuthContext);
	const [profileName] = useState(authDetails.profileName);

	const [welcomeMessage, setWelcomeMessage] = useState();
	const defaultWelcomeMessage = `Here are some of the steps you can take: \r\n* Explore property and assessment information about your property that we have on file\r\n* Browse detailed property assessment maps\r\n* Estimate your upcoming property tax bill\r\n* Download current and past assessment and tax notices\r\n* See the latest property tax account balance`;

	const toast = useToast();
	const welcomeToastErrorId = "welcome-toast-error";

	const getWelcomeMessage = () => {
		let url = `property/welcomemessage`;
		axiosMyProperty
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					if (
						returnObj.welcomeMessage !== "" ||
						returnObj.welcomeMessage !== null ||
						returnObj.welcomeMessage !== "null"
					) {
						setWelcomeMessage(returnObj.welcomeMessage);
					} else {
						setWelcomeMessage(defaultWelcomeMessage);
					}
				} else {
					setWelcomeMessage(defaultWelcomeMessage);
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(welcomeToastErrorId)) {
					toast(toastMessages.getToastError(welcomeToastErrorId));
				}
			});
	};

	return (
		<React.Fragment>
			{welcomeMessage !== null ? (
				<React.Fragment>
					<Box mb={1}>
						<chakra.span fontSize={"2xl"}>Hello {profileName}:</chakra.span>
					</Box>
					<Box mb={4} maxW={950}>
						<chakra.span fontSize={"xl"}>
							Welcome to the secure site for property owners. Here you will find assessment and taxation
							information specific to your Edmonton property.
						</chakra.span>
					</Box>
					<Box mb={2} className="welcome-message-container">
						<Markdown text={welcomeMessage}></Markdown>
					</Box>
					<Box>
						<Text>But first, let's get to your property.</Text>
					</Box>
				</React.Fragment>
			) : (
				<React.Fragment>
					<Box>
						<Skeleton mb={2} startColor="#e2e2e2" endColor="#bbbbbb" width={350} height={45}></Skeleton>
						<Skeleton mb={6} startColor="#e2e2e2" endColor="#bbbbbb" height={71}></Skeleton>
						<Skeleton mb={6} height={155} startColor="#e2e2e2" endColor="#bbbbbb"></Skeleton>
						<Skeleton startColor="#e2e2e2" endColor="#bbbbbb" width={300} height={30}></Skeleton>
					</Box>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

export default WelcomeMessage;
