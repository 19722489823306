import './DataUnavailable.css';

import { Box } from '@chakra-ui/react';
import React from 'react';

const DataUnavailable = () => {
	return (
		<Box>
			<Box className="announcement-no-data">
				<Box maxW={1050}>{process.env.REACT_APP_GENERIC_LOAD_ERROR}</Box>
			</Box>
		</Box>
	);
};

export default DataUnavailable;
