import './Paperless.css';

import * as toastMessages from '../../../../../toastMessages';

import { Box, Button, Flex, Image, ListItem, Modal, ModalBody, ModalContent, ModalOverlay, UnorderedList, chakra, useBreakpointValue, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';

import { AuditContext } from '../../../../../contexts/AuditContext';
import { AuthContext } from '../../../../../contexts/AuthContext';
import { axiosMyPropertyAuth } from '../../../../../apis';
import convenience_img from './Images/en_convenience_75.png';
import paperless_img from './Images/en_paperless_75.png';
import savings_img from './Images/en_savings_75.png';
import { useNavigate } from 'react-router-dom';

const Paperless = () => {
	const { authDetails, setAuthDetails } = useContext(AuthContext);
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });
	const { auditActivity } = useContext(AuditContext);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const toast = useToast();
	const paperlessToastErrorId = 'paperless-toast-error';

	let navigate = useNavigate();

	useEffect(() => {
		if (authDetails?.displayPaperlessPromo === 'Y' && authDetails?.profileIsNew === 'N') {
			onOpen();
		}
	}, []);

	const handleNoThankYou = (e) => {
		// validate password
		let payload = {
			uId: '',
		};

		let url = 'campaign/paperless';
		axiosMyPropertyAuth
			.put(url, payload)
			.then((resp) => {
				handleModalClose();
			})
			.catch((e) => {
				// error
				if (!toast.isActive(paperlessToastErrorId)) {
					toast(toastMessages.getToastError(paperlessToastErrorId));
				}
			});

		// audit
		auditActivity('Paperless Promo - Clicked No Thank You');
	};

	const handleRemindLater = (e) => {
		// audit
		auditActivity('Paperless Promo - Clicked Remind Me Later');
		handleModalClose();
		navigate(`/auth/property`, { replace: true });
	};

	const handleSignUp = (e) => {
		// audit
		auditActivity('Paperless Promo - Clicked Sign Me Up');
		handleModalClose();

		navigate(`/auth/profile?sec=np`, { replace: true });
	};

	const handleModalClose = () => {
		setAuthDetails({ ...authDetails, displayPaperlessPromo: 'N' });
		onClose();
	};

	return (
		<>
			<Modal closeOnOverlayClick={false} isOpen={isOpen}>
				<ModalOverlay />
				<ModalContent maxW={'60rem'}>
					<ModalBody>
						<Flex direction={['column']}>
							<Box className="paperless-main-title">
								WE WENT PAPERLESS.
								<br />
								WILL YOU?
								<Button zIndex={-1} h={0} w={0} _focus={{ border: 'none', boxShadow: 'none' }} outline={0} backgroundColor={'transparent'}></Button>
							</Box>
							<Box mt={3}>
								<chakra.span className="paperless-sub-title">Hello {authDetails.profileName}!</chakra.span>
								<Box maxW={800}>You can receive your property assessment and tax mail online, right here on MyProperty. Sign up today to receive notifications via email and text.</Box>
							</Box>
							<Box mt={4}>
								<chakra.span className="paperless-sub-title">MyProperty's online mail is all about:</chakra.span>
							</Box>
							<Box mt={6}>
								<Flex direction={['column', 'column', 'row']}>
									<Box w={isSmallerScreen ? '100%' : '33%'} pr={isSmallerScreen ? 0 : 8}>
										<Flex direction={['column']}>
											<Box mb={4} display={isSmallerScreen ? 'none' : ''}>
												<Image src={convenience_img} />
											</Box>
											<Box className="paperless-sub-title">Convenience</Box>
											<Box>Get updates about your property as soon as they become available. Always have access to current and past assessment and tax notices—24/7, wherever you are.</Box>
										</Flex>
									</Box>
									<Box w={isSmallerScreen ? '100%' : '33%'} pl={isSmallerScreen ? 0 : 4} pr={isSmallerScreen ? 0 : 4} mt={isSmallerScreen ? 8 : 0}>
										<Flex direction={['column']}>
											<Box mb={4} display={isSmallerScreen ? 'none' : ''}>
												<Image src={paperless_img} />
											</Box>
											<Box className="paperless-sub-title">Going Paperless</Box>
											<Box>We serve 400,000+ properties in Edmonton. That's a lot of paper and envelopes going in the mail several times a year. Let's cut this volume down and help the environment a little.</Box>
										</Flex>
									</Box>
									<Box w={isSmallerScreen ? '100%' : '33%'} pl={isSmallerScreen ? 0 : 8} mt={isSmallerScreen ? 8 : 0}>
										<Flex direction={['column']}>
											<Box mb={4} display={isSmallerScreen ? 'none' : ''}>
												<Image src={savings_img} />
											</Box>
											<Box className="paperless-sub-title">Savings</Box>
											<Box>Saving on the costs to send so much paper mail will help our city direct the funds to other much needed municipal programs and services.</Box>
										</Flex>
									</Box>
								</Flex>
							</Box>
							<Box mt={8}>
								<Box mb={1}>
									<chakra.span className="paperless-sub-title">Once you've set your electronic notifications</chakra.span>
								</Box>
								<Box ml={'6px'}>
									<UnorderedList>
										<ListItem>You'll no longer receive most paper copies in the mail.</ListItem>
										<ListItem>You'll be able to see full versions of your notices—no more redacted copies.</ListItem>
										<ListItem>You'll have access to your official correspondence for up to five years.</ListItem>
									</UnorderedList>
								</Box>
							</Box>
							<Box mt={6} mb={2}>
								<Flex direction={['column', 'column', 'row']}>
									<Box w={isSmallerScreen ? '100%' : '33%'} pr={isSmallerScreen ? 0 : 8}>
										<Button colorScheme="grey" variant={'outline'} width="100%" mt={isSmallerScreen ? 3 : 0} onClick={(e) => handleNoThankYou(e)}>
											NO, THANK YOU
										</Button>
									</Box>
									<Box w={isSmallerScreen ? '100%' : '33%'} pl={isSmallerScreen ? 0 : 4} pr={isSmallerScreen ? 0 : 4}>
										<Button colorScheme="grey" variant={'outline'} width="100%" mt={isSmallerScreen ? 3 : 0} onClick={(e) => handleRemindLater(e)}>
											REMIND ME LATER
										</Button>
									</Box>
									<Box w={isSmallerScreen ? '100%' : '33%'} pl={isSmallerScreen ? 0 : 8}>
										<Button colorScheme="blue" width="100%" mt={isSmallerScreen ? 3 : 0} onClick={(e) => handleSignUp(e)}>
											SIGN ME UP!
										</Button>
									</Box>
								</Flex>
							</Box>
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default Paperless;
