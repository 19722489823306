import './MethodologyGuide.css';

import * as toastMessages from '../../../../../../../toastMessages';

import { Box, Button, Flex, Skeleton, Spacer, chakra, useToast } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';

import { AuditContext } from '../../../../../../../contexts/AuditContext';
import { axiosMyPropertyAuth } from '../../../../../../../apis';

const MethodologyGuide = ({ accId }) => {
	useEffect(() => {
		getMethodologyGuide(accId);
	}, [accId]);

	const [methodologyGuide, setMethodologyGuide] = useState();
	const [allMethodologyGuideUrl] = useState(process.env.REACT_APP_MYPROPERTY_MTH_GUIDE);

	const { auditActivity } = useContext(AuditContext);

	const toast = useToast();
	const methodologyGuideToastErrorId = 'methodology-guide-toast-error';
	const methodologyGuideToastWarningId = 'methodology-guide-toast-warning';

	const getMethodologyGuide = (accId) => {
		let url = `assessment/methodologyguide/${accId}`;

		axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === 'true') {
					setMethodologyGuide(returnObj.webMethGuides);
				} else {
					if (!toast.isActive(methodologyGuideToastWarningId)) {
						toast(toastMessages.getToastWarning(methodologyGuideToastWarningId, returnObj.errorMsg, toastMessages.propertyMessages));
					}
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(methodologyGuideToastErrorId)) {
					toast(toastMessages.getToastError(methodologyGuideToastErrorId));
				}
			});
	};

	const handleViewMethodologyGuide = (e, url) => {
		e.preventDefault();
		window.open(url, '_blank', 'noopener');
	};

	return (
		<Box p={3} mt={1} className="methodology-guide-container">
			{methodologyGuide !== undefined ? (
				<React.Fragment>
					<Box mb={3} maxW={1000}>
						<chakra.span fontSize={'xl'}>To learn about the methods used to determine the value of your property for assessment purposes, review the following assessment methodology guides.</chakra.span>
					</Box>
					{methodologyGuide !== null
						? methodologyGuide.map((item, index) => (
								<Flex key={index} mb={1}>
									<Box>
										<chakra.span fontWeight={700}>{item.displayLabel}</chakra.span>
									</Box>
									<Spacer />
									<Box>
										<Button
											colorScheme="lightBlue"
											as="button"
											minW={'5rem'}
											size={'sm'}
											onClick={(e) => {
												auditActivity(`View ${item.displayLabel} Methodology Guide`, accId);
												handleViewMethodologyGuide(e, item.guideLocation);
											}}
										>
											View
										</Button>
									</Box>
								</Flex>
						  ))
						: null}
					<Flex>
						<Box>
							<chakra.span fontWeight={700}>All methodology guides</chakra.span>
						</Box>
						<Spacer />
						<Box>
							<Button
								colorScheme="lightBlue"
								as="button"
								minW={'5rem'}
								size={'sm'}
								onClick={(e) => {
									auditActivity('View Methodology Guide', accId);
									handleViewMethodologyGuide(e, allMethodologyGuideUrl);
								}}
							>
								View
							</Button>
						</Box>
					</Flex>
				</React.Fragment>
			) : (
				<Box>
					<Skeleton startColor="#e2e2e2" endColor="#bbbbbb" size="8" width={540} height={38}></Skeleton>
					<Skeleton mt={4} height={380} startColor="#e2e2e2" endColor="#bbbbbb"></Skeleton>
				</Box>
			)}
		</Box>
	);
};

export default MethodologyGuide;
