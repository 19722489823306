import { Box } from "@chakra-ui/react";
import React from "react";

const MultiUseMunicipalLabel = () => {
	return (
		<Box
			mt={1.5}
			ml={2}
			fontWeight={700}
			fontSize={".7em"}>
			<Box color={"#6b6b6b"}>Taxable Status</Box>
			<Box color={"#9d9d9d"}>Municipal</Box>
		</Box>
	);
};

export default MultiUseMunicipalLabel;
