import * as CommonFns from "../../../../../../../common";

import { Box, Flex, Link } from "@chakra-ui/react";
import React, { useContext, useState } from "react";

import { AuditContext } from "../../../../../../../contexts/AuditContext";
import Markdown from "../../../../../../Global/Markdown/Markdown";

const FileComplaint = ({ accId, localImprovement }) => {
	const [reviewBoardUrl] = useState(process.env.REACT_APP_REVIEW_BOARD_URL);

	const { auditActivity } = useContext(AuditContext);

	return (
		<React.Fragment>
			{localImprovement !== undefined ? (
				<React.Fragment>
					<Box>
						<Markdown text={localImprovement.liComplaintStepsMsg}></Markdown>
					</Box>
					<Box mt={4}>
						<Flex direction={["column"]}>
							<Box fontWeight={700}>
								<Flex>
									<Box minW={100}>File online</Box>
									<Box>
										<Link
											href={reviewBoardUrl}
											rel={"noopener"}
											target={"_blank"}
											onClick={() => {
												auditActivity("File Complaint - Edmonton ARB Click", accId);
											}}
										>
											edmontonarb.ca
										</Link>
									</Box>
								</Flex>
							</Box>
							<Box fontWeight={700}>
								<Flex>
									<Box minW={100}>Dealine</Box>
									<Box>{CommonFns.FormatDate(localImprovement.liComplaintDueDate)}</Box>
								</Flex>
							</Box>
							<Box fontWeight={700}>
								<Flex>
									<Box minW={100}>Fee</Box>
									<Box>{CommonFns.FormatCurrency(localImprovement.liComplaintFee, 0, true)}</Box>
								</Flex>
							</Box>
						</Flex>
					</Box>
					<Box mt={4}>
						<Markdown text={localImprovement.liComplaintBottoMsg}></Markdown>
					</Box>
				</React.Fragment>
			) : null}
		</React.Fragment>
	);
};

export default FileComplaint;
