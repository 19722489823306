import { Box, Container } from "@chakra-ui/react";

import React from "react";

const Layout = (props) => {
	return (
		<Container
			maxWidth={1280}
			p={0}>
			<Box>{props.children}</Box>
		</Container>
	);
};

export default Layout;
