import axios from "axios";

let apiAuthQueue = [];
let tokenIsRefreshing = false;

let protocol = window.location.protocol + "//";
let domain = window.location.hostname;

function onTokenFetched() {
	apiAuthQueue = apiAuthQueue.filter((callback) => callback());
}

function addToApiAuthQueue(callback) {
	apiAuthQueue.push(callback);
}

const refreshAccessToken = () => {
	let url_refreshToken = "authorization/refreshtoken";
	return axiosMyProperty
		.post(url_refreshToken)
		.then((res) => {
			const returnObj = res.data;
			localStorage.setItem("token", returnObj.token);
			return true;
		})
		.catch((e) => {
			window.location = "/#/sign-out";
			return false;
		});
};

//MY PROPERTY
const axiosMyProperty = axios.create({
	baseURL: protocol + domain + process.env.REACT_APP_MYPROPERTY_API_URL,
	withCredentials: true,
});

//MY PROPERTY AUTH
const axiosMyPropertyAuth = axios.create({
	baseURL: protocol + domain + process.env.REACT_APP_MYPROPERTY_API_URL,
	withCredentials: true,
});

axiosMyPropertyAuth.interceptors.request.use(function (config) {
	config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
	return config;
});

axiosMyPropertyAuth.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		const originalRequest = error.config;

		if (error.response.status === 401) {
			//if 401 and token is not currently being refreshed, get new token

			if (!tokenIsRefreshing) {
				tokenIsRefreshing = true;

				//get new access token
				refreshAccessToken().then((success) => {
					tokenIsRefreshing = false;
					if (success) {
						onTokenFetched();
					}
				});
			}

			const retryOriginalRequest = new Promise((resolve) => {
				addToApiAuthQueue(() => {
					originalRequest.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
					resolve(axiosRetryAuth(originalRequest));
				});
			});
			return retryOriginalRequest;
		} else {
			return Promise.reject(error);
		}
	}
);

//WEB LOGIC
const axiosWebLogic = axios.create({
	baseURL: protocol + domain + process.env.REACT_APP_WEBLOGIC_API_URL,
});

axiosWebLogic.interceptors.request = axiosMyPropertyAuth.interceptors.request;

axiosWebLogic.interceptors.response = axiosMyPropertyAuth.interceptors.response;

//MAPS
const axiosMaps = axios.create({
	baseURL: protocol + domain + process.env.REACT_APP_MAPS_API_URL,
});

axiosMaps.interceptors.request = axiosMyPropertyAuth.interceptors.request;

axiosMaps.interceptors.response = axiosMyPropertyAuth.interceptors.response;

//ADOBE LIVECYCLE
const axiosLiveCycle = axios.create({
	baseURL: protocol + domain + process.env.REACT_APP_LIVECYCLE_API_URL,
});

axiosLiveCycle.interceptors.request = axiosMyPropertyAuth.interceptors.request;

axiosLiveCycle.interceptors.response = axiosMyPropertyAuth.interceptors.response;

//RETRY CALL
const axiosRetryAuth = axios.create({});

axiosRetryAuth.interceptors.request = axiosMyPropertyAuth.interceptors.request;

axiosRetryAuth.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.response.status === 401) {
			//redirect to sign in page
			window.location = "/#/sign-out";
		} else {
			return Promise.reject(error);
		}
	}
);

//export default setAuthToken;
export { axiosMyProperty, axiosMyPropertyAuth, axiosWebLogic, axiosMaps, axiosLiveCycle };
