import './ChangePassword.css';

import * as toastMessages from '../../../../toastMessages';

import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputRightElement, useBreakpointValue } from '@chakra-ui/react';
import React, { useState } from 'react';

import Complexity from '../../../Global/Password/Complexity';
import PasswordStrengthBar from 'react-password-strength-bar';
import { axiosMyPropertyAuth } from '../../../../apis';
import { useForm } from 'react-hook-form';
import { useToast } from '@chakra-ui/react';

const ChangePassword = ({ userProfile }) => {
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });
	const toast = useToast();

	const [oldPasswordShow, setOldPasswordShow] = useState(false);
	const handleShowHideOldPassword = () => setOldPasswordShow(!oldPasswordShow);

	const [passwordShow, setPasswordShow] = useState(false);
	const handleShowHidePassword = () => setPasswordShow(!passwordShow);

	const [passwordConfirmShow, setPasswordConfirmShow] = useState(false);
	const handleShowHidePasswordConfirm = () => setPasswordConfirmShow(!passwordConfirmShow);

	const changePasswordToastWarningId = 'change-password-toast-warning';
	const changePasswordToastSuccessId = 'change-password-toast-success';
	const changePasswordToastErrorId = 'change-password-toast-error';

	const formLabelMinWidth = 210;
	const formControlMinWidth = 350;

	const {
		register,
		handleSubmit,
		reset: resetChangePassword,
		watch,
		formState: { errors },
	} = useForm({
		defaultValues: {
			uId: '',
			oldPassword: '',
			newPassword: '',
			passwordConfirm: '',
		},
	});

	const watchPassword = watch('newPassword', '');

	const passwordMatch = (value) => {
		return value === watchPassword ? true : false;
	};

	const handleClearChangePassword = (e) => {
		e.preventDefault();

		// form
		resetChangePassword();
	};

	const onChangePassword = (data) => {
		let url = 'profile/password';
		let payload = data;
		payload.uId = '';
		axiosMyPropertyAuth
			.put(url, payload)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === 'true') {
					// update succeeded
					if (!toast.isActive(changePasswordToastSuccessId)) {
						toast(toastMessages.getToastSuccess(changePasswordToastSuccessId, 'Password has been updated!'));
						resetChangePassword();
					}
				} else if (returnObj.retVal === 'false') {
					if (!toast.isActive(changePasswordToastWarningId)) {
						toast(toastMessages.getToastWarning(changePasswordToastWarningId, returnObj.errorMsg, toastMessages.changePasswordMessages));
					}
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(changePasswordToastErrorId)) {
					toast(toastMessages.getToastError(changePasswordToastErrorId));
				}
			});
	};

	return (
		<React.Fragment>
			<Box mt={5} mb={6}>
				<form onSubmit={handleSubmit(onChangePassword)}>
					<FormControl isInvalid={errors.oldPassword} mt={2}>
						<Flex direction={['column', 'column', 'row']}>
							<Box minW={formLabelMinWidth} mr={3}>
								<FormLabel htmlFor="oldPassword" textAlign={isSmallerScreen ? '' : 'end'} className="form-label-fixed">
									Current Password
								</FormLabel>
							</Box>
							<Box minW={formControlMinWidth}>
								<InputGroup>
									<Input
										id="oldPassword"
										type={oldPasswordShow ? 'text' : 'password'}
										autoComplete="off"
										{...register('oldPassword', {
											required: 'Current Password is required',
										})}
									/>
									<InputRightElement width="4.5rem">
										<Button h="1.75rem" w={'56px'} size="sm" colorScheme={'lightBlue'} onClick={handleShowHideOldPassword} tabIndex={-1}>
											{oldPasswordShow ? 'Hide' : 'Show'}
										</Button>
									</InputRightElement>
								</InputGroup>
							</Box>
							<Box ml={isSmallerScreen ? 0 : 3}>
								<FormErrorMessage colorScheme={'red'} className={'text-error pos'}>
									{errors.oldPassword && errors.oldPassword.message}
								</FormErrorMessage>
							</Box>
						</Flex>
					</FormControl>
					<FormControl isInvalid={errors.newPassword} mt={2}>
						<Flex direction={['column', 'column', 'row']}>
							<Box minW={formLabelMinWidth} mr={3}>
								<FormLabel htmlFor="newPassword" textAlign={isSmallerScreen ? '' : 'end'} className="form-label-fixed">
									<Complexity></Complexity>
									New Password
								</FormLabel>
							</Box>
							<Box minW={formControlMinWidth}>
								<InputGroup size="md">
									<Input
										id="newPassword"
										type={passwordShow ? 'text' : 'password'}
										autoComplete="off"
										{...register('newPassword', {
											required: 'New Password is required',
											pattern: {
												value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/,
												message: 'Your Password does not meet the requirements',
											},
										})}
									/>
									<InputRightElement width="4.5rem">
										<Button h="1.75rem" w={'56px'} size="sm" colorScheme={'lightBlue'} onClick={handleShowHidePassword} tabIndex={-1}>
											{passwordShow ? 'Hide' : 'Show'}
										</Button>
									</InputRightElement>
								</InputGroup>
								<PasswordStrengthBar password={watchPassword} minLength={8} shortScoreWord={'Too Short'} scoreWords={['Very Weak', 'Weak', 'Medium', 'Strong', 'Very Strong']} />
							</Box>
							<Box ml={isSmallerScreen ? 0 : 3}>
								<FormErrorMessage colorScheme={'red'} className={'text-error pos'}>
									{errors.newPassword && errors.newPassword.message}
								</FormErrorMessage>
							</Box>
						</Flex>
					</FormControl>

					<FormControl isInvalid={errors.passwordConfirm} mt={2}>
						<Flex direction={['column', 'column', 'row']}>
							<Box minW={formLabelMinWidth} mr={3}>
								<FormLabel htmlFor="passwordConfirm" textAlign={isSmallerScreen ? '' : 'end'} className="form-label-fixed">
									Retype New Password
								</FormLabel>
							</Box>
							<Box minW={formControlMinWidth}>
								<InputGroup size="md">
									<Input
										id="passwordConfirm"
										type={passwordConfirmShow ? 'text' : 'password'}
										autoComplete="off"
										{...register('passwordConfirm', {
											required: 'Retype New Password is required',
											validate: passwordMatch,
										})}
									/>
									<InputRightElement width="4.5rem">
										<Button h="1.75rem" w={'56px'} size="sm" colorScheme={'lightBlue'} onClick={handleShowHidePasswordConfirm} tabIndex={-2}>
											{passwordConfirmShow ? 'Hide' : 'Show'}
										</Button>
									</InputRightElement>
								</InputGroup>
							</Box>
							<Box ml={isSmallerScreen ? 0 : 3}>
								<FormErrorMessage colorScheme={'red'} className={'text-error pos'}>
									{errors.passwordConfirm && errors.passwordConfirm.message}
									{errors.passwordConfirm?.type === 'validate' && <span>Passwords do not match</span>}
								</FormErrorMessage>
							</Box>
						</Flex>
					</FormControl>

					<FormControl mt={isSmallerScreen ? 6 : 3}>
						<Flex direction={['column', 'column', 'row']}>
							<Box minW={formLabelMinWidth} display={isSmallerScreen ? 'none' : 'normal'} mr={3}>
								<FormLabel>&nbsp;</FormLabel>
							</Box>
							<Box>
								<Flex direction={['column', 'column', 'row']}>
									<Button colorScheme="blue" type={'submit'} mr={isSmallerScreen ? 0 : 4}>
										UPDATE
									</Button>
									<Button colorScheme="grey" type="reset" variant={'outline'} mt={isSmallerScreen ? 4 : 0} onClick={(e) => handleClearChangePassword(e)}>
										CLEAR
									</Button>
								</Flex>
							</Box>
						</Flex>
					</FormControl>
				</form>
			</Box>
		</React.Fragment>
	);
};
export default ChangePassword;
