import "./SalesTransfers.css";

import * as CommonFns from "../../../../../../common";
import * as toastMessages from "../../../../../../toastMessages";

import {
	Box,
	Button,
	Flex,
	FormControl,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Spacer,
	chakra,
	useBreakpointValue,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { axiosMyPropertyAuth, axiosWebLogic } from "../../../../../../apis";

import { AuditContext } from "../../../../../../contexts/AuditContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Markdown from "../../../../../Global/Markdown/Markdown";
import { Spinner } from "@chakra-ui/react";

const SalesTransfers = ({ accId, accountDetails }) => {
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [sortList, setSortList] = useState([]);
	const [payload, setPayload] = useState({});
	const [processing, setProcessing] = useState(false);
	const [isExcluded, setIsExcluded] = useState(false);
	const [exclusionMessage, setExclusionMessage] = useState("");

	const { auditActivity } = useContext(AuditContext);

	const toast = useToast();
	const salesReportToastErrorId = "sales-report-toast-error";

	const formLabelMinWidth = 125;
	const formControlMinWidth = 270;

	useEffect(() => {
		if (accountDetails !== undefined) {
			//get the client IP address for sign in
			getReportExclusionAndSort();
		}
	}, [accId, accountDetails]); // eslint-disable-line react-hooks/exhaustive-deps

	const getReportExclusionAndSort = () => {
		let url = `assessment/salesexclusion`;
		const exclusionPayload = {
			accId: accId,
			salesTaxClass: accountDetails.salesTaxClass,
			timeAdjusted: accountDetails.timeAdjusted,
		};

		axiosMyPropertyAuth
			.put(url, exclusionPayload)
			.then((resp) => {
				let returnObj = resp.data;

				if (returnObj.isExcluded === "Y") {
					//download not available
					setIsExcluded(true);
					setExclusionMessage(returnObj.exclusionMsg);
					//setExclusionMessage(`Our apologies. Due to the nature of your account, the Property Sales and Transfers report is not available through this website. Please
					//         <b><u><a href="https://myproperty.edmonton.ca/auth/feedback">Contact Us</a></u></b> for further information.</p><p></p>`)
				} else {
					//get/set sort drop downs
					let tempSort = returnObj.sortOrder;
					tempSort.unshift({ id: "", text: "Choose... [optional]", selected: "N" });
					setSortList(tempSort);

					//get sort 1 default selected
					let sort1DefaultId = tempSort.filter((x) => x.selected === "Y")[0].id;

					//get/set ip
					CommonFns.getIP().then((res) =>
						setPayload({
							...payload,
							accountNumber: accId.toString(),
							ipAddress: res,
							sort1: sort1DefaultId,
							sort2: tempSort[0].id,
						})
					);
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(salesReportToastErrorId)) {
					toast(toastMessages.getToastError(salesReportToastErrorId));
				}
			});
	};

	const handleChangeSort1 = (e) => {
		let selectedSort1 = e.target.value;
		setPayload({ ...payload, sort1: selectedSort1 });
	};

	const handleChangeSort2 = (e) => {
		let selectedSort2 = e.target.value;
		setPayload({ ...payload, sort2: selectedSort2 });
	};

	const handleReportDownload = (e) => {
		e.preventDefault();
		setProcessing(true);

		let url = "report/sales";
		axiosWebLogic
			.post(url, payload)
			.then((resp) => {
				let returnObj = resp.data;

				//audit log
				let activity = `Download Comparable Sales - Sort by ${payload.sort1} Then by ${payload.sort2}`;
				auditActivity(activity, accId);

				//download report
				CommonFns.pdfDownload(returnObj.report, returnObj.reportName);

				//close modal
				handleOnClose();
			})
			.catch((e) => {
				// error
				if (!toast.isActive(salesReportToastErrorId)) {
					toast(toastMessages.getToastError(salesReportToastErrorId));
				}
			})
			.finally(() => {
				setProcessing(false);
			});
	};

	const handleOnClose = () => {
		if (!isExcluded) {
			//reset selected sort
			let sort1DefaultId = sortList.filter((x) => x.selected === "Y")[0].id;
			let sort2DefaultId = sortList.filter((x) => x)[0].id;

			setPayload({ ...payload, sort1: sort1DefaultId, sort2: sort2DefaultId });
		}
		onClose();
	};

	return (
		<Box
			ml={3}
			mr={3}
			pb={3}
			pt={1}>
			<React.Fragment>
				<Box
					mb={3}
					maxW={1050}>
					<chakra.span fontSize={"xl"}>
						The City of Edmonton uses various data, including valid sales across Edmonton, in order to
						generate the assessed value of your property.
					</chakra.span>
				</Box>
				<Box
					mb={4}
					maxW={1050}>
					This report compiles valid and invalid property sales and transfers registered with the Alberta's
					Land Titles Office for properties that may be similar to yours.
				</Box>

				<Flex>
					<Box pt={1}>
						<chakra.span fontWeight={700}>Property sales and transfers</chakra.span>
					</Box>
					<Spacer />
					<Box>
						<Button
							colorScheme='lightBlue'
							as='button'
							minW={"5rem"}
							size={"sm"}
							onClick={onOpen}>
							Download
						</Button>
					</Box>
				</Flex>

				<Modal
					closeOnOverlayClick={false}
					isOpen={isOpen}
					onClose={handleOnClose}>
					<ModalOverlay />
					<ModalContent maxW={"38rem"}>
						<ModalHeader className={"modal-header"}>
							{!isExcluded ? (
								<>{`Property Sales and Transfers`}</>
							) : (
								<>{`Property Sales and Transfers - Not Available`}</>
							)}
						</ModalHeader>
						<ModalCloseButton className='modal-close-button'>
							<FontAwesomeIcon
								size={"2xl"}
								icon='fa-solid fa-xmark'
							/>
						</ModalCloseButton>
						<ModalBody>
							<Box
								mt={2}
								mb={2}>
								{!isExcluded ? (
									<React.Fragment>
										<FormControl>
											<Flex direction={["column", "column", "row"]}>
												<Box minW={formLabelMinWidth}>
													<FormLabel
														htmlFor='sort1'
														textAlign={isSmallerScreen ? "" : "end"}
														className='form-label-fixed'>
														Sort by
													</FormLabel>
												</Box>
												<Box minW={formControlMinWidth}>
													<Select
														id='sort1'
														name='sort1'
														onChange={(e) => handleChangeSort1(e)}
														iconSize={"1em"}
														icon={<FontAwesomeIcon icon='fa-solid fa-caret-down' />}
														value={payload.sort1}>
														{sortList.slice(1).map((item, index) => (
															<option
																key={index}
																value={item.id}>
																{item.text.trim()}
															</option>
														))}
													</Select>
												</Box>
											</Flex>
										</FormControl>
										<FormControl mt={2}>
											<Flex direction={["column", "column", "row"]}>
												<Box minW={formLabelMinWidth}>
													<FormLabel
														htmlFor='sort2'
														textAlign={isSmallerScreen ? "" : "end"}
														className='form-label-fixed'>
														Then by
													</FormLabel>
												</Box>
												<Box minW={formControlMinWidth}>
													<Select
														id='sort2'
														name='sort2'
														onChange={(e) => handleChangeSort2(e)}
														iconSize={"1em"}
														icon={<FontAwesomeIcon icon='fa-solid fa-caret-down' />}
														value={payload.sort2}>
														{sortList.map((item, index) => (
															<option
																key={index}
																value={item.id}>
																{item.text.trim()}
															</option>
														))}
													</Select>
												</Box>
											</Flex>
										</FormControl>
										{/* <FormControl mt={isSmallerScreen ? 6 : 3}>
											<Flex direction={["column", "column", "row"]}>
												<Box
													minW={formLabelMinWidth}
													display={isSmallerScreen ? "none" : "normal"}>
													<FormLabel>&nbsp;</FormLabel>
												</Box>
												<Box>
													<Flex direction={["column", "column", "row"]}>
														<Button
															colorScheme='lightBlue'
															size={"sm"}
															mr={isSmallerScreen ? 0 : 4}
															isDisabled={processing}
															onClick={(e) => {
																handleReportDownload(e);
															}}>
															Download
														</Button>
														<Box
															display={processing ? "normal" : "none"}
															mt={isSmallerScreen ? 2 : "7px"}>
															<Flex direction={["row"]}>
																<Spinner
																	size='sm'
																	speed='0.90s'
																	emptyColor='gray.200'
																	color='blue.500'
																/>
																<Box
																	className='sales-transfers-fade'
																	ml={2}
																	display={isSmallerScreen ? "normal" : "none"}>
																	Please be patient. Download may take some time...
																</Box>
															</Flex>
														</Box>
													</Flex>
												</Box>
											</Flex>
										</FormControl>

										 */}
										<Box display={processing ? "normal" : "none"}>
											<FormControl
												mt={isSmallerScreen ? 6 : 3}
												display={isSmallerScreen ? "none" : "normal"}>
												<Flex direction={["column", "column", "row"]}>
													<Box
														minW={formLabelMinWidth}
														display={isSmallerScreen ? "none" : "normal"}>
														<FormLabel>&nbsp;</FormLabel>
													</Box>
													<Box>
														<Flex direction={["column", "column", "row"]}>
															<Box className='sales-transfers-fade'>
																Please be patient. Download may take some time...
															</Box>
														</Flex>
													</Box>
												</Flex>
											</FormControl>
										</Box>
									</React.Fragment>
								) : (
									<React.Fragment>
										<Box
											pt={3}
											pb={3}>
											<Markdown text={exclusionMessage}></Markdown>
										</Box>
									</React.Fragment>
								)}
							</Box>
						</ModalBody>
						<ModalFooter className={"modal-footer"}>
							<Flex
								direction={["column", "column", "row"]}
								w={["100%", "100%", "auto"]}>
								<Button
									colorScheme='lightBlue'
									minW={["100%", "100%", 100]}
									size={"md"}
									//mr={[0, 0, 2]}
									isDisabled={processing}
									onClick={(e) => {
										handleReportDownload(e);
									}}>
									{processing ? (
										<>
											<Spinner
												size='sm'
												mr={2}
												speed='0.90s'
												emptyColor='gray.200'
												color='blue.500'
											/>
										</>
									) : null}
									Download
								</Button>
								{/* <Button
									colorScheme='grey'
									variant={"outline"}
									mt={[2, 2, 0]}
									minW={["100%", "100%", 100]}
									onClick={() => handleOnClose()}>
									Cancel
								</Button> */}
							</Flex>
						</ModalFooter>
					</ModalContent>
				</Modal>
			</React.Fragment>
		</Box>
	);
};

export default SalesTransfers;
