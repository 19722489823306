import "./NewAuthorization.css";

import * as CommonFns from "../../../../../common";
import * as toastMessages from "../../../../../toastMessages";

import {
	Box,
	Button,
	Center,
	Checkbox,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Skeleton,
	Spinner,
	Table,
	Tbody,
	Td,
	Text,
	Tfoot,
	Tr,
	chakra,
	useDisclosure,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { Grid, _ } from "gridjs-react";
import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "../../../../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LegalText from "../../../../Global/LegalText/LegalText";
import Markdown from "../../../../Global/Markdown/Markdown";
import { axiosMyPropertyAuth } from "../../../../../apis";
import { h } from "gridjs";
import { useToast } from "@chakra-ui/react";

const NewAuthorization = ({
	shareYourInfoDetails,
	openNewAuthModal,
	setOpenNewAuthModal,
	refreshDataToggle,
	setRefreshDataToggle,
}) => {
	const [allProperties, setAllProperties] = useState();
	//const [authorizedProperties, setAuthorizedProperties] = useState([]);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenRepAuthTerms, onOpen: onOpenRepAuthTerms, onClose: onCloseRepAuthTerms } = useDisclosure();
	const [isStepOne, setIsStepOne] = useState(true);
	const [isStepTwo, setIsStepTwo] = useState(false);
	const [isStepThree, setIsStepThree] = useState(false);
	const [isStepFour, setIsStepFour] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [authPropertiesError, setAuthPropertiesError] = useState(false);
	const [hasDuplicates, setHasDuplicates] = useState();
	const { authDetails } = useContext(AuthContext);
	const [authPropertyWithRepExists, setAuthPropertyWithRepExists] = useState(false);

	const toast = useToast();
	const shareYourInfoToastErrorId = "shareYourInfo-toast-error";

	const {
		register,
		handleSubmit,
		control,
		setValue,
		getValues,
		trigger,
		reset,
		clearErrors,
		watch,
		formState: { errors, isSubmitting },
	} = useForm({
		defaultValues: {
			authorizedProperties: [],
			email: "",
			isAuthRepFlag: false,
			isViewOnlyFlag: false,
			grantorName: "",
		},
	});

	const watchisAuthRepFlag = watch("isAuthRepFlag", false);

	useEffect(() => {
		if (openNewAuthModal) {
			getProperties();
			onOpen();
		}
	}, [openNewAuthModal]);

	const getProperties = () => {
		let url = `property/list`;

		axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					if (returnObj.accountsCol !== null) {
						setAllProperties(returnObj.accountsCol.sort((a, b) => a.sortOrder - b.sortOrder));
					} else {
						setAllProperties([]);
					}
				} else {
					if (!toast.isActive(shareYourInfoToastErrorId)) {
						toast(toastMessages.getToastError(shareYourInfoToastErrorId));
					}
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(shareYourInfoToastErrorId)) {
					toast(toastMessages.getToastError(shareYourInfoToastErrorId));
				}
			});
	};

	const handleModalClose = (e) => {
		resetForm();
		setOpenNewAuthModal(false);
		onClose();
	};

	const resetForm = () => {
		setIsStepOne(true);
		setIsStepTwo(false);
		setIsStepThree(false);
		setIsStepFour(false);
		setIsSuccess(false);
		setAuthPropertiesError(false);
		setHasDuplicates();
		reset();
	};

	const getModalHeader = () => {
		let header = "";

		if (allProperties?.length > 0) {
			if (isStepOne) {
				header = ": Step 1 - Properties";
			} else if (isStepTwo) {
				header = ": Step 2 - Recipient Email";
			} else if (isStepThree) {
				header = ": Step 3 - Assessment Year";
			} else if (isStepFour) {
				header = ": Confirmation";
			} else if (isSuccess) {
				header = " Complete!";
			}
		}

		return header;
	};

	// Grid columns
	const gridColumns = [
		{
			id: "selectFlag",
			name: h(
				"div",
				{
					style: {
						"text-align": "center",
					},
				},
				""
			),
			hidden: !isStepOne,
			formatter: (cell, row) => {
				return h(
					"div",
					{
						style: { "text-align": "center" },
					},
					_(
						<Controller
							control={control}
							name={`selectFlag${row.cells[2].data}`}
							defaultValue={cell}
							render={({ field: { onChange, value, ref } }) => (
								<Checkbox
									name={`selectFlag${row.cells[2].data}`}
									className='authorized-properties form-check-input'
									size={"lg"}
									onChange={(e) => {
										onChange(e);
										handleSelectPropertyClick(row.cells[2].data, e);
									}}
									ref={ref}
									isChecked={value}></Checkbox>
							)}
						/>
					)
				);
			},
			width: "5%",
		},
		{
			id: "address",
			name: "Address",
			formatter: (cell) => {
				return h(
					"div",
					{
						style: { "font-weight": "700" },
					},
					cell
				);
			},
			width: "30%",
		},
		{
			id: "accId",
			name: "Account",
			formatter: (cell) => {
				return h(
					"div",
					{
						style: { "font-weight": "700" },
					},
					cell
				);
			},
			width: "10%",
		},
		{
			id: "RepAuth",
			name: "Representative Authorization",
			formatter: (cell) => {
				return h(
					"div",
					{
						style: { "font-weight": "700", "text-align": "center" },
					},
					getValues("isAuthRepFlag") ? "Yes" : "No"
				);
			},
			width: "20%",
			hidden: !isStepFour,
		},
	];
	const gridClass = {
		table: "authorized-properties-grid",
	};

	const handleSelectPropertyClick = (accId, e) => {
		let isChecked = e.target.checked;
		let currentAuthorizedProperties = getValues("authorizedProperties");

		if (isChecked) {
			//add account to authorized list
			currentAuthorizedProperties.push(allProperties.find((x) => x.accId === accId));
		} else {
			//remove from authorized list
			currentAuthorizedProperties = currentAuthorizedProperties.filter((x) => x.accId !== accId);
		}
		setValue("authorizedProperties", currentAuthorizedProperties);
	};

	const handleIsAuthRepFlagClicked = (e) => {
		let isChecked = e.target.checked;
		if (isChecked) {
			setValue("isViewOnlyFlag", false);
		}
	};

	const handleIsViewOnlyFlagClicked = (e) => {
		let isChecked = e.target.checked;
		if (isChecked) {
			setValue("isAuthRepFlag", false);
		}
	};

	const validateAuthorizeFlags = () => {
		let isAuthRepFlag = getValues("isAuthRepFlag");
		let isViewOnlyFlag = getValues("isViewOnlyFlag");

		if (!isAuthRepFlag && !isViewOnlyFlag) {
			return false;
		} else {
			return true;
		}
	};

	const handleRepAuthTermsModalOpen = (e) => {
		e.preventDefault();
		onOpenRepAuthTerms();
	};

	const handleNextClick = () => {
		if (isStepOne) {
			let authorizedProperties = getValues("authorizedProperties");
			if (authorizedProperties.length === 0) {
				setAuthPropertiesError(true);
			} else {
				setIsStepOne(false);
				setIsStepTwo(true);
			}
		} else if (isStepTwo) {
			trigger("email").then((isValid) => {
				if (isValid) {
					setIsStepTwo(false);
					setIsStepThree(true);

					//check if any selected properties already have a representative
					existingRepresentativeCheck();
				}
			});
		} else if (isStepThree) {
			trigger("isAuthRepFlag").then((isValid) => {
				if (isValid) {
					if (getValues("isAuthRepFlag") === true) {
						trigger("grantorName").then((isValid) => {
							if (isValid) {
								setIsStepThree(false);
								setIsStepFour(true);
							}
						});
					} else {
						setIsStepThree(false);
						setIsStepFour(true);
					}
				}
			});
		}
	};

	const existingRepresentativeCheck = () => {
		let authorizedProperties = getValues("authorizedProperties");
		let repExists = false;

		if (shareYourInfoDetails.accountsCol?.length > 0) {
			authorizedProperties.forEach((x) => {
				repExists = shareYourInfoDetails.accountsCol.some(
					(y) => y.accId === x.accId && y.representativeFlag === "Y"
				);

				if (repExists) {
					setAuthPropertyWithRepExists(true);
				}
			});
		}
	};

	const handleClearClick = () => {
		if (isStepOne) {
			setValue("authorizedProperties", []);
			setAuthPropertiesError(false);
			//since this is step one, reset form to clear
			reset();
		} else if (isStepTwo) {
			setValue("email", "");
			clearErrors();
		} else if (isStepThree) {
			setValue("isAuthRepFlag", false);
			setValue("isViewOnlyFlag", false);
			setValue("grantorName", "");
			setAuthPropertyWithRepExists(false);
			clearErrors();
		}
	};

	const onSubmit = async (data) => {
		let url = "shareYourInfo";

		let payload = {};

		payload.accountsCol = [];

		data.authorizedProperties.forEach((x) => {
			payload.accountsCol.push({
				accId: x.accId,
				wuaId: x.wuaId,
				propertyAddress: x.address,
				emailAddress: data.email,
				representativeFlag: data.isAuthRepFlag ? "Y" : "N",
				grantorName: data.grantorName,
			});
		});

		return await axiosMyPropertyAuth
			.post(url, payload)
			.then((resp) => {
				let returnObj = resp.data;
				//if success, check if any duplicates and then show confirmation screen
				setHasDuplicates(resp.data.duplicateFlag === "Y" ? true : false);
				setIsStepFour(false);
				setIsSuccess(true);
				setRefreshDataToggle(!refreshDataToggle);
				return returnObj;
			})
			.catch((error) => {
				// error

				if (!toast.isActive(shareYourInfoToastErrorId)) {
					toast(toastMessages.getToastError(shareYourInfoToastErrorId));
				}
			});
	};

	const validateEmail = (value) => {
		if (authDetails.emailAddress === value) {
			return false;
		} else {
			return true;
		}
	};

	return (
		<>
			<Modal
				size={"4xl"}
				closeOnOverlayClick={false}
				closeOnEsc={false}
				isOpen={isOpen}
				onClose={handleModalClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className={"modal-header"}>New Authorization{getModalHeader()}</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					<ModalBody>
						{allProperties?.length > 0 ? (
							<>
								<Box mt={3}>
									{isStepOne ? (
										<>
											<Box my={3}>
												<Box
													pb={3}
													fontSize={"xl"}>
													Which properties do you want to provide access to?
												</Box>
												<Box>
													<Grid
														data={allProperties}
														columns={gridColumns}
														className={gridClass}
														search={false}
														sort={false}
														fixedHeader={true}
														height={allProperties?.length > 5 ? 245 : ""}></Grid>
												</Box>
												<Box
													colorScheme={"red"}
													className={"text-error pos"}>
													{authPropertiesError
														? "Please select at least one property before continuing."
														: ""}
												</Box>
											</Box>
										</>
									) : null}

									{isStepTwo ? (
										<>
											<>
												<Box>
													<Box
														pb={3}
														fontSize={"xl"}>
														Enter the email address of the person you would like to
														authorize.
													</Box>
													<FormControl
														isInvalid={errors.email}
														my={3}
														isRequired>
														<Flex
															direction={["column", "column", "row"]}
															w='100%'>
															<Box>
																<FormLabel
																	htmlFor='email'
																	className='form-label-fixed'>
																	Recipient email
																</FormLabel>
															</Box>
															<Box pr={2}>
																<Input
																	id='email'
																	type='email'
																	autoFocus
																	placeholder=''
																	autoComplete='off'
																	minW={["", "", 350]}
																	{...register("email", {
																		required: "Recipient email is required",
																		pattern: {
																			value: /^[\w.-]+@([\w-]+\.)+[\w-]{2,}$/g,
																			message: "Recipient email is invalid",
																		},
																		validate: validateEmail,
																	})}
																/>
															</Box>
															<Box>
																<FormErrorMessage
																	colorScheme={"red"}
																	className={"text-error pos"}>
																	{errors.email && errors.email.message}
																	{errors.email?.type === "validate" &&
																		"Recipient email cannot be the same as the profile email"}
																</FormErrorMessage>
															</Box>
														</Flex>
													</FormControl>
												</Box>
											</>
										</>
									) : null}

									{isStepThree ? (
										<>
											<Box>
												<Box
													mb={3}
													fontSize={"xl"}>
													<Markdown
														text={shareYourInfoDetails?.newAuthQuestionHdr}></Markdown>
												</Box>
												<Box mb={4}>
													<Markdown
														text={shareYourInfoDetails?.newAuthQuestionTxt}></Markdown>
												</Box>
												<Box my={5}>
													<FormControl
														isInvalid={errors.isAuthRepFlag}
														mt={2}>
														<Table className='authorized-properties-step3-table'>
															<Tbody>
																<Tr>
																	<Td w='5px'>
																		<Controller
																			control={control}
																			name='isAuthRepFlag'
																			rules={{ validate: validateAuthorizeFlags }}
																			render={({
																				field: { onChange, value, ref },
																			}) => (
																				<Checkbox
																					onChange={(e) => {
																						onChange(e);
																						handleIsAuthRepFlagClicked(e);
																					}}
																					ref={ref}
																					className='authorized-properties form-check-input'
																					isChecked={value}>
																					Yes, authorize this person as my
																					representative.
																				</Checkbox>
																			)}
																		/>
																	</Td>
																</Tr>
																<Tr>
																	<Td>
																		<Controller
																			control={control}
																			name='isViewOnlyFlag'
																			render={({
																				field: { onChange, value, ref },
																			}) => (
																				<Checkbox
																					onChange={(e) => {
																						onChange(e);
																						handleIsViewOnlyFlagClicked(e);
																					}}
																					ref={ref}
																					className='authorized-properties form-check-input'
																					isChecked={value}>
																					No, only allow them to view my
																					information.
																				</Checkbox>
																			)}
																		/>
																	</Td>
																</Tr>
															</Tbody>
															<Tfoot>
																<Tr></Tr>
															</Tfoot>
														</Table>
														<Box ml={2}>
															<FormErrorMessage
																colorScheme={"red"}
																className={"text-error pos"}>
																{errors.isAuthRepFlag?.type === "validate" &&
																	"Please make a selection before continuing"}
															</FormErrorMessage>
														</Box>
													</FormControl>
												</Box>

												{watchisAuthRepFlag ? (
													<>
														<Box mt={10}>
															{authPropertyWithRepExists ? (
																<>
																	<Box
																		border={"1px"}
																		p={2}>
																		<Markdown
																			text={
																				shareYourInfoDetails?.shareYourInfoRepDup
																			}></Markdown>
																	</Box>
																</>
															) : null}

															<Box mt={5}>
																<Box>
																	<Markdown
																		text={
																			shareYourInfoDetails?.shareYourInfoRepName
																		}></Markdown>
																</Box>
																<FormControl
																	isInvalid={errors.grantorName}
																	my={3}
																	isRequired>
																	<Flex
																		direction={["column", "column", "row"]}
																		w='100%'>
																		<Box>
																			<FormLabel
																				htmlFor='grantorName'
																				className='form-label-fixed'>
																				Your name
																			</FormLabel>
																		</Box>
																		<Box pr={2}>
																			<Input
																				id='grantorName'
																				type='grantorName'
																				placeholder=''
																				autoComplete='off'
																				minW={["", "", 350]}
																				{...register("grantorName", {
																					required:
																						"Please enter your name before continuing",
																				})}
																			/>
																		</Box>
																		<Box>
																			<FormErrorMessage
																				colorScheme={"red"}
																				className={"text-error pos"}>
																				{errors.grantorName &&
																					errors.grantorName.message}
																			</FormErrorMessage>
																		</Box>
																	</Flex>
																</FormControl>
															</Box>
														</Box>
													</>
												) : null}
											</Box>
										</>
									) : null}

									{isStepFour ? (
										<>
											<Box>
												<Box
													mb={3}
													fontSize={"xl"}>
													Please review your authorization
												</Box>
												<Box>
													<Markdown text={shareYourInfoDetails?.newAuthReviewTxt}></Markdown>
												</Box>
												<Box my={3}>
													<Flex>
														<Text fontWeight={"bold"}>
															Authorized party's email address:
														</Text>
														<Text ml={2}>{getValues("email")}</Text>
													</Flex>
													<Flex>
														<Text fontWeight={"bold"}>Date authorization expires:</Text>
														<Text ml={2}>
															{CommonFns.FormatDate(shareYourInfoDetails.viewerExpDate)}
														</Text>
													</Flex>
												</Box>
												<Box>
													<Grid
														data={getValues("authorizedProperties").sort(
															(a, b) => a.sortOrder - b.sortOrder
														)}
														columns={gridColumns}
														className={gridClass}
														search={false}
														sort={false}></Grid>
												</Box>
												<Box my={3}>
													<FormControl isInvalid={errors.repAuthTerms}>
														<Controller
															control={control}
															name='repAuthTerms'
															rules={{
																required:
																	"You must accept the Representative Authorization Terms",
															}}
															render={({ field: { onChange, value, ref } }) => (
																<Checkbox
																	size={"lg"}
																	onChange={onChange}
																	ref={ref}
																	className='authorized-properties form-check-input'
																	isChecked={value}>
																	<chakra.span
																		fontWeight={700}
																		fontSize={".85em"}>
																		I've read and agree to the{" "}
																		<Link
																			className='legal-link'
																			onClick={(e) =>
																				handleRepAuthTermsModalOpen(e)
																			}>
																			Representative Authorization Terms
																		</Link>
																		{". "}
																		<chakra.span
																			color={"#00a5d4"}
																			fontStyle={"italic"}>
																			(mandatory)
																		</chakra.span>
																	</chakra.span>
																</Checkbox>
															)}
														/>
														<FormErrorMessage
															colorScheme={"red"}
															className={"text-error"}>
															{errors.repAuthTerms && errors.repAuthTerms.message}
														</FormErrorMessage>
													</FormControl>
												</Box>
											</Box>
										</>
									) : null}

									{isSuccess ? (
										<>
											{hasDuplicates !== undefined ? (
												<>
													<Box>
														<Box
															mb={3}
															fontSize={"xl"}>
															{hasDuplicates ? (
																<>
																	<Markdown
																		text={
																			shareYourInfoDetails?.authThankYouDupHdr
																		}></Markdown>
																</>
															) : (
																<>
																	<Markdown
																		text={
																			shareYourInfoDetails?.authThankYouNoDupHdr
																		}></Markdown>
																</>
															)}
														</Box>
														<Box>
															{hasDuplicates ? (
																<>
																	<Markdown
																		text={shareYourInfoDetails?.authThankYouDupTxt.replace(
																			"**[emailAddress]**",
																			getValues("email")
																		)}></Markdown>
																</>
															) : (
																<>
																	<Markdown
																		text={shareYourInfoDetails?.authThankYouNoDupTxt.replace(
																			"**[emailAddress]**",
																			getValues("email")
																		)}></Markdown>
																</>
															)}
														</Box>
														{getValues("isAuthRepFlag") ? (
															<Box
																mt={3}
																mx={-2}
																mb={2}
																py={1}>
																<Box mx={2}>
																	<Markdown
																		text={
																			shareYourInfoDetails?.RepresentativeMsg
																		}></Markdown>
																</Box>
															</Box>
														) : null}
													</Box>
												</>
											) : (
												<>
													<Skeleton
														height={10}
														width={"500px"}
														startColor='#e2e2e2'
														endColor='#bbbbbb'></Skeleton>
													<Skeleton
														mt={4}
														height={100}
														startColor='#e2e2e2'
														endColor='#bbbbbb'></Skeleton>
												</>
											)}
										</>
									) : null}
								</Box>
							</>
						) : (
							<>
								{allProperties?.length === 0 ? (
									<>
										<Box my={5}>
											You must add properties to your profile before sharing your information. You
											can add properties by visiting the Home screen and clicking Add a Property.
										</Box>
									</>
								) : (
									<>
										<Center>
											<Spinner size='xl'></Spinner>
										</Center>
									</>
								)}
							</>
						)}
					</ModalBody>
					<ModalFooter className={"modal-footer"}>
						{allProperties?.length > 0 ? (
							<Flex
								direction={["column", "column", "row"]}
								w={["100%", "100%", "auto"]}>
								{isStepOne || isStepTwo || isStepThree ? (
									<>
										<Button
											colorScheme='lightBlue'
											onClick={() => handleNextClick()}
											minW={["100%", "100%", 100]}
											mr={[0, 0, 2]}>
											NEXT
										</Button>

										<Button
											colorScheme='grey'
											type='reset'
											variant={"outline"}
											mt={[2, 2, 0]}
											minW={["100%", "100%", 100]}
											onClick={(e) => handleClearClick(e)}>
											CLEAR
										</Button>
									</>
								) : null}
								{isStepFour ? (
									<>
										<Button
											colorScheme='lightBlue'
											minW={["100%", "100%", 100]}
											mr={[0, 0, 2]}
											onClick={handleSubmit((data) => onSubmit(data))}
											isDisabled={authDetails.isAdmin}
											isLoading={isSubmitting}
											loadingText={"SEND AUTHORIZATION"}
											//mr={2}
										>
											SEND AUTHORIZATION
										</Button>
										<Button
											colorScheme='grey'
											variant={"outline"}
											mt={[2, 2, 0]}
											minW={["100%", "100%", 100]}
											onClick={(e) => handleModalClose()}>
											Cancel
										</Button>
									</>
								) : null}
							</Flex>
						) : null}
					</ModalFooter>
				</ModalContent>
			</Modal>

			{/* REPRESENTATIVE AUTHORIZAATION TERMS */}
			<Modal
				closeOnOverlayClick={true}
				isOpen={isOpenRepAuthTerms}
				onClose={onCloseRepAuthTerms}>
				<ModalOverlay />
				<ModalContent maxW={"57rem"}>
					<ModalHeader className={"modal-header"}>Representative Authorization - Terms</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					<ModalBody>
						<LegalText displayMessageTypes={["AGENT_REPRESENTATIVE_AUTHORIZATION_TERMS"]}></LegalText>
					</ModalBody>
					<ModalFooter className={"modal-footer"}>
						{/* <Button colorScheme='blue' size={"sm"} onClick={(e) => handleRepAuthTermsModalClose(e)}>
							Close
						</Button> */}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};
export default NewAuthorization;
