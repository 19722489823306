import "gridjs/dist/theme/mermaid.css";
import "./MailPreferences.css";

import * as CommonFns from "../../../../../common";
import * as toastMessages from "../../../../../toastMessages";

import {
	Box,
	Button,
	Checkbox,
	Flex,
	FormControl,
	FormErrorMessage,
	HStack,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Portal,
	VStack,
	chakra,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { Grid, _ } from "gridjs-react";
import React, { useContext, useEffect, useState } from "react";

import AddProperty from "../../../Property/AddProperty/AddProperty";
import { AuditContext } from "../../../../../contexts/AuditContext";
import { AuthContext } from "../../.././../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LegalText from "../../../../Global/LegalText/LegalText";
import { axiosMyPropertyAuth } from "../../../../../apis";
import { h } from "gridjs";
import { useNavigate } from "react-router-dom";

const MailPreferences = ({ smsPhoneNum, onMailPreferencesSave, managePropertyAndMail }) => {
	const { authDetails, setAuthDetails } = useContext(AuthContext);
	const { auditActivity } = useContext(AuditContext);
	const [gridItems, setGridItems] = useState([]);
	const [originalItems, setOriginalItems] = useState([]);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenUserAgreement, onOpen: onOpenUserAgreement, onClose: onCloseUserAgreement } = useDisclosure();
	const { isOpen: isOpenPrivacy, onOpen: onOpenPrivacy, onClose: onClosePrivacy } = useDisclosure();
	const { isOpen: isOpenNoChanges, onOpen: onOpenNoChanges, onClose: onCloseNoChanges } = useDisclosure();
	const { isOpen: isOpenSuccess, onOpen: onOpenSuccess, onClose: onCloseSuccess } = useDisclosure();
	const [errorMessage, setErrorMessage] = useState("");
	const [origElectronicPreference, setOrigElectronicPreference] = useState(false);
	const [showEmailTextSuccess, setShowEmailTextSuccess] = useState(false);
	const [showMailSuccess, setShowMailSuccess] = useState(false);
	const [showDefaultSuccess, setShowDefaultSuccess] = useState(false);
	const [addPropertyType, setAddPropertyType] = useState(CommonFns.AddPropertyTypeEnum.None);

	const toast = useToast();
	const notificationToastErrorId = "notification-toast-error";

	let navigate = useNavigate();

	const [accountsLinked, setAccountsLinked] = useState(false);
	const [hasUnVerifiedAccount, setHasUnverifiedAccount] = useState(false);

	const [notAllowUpdateTooltip] = useState(
		"This property is fully or partially not residential. Notification preferences have been disabled. You will continue to receive paper mail for this property."
	);
	const [primaryOwnerTooltip] = useState(
		"You are considered a primary owner of this property. As a result, our correspondence must reach you&mdash;electronically or by paper mail."
	);

	const uId = authDetails.uId;

	const {
		handleSubmit,
		control,
		setValue,
		getValues,
		formState: { errors },
	} = useForm();

	useEffect(() => {
		loadData();
	}, []);

	const loadData = () => {
		setGridItems([]);
		setOriginalItems([]);
		getPreferences();
		getElectronicPreferences();

		setShowEmailTextSuccess(false);
		setShowMailSuccess(false);
		setShowDefaultSuccess(false);

		setValue("hasAgreed", false);
	};

	const getPreferences = () => {
		let url = `notification`;

		axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;
				//if noAccountsLinked is N, then accounts are linked
				if (returnObj.noAccountsLinkedFlag === "N") {
					setAccountsLinked(true);

					if (returnObj.notificationPrefCol !== null) {
						let temp = [...returnObj.notificationPrefCol];
						temp.forEach((x) => {
							x.emailFlag = x.emailFlag === "Y" ? true : false;
							x.mailFlag = x.mailFlag === "Y" ? true : false;
							x.noneFlag = x.noneFlag === "Y" ? true : false;
							x.textFlag = x.textFlag === "Y" ? true : false;
							x.allowUpdFlag = x.allowUpdFlag === "Y" ? true : false;
							x.isPrimaryOwner = x.ownerOrder === 1 || x.ownerOrder === 2 ? true : false;
						});

						setGridItems(temp.sort((a, b) => a.sortOrder - b.sortOrder));
						setOriginalItems(JSON.parse(JSON.stringify(temp)));
					}
				} else {
					setAccountsLinked(false);
					setGridItems([]);
				}

				if (returnObj.hasUnverifiedAcctFlag === "Y") {
					setHasUnverifiedAccount(true);
				} else {
					setHasUnverifiedAccount(false);
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(notificationToastErrorId)) {
					toast(toastMessages.getToastError(notificationToastErrorId));
				}
			});
	};

	const getElectronicPreferences = () => {
		let url = `notification/electronic`;

		axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;

				let isSubscribed = returnObj.isSubscribed === "Y" ? true : false;
				setOrigElectronicPreference(isSubscribed);
				setValue("caslFlag", isSubscribed);
			})
			.catch((error) => {
				// error
				if (!toast.isActive(notificationToastErrorId)) {
					toast(toastMessages.getToastError(notificationToastErrorId));
				}
			});
	};

	// Grid columns
	const gridColumns = [
		{
			id: "sortOrder",
			name: "",
			formatter: (cell) => {
				return h(
					"div",
					{
						style: { "font-weight": "700" },
					},
					cell
				);
			},
			width: "2%",
		},
		{
			id: "address",
			name: "Address",
			formatter: (cell) => {
				return h(
					"div",
					{
						style: { "font-weight": "700" },
					},
					cell
				);
			},
			width: "40%",
		},
		{
			id: "accId",
			name: "Account",
			formatter: (cell) => {
				return h(
					"div",
					{
						style: { "font-weight": "700" },
					},
					cell
				);
			},
			width: "15%",
		},
		{
			id: "emailFlag",
			name: h(
				"div",
				{
					style: {
						"text-align": "center",
					},
				},
				"Email"
			),
			formatter: (cell, row) => {
				return h(
					"div",
					{
						style: { "text-align": "center" },
					},
					_(
						<Controller
							control={control}
							name={`emailFlag${row.cells[2].data}`}
							defaultValue={cell}
							render={({ field: { onChange, value, ref } }) => (
								<Checkbox
									name={`emailFlag${row.cells[2].data}`}
									className='preferences form-check-input'
									size={"lg"}
									onChange={(e) => {
										onChange();
										handleClick(row.cells[2].data, e);
									}}
									ref={ref}
									isChecked={value}
									disabled={!row.cells[7].data || row.cells[4].data}
									title={row.cells[7].data ? "" : notAllowUpdateTooltip}></Checkbox>
							)}
						/>
					)
				);
			},
			width: "10%",
		},
		{
			id: "textFlag",
			name: h(
				"div",
				{
					style: {
						"text-align": "center",
					},
				},
				"Text"
			),
			formatter: (cell, row) => {
				return h(
					"div",
					{
						style: { "text-align": "center" },
					},

					_(
						<Controller
							control={control}
							name={`textFlag${row.cells[2].data}`}
							defaultValue={cell}
							render={({ field: { onChange, value, ref } }) => (
								<Checkbox
									name={`textFlag${row.cells[2].data}`}
									className='preferences form-check-input'
									size={"lg"}
									onChange={(e) => {
										onChange();
										handleClick(row.cells[2].data, e);
									}}
									ref={ref}
									isChecked={value}
									disabled={!row.cells[7].data}
									title={row.cells[7].data ? "" : notAllowUpdateTooltip}></Checkbox>
							)}
						/>
					)
				);
			},
			width: "10%",
		},
		{
			id: "mailFlag",
			name: h(
				"div",
				{
					style: { "text-align": "center" },
				},
				"Paper Mail"
			),
			formatter: (cell, row) => {
				return h(
					"div",
					{
						style: { "text-align": "center" },
					},

					_(
						<Controller
							control={control}
							name={`mailFlag${row.cells[2].data}`}
							defaultValue={cell}
							render={({ field: { onChange, value, ref } }) => (
								<Checkbox
									name={`mailFlag${row.cells[2].data}`}
									className='preferences form-check-input'
									size={"lg"}
									onChange={(e) => {
										onChange();
										handleClick(row.cells[2].data, e);
									}}
									ref={ref}
									isChecked={value}
									disabled={!row.cells[7].data}
									title={row.cells[7].data ? "" : notAllowUpdateTooltip}></Checkbox>
							)}
						/>
					)
				);
			},
			width: "10%",
		},
		{
			id: "noneFlag",
			name: h(
				"div",
				{
					style: { "text-align": "center" },
				},
				_(
					<>
						None
						<Popover>
							<PopoverTrigger>
								<Button pl={8}>
									<FontAwesomeIcon
										icon='fas fa-info-circle'
										className='profile-i-icon'
									/>
								</Button>
							</PopoverTrigger>
							<Portal>
								<PopoverContent className='gridjs-popover-content'>
									<PopoverArrow />
									<PopoverBody className='gridjs-popover-body'>
										This option is available to secondary property owners only. If you are
										considered a primary owner, our correspondence must reach
										you&mdash;electronically or by paper mail.
									</PopoverBody>
								</PopoverContent>
							</Portal>
						</Popover>
					</>
				)
			),
			formatter: (cell, row) => {
				return h(
					"div",
					{
						style: { "margin-left": "15px" },
					},

					_(
						<Controller
							control={control}
							name={`noneFlag${row.cells[2].data}`}
							defaultValue={cell}
							render={({ field: { onChange, value, ref } }) => (
								<Checkbox
									name={`noneFlag${row.cells[2].data}`}
									className='preferences form-check-input'
									size={"lg"}
									onChange={(e) => {
										onChange();
										handleClick(row.cells[2].data, e);
									}}
									ref={ref}
									isChecked={value}
									disabled={!row.cells[7].data || row.cells[8].data}
									title={
										row.cells[7].data
											? row.cells[8].data
												? primaryOwnerTooltip
												: ""
											: notAllowUpdateTooltip
									}></Checkbox>
							)}
						/>
					)
				);
			},
			width: "10%",
		},
		{
			id: "allowUpdFlag",
			hidden: true,
		},
		{
			id: "isPrimaryOwner",
			hidden: true,
		},
	];

	const gridClass = {
		table: "notifications-grid",
	};

	const handleClick = (accId, e) => {
		e.preventDefault();

		let tempItems = [...gridItems];
		let selectedItem = tempItems.find((x) => x.accId === accId);

		let targetName = e.target.name.replace(accId, "");
		let emailFlagName = "emailFlag" + accId;
		let textFlagName = "textFlag" + accId;
		let mailFlagName = "mailFlag" + accId;
		let noneFlagName = "noneFlag" + accId;

		switch (targetName) {
			case "emailFlag":
				selectedItem.emailFlag = !selectedItem.emailFlag;

				if (selectedItem.emailFlag) {
					setValue(textFlagName, false);
					setValue(mailFlagName, false);
					setValue(noneFlagName, false);
					selectedItem.textFlag = false;
					selectedItem.mailFlag = false;
					selectedItem.noneFlag = false;
				}
				break;
			case "textFlag":
				selectedItem.textFlag = !selectedItem.textFlag;
				//if text selected, automatically select email
				if (selectedItem.textFlag) {
					setValue(emailFlagName, true);
					setValue(mailFlagName, false);
					setValue(noneFlagName, false);
					selectedItem.emailFlag = true;
					selectedItem.mailFlag = false;
					selectedItem.noneFlag = false;
				}
				break;
			case "mailFlag":
				selectedItem.mailFlag = !selectedItem.mailFlag;
				if (selectedItem.mailFlag) {
					setValue(emailFlagName, false);
					setValue(textFlagName, false);
					setValue(noneFlagName, false);
					selectedItem.emailFlag = false;
					selectedItem.textFlag = false;
					selectedItem.noneFlag = false;
				}
				break;
			case "noneFlag":
				selectedItem.noneFlag = !selectedItem.noneFlag;
				if (selectedItem.noneFlag) {
					setValue(emailFlagName, false);
					setValue(textFlagName, false);
					setValue(mailFlagName, false);
					selectedItem.emailFlag = false;
					selectedItem.textFlag = false;
					selectedItem.mailFlag = false;
				}
				break;
			default:
			//no default
		}

		setGridItems(tempItems);
	};

	const handleUserAgreementModalOpen = (e) => {
		e.preventDefault();
		onOpenUserAgreement();
	};

	// const handleUserAgreementModalClose = (e) => {
	// 	e.preventDefault();
	// 	onCloseUserAgreement();
	// };

	const handlePrivacyModalOpen = (e) => {
		e.preventDefault();
		onOpenPrivacy();
	};

	// const handlePrivacyModalClose = (e) => {
	// 	e.preventDefault();
	// 	onClosePrivacy();
	// };

	const handleElecNotifModalOpen = (e) => {
		e.preventDefault();
		onOpen();
	};

	// const handleElecNotifModalClose = (e) => {
	// 	e.preventDefault();
	// 	onClose();
	// };

	// const handleNoChangesModalClose = (e) => {
	// 	e.preventDefault();
	// 	onCloseNoChanges();
	// };

	const handleSuccessModalClose = () => {
		//e.preventDefault();
		onCloseSuccess();

		//reload profile info
		onMailPreferencesSave();

		//reload data
		loadData();
	};

	const handleAddProperty = (e) => {
		e.preventDefault();
		setAddPropertyType(CommonFns.AddPropertyTypeEnum.Add);
		setAuthDetails({
			...authDetails,
			addPropertyModal: true,
		});
	};

	const handleOnPropertyAdded = (mailCount) => {
		getPreferences();
		getElectronicPreferences();
		managePropertyAndMail("add", 0, 0, mailCount);
	};

	const handleAddPropertyClose = () => {
		//set apppropertytype back to none so modal is not triggered to open from the other add modal on the myproperty page
		setAddPropertyType(CommonFns.AddPropertyTypeEnum.None);
		setAuthDetails({
			...authDetails,
			addPropertyModal: false,
		});
	};

	const onSubmit = (data) => {
		let valid = validatePreferences();
		if (valid) {
			// audit
			auditActivity("Notification Preferences - Clicked Update Preferences");

			let preferenceData = { uId: uId, notificationPrefCol: [] };

			//check if changes exist for preferences section
			let hasPreferencesChanged = false;
			//let emailTextChanged = false;
			let emailChanged = false;
			let textChanged = false;
			let mailChanged = false;
			let noneChanged = false;
			let hasNewEmailTextPref = false;
			let hasNewMailPref = false;
			let hasElectronicPreferenceChanged = false;
			let item = null;

			originalItems.forEach((x) => {
				item = gridItems.find((y) => y.accId === x.accId);

				emailChanged = item.emailFlag !== x.emailFlag;
				textChanged = item.textFlag !== x.textFlag;

				mailChanged = item.mailFlag !== x.mailFlag;
				noneChanged = item.noneFlag !== x.noneFlag;

				if (emailChanged || textChanged || mailChanged || noneChanged) {
					hasPreferencesChanged = true;

					//set flag if changes included adding email or text notifications
					if ((emailChanged && item.emailFlag) || (textChanged && item.textFlag)) {
						hasNewEmailTextPref = true;
					}

					//set flag if changes included adding paper mail notifications
					if (mailChanged && item.mailFlag) {
						hasNewMailPref = true;
					}

					preferenceData.notificationPrefCol.push({
						accId: item.accId,
						wuaId: x.wuaId,
						wnpId: x.wnpId,
						correspItem: x.correspItem,
						emailFlag: item.emailFlag ? "Y" : "N",
						textFlag: item.textFlag ? "Y" : "N",
						mailFlag: item.mailFlag ? "Y" : "N",
						noneFlag: item.noneFlag ? "Y" : "N",
					});
				}
			});

			//check if there have been any change to electronic preference
			if (data.caslFlag !== origElectronicPreference) {
				hasElectronicPreferenceChanged = true;
			}

			//if no changes then display no changes modal
			if (!hasPreferencesChanged && !hasElectronicPreferenceChanged) {
				//display no changes modal and return
				onOpenNoChanges();
				return;
			} else {
				if (hasPreferencesChanged) {
					updateNotificationPreferences(preferenceData, hasNewEmailTextPref).then((notificationRes) => {
						if (notificationRes !== undefined) {
							if (hasElectronicPreferenceChanged) {
								updateElectronicPreferences(data.caslFlag).then((electronicRes) => {
									if (electronicRes !== undefined) {
										showSuccessMessage(hasNewEmailTextPref, hasNewMailPref);
									}
								});
							} else {
								showSuccessMessage(hasNewEmailTextPref, hasNewMailPref);
							}
						}
					});
				} else {
					updateElectronicPreferences(data.caslFlag).then((electronicRes) => {
						if (electronicRes !== undefined) {
							showSuccessMessage(false, false);
						}
					});
				}
			}
		} else {
			return false;
		}
	};

	const updateNotificationPreferences = async (payload, newEmailChange) => {
		let url = "notification";

		payload.uId = "";
		payload.newEmailChange = newEmailChange;
		payload.profileName = authDetails.profileName;
		payload.emailAddress = authDetails.emailAddress;

		return await axiosMyPropertyAuth
			.put(url, payload)
			.then((resp) => {
				let returnObj = resp.data;
				return returnObj;
			})
			.catch((error) => {
				// error

				if (!toast.isActive(notificationToastErrorId)) {
					toast(toastMessages.getToastError(notificationToastErrorId));
				}
			});
	};

	const updateElectronicPreferences = async (caslFlag) => {
		let url = "notification/electronic";
		let electronicPayload = { uId: "", caslFlag: caslFlag ? "Y" : "N" };

		return await axiosMyPropertyAuth
			.put(url, electronicPayload)
			.then((resp) => {
				let returnObj = resp.data;
				return returnObj;
			})
			.catch((error) => {
				// error

				if (!toast.isActive(notificationToastErrorId)) {
					toast(toastMessages.getToastError(notificationToastErrorId));
				}
			});
	};

	const showSuccessMessage = (hasNewEmailTextPref, hasNewMailTextPref) => {
		//show Email/Text success message if changes were to add email or text
		if (hasNewEmailTextPref) {
			setShowEmailTextSuccess(true);
		} else {
			//show mail success message if changes were to add paper mail
			if (hasNewMailTextPref) {
				setShowMailSuccess(true);
			} else {
				//show default success message for any other changes
				setShowDefaultSuccess(true);
			}
		}
		//show Success modal
		onOpenSuccess();
	};

	const validatePreferences = () => {
		//make sure at least one preference is set for all accounts
		//let tempItems = [...gridItems];
		let tempItems = JSON.parse(JSON.stringify(gridItems));

		//find any accounts that don't have any preferences set but had a previsouly set preference.  Accounts that never had any preferences set before are not required to be set
		let noPreferences = tempItems.some(
			(x) =>
				x.allowUpdFlag &&
				!x.emailFlag &&
				!x.textFlag &&
				!x.mailFlag &&
				!x.noneFlag &&
				originalItems.some(
					(y) => y.accId === x.accId && (y.emailFlag || y.textFlag || y.mailFlag || y.noneFlag)
				)
		);

		if (noPreferences) {
			setErrorMessage("Please set notification preferences for all available properties.");
			return false;
		}

		//check that if there are changes to the preferences grid AND Email or Text was selected, the User Agreement checkbox was checked
		const hasChanged = JSON.stringify(tempItems.sort()) !== JSON.stringify(originalItems.sort());

		if (hasChanged) {
			let emailTextSelected = tempItems.some((x) => x.emailFlag || x.textFlag);
			let isAgreementChecked = getValues("hasAgreed");

			if (emailTextSelected && !isAgreementChecked) {
				setErrorMessage(
					"You must accept the User Agreement and Privacy Terms if you want to receive notifications via email or text."
				);
				return false;
			}
		}

		//check if a phone number is available if text was selected for one or more accounts
		let noPhone = tempItems.some((x) => x.textFlag) && smsPhoneNum === null;

		if (noPhone) {
			setErrorMessage("A mobile phone number must be provided in order to receive text message notifications.");
			return false;
		}

		setErrorMessage("");
		return true;
	};

	const handleContactUs = (e) => {
		e.preventDefault();
		navigate("/auth/feedback", { replace: true });
	};

	const handleVerifyOwnership = (e) => {
		e.preventDefault();
		navigate("/auth/property", { replace: true });
	};

	return (
		<React.Fragment>
			<Box
				mb={3}
				mt={3}
				maxW={1050}>
				<chakra.span fontSize={"2xl"}>Official mail</chakra.span>
			</Box>
			<Box
				maxW={1050}
				pb={2}
				fontSize={"xl"}>
				Stop paper mail to your current mailing address and receive notifications when your official property
				assessment and tax mail appears on MyProperty&mdash;
				<chakra.span fontStyle={"italic"}>
					at this time available to owners of residential properties only.
				</chakra.span>
			</Box>
			<Box>
				<form onSubmit={handleSubmit(onSubmit)}>
					{accountsLinked ? (
						<>
							<Grid
								className={gridClass}
								data={gridItems}
								columns={gridColumns}
								search={false}
								sort={false}></Grid>

							{gridItems.length > 0 ? (
								<FormControl
									isInvalid={errors.hasAgreed || errorMessage !== ""}
									ml={1}>
									<Box mt={1}>
										<Controller
											control={control}
											name='hasAgreed'
											defaultValue={false}
											render={({ field: { onChange, value, ref } }) => (
												<Checkbox
													size={"lg"}
													onChange={onChange}
													ref={ref}
													isChecked={value}>
													<chakra.span
														fontWeight={700}
														fontSize={".85em"}>
														I've read and agree to the{" "}
														<Link
															className='legal-link'
															onClick={(e) => handleUserAgreementModalOpen(e)}>
															User Agreement
														</Link>{" "}
														and{" "}
														<Link
															className='legal-link'
															onClick={(e) => handlePrivacyModalOpen(e)}>
															Privacy Terms
														</Link>
														.
														<chakra.span
															color={"#00a5d4"}
															fontStyle={"italic"}>
															{" "}
															(mandatory for email and text)
														</chakra.span>
													</chakra.span>
												</Checkbox>
											)}
											rules={
												{
													//validate: validatePreferences
												}
											}
										/>

										<FormErrorMessage
											colorScheme={"red"}
											className={"text-error"}>
											{errors.hasAgreed?.type === "required" && errors.hasAgreed.message}
											{/* {errors.hasAgreed?.type === 'validate' && errorMessage}  */}
											{errorMessage !== "" && errorMessage}
										</FormErrorMessage>
									</Box>
								</FormControl>
							) : (
								<></>
							)}
						</>
					) : (
						<>
							<Box className='list-property-no-records'>
								It looks like you haven't connected any properties to your online profile. To do so,
								please <Link onClick={(e) => handleAddProperty(e)}>add a property</Link>.
							</Box>
							<AddProperty
								onPropertyAdded={handleOnPropertyAdded}
								hideAddButton={true}
								addPropertyType={addPropertyType}
								handleAddPropertyClose={handleAddPropertyClose}></AddProperty>
						</>
					)}
					{hasUnVerifiedAccount ? (
						<>
							<Box
								mt={".1in"}
								className='list-property-no-records'>
								It looks like you have not verified ownership for all properties. To proceed setting
								notification preferences, please first{" "}
								<Link onClick={(e) => handleVerifyOwnership(e)}>verify ownership</Link>.
							</Box>
						</>
					) : (
						<></>
					)}

					<Box>
						<Box
							mb={3}
							mt={10}
							maxW={1050}>
							<chakra.span fontSize={"2xl"}>Updates, reminders and other extras</chakra.span>
						</Box>
						<Box
							maxW={1050}
							pb={2}>
							We might have something great going on or just a reminder about an upcoming deadline or be
							in need of your feedback. Check here to receive our updates, reminders and other extras
							about property assessment and taxes.
						</Box>
						<Box ml={1}>
							<Controller
								control={control}
								name='caslFlag'
								defaultValue={origElectronicPreference}
								render={({ field: { onChange, value, ref } }) => (
									<Checkbox
										size={"lg"}
										onChange={onChange}
										ref={ref}
										isChecked={value}>
										<chakra.span
											fontWeight={700}
											fontSize={".85em"}>
											Yes, I would like to receive updates, reminders and other extras and agree
											to{" "}
											<Link
												className='legal-link'
												onClick={(e) => handleElecNotifModalOpen(e)}>
												Electronic Notification Preferences Terms
											</Link>
											{". "}
											<chakra.span
												color={"#00a5d4"}
												fontStyle={"italic"}>
												(optional)
											</chakra.span>
										</chakra.span>
									</Checkbox>
								)}
							/>
						</Box>
					</Box>
					<Box mt={5}>
						<Button
							colorScheme='blue'
							type='submit'>
							UPDATE PREFERENCES
						</Button>
					</Box>
				</form>
			</Box>

			{/* USER AGREEMENT */}
			<Modal
				closeOnOverlayClick={true}
				isOpen={isOpenUserAgreement}
				onClose={onCloseUserAgreement}
				scrollBehavior={"inside"}>
				<ModalOverlay />
				<ModalContent maxW={"57rem"}>
					<ModalHeader className={"modal-header"}>Notification Preferences - Official Mail</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					<ModalBody>
						<LegalText displayMessageTypes={["WEB USER AGREEMENT"]}></LegalText>
					</ModalBody>
					<ModalFooter className={"modal-footer"}>
						{/* <Button colorScheme="blue" size={'sm'} onClick={(e) => handleUserAgreementModalClose(e)}>
							Close
						</Button> */}
					</ModalFooter>
				</ModalContent>
			</Modal>

			{/* PRIVACY TERMS */}
			<Modal
				closeOnOverlayClick={true}
				isOpen={isOpenPrivacy}
				onClose={onClosePrivacy}>
				<ModalOverlay />
				<ModalContent maxW={"57rem"}>
					<ModalHeader className={"modal-header"}>Notification Preferences - Official Mail</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					<ModalBody>
						<LegalText displayMessageTypes={["WEB ELECTRONIC COMMUNICATIONS PRIVACY TERMS"]}></LegalText>
					</ModalBody>
					<ModalFooter className={"modal-footer"}>
						{/* <Button colorScheme='blue' size={"sm"} onClick={(e) => handlePrivacyModalClose(e)}>
							Close
						</Button> */}
					</ModalFooter>
				</ModalContent>
			</Modal>

			{/* ELECTRONIC NOTIFICATION TERMS */}
			<Modal
				closeOnOverlayClick={true}
				isOpen={isOpen}
				onClose={onClose}>
				<ModalOverlay />
				<ModalContent maxW={"57rem"}>
					<ModalHeader className={"modal-header"}>Electronic Notification Preferences - Terms</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					<ModalBody>
						<LegalText displayMessageTypes={["WEB ELECTRONIC NOTIFICATION PREFS"]}></LegalText>
					</ModalBody>
					<ModalFooter className={"modal-footer"}>
						{/* <Button colorScheme='blue' size={"sm"} onClick={(e) => handleElecNotifModalClose(e)}>
							Close
						</Button> */}
					</ModalFooter>
				</ModalContent>
			</Modal>

			{/* NO CHANGES */}
			<Modal
				closeOnOverlayClick={true}
				isOpen={isOpenNoChanges}
				onClose={onCloseNoChanges}>
				<ModalOverlay />
				<ModalContent maxW={"57rem"}>
					<ModalHeader className={"modal-header"}>No Changes Detected</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					<ModalBody>
						<Box
							maxW={1050}
							py={5}
							px={10}>
							No changes to notification preferences have been detected. Please ensure you set your
							preferences before proceeding.
						</Box>
					</ModalBody>
					<ModalFooter className={"modal-footer"}>
						<Flex w={["100%", "100%", "auto"]}>
							<Button
								colorScheme='blue'
								minW={["100%", "100%", 100]}
								onClick={(e) => onCloseNoChanges()}>
								OK
							</Button>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>

			{/* UPDATE SUCCESS */}
			<Modal
				closeOnOverlayClick={true}
				isOpen={isOpenSuccess}
				onClose={handleSuccessModalClose}>
				<ModalOverlay />
				<ModalContent maxW={"57rem"}>
					<ModalHeader className={"modal-header"}>Notification Preferences Updated</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					<ModalBody>
						<HStack
							spacing={5}
							pl={10}
							py={5}
							align={"top"}>
							<Box className='success-icon'>
								<FontAwesomeIcon icon='fas fa-check-circle' />
							</Box>
							<Box>
								<VStack
									align={"left"}
									mt={4}
									spacing={0}>
									<Box fontWeight={700}>Thank you!</Box>

									{showEmailTextSuccess ? (
										<>
											<Box>Your preferences have been updated.</Box>
											<Box>You will receive a confirmation email shortly.</Box>
										</>
									) : (
										<></>
									)}
									{showMailSuccess ? (
										<>
											<Box maxW={1050}>
												Your paper mail will be sent to the mailing address we have on file for
												your property.
											</Box>
											<Box>
												<Link
													target={"_self"}
													onClick={(e) => handleContactUs(e)}>
													Contact Us
												</Link>{" "}
												to verify or update your mailing address.
											</Box>
										</>
									) : (
										<></>
									)}

									{showDefaultSuccess ? (
										<>
											<Box>Your preferences have been updated.</Box>
										</>
									) : (
										<></>
									)}
								</VStack>
							</Box>
						</HStack>
					</ModalBody>
					<ModalFooter className={"modal-footer"}>
						<Flex w={["100%", "100%", "auto"]}>
							<Button
								colorScheme='blue'
								minW={["100%", "100%", 100]}
								onClick={() => handleSuccessModalClose()}>
								OK
							</Button>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</React.Fragment>
	);
};

export default MailPreferences;
