import { createContext, useEffect, useState } from "react";

import { axiosMyPropertyAuth } from "../apis";

export const AuthContext = createContext();

const ISAUTHENTICATED_KEY = "isAuthenticated";
const UID_KEY = "uId";
const EMAILADDRESS_KEY = "emailAddress";
const PROFILENAME_KEY = "profileName";
const ISADMIN_KEY = "isAdmin";
const UNREADMAILCOUNT_KEY = "unreadMailCount";
const UNREADSHAREDMAILCOUNT_KEY = "unreadSharedMailCount";
const ACCOUNTNUMBER_KEY = "accId";
const PROPERTYCOUNT_KEY = "propertyCount";
const ADDPROPERTYMODAL_KEY = "addPropertyModal";
const INUPDATEEMAILPROCESS_KEY = "inUpdateEmailProcess";
const MOBILEPHONENUMBERATTEMPTS_KEY = "mobilePhoneNumberAttempts";
const PROFILEISNEW_KEY = "profileIsNew";
const DISPLAYPAPERLESSPROMO_KEY = "displayPaperlessPromo";
const DISPLAYPENDINGAUTHORIZEDPROPERTIES_KEY = "displayPendingAuthorizedProperties";

const AuthContextProvider = ({ children }) => {
	const [authDetails, setAuthDetails] = useState({
		isAuthenticated: sessionStorage.getItem(ISAUTHENTICATED_KEY) === "true" ? true : false,
		uId: sessionStorage.getItem(UID_KEY),
		emailAddress: sessionStorage.getItem(EMAILADDRESS_KEY),
		profileName: sessionStorage.getItem(PROFILENAME_KEY),
		isAdmin: sessionStorage.getItem(ISADMIN_KEY) === "true" ? true : false,
		unreadMailCount: sessionStorage.getItem(UNREADMAILCOUNT_KEY),
		unreadSharedMailCount: sessionStorage.getItem(UNREADSHAREDMAILCOUNT_KEY),
		accId: sessionStorage.getItem(ACCOUNTNUMBER_KEY),
		propertyCount: sessionStorage.getItem(PROPERTYCOUNT_KEY),
		addPropertyModal: sessionStorage.getItem(ADDPROPERTYMODAL_KEY),
		inUpdateEmailProcess: sessionStorage.getItem(INUPDATEEMAILPROCESS_KEY),
		mobilePhoneNumberAttempts: sessionStorage.getItem(MOBILEPHONENUMBERATTEMPTS_KEY),
		displayPaperlessPromo: sessionStorage.getItem(DISPLAYPAPERLESSPROMO_KEY),
		profileIsNew: sessionStorage.getItem(PROFILEISNEW_KEY),
		displayPendingAuthorizedProperties: sessionStorage.getItem(DISPLAYPENDINGAUTHORIZEDPROPERTIES_KEY),
	});

	useEffect(() => {
		sessionStorage.setItem(ISAUTHENTICATED_KEY, authDetails.isAuthenticated);
		sessionStorage.setItem(UID_KEY, authDetails.uId);
		sessionStorage.setItem(EMAILADDRESS_KEY, authDetails.emailAddress);
		sessionStorage.setItem(PROFILENAME_KEY, authDetails.profileName);
		sessionStorage.setItem(ISADMIN_KEY, authDetails.isAdmin);
		sessionStorage.setItem(UNREADMAILCOUNT_KEY, authDetails.unreadMailCount);
		sessionStorage.setItem(UNREADSHAREDMAILCOUNT_KEY, authDetails.unreadSharedMailCount);
		sessionStorage.setItem(ACCOUNTNUMBER_KEY, authDetails.accId);
		sessionStorage.setItem(PROPERTYCOUNT_KEY, authDetails.propertyCount);
		sessionStorage.setItem(ADDPROPERTYMODAL_KEY, authDetails.addPropertyModal);
		sessionStorage.setItem(INUPDATEEMAILPROCESS_KEY, authDetails.inUpdateEmailProcess);
		sessionStorage.setItem(MOBILEPHONENUMBERATTEMPTS_KEY, authDetails.mobilePhoneNumberAttempts);
		sessionStorage.setItem(DISPLAYPAPERLESSPROMO_KEY, authDetails.displayPaperlessPromo);
		sessionStorage.setItem(PROFILEISNEW_KEY, authDetails.profileIsNew);
		sessionStorage.setItem(DISPLAYPENDINGAUTHORIZEDPROPERTIES_KEY, authDetails.displayPendingAuthorizedProperties);
	}, [authDetails]);

	const resetAuthDetails = () => {
		setAuthDetails({
			...authDetails,
			isAuthenticated: false,
			emailAddress: "",
			profileName: "",
			uId: "",
			isAdmin: false,
			unreadMailCount: 0,
			unreadSharedMailCount: 0,
			accId: 0,
			propertyCount: 0,
			addPropertyModal: false,
			inUpdateEmailProcess: false,
			mobilePhoneNumberAttempts: 0,
			displayPaperlessPromo: "N",
			profileIsNew: "N",
			displayPendingAuthorizedProperties: "Y",
		});
	};

	const setAuthAccountNumber = (accId) => {
		setAuthDetails({
			...authDetails,
			accId: accId,
		});
	};

	const resetAuthAccountNumber = () => {
		setAuthDetails({
			...authDetails,
			accId: 0,
		});
	};

	const setAuthInUpdateEmailProcess = () => {
		setAuthDetails({
			...authDetails,
			inUpdateEmailProcess: true,
		});
	};

	const setAuthUnreadMailCount = (value) => {
		setAuthDetails({
			...authDetails,
			unreadMailCount: value,
		});
	};

	const setAuthUnreadSharedMailCount = (value) => {
		setAuthDetails({
			...authDetails,
			unreadSharedMailCount: value,
		});
	};

	const checkEmailUpdate = () => {
		if (authDetails.inUpdateEmailProcess) {
			let url = `profile`;
			axiosMyPropertyAuth
				.get(url)
				.then((resp) => {
					let tacsEmailAddress = resp.data.emailAddress;
					if (authDetails.emailAddress !== tacsEmailAddress) {
						setAuthDetails({
							...authDetails,
							emailAddress: tacsEmailAddress,
						});
					}
				})
				.catch((error) => {});
		}
	};

	return (
		<AuthContext.Provider
			value={{
				authDetails,
				setAuthDetails,
				resetAuthDetails,
				setAuthAccountNumber,
				resetAuthAccountNumber,
				setAuthInUpdateEmailProcess,
				checkEmailUpdate,
				setAuthUnreadMailCount,
				setAuthUnreadSharedMailCount,
			}}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContextProvider;
