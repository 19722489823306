import * as toastMessages from "../../../toastMessages";

import { Box, Button, Flex, FormLabel, Link, chakra, useBreakpointValue, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { axiosMyProperty } from "../../../apis";

const Verify = () => {
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });

	const [uId, setUId] = useState();
	const [emailAddress, setEmailAddress] = useState("");
	const [emailUpdated, setEmailUpdated] = useState(false);

	const [hasError, setHasError] = useState(false);
	const [hasErrorAssociated, setHasErrorAssociated] = useState(false);
	const [hasErrorExpired, setHasErrorExpired] = useState(false);
	const [hasErrorMismatch, setHasErrorMismatch] = useState(false);
	const [hasGenericError, setHasGenericError] = useState(false);

	const [isResendThankYou, setIsResendThankYou] = useState(false);
	const [isLoadingResendVerification, setIsLoadingResendVerification] = useState(false);

	let navigate = useNavigate();
	const location = useLocation();

	const toast = useToast();
	const updateEmailVerifyToastErrorId = "update-email-verify-toast-error";
	const updateEmailVerifyToastWarningId = "update-email-verify-toast-warning";

	const formLabelMinWidth = 150;

	useEffect(() => {
		let uId = new URLSearchParams(location.search).get("id");
		let emailAddress = new URLSearchParams(location.search).get("e");

		if ((uId === null) | (emailAddress === null)) {
			setHasError(true);
			setHasGenericError(true);
		} else {
			setUId(uId);
			setEmailAddress(emailAddress);
			updateEmail(uId, emailAddress);
		}
	}, []);

	const updateEmail = (uId, emailAddress) => {
		let payload = {
			uId: uId,
			newEmailAddress: emailAddress,
		};

		let url = "account/email/update";
		axiosMyProperty
			.put(url, payload)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					setEmailUpdated(true);
					setUId();
				} else {
					setHasError(true);

					switch (returnObj.errorMsg.toLowerCase()) {
						case "verify failed. email is already associated with a profile.":
							setHasErrorAssociated(true);
							// no resetStates(); here, need to use the emailAddress to for the sign in option
							break;

						case "verification period has expired":
							setHasErrorExpired(true);
							// no resetStates(); here, need to use the uId and emailAddress to resend the verification email
							break;

						case "cannot verify this email address. the email used to verify is not the same stored on the profile.":
							setHasErrorMismatch(true);
							resetStates();
							break;

						default:
							setHasGenericError(true);
							resetStates();
							break;
					}
				}
			})
			.catch((error) => {
				// error
				resetStates();
				if (!toast.isActive(updateEmailVerifyToastErrorId)) {
					toast(toastMessages.getToastError(updateEmailVerifyToastErrorId));
				}
			});
	};

	const handleResendVerification = (e) => {
		e.preventDefault();

		setIsLoadingResendVerification(true);

		let payload = {
			uId: uId,
			emailAddress: emailAddress,
		};

		let url = "profile/email/update/resend";
		axiosMyProperty
			.put(url, payload)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.isSuccess === true) {
					payload = null;
					setUId();
					resetErrors();
					setIsResendThankYou(true);
					setIsLoadingResendVerification(false);
				} else {
					payload = null;
					if (!toast.isActive(updateEmailVerifyToastWarningId)) {
						toast(
							toastMessages.getToastWarning(
								updateEmailVerifyToastWarningId,
								returnObj.errorMsg,
								toastMessages.changeEmailMessages
							)
						);
					}
					setIsLoadingResendVerification(false);
				}
			})
			.catch((e) => {
				// error
				payload = null;
				if (!toast.isActive(updateEmailVerifyToastErrorId)) {
					toast(toastMessages.getToastError(updateEmailVerifyToastErrorId));
				}
				setIsLoadingResendVerification(false);
			});
	};

	const resetErrors = () => {
		setHasError(false);
		setHasErrorAssociated(false);
		setHasErrorExpired(false);
		setHasErrorMismatch(false);
		setHasGenericError(false);
	};

	const resetStates = () => {
		setUId();
		setEmailAddress();
	};

	const handleSignIn = (e) => {
		e.preventDefault();
		navigate(`/sign-in?emailAddress=${emailAddress}`, { replace: true });
	};

	return (
		<React.Fragment>
			{emailUpdated ? (
				<Flex direction={["column", "column", "row"]}>
					<Box
						minW={formLabelMinWidth}
						display={isSmallerScreen ? "none" : "normal"}>
						<FormLabel>&nbsp;</FormLabel>
					</Box>
					<Box>
						<Flex direction={["column"]}>
							<Box>
								<chakra.span
									fontSize={"1.45em"}
									fontWeight={400}>
									Thank You. Now, sign In.
								</chakra.span>
							</Box>
							<Box mt={2}>
								<Box maxW={550}>
									Your email has been updated on MyProperty. Please sign in with your new email to go
									to the MyProperty website.
								</Box>
								<Box mt={4}>
									<Button
										colorScheme={"blue"}
										w={100}
										onClick={(e) => handleSignIn(e, true)}>
										SIGN IN
									</Button>
								</Box>
							</Box>
						</Flex>
					</Box>
				</Flex>
			) : null}

			{isResendThankYou ? (
				<Flex direction={["column", "column", "row"]}>
					<Box
						minW={formLabelMinWidth}
						display={isSmallerScreen ? "none" : "normal"}>
						<FormLabel>&nbsp;</FormLabel>
					</Box>
					<Box>
						<Flex direction={["column"]}>
							<Box>
								<chakra.span
									fontSize={"1.45em"}
									fontWeight={400}>
									Update Email Verification
								</chakra.span>
							</Box>
							<Box>
								<Flex direction={["column"]}>
									<Box mt={2}>
										<Box>Thank you.</Box>A new verification email has been sent to{" "}
										<chakra.span fontWeight={700}>{emailAddress}</chakra.span>.
									</Box>
								</Flex>
							</Box>
						</Flex>
					</Box>
				</Flex>
			) : null}

			{hasError ? (
				<React.Fragment>
					<Flex direction={["column", "column", "row"]}>
						<Box
							minW={formLabelMinWidth}
							display={isSmallerScreen ? "none" : "normal"}>
							<FormLabel>&nbsp;</FormLabel>
						</Box>
						<Box>
							<Flex direction={["column"]}>
								<Box>
									<chakra.span
										fontSize={"1.45em"}
										fontWeight={400}>
										Update Email Verification
									</chakra.span>
								</Box>
								<Box mt={2}>
									<Box maxW={600}>
										{hasErrorAssociated ? (
											<Box>
												Sorry, we are unable to verify this email. It appears to already be
												associated with another MyProperty account. Try{" "}
												<Link onClick={(e) => handleSignIn(e)}>signing in</Link> with this
												account or email{" "}
												<Link
													href={"mailto:taxes@edmonton.ca"}
													rel={"noopener"}
													target={"_blank"}>
													taxes@edmonton.ca
												</Link>{" "}
												for assistance.
											</Box>
										) : null}
										{hasErrorExpired ? (
											<Box>
												<Box>
													The verification link has expired. Send yourself a new verification
													email.
												</Box>
												<Box mt={3}>
													<Button
														isLoading={isLoadingResendVerification}
														loadingText={"SEND NEW VERIFICATION EMAIL"}
														colorScheme={"blue"}
														onClick={(e) => handleResendVerification(e, true)}>
														SEND NEW VERIFICATION EMAIL
													</Button>
												</Box>
											</Box>
										) : null}
										{hasGenericError | hasErrorMismatch ? (
											<Box>
												Oops... Something went wrong while trying to process the request. Please
												check your verification email and try again.
											</Box>
										) : null}
									</Box>
								</Box>
							</Flex>
						</Box>
					</Flex>
				</React.Fragment>
			) : null}
		</React.Fragment>
	);
};

export default Verify;
