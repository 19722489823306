import { Badge, Box, Skeleton, chakra } from "@chakra-ui/react";

import React from "react";

const AddressAccount = ({ accId, accountDetails }) => {
	return (
		<React.Fragment>
			{accountDetails !== undefined ? (
				<Box mb={2}>
					<chakra.span
						fontSize={"1.45em"}
						fontWeight={400}>
						{accountDetails.address}; Account {accId}
					</chakra.span>
					{accountDetails.accessType === "VIEWER" ? (
						<>
							<Badge
								ml={2}
								mb={0.5}
								bg={"#e9eaea"}
								color={"#004060"}>
								Shared With Me
							</Badge>
						</>
					) : null}
				</Box>
			) : (
				<Box mb={2}>
					<Skeleton
						startColor='#e2e2e2'
						endColor='#bbbbbb'
						size='8'
						width={700}
						height={38}></Skeleton>
				</Box>
			)}
		</React.Fragment>
	);
};

export default AddressAccount;
