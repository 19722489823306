import { createContext, useEffect, useState } from "react";

import { axiosMyPropertyAuth } from "../apis";

export const BannerContext = createContext();

const BANNERITEMS_KEY = "bannerItems";
const ALLBANNERCLOSED_KEY = "allBannerClosed";

const BannerContextProvider = ({ children }) => {
	const [bannerItems, setBannerItems] = useState(JSON.parse(sessionStorage.getItem(BANNERITEMS_KEY)));
	const [allBannerClosed, setAllBannerClosed] = useState(sessionStorage.getItem(ALLBANNERCLOSED_KEY));

	useEffect(() => {
		sessionStorage.setItem(BANNERITEMS_KEY, JSON.stringify(bannerItems));
	}, [bannerItems]);

	useEffect(() => {
		sessionStorage.setItem(ALLBANNERCLOSED_KEY, allBannerClosed);
	}, [allBannerClosed]);

	const getBannerItems = () => {
		let localStorage = sessionStorage.getItem(BANNERITEMS_KEY);
		localStorage = JSON.parse(localStorage);
		if (localStorage !== undefined && localStorage !== null) {
			if (localStorage.length !== 0) {
				setBannerItems(localStorage);
			} else {
				if (!allBannerClosed) {
					getAndSetBanners();
				}
			}
		} else {
			getAndSetBanners();
		}
	};

	const getAndSetBanners = () => {
		let url = `banner`;
		axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					if (resp.data.items.length > 0) {
						setBannerItems(resp.data.items);
						setAllBannerClosed(false);
					} else {
						setBannerItems([]);
						setAllBannerClosed(true);
					}
				} else {
					setBannerItems([]);
					setAllBannerClosed(true);
				}
			})
			.catch((error) => {
				// error
				setBannerItems([]);
				setAllBannerClosed(true);
			});
	};

	const closeBannerItem = (id, messageType, closureType) => {
		if (closureType === "perm") {
			// perm closure
			let payload = {
				uId: "",
				bannerMsgType: messageType,
			};

			let url = `banner`;
			axiosMyPropertyAuth.put(url, payload);
		}

		// update the context
		removeFromBannerItems(id);
	};

	const removeFromBannerItems = (id) => {
		// get the current list of bannerItems to modify
		let currentBannerItems = [...bannerItems];

		// filter out the banner item by id value
		const updatedBannerItems = [
			...currentBannerItems.filter((x) => {
				return x.id !== id;
			}),
		];

		if (updatedBannerItems.length === 0) {
			setAllBannerClosed(true);
		}

		setBannerItems(updatedBannerItems);
	};

	const resetBannerItems = () => {
		sessionStorage.setItem(BANNERITEMS_KEY, JSON.stringify([]));
		setBannerItems([]);
		setAllBannerClosed(false);
	};

	const refreshBannerItem = (id) => {
		//get all banners from db and remove specific banner locally if it no longer exists in the db
		
		let url = `banner`;
		axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					var allBanners = resp.data.items;
					if (allBanners?.length > 0) {
						//check if banner item still exists
						var isExists = allBanners.some((x) => x.id === id);

						if (!isExists) {
							//remove banner item from context
							removeFromBannerItems(id);
						}
					} else {
						removeFromBannerItems(id);
					}
				}
			})
			.catch((error) => {
				// error
			});
	};

	return (
		<BannerContext.Provider
			value={{ bannerItems, getBannerItems, closeBannerItem, resetBannerItems, refreshBannerItem }}>
			{children}
		</BannerContext.Provider>
	);
};

export default BannerContextProvider;
