import * as toastMessages from "../../../../../../../toastMessages";

import {
	Box,
	Button,
	Center,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Stack,
	Text,
	useBreakpointValue,
	useDisclosure,
	useMediaQuery,
	useToast,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";

import { AuditContext } from "../../../../../../../contexts/AuditContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputMask from "react-input-mask";
import Markdown from "../../../../../../Global/Markdown/Markdown";
import { axiosMyPropertyAuth } from "../../../../../../../apis";
import { useForm } from "react-hook-form";

const CrmRequest = ({ accId, emailAddress, crmDetail, applicationDetail, applicationReason }) => {
	const { auditActivity } = useContext(AuditContext);

	const isSmallerScreen = useBreakpointValue({ base: true, md: false });
	const [isLargerThan480] = useMediaQuery("(min-width: 480px)");
	const { isOpen: isOpenCrm, onOpen: onOpenCrm, onClose: onCloseCrm } = useDisclosure();

	const toast = useToast();
	const mppCrmToastWarningId = "tax-crm-toast-warning";

	const [isRequestApplicationStep1, setIsRequestApplicationStep1] = useState(true);
	const [isRequestApplicationStep2, setIsRequestApplicationStep2] = useState(false);

	const {
		register: registerCrm,
		handleSubmit: handleSubmitCrm,
		reset: resetCrm,
		setValue,
		formState: { errors: errorsCrm },
	} = useForm({
		defaultValues: {
			accId: accId,
			emailAddress: emailAddress,
		},
	});

	const formLabelMinWidth = 200;
	const formControlMinWidth = 250;
	const formControlMinWidthLg = 420;

	const handleCrmOpen = async (e) => {
		e.preventDefault();
		await getUserProfile();
		auditActivity("MPP - Clicked Request Application");
		setIsRequestApplicationStep1(true);
		setIsRequestApplicationStep2(false);
		onOpenCrm();
	};

	const getUserProfile = async () => {
		let url = `profile`;
		await axiosMyPropertyAuth.get(url).then((resp) => {
			let returnObj = resp.data;
			if (returnObj.retVal === "true") {
				setValue("phoneNumber", returnObj.smsPhoneNum !== null ? returnObj.smsPhoneNum : "");
			} else {
				setValue("phoneNumber", "");
			}
		});
	};

	const handleCrmClose = () => {
		//e.preventDefault();
		onCloseCrm();
		resetCrm();
		setIsRequestApplicationStep1(true);
		setIsRequestApplicationStep2(false);
	};

	const handleCrmClear = (e) => {
		e.preventDefault();
		resetCrm();
	};

	const handleCrmSubmit = (data) => {
		let payload = {
			accId: accId.toString(),
			firstName: data.firstName !== null ? data.firstName.trim() : "",
			lastName: data.lastName !== null ? data.lastName.trim() : "",
			phoneNumber: data.phoneNumber !== null ? data.phoneNumber.trim().replaceAll("-", "") : "",
			emailAddress: emailAddress,
			unitNumber: crmDetail.suiteNum !== null ? crmDetail.suiteNum.trim() : "",
			houseNumber: crmDetail.houseNum !== null ? crmDetail.houseNum.trim() : "",
			streetNumber: crmDetail.street !== null ? crmDetail.street.trim() : "",
			citySection:
				crmDetail.street !== null
					? crmDetail.street.substring(crmDetail.street.length, crmDetail.street.length - 2)
					: "",
			isCondoTitleParking: crmDetail.condoAccessoryFlag === "Y" ? true : false,
			reason: applicationReason,
		};

		let url = "taxes/mpp/crm";
		axiosMyPropertyAuth.post(url, payload).then((resp) => {
			let returnObj = resp.data;
			if (returnObj.isSuccess) {
				setIsRequestApplicationStep1(false);
				setIsRequestApplicationStep2(true);
			} else {
				setIsRequestApplicationStep1(true);
				setIsRequestApplicationStep2(false);

				if (!toast.isActive(mppCrmToastWarningId)) {
					toast(
						toastMessages.getToastWarning(
							mppCrmToastWarningId,
							"cannot sent email",
							toastMessages.crmTicketMessages
						)
					);
				}
			}
		});
	};

	return (
		<React.Fragment>
			<Button
				mt={4}
				mb={4}
				colorScheme='lightBlue'
				w={!isLargerThan480 ? "100%" : "50%"}
				onClick={(e) => {
					handleCrmOpen(e);
				}}>
				Request Your Application
			</Button>

			<Modal
				size={"4xl"}
				closeOnEsc={false}
				closeOnOverlayClick={false}
				isOpen={isOpenCrm}
				onClose={handleCrmClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className={"modal-header"}>Request Your Application</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					<ModalBody>
						{isRequestApplicationStep1 ? (
							<React.Fragment>
								<Box mb={6}>
									<Text fontSize={"xl"}>We just need a little more information from you.</Text>
								</Box>
								{/* <form> */}
								<FormControl
									isInvalid={errorsCrm.accId}
									mt={2}>
									<Flex direction={["column", "column", "row"]}>
										<Box minW={formLabelMinWidth}>
											<FormLabel
												htmlFor='accId'
												textAlign={isSmallerScreen ? "" : "end"}
												className='form-label-fixed'>
												Account Number
											</FormLabel>
										</Box>
										<Box minW={!isLargerThan480 ? "" : formControlMinWidth}>
											<Input
												readOnly
												id='accId'
												type='number'
												autoComplete='off'
												{...registerCrm("accId")}
											/>
										</Box>
									</Flex>
								</FormControl>
								<FormControl
									isInvalid={errorsCrm.emailAddress}
									mt={2}>
									<Flex direction={["column", "column", "row"]}>
										<Box minW={formLabelMinWidth}>
											<FormLabel
												htmlFor='emailAddress'
												textAlign={isSmallerScreen ? "" : "end"}
												className='form-label-fixed'>
												Your Email
											</FormLabel>
										</Box>
										<Box minW={!isLargerThan480 ? "" : formControlMinWidthLg}>
											<Input
												readOnly
												id='emailAddress'
												type='text'
												autoComplete='off'
												{...registerCrm("emailAddress")}
											/>
										</Box>
									</Flex>
								</FormControl>
								<FormControl
									isInvalid={errorsCrm.firstName}
									mt={2}>
									<Flex direction={["column", "column", "row"]}>
										<Box minW={formLabelMinWidth}>
											<FormLabel
												htmlFor='firstName'
												textAlign={isSmallerScreen ? "" : "end"}
												className='form-label-fixed'>
												First Name
											</FormLabel>
										</Box>
										<Box minW={!isLargerThan480 ? "" : formControlMinWidth}>
											<Input
												id='firstName'
												type='text'
												autoComplete='off'
												{...registerCrm("firstName", {
													required: "First Name is required",
												})}
											/>
										</Box>
										<Box ml={isSmallerScreen ? 0 : 3}>
											<FormErrorMessage
												colorScheme={"red"}
												className={"text-error pos"}>
												{errorsCrm.firstName && errorsCrm.firstName.message}
											</FormErrorMessage>
										</Box>
									</Flex>
								</FormControl>
								<FormControl
									isInvalid={errorsCrm.lastName}
									mt={2}>
									<Flex direction={["column", "column", "row"]}>
										<Box minW={formLabelMinWidth}>
											<FormLabel
												htmlFor='lastName'
												textAlign={isSmallerScreen ? "" : "end"}
												className='form-label-fixed'>
												Last Name
											</FormLabel>
										</Box>
										<Box minW={!isLargerThan480 ? "" : formControlMinWidth}>
											<Input
												id='lastName'
												type='text'
												autoComplete='off'
												{...registerCrm("lastName", {
													required: "Last Name is required",
												})}
											/>
										</Box>
										<Box ml={isSmallerScreen ? 0 : 3}>
											<FormErrorMessage
												colorScheme={"red"}
												className={"text-error pos"}>
												{errorsCrm.lastName && errorsCrm.lastName.message}
											</FormErrorMessage>
										</Box>
									</Flex>
								</FormControl>
								<FormControl
									isInvalid={errorsCrm.phoneNumber}
									mt={2}>
									<Flex direction={["column", "column", "row"]}>
										<Box minW={formLabelMinWidth}>
											<FormLabel
												htmlFor='phoneNumber'
												textAlign={isSmallerScreen ? "" : "end"}
												className='form-label-fixed'>
												Daytime Phone Number
											</FormLabel>
										</Box>
										<Box minW={!isLargerThan480 ? "" : formControlMinWidth}>
											<Input
												id='phoneNumber'
												type='tel'
												as={InputMask}
												mask='999-999-9999'
												autoComplete='off'
												{...registerCrm("phoneNumber", {
													required: "Daytime Phone Number is required",
													pattern: {
														value: /^[\d]{3}?-?[\d]{3}-?[\d]{4}$/g,
														message: "Daytime Phone Number is formatted incorrectly",
													},
												})}
											/>
										</Box>
										<Box ml={isSmallerScreen ? 0 : 3}>
											<FormErrorMessage
												colorScheme={"red"}
												className={"text-error pos"}>
												{errorsCrm.phoneNumber && errorsCrm.phoneNumber.message}
											</FormErrorMessage>
										</Box>
									</Flex>
								</FormControl>
								{/* <FormControl mt={isSmallerScreen ? 6 : 3} mb={isSmallerScreen ? 6 : 3}>
									<Flex direction={["column", "column", "row"]}>
										<Box minW={formLabelMinWidth} display={isSmallerScreen ? "none" : "normal"}>
											<FormLabel>&nbsp;</FormLabel>
										</Box>
										<Box>
											<Flex direction={["column", "column", "row"]}>
												<Button
													colorScheme='blue'
													width={isSmallerScreen ? "100%" : 100}
													mr={isSmallerScreen ? 0 : 4}
													onClick={handleSubmitCrm((data) => handleCrmSubmit(data))}>
													SUBMIT
												</Button>
												<Button
													type='reset'
													colorScheme='grey'
													variant={"outline"}
													width={isSmallerScreen ? "100%" : 100}
													mr={isSmallerScreen ? 0 : 2}
													mt={isSmallerScreen ? 6 : 0}
													onClick={(e) => handleCrmClear(e)}>
													CLEAR
												</Button>
											</Flex>
										</Box>
									</Flex>
								</FormControl> */}
								{/* </form> */}
							</React.Fragment>
						) : null}
						{isRequestApplicationStep2 ? (
							<React.Fragment>
								<ModalBody className={"mpp-modal-body"}>
									<Box
										mt={2}
										mb={2}>
										<Stack
											direction={["column", "row"]}
											spacing='36px'>
											<Box>
												<Center h={"100%"}>
													<FontAwesomeIcon
														className='mpp-success-icon'
														icon='fas fa-check-circle'
													/>
												</Center>
											</Box>
											<Box>
												<Stack direction={["column"]}>
													<Box>
														<Text fontSize={"lg"}>
															<Markdown
																text={
																	applicationDetail.mppApplError.requestSent1Msg
																}></Markdown>
														</Text>
													</Box>
													<Box>
														<Text>
															<Markdown
																text={
																	applicationDetail.mppApplError.requestSent2Msg
																}></Markdown>
														</Text>
													</Box>
												</Stack>
											</Box>
										</Stack>
									</Box>
								</ModalBody>
							</React.Fragment>
						) : null}
					</ModalBody>
					<ModalFooter
						className={"modal-footer"}
						mt={2}>
						{isRequestApplicationStep1 ? (
							<>
								<Flex
									direction={["column", "column", "row"]}
									w={["100%", "100%", "auto"]}>
									<Button
										colorScheme='blue'
										minW={["100%", "100%", 100]}
										mr={[0, 0, 2]}
										onClick={handleSubmitCrm((data) => handleCrmSubmit(data))}>
										SUBMIT
									</Button>
									<Button
										type='reset'
										colorScheme='grey'
										variant={"outline"}
										//width={isSmallerScreen ? "100%" : 100}
										minW={["100%", "100%", 100]}
										mt={[2, 2, 0]}
										onClick={(e) => handleCrmClear(e)}>
										CLEAR
									</Button>
								</Flex>
							</>
						) : null}
						{/* <Button
							colorScheme='blue'
							size={"sm"}
							onClick={(e) => handleCrmClose(e)}>
							Close
						</Button> */}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</React.Fragment>
	);
};

export default CrmRequest;
