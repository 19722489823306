import * as CommonFns from "../../../../../../common";

import { Box, Button, FormControl, FormLabel, SimpleGrid, Stack, Text, useBreakpointValue } from "@chakra-ui/react";
import React, { useContext, useState } from "react";

import Application from "./Application/Application";
import { AuditContext } from "../../../../../../contexts/AuditContext";
import { AuthContext } from "../../../../../../contexts/AuthContext";
import Markdown from "../../../../../Global/Markdown/Markdown";

const MonthlyPaymentPlan = ({ accId, mppDetails }) => {
	const [mppChangeUrl] = useState(process.env.REACT_APP_MONTHLY_PAYMENT_PLAN_CHANGE_URL);

	const isSmallerScreen = useBreakpointValue({ base: true, md: false });

	const { authDetails } = useContext(AuthContext);
	const { auditActivity } = useContext(AuditContext);

	const handleViewMppUrl = (e, url) => {
		e.preventDefault();
		window.open(url, "_blank", "noopener");
	};

	return (
		<Box
			pb={2}
			pl={3}
			pr={3}>
			<React.Fragment>
				{mppDetails.mppEnrolledFlag === "Y" || mppDetails.mppEnrolledFlag === "P" ? (
					<React.Fragment>
						<Box maxW={950}>
							<Text fontSize={"xl"}>
								{mppDetails.mppEnrolledFlag === "Y" ? (
									<>Thank you for being a part of the City's Property Tax Monthly Payment Plan.</>
								) : (
									<>Thank you for applying for the City's Property Tax Monthly Payment Plan.</>
								)}
							</Text>
						</Box>
						<Box
							mt={4}
							mb={3}>
							<SimpleGrid columns={{ sm: 1, md: 2 }}>
								{mppDetails.mppEnrolledDataAvailableFlag === "Y" ? (
									<Box
										w={isSmallerScreen ? "100%" : "75%"}
										paddingLeft={isSmallerScreen ? 0 : 5}>
										<Box>
											<Stack>
												<Box mb={1}>
													<Text>Your next automatic withdrawal will be:</Text>
												</Box>
												<FormControl>
													<Stack
														direction={"row"}
														spacing={{ base: "1.5", md: "8" }}
														justify='space-between'>
														<FormLabel
															variant='inline'
															mb={0}
															fontSize={"1em"}>
															Withdrawal Amount
														</FormLabel>
														<Text
															fontSize={"1em"}
															mb={0}
															fontWeight={700}
															textAlign={"end"}>
															{CommonFns.FormatCurrency(
																mppDetails.mppWithdrawalAmount,
																2
															)}
														</Text>
													</Stack>
												</FormControl>
												<FormControl>
													<Stack
														direction={"row"}
														spacing={{ base: "1.5", md: "8" }}
														justify='space-between'>
														<FormLabel
															variant='inline'
															mb={0}
															fontSize={"1em"}>
															Withdrawal Date
														</FormLabel>
														<Text
															fontSize={"1em"}
															mb={0}
															fontWeight={700}
															textAlign={"end"}>
															{CommonFns.FormatDate(mppDetails.mppWithdrawalDate)}
														</Text>
													</Stack>
												</FormControl>
											</Stack>
										</Box>
									</Box>
								) : (
									<Box
										w={isSmallerScreen ? "100%" : "75%"}
										paddingLeft={isSmallerScreen ? 0 : 5}>
										<Text mb={0}>
											<Markdown text={mppDetails.mppEnrolledMsgLeft}></Markdown>
										</Text>
									</Box>
								)}
								<Box
									w={isSmallerScreen ? "100%" : "84%"}
									borderLeft={isSmallerScreen ? "0px" : "2px"}
									paddingLeft={isSmallerScreen ? 0 : 20}
									mt={isSmallerScreen ? 5 : 0}>
									<Stack>
										<Box mb={2}>
											<Text>
												<Markdown text={mppDetails.mppEnrolledMsgRight}></Markdown>
											</Text>
										</Box>
										<Box textAlign={"end"}>
											<Button
												colorScheme='lightBlue'
												as='button'
												minW={"5rem"}
												size={"xs"}
												onClick={(e) => {
													auditActivity("Request Change MPP", accId);
													handleViewMppUrl(e, mppChangeUrl);
												}}>
												View
											</Button>
										</Box>
									</Stack>
								</Box>
							</SimpleGrid>
						</Box>
					</React.Fragment>
				) : (
					<React.Fragment>
						<Box mb={1}>
							<Box maxW={950}>
								<Text fontSize={"xl"}>
									The City of Edmonton offers a Property Tax Monthly Payment Plan that enables you to
									pay your taxes monthly, instead of in one annual payment.
								</Text>
								<Text mt={3}>
									After you enrol in this plan, your monthly property tax amount will be withdrawn
									directly from your bank account on the first business day of each month. By paying
									your property taxes monthly through this plan, you can be certain your property
									taxes are paid in full each year and you will not incur any late-payment penalties.
								</Text>
							</Box>
							<Box
								mt={3}
								textAlign={"right"}>
								<Application
									accId={accId}
									emailAddress={authDetails.emailAddress}></Application>
							</Box>
						</Box>
					</React.Fragment>
				)}
			</React.Fragment>
		</Box>
	);
};

export default MonthlyPaymentPlan;
