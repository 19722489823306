import "./ViewPdf.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import { Box, Button, Flex, VStack, useBreakpointValue } from "@chakra-ui/react";
import { Document, Page, pdfjs } from "react-pdf";
import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ViewPdf = ({ pdfBase64 }) => {
	const isSmallerScreen = useBreakpointValue({ base: true, lg: false });
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [loadError, setLoadError] = useState(false);

	const onPdfLoadSuccess = (pdf) => {
		setNumPages(pdf.numPages);
		setPageNumber(1);
		setLoadError(false);
	};

	const onPdfLoadError = () => {
		setLoadError(true);
	};

	function changePage(offset) {
		setPageNumber((prevPageNumber) => prevPageNumber + offset);
	}

	const previousPage = (e) => {
		e.preventDefault();
		changePage(-1);
	};

	const nextPage = (e) => {
		e.preventDefault();
		changePage(1);
	};

	return (
		<React.Fragment>
			{!loadError ? (
				<Box>
					<VStack>
						<Box
							className="view-pdf-container"
							overflow={"scroll"}
							overflowX={isSmallerScreen ? "scroll" : "hidden"}
							height={600}
							width={"100%"}
						>
							<Document
								file={`data:application/pdf;base64,${pdfBase64}`}
								onLoadSuccess={onPdfLoadSuccess}
								onLoadError={onPdfLoadError}
							>
								<Page pageNumber={pageNumber} scale={1.7} />
							</Document>
						</Box>

						<Box pt={4} pb={4}>
							<Flex alignItems={"center"}>
								<Box>
									<Button
										colorScheme={"lightBlue"}
										isDisabled={pageNumber <= 1}
										onClick={(e) => previousPage(e)}
										size={"sm"}
									>
										<FontAwesomeIcon icon="fa-solid fa-chevron-left" />
									</Button>
								</Box>
								<Box>
									<Box ml={2} mr={2} fontSize={".9em"} lineHeight={".9em"}>
										<VStack alignItems={"center"}>
											<Box>Page</Box>
											<Box>
												{pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
											</Box>
										</VStack>
									</Box>
								</Box>
								<Box>
									<Button
										colorScheme={"lightBlue"}
										isDisabled={pageNumber === numPages}
										onClick={(e) => nextPage(e)}
										size={"sm"}
									>
										<FontAwesomeIcon icon="fa-solid fa-chevron-right" />
									</Button>
								</Box>
							</Flex>
						</Box>
					</VStack>
				</Box>
			) : (
				<Box>The selected mail item is not available at this time. Please try again later.</Box>
			)}
		</React.Fragment>
	);
};

export default ViewPdf;
