import "./Message.css";

import { Box } from "@chakra-ui/react";
import Markdown from "../../../../../Global/Markdown/Markdown";
import React from "react";

const Message = ({ taxTabMessage }) => {
	return (
		<React.Fragment>
			<Box className="taxes-announcement taxes-announcement-light-blue">
				<Box maxW={1050}>
					<Markdown text={taxTabMessage}></Markdown>
				</Box>
			</Box>
		</React.Fragment>
	);
};

export default Message;
