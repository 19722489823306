import { Box } from "@chakra-ui/react";
import React from "react";

const MultiUsePropertyUseLabel = () => {
	return (
		<Box
			mt={2.5}
			ml={2}
			fontWeight={700}
			fontSize={".7em"}>
			<Box color={"#6b6b6b"}>Property Use</Box>
		</Box>
	);
};

export default MultiUsePropertyUseLabel;
