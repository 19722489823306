import { Box, Button, Flex, useBreakpointValue } from "@chakra-ui/react";

import React from "react";
import { useNavigate } from "react-router-dom";

const PropertyOwner = () => {
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });
	let navigate = useNavigate();

	const handleSignIn = (event) => {
		event.preventDefault();
		navigate("/sign-in", { replace: true });
	};
	const handleCreateProfile = (event) => {
		event.preventDefault();
		navigate("/create-profile", { replace: true });
	};
	return (
		<React.Fragment>
			<Box className='sign-in-container'>
				<Flex
					direction={["column", "column", "row", "row"]}
					alignItems={"center"}>
					<Box w={isSmallerScreen ? "100%" : 550}>
						<Flex direction={["column"]}>
							<Box className='sign-in-title'>MyProperty Account Access</Box>
							<Box className='sign-in-text'>
								Create an account and add your property to access detailed property assessment and tax
								information.
							</Box>
						</Flex>
					</Box>
					<Box
						w={isSmallerScreen ? "100%" : 235}
						ml={isSmallerScreen ? 4 : 10}
						mt={isSmallerScreen ? 2 : 4}
						mb={isSmallerScreen ? 2 : 0}
						mr={4}>
						<Box>
							<Button
								colorScheme='blue'
								w={"100%"}
								onClick={handleSignIn}>
								SIGN IN
							</Button>
						</Box>
						<Box mt={2}>
							<Button
								colorScheme='blue'
								className='sign-in-button'
								onClick={handleCreateProfile}>
								CREATE PROFILE
							</Button>
						</Box>
					</Box>
				</Flex>
			</Box>
		</React.Fragment>
	);
};

export default PropertyOwner;
