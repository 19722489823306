import './History.css';

import * as toastMessages from '../../../../../toastMessages';

import { Box, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import AssessmentChange from './AssessmentChange/AssessmentChange';
import AssessmentTaxRelationship from './AssessmentTaxRelationship/AssessmentTaxRelationship';
import Markdown from '../../../../Global/Markdown/Markdown';
import TaxesPaid from './TaxesPaid/TaxesPaid';
import { axiosMyPropertyAuth } from '../../../../../apis';

const History = ({ accId }) => {
	const toast = useToast();
	const historyToastErrorId = 'history-toast-error';
	const [displayAttentionMessage, setDisplayAttentionMessage] = useState(false);
	const [attentionMessage, setAttentionMessage] = useState('');
	const [displayHistory, setDisplayHistory] = useState(false);
	const [historyMessages, setHistoryMessages] = useState({});
	const [historyDetails, setHistoryDetails] = useState({});
	const [historyTaxesPercentThreshold] = useState(process.env.REACT_APP_HISTORY_TAXES_PERCENT_THRESHOLD);
	const [assessmentChartMessage, setAssessmentChartMessage] = useState('');
	const [assessmentScenarioMessage, setAssessmentScenarioMessage] = useState('');
	const [assessmentScenarioAttnMessage, setAssessmentScenarioAttnMessage] = useState('');
	const [taxIntroMessage, setTaxIntroMessage] = useState('');
	const [taxChartMessage, setTaxChartMessage] = useState('');
	const [taxScenarioMessage, setTaxScenarioMessage] = useState('');
	const [propertyTotal, setPropertyTotal] = useState(0);
	const [municipalTotal, setMunicipalTotal] = useState(0);
	const [totalGreaterThan15000, setTotalGreaterThan15000] = useState(false);
	const [totalGreaterThan60000, setTotalGreaterThan60000] = useState(false);

	useEffect(() => {
		if (accId !== undefined) {
			getHistory();
		}
	}, [accId]);

	const getHistory = () => {
		let url = `history/${accId}`;
		axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;
				setHistoryDetails(returnObj);

				let webAsmtHistCol = returnObj.webAsmtHistCol || [];
				let webTaxHistCol = returnObj.webTaxHistCol || [];

				if (webAsmtHistCol.length === 0 && webTaxHistCol.length === 0) {
					setDisplayHistory(false);
					setDisplayAttentionMessage(true);
				} else {
					setDisplayHistory(true);
					getHistoryMessages(returnObj);
					if (returnObj.webAsmtHistCol.some((x) => x.amendedFlag === 'Y') || returnObj.webTaxHistCol.some((x) => x.amendedFlag === 'Y')) {
						setDisplayAttentionMessage(true);
					}
				}

				setAttentionMessage(returnObj.msg ?? '');
			})
			.catch((error) => {
				// error
				if (!toast.isActive(historyToastErrorId)) {
					toast(toastMessages.getToastError(historyToastErrorId));
				}
			});
	};

	const getHistoryMessages = (details) => {
		let url = `history/messages`;
		axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === 'true') {
					setHistoryMessages(returnObj);
					//manage data display
					manageDataDisplay(details, returnObj);
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(historyToastErrorId)) {
					toast(toastMessages.getToastError(historyToastErrorId));
				}
			});
	};

	const manageDataDisplay = (details, messages) => {
		let propTotal = 0;
		let muniTotal = 0;

		details.webTaxHistCol?.forEach((x) => {
			propTotal += (x.myPropTaxTotal == null) | (x.baselineRecordFlag === 'Y') ? 0 : x.myPropTaxTotal;
			muniTotal += (x.cityTaxTotal == null) | (x.baselineRecordFlag === 'Y') ? 0 : x.cityTaxTotal;

			if (x.myPropTaxTotal > 15000 || x.cityTaxTotal > 15000) {
				setTotalGreaterThan15000(true);
			}

			if (x.myPropTaxTotal > 60000 || x.cityTaxTotal > 60000) {
				setTotalGreaterThan60000(true);
			}
		});

		setPropertyTotal(propTotal);
		setMunicipalTotal(muniTotal);

		if (propTotal !== 0 && muniTotal !== 0) {
			manageDisplayScenario(propTotal, muniTotal, messages);
		} else {
			setDisplayHistory(false);
		}
	};

	const manageDisplayScenario = (propertyTotal, municipalTotal, messages) => {
		let percentCalculated = 0;
		let absoluteCalculated = 0;

		percentCalculated = (propertyTotal - municipalTotal) / municipalTotal;
		absoluteCalculated = Math.abs(percentCalculated);

		if (absoluteCalculated <= historyTaxesPercentThreshold) {
			//set Limit Within Text
			setAssessmentScenarioMessage(messages.web5yr1AsmtWithin5Pct);
		} else {
			if (percentCalculated < 0) {
				//set Limit Below Text
				setAssessmentScenarioMessage(messages.web5yr1AsmtUnder5Pct);
			} else {
				//set Limit Above Text
				setAssessmentScenarioMessage(messages.web5yr1AsmtOver5Pct);
				setAssessmentScenarioAttnMessage(messages.web5yr1AsmtOver5Det);
			}
		}

		//set other messages
		setAssessmentChartMessage(messages.web5yr2AsmtChartMessage);
		setTaxIntroMessage(messages.web5yr8TaxIntro);
		setTaxChartMessage(messages.web5yr10TaxChartMessage);
		setTaxScenarioMessage(messages.web5yr9TaxDetails);
	};

	return (
		<React.Fragment>
			{displayAttentionMessage ? (
				<>
					<Box className="announcement announcement-light-blue">
						<Box maxW={1050}>
							<Markdown text={attentionMessage}></Markdown>
						</Box>
					</Box>
				</>
			) : (
				<></>
			)}

			{displayHistory ? (
				<>
					<Box>
						<AssessmentChange accId={accId} historyDetails={historyDetails} scenarioMessage={assessmentScenarioMessage} scenarioAttnMessage={assessmentScenarioAttnMessage} chartMessage={assessmentChartMessage} />
					</Box>

					<Box mt={10}>
						<AssessmentTaxRelationship historyMessages={historyMessages}></AssessmentTaxRelationship>
					</Box>

					<Box mt={10}>
						<TaxesPaid
							accId={accId}
							historyDetails={historyDetails}
							scenarioMessage={taxScenarioMessage}
							taxIntroMessage={taxIntroMessage}
							chartMessage={taxChartMessage}
							propertyTotal={propertyTotal}
							municipalTotal={municipalTotal}
							totalGreaterThan15000={totalGreaterThan15000}
							totalGreaterThan60000={totalGreaterThan60000}
						/>
					</Box>
				</>
			) : (
				<></>
			)}
		</React.Fragment>
	);
};
export default History;
