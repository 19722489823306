import "../../../Global/Styles/Accordion.css";

import { Box, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs, chakra, useMediaQuery } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import AddressAccount from "./AddressAccount/AddressAccount";
import Assessment from "./Assessment/Assessment";
import { AuditContext } from "../../../../contexts/AuditContext";
import { AuthContext } from "../../../../contexts/AuthContext";
import History from "./History/History";
import Message from "./Taxes/Message/Message";
import Taxes from "./Taxes/Taxes";
import { axiosMyPropertyAuth } from "../../../../apis";
import { useNavigate } from "react-router-dom";

const Detail = () => {
	const { authDetails } = useContext(AuthContext);
	const { auditActivity } = useContext(AuditContext);
	let navigate = useNavigate();

	const [accId, setAccId] = useState();
	const [tabIndex, setTabIndex] = useState(0);
	const [toggleTaxEstimator, setToggleTaxEstimator] = useState(false);
	const [toggleYourPropertyTaxes, setToggleYourPropertyTaxes] = useState(false);
	const [accountDetails, setAccountDetails] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);

	const [isLargerThan480] = useMediaQuery("(min-width: 480px)");

	useEffect(() => {
		validateProfileAccount(authDetails.accId);
	}, [authDetails.accId]);

	const handleAuditDetailTabClick = (activity) => {
		auditActivity(activity, accId);
	};

	const getAccountDetails = (accId) => {
		setIsLoading(true);
		setIsError(false);

		let payload = {
			uId: "",
			accId: accId,
		};

		let url = `property`;
		axiosMyPropertyAuth
			.post(url, payload)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					setAccountDetails(returnObj);

					setIsLoading(false);
					setIsError(false);
				} else {
					setIsLoading(false);
					setIsError(true);
				}
			})
			.catch((error) => {
				// error
				setIsLoading(false);
				setIsError(true);
			});
	};

	const handleTabsChange = (index) => {
		setTabIndex(index);

		// reset tax estimator toggle to false as the user can again press the estimate/view button and re-trigger the opening of the section
		// assessment tab (index 0)
		if (index === 0) {
			setToggleTaxEstimator(false);
		}
	};

	const openTaxEstimator = (showEstimate) => {
		setTabIndex(1);
		if (!showEstimate) {
			//open your property taxes
			setToggleYourPropertyTaxes(!toggleYourPropertyTaxes);
		} else {
			//open tax estimator
			setToggleTaxEstimator(!toggleTaxEstimator);
		}
	};

	const openHistory = () => {
		setTabIndex(2);
	};

	const navigateHome = () => {
		navigate("/auth/property", { replace: true });
	};

	const validateProfileAccount = (accId) => {
		let url = "profile/validate";

		let payload = {
			uId: "",
			accId: accId,
		};

		axiosMyPropertyAuth
			.put(url, payload)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					if (returnObj.isValid === "Y") {
						// account is valid for the profile
						setAccId(accId);
						getAccountDetails(accId);
						payload = null;
					} else {
						payload = null;
						navigateHome();
					}
				} else {
					payload = null;
					navigateHome();
				}
			})
			.catch((error) => {
				payload = null;
				navigateHome();
			});
	};

	return (
		<React.Fragment>
			{accId !== undefined ? (
				<React.Fragment>
					<AddressAccount
						accId={accId}
						accountDetails={accountDetails}></AddressAccount>

					<Tabs
						isFitted
						variant='unstyled'
						index={tabIndex}
						onChange={handleTabsChange}>
						<TabList
							mb='1em'
							className='tab-list'>
							<Tab
								className='tab'
								_selected={{ color: "white", bg: "blue.500" }}
								_hover={{ color: "white", bg: "blue.500" }}
								onClick={() => handleAuditDetailTabClick("View Assessment Tab")}>
								<chakra.span
									fontWeight={700}
									fontSize={!isLargerThan480 ? ".9em" : "1em"}>
									Assessment
								</chakra.span>
							</Tab>
							<Tab
								className='tab margin'
								_selected={{ color: "white", bg: "blue.500" }}
								_hover={{ color: "white", bg: "blue.500" }}
								onClick={() => handleAuditDetailTabClick("View Taxes Tab")}>
								<chakra.span
									fontWeight={700}
									fontSize={!isLargerThan480 ? ".9em" : "1em"}>
									Taxes
								</chakra.span>
							</Tab>
							<Tab
								className='tab'
								_selected={{ color: "white", bg: "blue.500" }}
								_hover={{ color: "white", bg: "blue.500" }}
								onClick={() => handleAuditDetailTabClick("View History Tab")}>
								<chakra.span
									fontWeight={700}
									fontSize={!isLargerThan480 ? ".9em" : "1em"}>
									History
								</chakra.span>
							</Tab>
						</TabList>
						<TabPanels>
							<TabPanel className='tab-panel'>
								{/* ASSESSMENT */}
								<Assessment
									accId={accId}
									openTaxEstimator={openTaxEstimator}
									openHistory={openHistory}
									accountDetails={accountDetails}
								/>
							</TabPanel>
							<TabPanel className='tab-panel'>
								{accountDetails?.taxTabMessage !== null ? (
									<Box mb={2}>
										<Message taxTabMessage={accountDetails?.taxTabMessage}></Message>
									</Box>
								) : null}
								{accountDetails?.taxTabDisplayDtlsFlag === "Y" ? (
									<Taxes
										accId={accId}
										toggleTaxEstimator={toggleTaxEstimator}
										toggleYourPropertyTaxes={toggleYourPropertyTaxes}
										accountDetails={accountDetails}
										isLoading={isLoading}
										isError={isError}></Taxes>
								) : null}
							</TabPanel>
							<TabPanel className='tab-panel'>
								<History accId={accId}></History>
							</TabPanel>
						</TabPanels>
					</Tabs>
				</React.Fragment>
			) : (
				<React.Fragment>
					<Spinner size='sm' /> Loading...
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

export default Detail;
