import "./ReviewBoard.css";

import * as CommonFns from "../../../../../../common";

import { Box, Button, Flex, Link, ListItem, Text, UnorderedList, chakra, useMediaQuery } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import { AuditContext } from "../../../../../../contexts/AuditContext";
import { useNavigate } from "react-router-dom";

const ReviewBoard = ({ accId, assessmentDetails }) => {
	const [deadline, setDeadline] = useState();
	const [fee, setFee] = useState();
	const [reviewBoardUrl] = useState(process.env.REACT_APP_REVIEW_BOARD_URL);

	const { auditActivity } = useContext(AuditContext);

	const [isLargerThan992] = useMediaQuery("(min-width: 992px)");

	let navigate = useNavigate();

	useEffect(() => {
		if (assessmentDetails !== undefined) {
			getDetails();
		}
	}, [assessmentDetails]);

	const getDetails = () => {
		//complaint deadline
		if (assessmentDetails.complaintDate.toUpperCase().includes("SEE LATEST NOTICE")) {
			setDeadline(assessmentDetails.complaintDate);
		} else {
			setDeadline(CommonFns.FormatDate(new Date(assessmentDetails.complaintDate)));
		}

		//fee
		setFee(assessmentDetails.feeAmount);
	};

	const handleContactUsClick = (e) => {
		e.preventDefault();
		navigate("/auth/feedback", { replace: true });
	};

	return (
		<Box
			px={3}
			pt={1}
			pb={3}>
			<React.Fragment>
				<Box mb={6}>
					<chakra.span
						fontSize={"xl"}
						maxW={1050}>
						We are dedicated to ensuring your property's value is fair and correct. If you are concerned
						with the information we use to calculate your property's assessed value, we encourage you to use
						these avenues to receive answers.
					</chakra.span>
				</Box>
				<Box display={{ lg: "flex" }}>
					<Box
						minW={"33.3%"}
						pr={4}
						borderRight={isLargerThan992 ? "1px solid #fff" : "none"}
						borderBottom={isLargerThan992 ? "none" : "1px solid #fff"}
						pb={isLargerThan992 ? 0 : 4}>
						<Box
							fontWeight={700}
							textTransform={"uppercase"}>
							Online
						</Box>
						<Box fontSize={".9em"}>
							<Text mt={2}>
								This secure website aims to give you all of the information we have on file for your
								property.
							</Text>
							<Text mt={3}>Some of the features you could benefit from include</Text>
							<UnorderedList
								pt='3'
								pl='10'>
								<ListItem fontWeight={700}>Property assessment detail report,</ListItem>
								<ListItem fontWeight={700}>Assessment methodology guides,</ListItem>
								<ListItem fontWeight={700}>Maps,</ListItem>
								<ListItem fontWeight={700}>
									Property sales and transfers report <chakra.span fontWeight={400}>and</chakra.span>
								</ListItem>
								<ListItem fontWeight={700}>Property tax estimator.</ListItem>
							</UnorderedList>
						</Box>
					</Box>
					<Box
						minW={"33.3%"}
						pr={isLargerThan992 ? 4 : 0}
						pl={isLargerThan992 ? 4 : 0}
						borderRight={isLargerThan992 ? "1px solid #fff" : "none"}
						borderBottom={isLargerThan992 ? "none" : "1px solid #fff"}
						pb={isLargerThan992 ? 0 : 4}
						pt={isLargerThan992 ? 0 : 4}>
						<Box
							fontWeight={700}
							textTransform={"uppercase"}>
							One-on-one Support
						</Box>
						<Box fontSize={".9em"}>
							<Text mt={2}>If you find any errors, get in touch with our assessors first.</Text>
							<Text mt={3}>
								We can resolve most assessment-related concerns and correct confirmed errors—with no
								formal complaint fees required.
							</Text>
							<Button
								mt={4}
								mr={2}
								size='sm'
								width={120}
								colorScheme={"lightBlue"}
								tabIndex={-1}
								onClick={(e) => {
									auditActivity("Review Board - Contact Us Click", accId);
									handleContactUsClick(e);
								}}>
								CONTACT US
							</Button>
						</Box>
					</Box>
					<Box
						minW={"33.3%"}
						pl={isLargerThan992 ? 4 : 0}
						pt={isLargerThan992 ? 0 : 4}>
						<Box
							fontWeight={700}
							textTransform={"uppercase"}>
							Formal Complaint
						</Box>
						<Box fontSize={".9em"}>
							<Text mt={2}>
								If you disagree with your assessment, you can file a formal complaint with the
								Assessment Review Board.
							</Text>
							<Box mt={3}>
								<Flex direction={["column"]}>
									<Box fontWeight={700}>
										<Flex>
											<Box minW={100}>File online</Box>
											<Box>
												<Link
													className='arb-link'
													href={reviewBoardUrl}
													rel={"noopener"}
													target={"_blank"}
													onClick={() => {
														auditActivity("Review Board - Edmonton ARB Click", accId);
													}}>
													edmontonarb.ca
												</Link>
											</Box>
										</Flex>
									</Box>
									<Box fontWeight={700}>
										<Flex>
											<Box minW={100}>Deadline</Box>
											<Box> {deadline}</Box>
										</Flex>
									</Box>
									<Box fontWeight={700}>
										<Flex>
											<Box minW={100}>Fee</Box>
											<Box>{fee}</Box>
										</Flex>
									</Box>
								</Flex>
							</Box>
							<Text mt={3}>
								You can file a formal complaint against your property's assessed value before the
								complaint deadline, but you cannot formally complain against the amount of property
								taxes stated on your tax notice.
							</Text>
						</Box>
					</Box>
				</Box>
			</React.Fragment>
		</Box>
	);
};
export default ReviewBoard;
