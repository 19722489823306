import "gridjs/dist/theme/mermaid.css";
import "./PropertyAuthorization.css";

import * as toastMessages from "../../../../toastMessages";

import {
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Badge,
	Box,
	Button,
	Flex,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spacer,
	Table,
	Tbody,
	Td,
	Text,
	Tr,
	chakra,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import { Grid, _ } from "gridjs-react";
import React, { useContext, useEffect, useState } from "react";

import { AuditContext } from "../../../../contexts/AuditContext";
import { AuthContext } from "../../../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Markdown from "../../../Global/Markdown/Markdown";
import { axiosMyPropertyAuth } from "../../../../apis";
import { h } from "gridjs";
import { useNavigate } from "react-router-dom";

const PropertyAuthorization = ({ loadAuthorizedPropertiesToggle }) => {
	const { auditActivity } = useContext(AuditContext);
	const { authDetails, setAuthDetails, setAuthAccountNumber } = useContext(AuthContext);

	let navigate = useNavigate();

	const toast = useToast();
	const listAuthorizedPropertyToastErrorId = "list-authorized-property-toast-error";
	const listAuthorizedPropertyToastWarningId = "list-authorized-property-toast-warning";
	const listAuthorizedPropertyToastInfoId = "list-authorized-property-toast-info";

	const [pendingAuthoirzedGridItems, setPendingAuthoirzedGridItems] = useState();
	const [pendingAuthoirzed, setPendingAuthoirzed] = useState(null);
	const [pendingAuthoirzedPageLimit] = useState(10);
	const [pendingAuthoirzedCurrentPage, setPendingAuthoirzedCurrentPage] = useState(0);
	const [isPendingAuthoirzedPaging, setIsPendingAuthoirzedPaging] = useState(false);

	const {
		isOpen: isOpenPendingAuthorization,
		onOpen: onOpenPendingAuthorization,
		onClose: onClosePendingAuthorization,
	} = useDisclosure();

	const {
		isOpen: isOpenPendingAuthorizationDecline,
		onOpen: onOpenPendingAuthorizationDecline,
		onClose: onClosePendingAuthorizationDecline,
	} = useDisclosure();

	const {
		isOpen: isOpenPendingAuthorizationExpired,
		onOpen: onOpenPendingAuthorizationExpired,
		onClose: onClosePendingAuthorizationExpired,
	} = useDisclosure();

	const [pendingDecline, setPendingDecline] = useState(null);
	const [pendingAuthorizationCount, setPendingAuthorizationCount] = useState(0);

	const {
		isOpen: isOpenPendingAuthorizationRemove,
		onOpen: onOpenPendingAuthorizationRemove,
		onClose: onClosePendingAuthorizationRemove,
	} = useDisclosure();

	const [deletingAuthorizedProperty, setDeletingAuthorizedProperty] = useState({ wauId: "", address: "" });

	useEffect(() => {
		getAuthorizedProperties();
	}, [loadAuthorizedPropertiesToggle]);

	useEffect(() => {
		if (
			authDetails?.displayPendingAuthorizedProperties === "Y" &&
			authDetails?.displayPaperlessPromo === "N" &&
			authDetails?.isAdmin === false
		) {
			getPendingAuthorizedProperties();
			setAuthDetails({ ...authDetails, displayPendingAuthorizedProperties: "N" });
		}
	}, [authDetails, authDetails.displayPaperlessPromo]);

	const getAuthorizedProperties = () => {
		if (authDetails?.isAdmin === false) {
			let url = `propertyauthorization/list`;

			axiosMyPropertyAuth
				.get(url, { headers: { Accept: "application/json" } })
				.then((resp) => {
					let returnObj = resp.data.replace(/(\r\n|\n|\r)/gm, "");
					returnObj = JSON.parse(returnObj);
					if (returnObj.retVal === "true") {
						if (returnObj.accountsCol !== null) {
							let accounts = returnObj.accountsCol;
							setPendingAuthoirzedGridItems(accounts.sort((a, b) => a.sortOrder - b.sortOrder));

							if (returnObj.accountsCol.length > pendingAuthoirzedPageLimit) {
								setIsPendingAuthoirzedPaging(true);
							} else {
								setIsPendingAuthoirzedPaging(false);
							}
						} else {
							setPendingAuthoirzedGridItems([]);
						}
					} else {
						setPendingAuthoirzedGridItems([]);
						if (!toast.isActive(listAuthorizedPropertyToastWarningId)) {
							toast(
								toastMessages.getToastWarning(
									listAuthorizedPropertyToastWarningId,
									"shared with me unavailable",
									toastMessages.propertyAuthorizationMessages
								)
							);
						}
					}
				})
				.catch((error) => {
					// error
					if (!toast.isActive(listAuthorizedPropertyToastErrorId)) {
						toast(toastMessages.getToastError(listAuthorizedPropertyToastErrorId));
					}
				});
		}
	};

	const keepGridPage = () => {
		if (pendingAuthoirzedGridItems.length > pendingAuthoirzedPageLimit) {
			// keep the user on the current page when showing the modal

			let currentPage = document
				.getElementById("table-prop-auth")
				.getElementsByClassName("gridjs-currentPage")[0].innerHTML;

			setPendingAuthoirzedCurrentPage(Number(currentPage) - 1);
		}
	};

	const viewProperty = (accId) => {
		// set the auth context with the account being viewed
		setAuthAccountNumber(accId);

		// audit
		auditActivity("View account", accId);

		// navigate the user to the property detail
		navigate(`/auth/property-detail`, { replace: true });
	};

	const handleRemovingAuthorizedProperty = (wauId, address, accId, status) => {
		if (status === null) {
			removeAuthorizedProperty(wauId, address, accId);
		} else {
			if (status.toLowerCase() !== "pending") {
				removeAuthorizedProperty(wauId, address, accId);
			}
		}
	};

	const removeAuthorizedProperty = (wauId, address, accId) => {
		setDeletingAuthorizedProperty({
			accId: accId,
			wuaId: wauId,
			address: address,
		});
		keepGridPage();
		onOpenPendingAuthorizationRemove();
	};

	const handleCancelRemoveAuthorizedProperty = () => {
		resetDeletingAccount();
		onClosePendingAuthorizationRemove();
	};

	const handleConfirmRemoveAuthorizedProperty = (e) => {
		e.preventDefault();
		handleRemoveAuthorizedProperty();
	};

	const resetDeletingAccount = () => {
		setDeletingAuthorizedProperty({
			accId: "",
			wuaId: "",
			address: "",
		});
	};

	const handleRemoveAuthorizedProperty = () => {
		let url = `propertyauthorization/remove`;

		let payload = {
			uId: "",
			wuaId: deletingAuthorizedProperty.wuaId,
		};

		axiosMyPropertyAuth
			.post(url, payload)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					// audit
					auditActivity("Authorized Account Deleted from Profile", deletingAuthorizedProperty.wauId);

					resetDeletingAccount();

					//get authorized properties
					getAuthorizedProperties();

					// using custom toast here
					// not using toastMessages.js
					// not using id, allows multiple toasts to appear if a user is faster than the 3000ms close timer
					// this prevents console errors
					toast({
						title: "Success!",
						description: "Authorized Property has been removed.",
						status: "success",
						position: "top-right",
						duration: 3000,
						isClosable: true,
					});
				} else {
					if (!toast.isActive(listAuthorizedPropertyToastWarningId)) {
						toast(
							toastMessages.getToastWarning(
								listAuthorizedPropertyToastWarningId,
								"issue removing property",
								toastMessages.propertyAuthorizationMessages
							)
						);
					}
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(listAuthorizedPropertyToastErrorId)) {
					toast(toastMessages.getToastError(listAuthorizedPropertyToastErrorId));
				}
			});

		onClosePendingAuthorizationRemove();
	};

	const sortProperty = (direction, wuaId, originalSortOrder) => {
		if (direction !== "none") {
			let sortOrder;
			switch (direction) {
				case "up":
					sortOrder = originalSortOrder - 1;
					break;

				case "down":
					sortOrder = originalSortOrder + 1;

					break;
				// no default
			}

			// can only get away with this because paging is not enabled on the isPropertyView
			if (pendingAuthoirzedGridItems.length > pendingAuthoirzedPageLimit) {
				let currentPage = document.getElementsByClassName("gridjs-currentPage")[0].innerHTML;
				setPendingAuthoirzedCurrentPage(Number(currentPage) - 1);
			}

			//axios call to sort
			let url = `propertyauthorization/updatesortorder`;
			const payload = {
				uId: "",
				wuaId: wuaId,
				sortOrder: sortOrder,
			};

			axiosMyPropertyAuth
				.put(url, payload)
				.then((resp) => {
					let returnObj = resp.data;

					if (returnObj.retVal === "true") {
						// audit
						auditActivity("Authorized Account Sorted", wuaId);
						getAuthorizedProperties();
					} else {
						if (!toast.isActive(listAuthorizedPropertyToastWarningId)) {
							toast(
								toastMessages.getToastWarning(
									listAuthorizedPropertyToastWarningId,
									"issue sorting property",
									toastMessages.propertyAuthorizationMessages
								)
							);
						}
					}
				})
				.catch((error) => {
					// error
					if (!toast.isActive(listAuthorizedPropertyToastErrorId)) {
						toast(toastMessages.getToastError(listAuthorizedPropertyToastErrorId));
					}
				});
		}
	};

	const showPendingAuthorized = () => {
		keepGridPage();
		getPendingAuthorizedProperties();
	};

	const getPendingAuthorizedProperties = () => {
		if (authDetails?.isAdmin === false) {
			let url = `propertyauthorization/pending/${authDetails.emailAddress}`;

			axiosMyPropertyAuth
				.get(url, { headers: { Accept: "application/json" } })
				.then((resp) => {
					let returnObj = resp.data.replace(/(\r\n|\n|\r)/gm, "");
					returnObj = JSON.parse(returnObj);
					if (returnObj.retVal === "true") {
						if (returnObj.ownerEmailCol !== null) {
							setPendingAuthoirzed(returnObj);
							setPendingAuthorizationCount(returnObj.ownerEmailCol.length);
							onOpenPendingAuthorization();
						} else {
							setPendingAuthoirzed(null);
							setPendingAuthorizationCount(0);
						}
					} else {
						setPendingAuthoirzed(null);
						setPendingAuthorizationCount(0);

						if (!toast.isActive(listAuthorizedPropertyToastWarningId)) {
							toast(
								toastMessages.getToastWarning(
									listAuthorizedPropertyToastWarningId,
									"owner detail unavailable",
									toastMessages.propertyAuthorizationMessages
								)
							);
						}
					}
				})
				.catch((error) => {
					// error
					if (!toast.isActive(listAuthorizedPropertyToastErrorId)) {
						toast(toastMessages.getToastError(listAuthorizedPropertyToastErrorId));
					}
				});
		}
	};

	const declineAuthorization = (e, accounts, index, ownerEmailAddress) => {
		e.preventDefault();

		let payload = {
			ownerEmailAddress: ownerEmailAddress,
			profileEmailAddress: authDetails.emailAddress,
			authMode: "DECLINE",
			uId: "",
			accountsCol: accounts,
			index: index,
		};

		setPendingDecline(payload);

		onOpenPendingAuthorizationDecline();
	};

	const acceptAuthorization = (e, accounts, index, ownerEmailAddress) => {
		e.preventDefault();

		let payload = {
			ownerEmailAddress: ownerEmailAddress,
			profileEmailAddress: authDetails.emailAddress,
			authMode: "ACCEPT",
			uId: "",
			accountsCol: accounts,
			index: index,
		};

		processAcceptDeclineAuthorization(payload);
	};

	const handlePendingAuthorizationDecline = (e) => {
		e.preventDefault();
		processAcceptDeclineAuthorization(pendingDecline);
		onClosePendingAuthorizationDecline();
		clearPendingDecline();
	};

	// const handleClosePendingAuthorizationDecline = (e) => {
	// 	e.preventDefault();
	// 	onClosePendingAuthorizationDecline();
	// 	clearPendingDecline();
	// };

	const processAcceptDeclineAuthorization = (payload) => {
		let url = `propertyauthorization/acceptdecline`;

		axiosMyPropertyAuth
			.post(url, payload)
			.then((resp) => {
				let returnObj = resp.data;

				if (returnObj.isSuccess) {
					// update count
					let count = pendingAuthorizationCount;
					setPendingAuthorizationCount((count -= 1));

					// hide accept/decline buttons
					let divAcceptDecline = document.getElementById(`div-accept-decline-${payload.index}`);
					divAcceptDecline.style.display = "none";

					// audit
					auditActivity(`${payload.authMode === "ACCEPT" ? "Accepted" : "Declined"} property authorization`);

					if (returnObj.authRepIsOwnerFlag === "Y") {
						//show a warning toast as one or more of the accepted shared properties is already in the profile
						toast(
							toastMessages.getToastInfo(
								listAuthorizedPropertyToastInfoId,
								"One or more of the accepted shared properties already exists in your profile.",
								toastMessages.propertyAuthorizationMessages
							)
						);
					}

					// refresh the authorized grid
					getAuthorizedProperties();
				} else {
					if (!toast.isActive(listAuthorizedPropertyToastWarningId)) {
						toast(
							toastMessages.getToastWarning(
								listAuthorizedPropertyToastWarningId,
								returnObj.errorMsg,
								toastMessages.propertyAuthorizationMessages
							)
						);
					}
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(listAuthorizedPropertyToastErrorId)) {
					toast(toastMessages.getToastError(listAuthorizedPropertyToastErrorId));
				}
			})
			.finally(() => {
				clearPendingDecline();
			});
	};

	const clearPendingDecline = () => {
		setPendingDecline(null);
	};

	const showPendingExpired = () => {
		onOpenPendingAuthorizationExpired();
	};

	const handleClosePendingAuthorizationExpired = (e) => {
		e.preventDefault();
		onClosePendingAuthorizationExpired();
	};

	// Grid columns
	const gridColumns = [
		{
			id: "wuaId",
			name: "Id",
			hidden: true,
		},
		{
			id: "sortUp",
			formatter: (cell, row) => {
				return h(
					"div",
					{
						className: "grid-button light-blue sort-up",
						onClick: (e) => {
							e.preventDefault();
							row.cells[9].data !== null
								? row.cells[9].data.toLowerCase() === "pending"
									? sortProperty("none", row.cells[0].data, row.cells[3].data)
									: sortProperty("up", row.cells[0].data, row.cells[3].data)
								: sortProperty("up", row.cells[0].data, row.cells[3].data);
						},
						title: "Sort property up",
					},
					_(
						row.cells[9].data !== null ? (
							row.cells[9].data.toLowerCase() === "pending" ? (
								<Box w={"15px"}></Box>
							) : (
								<Button>
									<FontAwesomeIcon icon='fa-solid fa-chevron-up' />
								</Button>
							)
						) : (
							<Button>
								<FontAwesomeIcon icon='fa-solid fa-chevron-up' />
							</Button>
						)
					)
				);
			},
			width: "3%",
		},
		{
			id: "sortDown",
			formatter: (cell, row) => {
				return h(
					"div",
					{
						className: "grid-button light-blue sort-up",
						onClick: (e) => {
							e.preventDefault();
							row.cells[9].data !== null
								? row.cells[9].data.toLowerCase() === "pending"
									? sortProperty("none", row.cells[0].data, row.cells[3].data)
									: sortProperty("down", row.cells[0].data, row.cells[3].data)
								: sortProperty("down", row.cells[0].data, row.cells[3].data);
						},
						title: "Sort property up",
					},
					_(
						row.cells[9].data !== null ? (
							row.cells[9].data.toLowerCase() === "pending" ? (
								<Box w={"15px"}></Box>
							) : (
								<Button>
									<FontAwesomeIcon icon='fa-solid fa-chevron-down' />
								</Button>
							)
						) : (
							<Button>
								<FontAwesomeIcon icon='fa-solid fa-chevron-down' />
							</Button>
						)
					)
				);
			},
			width: "3%",
		},
		{
			id: "sortOrder",
			formatter: (cell, row) => {
				return h(
					"div",
					{
						title: "Sort order",
					},
					_(
						row.cells[9].data !== null ? (
							row.cells[9].data.toLowerCase() === "pending" ? (
								<Box w={"15px"}></Box>
							) : (
								<chakra.span fontWeight={700}>{cell}</chakra.span>
							)
						) : (
							<chakra.span fontWeight={700}>{cell}</chakra.span>
						)
					)
				);
			},
			//width: "10%",
		},
		{
			id: "address",
			name: "Address",
			formatter: (cell, row) => {
				return h(
					"div",
					{
						onClick: (e) => {
							e.preventDefault();
							row.cells[9].data !== null
								? row.cells[9].data.toLowerCase() === "expired"
									? showPendingExpired()
									: row.cells[9].data.toLowerCase() === "pending"
									? showPendingAuthorized()
									: viewProperty(row.cells[5].data)
								: viewProperty(row.cells[5].data);
						},
					},
					_(
						<>
							<Flex>
								<Link
									className='grid-link view'
									title={
										row.cells[9].data !== null
											? row.cells[9].data.toLowerCase() === "expired"
												? "Access to this shared property has expired."
												: row.cells[9].data.toLowerCase() === "pending"
												? "Approve or decline authorization"
												: "View property details"
											: "View property details"
									}
									fontWeight={700}>
									{cell}
								</Link>
								<Spacer />
								<Text
									title={
										row.cells[10].data.toLowerCase() === "y"
											? "You have been authorized as a representative for this account. Representatives can act on behalf of the owner when speaking to an assessor, request information about the current assessment and agree to changes such as assessment value and exemption status."
											: ""
									}>
									{row.cells[10].data.toLowerCase() === "y" ? "Representative" : ""}
								</Text>
							</Flex>
						</>
					)
				);
			},
			sort: {
				compare: (a, b) => {
					if (a > b) {
						return 1;
					} else if (b > a) {
						return -1;
					} else {
						return 0;
					}
				},
			},
			width: "55%",
		},
		{
			id: "accId",
			name: "Account",
			formatter: (cell, row) => {
				return h(
					"div",
					{},
					_(
						<chakra.span
							title='Property account number'
							fontWeight={700}>
							{cell}
						</chakra.span>
					)
				);
			},
			sort: {
				compare: (a, b) => {
					if (a > b) {
						return 1;
					} else if (b > a) {
						return -1;
					} else {
						return 0;
					}
				},
			},
			width: "15%",
		},
		{
			id: "badges",
			formatter: (cell, row) => {
				return h(
					"div",
					{
						className:
							row.cells[9].data !== null
								? (row.cells[9].data.toLowerCase() === "pending") |
								  (row.cells[9].data.toLowerCase() === "expired")
									? "grid-authorized-badge"
									: ""
								: row.cells[8].data === "Y"
								? "grid-authorized-badge"
								: "",
						title:
							row.cells[9].data !== null
								? row.cells[9].data.toLowerCase() === "expired"
									? "Access to this shared property has expired."
									: row.cells[9].data.toLowerCase() === "pending"
									? "This property is pending approval to view."
									: ""
								: row.cells[8].data === "Y"
								? "This property has been authorized to view."
								: "",
					},
					_(
						<chakra.span>
							{row.cells[9].data !== null ? row.cells[9].data : row.cells[8].data === "Y" ? "New" : ""}
						</chakra.span>
					)
				);
			},
			//width: "7%",
		},
		{
			id: "delete",
			sort: false,
			formatter: (cell, row) => {
				return h(
					"button",
					{
						className: "grid-button red xl",
						onClick: (e) => {
							e.preventDefault();
							handleRemovingAuthorizedProperty(
								row.cells[0].data,
								row.cells[4].data,
								row.cells[5].data,
								row.cells[9].data
							);
						},
						title: "Remove authorized property from your profile",
					},
					_(
						row.cells[9].data !== null ? (
							(row.cells[9].data.toLowerCase() === "expired") |
							(row.cells[9].data.toLowerCase() === "pending") ? null : (
								<FontAwesomeIcon
									fontSize={"1.15em"}
									icon='fa-solid fa-square-xmark'
								/>
							)
						) : (
							<FontAwesomeIcon
								fontSize={"1.15em"}
								icon='fa-solid fa-square-xmark'
							/>
						)
					)
				);
			},
			//width: "2%",
		},
		{
			id: "displayAsNewFlag", //8
			name: "displayAsNewFlag",
			hidden: true,
		},
		{
			id: "authStatus", //9
			name: "authStatus",
			hidden: true,
		},
		{
			id: "repFlag", //10
			hidden: true,
		},
	];

	// Grid paging
	const gridPagination = {
		enabled: pendingAuthoirzedGridItems !== undefined ? (isPendingAuthoirzedPaging ? true : false) : false,
		page:
			pendingAuthoirzedGridItems !== undefined
				? pendingAuthoirzedGridItems.length > pendingAuthoirzedPageLimit
					? pendingAuthoirzedCurrentPage
					: 0
				: 0,
		limit: pendingAuthoirzedPageLimit,
		resetPageOnUpdate: false,
	};

	// Grid search
	const gridSearch = {
		selector: (cell, rowIndex, cellIndex) => {
			if (cellIndex === 4) return cell; //address

			if (cellIndex === 5) return cell; //account number
		},
	};

	return (
		<React.Fragment>
			<Modal
				closeOnOverlayClick={false}
				closeOnEsc={false}
				isOpen={isOpenPendingAuthorization}
				onClose={onClosePendingAuthorization}
				size={"3xl"}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className={"modal-header"}>You've been authorized by a property owner!</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					<ModalBody>
						<Box>
							<Markdown text={pendingAuthoirzed?.msgAcceptDeclineTop}></Markdown>
						</Box>
						<Box
							mt={3}
							mb={3}>
							<Accordion allowMultiple>
								{pendingAuthoirzed?.ownerEmailCol?.map((owner, index) => (
									<AccordionItem
										key={index}
										className='accordion-item'
										mb={3}>
										{({ isExpanded }) => (
											<React.Fragment>
												<AccordionButton
													className={`accordion-heading ${isExpanded ? "expanded" : ""}`}>
													<Flex w={"100%"}>
														<Box w={"100%"}>
															<Flex>
																<Box mr={2}>
																	{isExpanded ? (
																		<Box className='accordion-expand-icon-container expanded'>
																			<FontAwesomeIcon icon='fa-solid fa-minus' />
																		</Box>
																	) : (
																		<Box className='accordion-expand-icon-container collapsed'>
																			<FontAwesomeIcon icon='fa-solid fa-plus' />
																		</Box>
																	)}
																</Box>
																<Box mr={2}>
																	<Text>{owner.emailAddress}</Text>
																</Box>
															</Flex>
														</Box>
														<Spacer></Spacer>
														<Box
															w={"50%"}
															mr={2}
															fontSize={".8em"}
															position={"relative"}
															top={"3px"}>
															<Flex>
																<Box mr={2}>
																	<Badge
																		background={isExpanded ? "#e9eaea" : "#004060"}
																		color={isExpanded ? "#004060" : "#FFF"}>
																		{owner?.accountsCol.length.toString()}
																	</Badge>
																</Box>
																<Box>
																	<Text
																		w={"100px"}
																		textAlign={"left"}>
																		{owner?.accountsCol.length > 1
																			? "Properties"
																			: "Property"}
																	</Text>
																</Box>
															</Flex>
														</Box>
														<Spacer></Spacer>
														<Box
															w={"40%"}
															position={"relative"}
															top={"-1px"}>
															<Box id={`div-accept-decline-${index}`}>
																<Flex>
																	<Box mr={2}>
																		<Button
																			h={"26px"}
																			colorScheme={"lightBlue"}
																			size={"sm"}
																			onClick={(e) =>
																				acceptAuthorization(
																					e,
																					owner?.accountsCol,
																					index,
																					owner.emailAddress
																				)
																			}>
																			ACCEPT
																		</Button>
																	</Box>
																	<Box>
																		<Button
																			h={"26px"}
																			colorScheme={"red"}
																			size={"sm"}
																			onClick={(e) =>
																				declineAuthorization(
																					e,
																					owner?.accountsCol,
																					index,
																					owner.emailAddress
																				)
																			}>
																			DECLINE
																		</Button>
																	</Box>
																</Flex>
															</Box>
														</Box>
													</Flex>
												</AccordionButton>
												<AccordionPanel
													pb={4}
													className='accordion-panel'>
													<Box>
														<Table className='prop-auth'>
															<Tbody>
																{owner?.accountsCol?.map((account, index) => (
																	<Tr key={index}>
																		<Td>
																			<Box w={"400px"}>
																				{account.propertyAddress}
																			</Box>
																		</Td>
																		<Td textAlign={"center"}>{account.accId}</Td>
																		<Td textAlign={"center"}>
																			{account.representativeFlag === "Y" ? (
																				<Box w={"114px"}>Representative</Box>
																			) : account.representativeFlag === "N" ? (
																				<Box w={"114px"}></Box>
																			) : (
																				<Box w={"114px"}></Box>
																			)}
																		</Td>
																	</Tr>
																))}
															</Tbody>
														</Table>
													</Box>
												</AccordionPanel>
											</React.Fragment>
										)}
									</AccordionItem>
								))}
							</Accordion>
						</Box>
						<Box>
							<Markdown text={pendingAuthoirzed?.msgAcceptDeclineBottom}></Markdown>
						</Box>
					</ModalBody>
					<ModalFooter className={"modal-footer"}>
						<Box>
							{pendingAuthorizationCount > 0 ? (
								<>
									<Button
										colorScheme={"blue"}
										size={"sm"}
										onClick={(e) => onClosePendingAuthorization()}>
										Ask Me Later
									</Button>
								</>
							) : null}
						</Box>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<Modal
				closeOnOverlayClick={false}
				closeOnEsc={false}
				isOpen={isOpenPendingAuthorizationDecline}
				onClose={onClosePendingAuthorizationDecline}
				size={"2xl"}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className={"modal-header"}>Decline Invitation to View Properties</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					<ModalBody>
						<Box>
							Are you sure you want to decline this invitation from{" "}
							<chakra.span fontWeight={700}>{pendingDecline?.ownerEmailAddress}</chakra.span>?
						</Box>
						<Box
							mt={3}
							mb={2}>
							If you decline this invitation, access can only be granted again by the property owner
							sending you a new invitation.
						</Box>
					</ModalBody>
					<ModalFooter className={"modal-footer"}>
						<Flex
							direction={["column", "column", "row"]}
							w={["100%", "100%", "auto"]}>
							<Button
								colorScheme={"red"}
								minW={["100%", "100%", 100]}
								mr={[0, 0, 2]}
								onClick={(e) => handlePendingAuthorizationDecline(e)}>
								YES, DECLINE
							</Button>
							<Button
								colorScheme='grey'
								variant={"outline"}
								mt={[2, 2, 0]}
								minW={["100%", "100%", 100]}
								onClick={() => onClosePendingAuthorizationDecline()}>
								Cancel
							</Button>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<Modal
				closeOnOverlayClick={false}
				isOpen={isOpenPendingAuthorizationRemove}
				onClose={onClosePendingAuthorizationRemove}>
				<ModalOverlay />
				<ModalContent maxW={"35rem"}>
					<ModalHeader className={"modal-header"}>Remove Authorized Property - Confirmation</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					<ModalBody>
						<Box
							mt={2}
							mb={4}>
							Are you sure you want to remove this authorized property?
						</Box>
						<Box mb={2}>
							<Box>
								Account: <chakra.span fontWeight={700}>{deletingAuthorizedProperty.accId}</chakra.span>
							</Box>
							<Box>
								Address:{" "}
								<chakra.span fontWeight={700}>{deletingAuthorizedProperty.address}</chakra.span>
							</Box>
						</Box>
					</ModalBody>
					<ModalFooter className={"modal-footer"}>
						<Flex
							direction={["column", "column", "row"]}
							w={["100%", "100%", "auto"]}>
							<Button
								colorScheme={"red"}
								minW={["100%", "100%", 100]}
								mr={[0, 0, 2]}
								onClick={(e) => handleConfirmRemoveAuthorizedProperty(e)}>
								YES, REMOVE
							</Button>
							<Button
								colorScheme='grey'
								variant={"outline"}
								mt={[2, 2, 0]}
								minW={["100%", "100%", 100]}
								onClick={(e) => handleCancelRemoveAuthorizedProperty(e)}>
								Cancel
							</Button>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<Modal
				closeOnOverlayClick={false}
				closeOnEsc={false}
				isOpen={isOpenPendingAuthorizationExpired}
				onClose={onClosePendingAuthorizationExpired}
				size={"2xl"}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className={"modal-header"}>Shared Access Expired</ModalHeader>
					<ModalBody>
						<Box
							mt={2}
							mb={2}>
							Access to this shared property has expired. The owner will need to renew your access to
							continue to allow viewing.
						</Box>
					</ModalBody>
					<ModalFooter className={"modal-footer"}>
						<Box>
							<Button
								colorScheme={"blue"}
								size={"sm"}
								onClick={(e) => handleClosePendingAuthorizationExpired(e)}>
								Close
							</Button>
						</Box>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<Box>
				{pendingAuthoirzedGridItems !== undefined ? (
					pendingAuthoirzedGridItems.length > 0 ? (
						<Box>
							<Box
								position={"relative"}
								top={"41px"}>
								<chakra.span fontSize={"1.5em"}>Properties Shared With Me</chakra.span>
							</Box>
							<Box
								id='table-prop-auth'
								mt={2}
								className={"property-list-table"}>
								<Grid
									data={pendingAuthoirzedGridItems}
									columns={gridColumns}
									pagination={gridPagination}
									search={gridSearch}
									sort={false}></Grid>
							</Box>
						</Box>
					) : null
				) : null}
			</Box>
		</React.Fragment>
	);
};

export default PropertyAuthorization;
