import * as toastMessages from "../../../../toastMessages";

import { Box, Button, Flex, FormLabel, Image, Link, chakra, useBreakpointValue, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { axiosMyProperty } from "../../../../apis";

const Verify = () => {
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });
	let navigate = useNavigate();
	const location = useLocation();

	const [emailAddress, setEmailAddress] = useState("");
	const [uId, setUId] = useState("");

	const [hasError, setHasError] = useState(false);
	const [hasErrorAccountNotFound, setHasErrorAccountNotFound] = useState(false);
	const [hasErrorExpired, setHasErrorExpired] = useState(false);
	const [hasErrorNoUId, setHasErrorNoUId] = useState(false);
	const [hasGenericError, setHasGenericError] = useState(false);

	const [isAccountActivated, setIsAccountActivated] = useState(false);
	const [isPreviouslyActivated, SetIsPreviouslyActivated] = useState(false);
	const [isResendThankYou, setIsResendThankYou] = useState(false);
	const [resendEmailAddress, setResendEmailAddress] = useState();
	const [isLoadingResendVerification, setIsLoadingResendVerification] = useState(false);

	const toast = useToast();
	const verifyToastErrorId = "verify-toast-error";
	const verifyToastWarningId = "verify-toast-warning";

	const formLabelMinWidth = 210;

	const ImageStep3 = require("../Images/step_03.png");

	useEffect(() => {
		let uId = new URLSearchParams(location.search).get("id");
		let emailAddress = new URLSearchParams(location.search).get("e");

		if ((uId === null) | (emailAddress === null)) {
			setHasError(true);
			setHasGenericError(true);
		} else {
			setUId(uId);
			setEmailAddress(emailAddress);
			getProfileActive(uId, emailAddress);
		}
	}, []);

	const getProfileActive = (uId, emailAddress) => {
		let url = `profile/active/${uId}`;
		axiosMyProperty
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					if (returnObj.isActive === "Y") {
						SetIsPreviouslyActivated(true);
					} else {
						verifyProfile(uId, emailAddress);
					}
				} else {
					setHasError(true);
					if (returnObj.errorMsg.toLowerCase() === "profile_uid does not exist") {
						setHasErrorNoUId(true);
						resetStates();
					} else {
						setHasGenericError(true);
						resetStates();
					}
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(verifyToastErrorId)) {
					toast(toastMessages.getToastError(verifyToastErrorId));
				}
			});
	};

	const verifyProfile = (uId, emailAddress) => {
		let payload = {
			uId: uId,
			emailAddress: emailAddress,
		};

		let url = `account`;
		axiosMyProperty
			.put(url, payload)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					if (returnObj.activated === "Y") {
						// activated
						setIsAccountActivated(true);
					}
				} else if (returnObj.retVal === "false" && returnObj.activated === "N") {
					setHasError(true);

					switch (returnObj.errorMsg.toLowerCase()) {
						case "account not found":
							setHasErrorAccountNotFound(true);
							resetStates();
							break;

						case "link expired":
							setHasErrorExpired(true);
							// no resetStates(); here, need to use the uId and emailAddress to resend the verification email
							break;

						default:
							setHasGenericError(true);
							resetStates();
							break;
					}
				} else {
					setHasError(true);
					setHasGenericError(true);
					resetStates();
				}
			})
			.catch((error) => {
				// error
				resetStates();
				if (!toast.isActive(verifyToastErrorId)) {
					toast(toastMessages.getToastError(verifyToastErrorId));
				}
			});
	};

	const handleResendVerification = (e) => {
		e.preventDefault();

		setIsLoadingResendVerification(true);

		let payload = {
			uId: uId,
			toAddress: emailAddress,
			profileName: "",
		};

		let url = "account/verify";
		axiosMyProperty
			.post(url, payload)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.isSuccess === true) {
					payload = null;

					// store the email address in a different state, the original states below will be reset for sec/priv reasons
					setResendEmailAddress(emailAddress);

					resetStates();
					resetErrors();
					setIsResendThankYou(true);

					setIsLoadingResendVerification(false);
				} else {
					payload = null;
					if (!toast.isActive(verifyToastWarningId)) {
						toast(
							toastMessages.getToastWarning(
								verifyToastWarningId,
								returnObj.errorMsg,
								toastMessages.propertyMessages
							)
						);
					}

					setIsLoadingResendVerification(false);
				}
			})
			.catch((e) => {
				// error
				if (!toast.isActive(verifyToastErrorId)) {
					toast(toastMessages.getToastError(verifyToastErrorId));
				}
				setIsLoadingResendVerification(false);
			});
	};

	const resetErrors = () => {
		setHasError(false);
		setHasErrorAccountNotFound(false);
		setHasErrorExpired(false);
		setHasErrorNoUId(false);
		setHasGenericError(false);
	};

	const resetStates = () => {
		setUId();
		setEmailAddress();
	};

	const handleSignIn = (e) => {
		e.preventDefault();
		navigate(`/sign-in?emailAddress=${emailAddress}`, { replace: true });
	};

	return (
		<React.Fragment>
			<React.Fragment>
				{!hasError && !isResendThankYou ? (
					<Flex direction={["column", "column", "row"]}>
						<Box
							minW={formLabelMinWidth}
							display={isSmallerScreen ? "none" : "normal"}>
							<FormLabel>&nbsp;</FormLabel>
						</Box>
						<Box>
							<Flex direction={["column"]}>
								<Box mb={8}>
									<Image
										src={ImageStep3}
										alt='Step 3 - Sign In'></Image>
								</Box>
							</Flex>
						</Box>
					</Flex>
				) : null}

				{isAccountActivated ? (
					<Flex direction={["column", "column", "row"]}>
						<Box
							minW={formLabelMinWidth}
							display={isSmallerScreen ? "none" : "normal"}>
							<FormLabel>&nbsp;</FormLabel>
						</Box>
						<Box>
							<Flex direction={["column"]}>
								<Box>
									<chakra.span
										fontSize={"1.45em"}
										fontWeight={400}>
										Thank You. Now, Sign In.
									</chakra.span>
								</Box>
								<Box mt={2}>
									<Box>
										Your email is now verified and profile has been set up. Please sign in to go to
										the MyProperty website.
									</Box>
									<Box mt={2}>
										<Button
											colorScheme={"blue"}
											w={100}
											onClick={(e) => handleSignIn(e, true)}>
											SIGN IN
										</Button>
									</Box>
								</Box>
							</Flex>
						</Box>
					</Flex>
				) : null}

				{isResendThankYou ? (
					<Box>
						<Flex direction={["column", "column", "row"]}>
							<Box
								minW={formLabelMinWidth}
								display={isSmallerScreen ? "none" : "normal"}>
								<FormLabel>&nbsp;</FormLabel>
							</Box>
							<Box>
								<Flex direction={["column"]}>
									<Box>
										<chakra.span
											fontSize={"1.45em"}
											fontWeight={400}>
											Profile Verification
										</chakra.span>
									</Box>
									<Box>
										<Flex direction={["column"]}>
											<Box mt={2}>
												<Box>Thank you.</Box>
												<Box>
													A new verification email has been sent to{" "}
													<chakra.span fontWeight={700}>{resendEmailAddress}</chakra.span>.
												</Box>
											</Box>
										</Flex>
									</Box>
								</Flex>
							</Box>
						</Flex>
					</Box>
				) : null}

				{isPreviouslyActivated ? (
					<Box>
						<Flex direction={["column", "column", "row"]}>
							<Box
								minW={formLabelMinWidth}
								display={isSmallerScreen ? "none" : "normal"}>
								<FormLabel>&nbsp;</FormLabel>
							</Box>
							<Box>
								<Flex direction={["column"]}>
									<Box>
										<chakra.span
											fontSize={"1.45em"}
											fontWeight={400}>
											Profile Verification
										</chakra.span>
									</Box>
									<Box mt={2}>
										This profile has already been activated, please{" "}
										<Link onClick={(e) => handleSignIn(e)}>sign in</Link>.
									</Box>
								</Flex>
							</Box>
						</Flex>
					</Box>
				) : null}

				{hasError ? (
					<React.Fragment>
						<Flex direction={["column", "column", "row"]}>
							<Box
								minW={formLabelMinWidth}
								display={isSmallerScreen ? "none" : "normal"}>
								<FormLabel>&nbsp;</FormLabel>
							</Box>
							<Box>
								<Flex direction={["column"]}>
									<Box>
										<chakra.span
											fontSize={"1.45em"}
											fontWeight={400}>
											Profile Verification
										</chakra.span>
									</Box>
									<Box mt={2}>
										<Box maxW={600}>
											{hasErrorAccountNotFound | hasErrorNoUId ? (
												<Box>
													<Box>
														It looks like the profile cannot be found or has already been
														verified.
													</Box>
													<Box>
														Please{" "}
														<Link onClick={(e) => handleSignIn(e, true)}>sign in</Link> or
														check the verification link and try again.
													</Box>
												</Box>
											) : null}
											{hasErrorExpired ? (
												<Box>
													<Box>
														The verification link has expired. Send yourself a new
														verification email.
													</Box>
													<Box mt={3}>
														<Button
															isLoading={isLoadingResendVerification}
															loadingText={"SEND NEW VERIFICATION EMAIL"}
															colorScheme={"blue"}
															onClick={(e) => handleResendVerification(e, true)}>
															SEND NEW VERIFICATION EMAIL
														</Button>
													</Box>
												</Box>
											) : null}
											{hasGenericError ? (
												<Box>
													Oops... Something went wrong while trying to process the request.
													Please check your verification email and try again.
												</Box>
											) : null}
										</Box>
									</Box>
								</Flex>
							</Box>
						</Flex>
					</React.Fragment>
				) : null}
			</React.Fragment>
		</React.Fragment>
	);
};

export default Verify;
