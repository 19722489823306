import "./AddProperty.css";

import * as CommonFns from "../../../../common";
import * as toastMessages from "../../../../toastMessages";

import {
	Box,
	Button,
	Center,
	Divider,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	InputGroup,
	InputLeftAddon,
	InputRightElement,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Radio,
	RadioGroup,
	Stack,
	Text,
	chakra,
	useBreakpointValue,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import { AuditContext } from "../../../../contexts/AuditContext";
import { AuthContext } from "../../../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link as RouteLink } from "react-router-dom";
import { axiosMyPropertyAuth } from "../../../../apis";
import { useForm } from "react-hook-form";

const AddProperty = ({
	onPropertyAdded,
	onPropertyVerified,
	hideAddButton,
	handleNoOwnerRemoveProperty,
	addPropertyType,
	handleAddPropertyClose,
}) => {
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });
	const { authDetails, setAuthDetails } = useContext(AuthContext);
	const { auditActivity } = useContext(AuditContext);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const [currentStep, setCurrentStep] = useState("1");
	const [isStepOne, setIsStepOne] = useState(true);
	const [isStepTwo, setIsStepTwo] = useState(false);
	const [isStepOwnersOnly, setIsStepOwnersOnly] = useState(false);
	const [isStepOwnerLinked, setIsStepOwnerLinked] = useState(false);

	const [accId, setAccId] = useState();
	const [password, setPassword] = useState();

	const [property, setProperty] = useState();
	const [propertyOwnersUnfiltered, setPropertyOwnersUnfiltered] = useState([{}]);
	const [propertyOwners, setPropertyOwners] = useState([{}]);
	const [selectedPropertyPartyId, setSelectedPropertyPartyId] = useState();
	const [ownerLinked, setOwnerLinked] = useState();
	const [displayOwnerError, setDisplayOwnerError] = useState(false);

	const [propertyModType, setPropertyModType] = useState();

	const propertyOwnerSearchThreshold = 6;

	const toast = useToast();
	const addPropertyToastErrorId = "add-profile-toast-error";
	const addPropertyToastWarningId = "add-profile-toast-warning";

	const formLabelMinWidth = 150;
	const formControlMinWidth = 250;

	const isAdmin = authDetails.isAdmin;
	const {
		register: registerAccount,
		handleSubmit: handleSubmitAccount,
		reset: resetAccount,
		formState: { errors: errorsAccount },
		getValues,
		setValue,
	} = useForm({
		defaultValues: {
			accId: "",
			password: "",
		},
	});

	useEffect(() => {
		if (authDetails.addPropertyModal === true) {
			if (addPropertyType === CommonFns.AddPropertyTypeEnum.Verify) {
				setAccId(authDetails.accId);
				getPropertyOwner(authDetails.accId);
				setPropertyModType(CommonFns.AddPropertyTypeEnum.Verify);

				// open modal
				// modal (onOpen()) is set to open once getPropertyOwner has executed
				// this should prevent the modal opening and showing "step 1" detail prior to swapping to "step 2" once owner information is loaded.

				// set addPropertyModal to false not to show the modal again until a new request is made.
				setAuthDetails({
					...authDetails,
					addPropertyModal: false,
				});
			} else if (addPropertyType === CommonFns.AddPropertyTypeEnum.Add) {
				setPropertyModType(CommonFns.AddPropertyTypeEnum.Add);

				// open modal
				onOpen();

				// set addPropertyModal to false not to show the modal again until a new request is made.
				setAuthDetails({
					...authDetails,
					addPropertyModal: false,
				});
			}
		}
	}, [addPropertyType]);

	const handleAccount = (data) => {
		setAccId(data.accId);
		setPassword(data.password);

		let payload = {
			uId: "",
			accId: Number(data.accId),
			password: data.password.toUpperCase(),
		};

		if (isAdmin) {
			payload.password = "";
			setPassword("");
		}

		let url = "property/add/validate";
		axiosMyPropertyAuth
			.post(url, payload)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					if (returnObj.isValidFlag === "Y") {
						// success
						getPropertyOwner(payload.accId);
					} else {
						// warning
						// not checking the toast id here
						// a user could fix the account number or password quick enough that a different toast needs to be displayed
						toast(
							toastMessages.getToastWarning(
								addPropertyToastWarningId,
								returnObj.validationMsg,
								toastMessages.propertyMessages
							)
						);
					}
				} else {
					// warning
					if (!toast.isActive(addPropertyToastWarningId)) {
						toast(
							toastMessages.getToastWarning(
								addPropertyToastWarningId,
								returnObj.errorMsg,
								toastMessages.propertyMessages
							)
						);
					}
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(addPropertyToastErrorId)) {
					toast(toastMessages.getToastError(addPropertyToastErrorId));
				}
			});
	};

	const handleClearAccountForm = (e) => {
		e.preventDefault();

		// form
		resetAccount();
	};

	const getPropertyOwner = (accId) => {
		let url = `property/add/owner/${accId}`;

		axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					// get the property list
					let property = resp.data.gmWebPropOwnersObjTyp[0];
					setProperty(property);

					// get the owners from the property list
					let propertyOwners = property.propOwnersCol;

					// add a final option to the list of property owners
					propertyOwners.push({
						prtId: -1,
						prtName: "That's not me",
					});
					setPropertyOwners(propertyOwners);
					setPropertyOwnersUnfiltered(propertyOwners);

					// change step
					setCurrentStep("2");
					setIsStepOne(false);
					setIsStepTwo(true);

					// specific to veifying an owner
					// open the modal once owner information has been retreived
					// this should prevent the modal from showing "step 1" before swapping to "step 2" automatically
					if (addPropertyType === CommonFns.AddPropertyTypeEnum.Verify) {
						onOpen();
					}
				} else {
					// warning
					if (!toast.isActive(addPropertyToastWarningId)) {
						toast(
							toastMessages.getToastWarning(
								addPropertyToastWarningId,
								returnObj.errorMsg,
								toastMessages.propertyMessages
							)
						);
					}
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(addPropertyToastErrorId)) {
					toast(toastMessages.getToastError(addPropertyToastErrorId));
				}
			});
	};

	const handleSelectedPropertyOwner = (prtId) => {
		setSelectedPropertyPartyId(prtId);
	};

	const handleOwnerSubmit = (e) => {
		e.preventDefault();

		if ((selectedPropertyPartyId === undefined) | (selectedPropertyPartyId === null)) {
			setDisplayOwnerError(true);
		} else if (selectedPropertyPartyId === -1) {
			// thats not me selected

			// audit
			auditActivity("Owner Verification - That's Not Me", accId);

			// set steps
			setIsStepOne(false);
			setIsStepTwo(false);
			setIsStepOwnersOnly(true);
		} else {
			getOwnerLinked();
		}
	};

	const handleOwnerCancel = (e) => {
		e.preventDefault();
		closeAddPropertyModal();
	};

	const getOwnerLinked = () => {
		let payload = {
			accId: Number(accId),
			prtId: selectedPropertyPartyId,
			uId: "",
		};

		let url = "property/add/owner";
		axiosMyPropertyAuth
			.post(url, payload)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					if (returnObj.isLinkedFlag === "N") {
						// account is not linked to a different profile
						// success

						addProperty();
					} else {
						// owner is linked to another profile
						// swap views to show the masked email and message
						setOwnerLinked(resp.data);
						setIsStepOne(false);
						setIsStepTwo(false);
						setIsStepOwnersOnly(false);
						setIsStepOwnerLinked(true);
						// clean-up
						handleCleanUp();
					}
				} else {
					// warning
					if (!toast.isActive(addPropertyToastWarningId)) {
						toast(
							toastMessages.getToastWarning(
								addPropertyToastWarningId,
								returnObj.errorMsg,
								toastMessages.propertyMessages
							)
						);
					}
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(addPropertyToastErrorId)) {
					toast(toastMessages.getToastError(addPropertyToastErrorId));
				}
			});
	};

	const handleOnOpenAddProperty = (e) => {
		e.preventDefault();
		setPropertyModType(CommonFns.AddPropertyTypeEnum.Add);
		onOpen();
	};

	const addProperty = () => {
		let payload = {
			password: addPropertyType === CommonFns.AddPropertyTypeEnum.Add ? password : "",
			addType:
				addPropertyType === CommonFns.AddPropertyTypeEnum.Add
					? "add"
					: addPropertyType === CommonFns.AddPropertyTypeEnum.Verify
					? "verify"
					: "none",
			accId: Number(accId),
			prtId: selectedPropertyPartyId,
			uId: "",
			sortOrder: null,
		};

		let url = "property/add/account";
		axiosMyPropertyAuth
			.post(url, payload)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					if (propertyModType === CommonFns.AddPropertyTypeEnum.Add) {
						// audit
						auditActivity("Account Added to Profile", Number(accId));
						onPropertyAdded(returnObj.newMyMailOwnerCount);
					} else if (propertyModType === CommonFns.AddPropertyTypeEnum.Verify) {
						// audit
						auditActivity("Owner Added to Property", Number(accId));
						onPropertyVerified(Number(accId), selectedPropertyPartyId, returnObj.newMyMailOwnerCount);
					}

					closeAddPropertyModal();

					if (addPropertyType !== CommonFns.AddPropertyTypeEnum.Verify) {
						// using custom toast here
						// not using toastMessages.js
						// not using id, allows multiple toasts to appear if a user is faster than the 3000ms close timer
						// this prevents console errors
						toast({
							title: "Success!",
							description: "Property has been added.",
							status: "success",
							position: "top-right",
							duration: 3000,
							isClosable: true,
						});
					}
				} else {
					// failed gracefully
					if (!toast.isActive(addPropertyToastWarningId)) {
						toast(
							toastMessages.getToastWarning(
								addPropertyToastWarningId,
								returnObj.errorMsg,
								toastMessages.propertyMessages
							)
						);
					}
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(addPropertyToastErrorId)) {
					toast(toastMessages.getToastError(addPropertyToastErrorId));
				}
			});
	};

	const handleModalClose = () => {
		//e.preventDefault();

		if (addPropertyType === CommonFns.AddPropertyTypeEnum.Verify && isStepOwnersOnly) {
			handleNoOwnerRemoveProperty();
		}

		closeAddPropertyModal();
	};

	const closeAddPropertyModal = () => {
		handleCleanUp();
		resetSteps();

		//need to call parent page to reset addPropertyType if set, to prevent duplicate modals from opening at once
		if (
			addPropertyType === CommonFns.AddPropertyTypeEnum.Add ||
			addPropertyType === CommonFns.AddPropertyTypeEnum.Verify
		) {
			handleAddPropertyClose();
		}

		onClose();
	};

	const handleCleanUp = () => {
		// form
		resetAccount();

		// selected owner
		setSelectedPropertyPartyId();

		// selected error
		setDisplayOwnerError(false);

		// mod type
		setPropertyModType();
	};

	const resetSteps = () => {
		// reset steps to default
		setCurrentStep("1");
		setIsStepOne(true);
		setIsStepTwo(false);
		setIsStepOwnersOnly(false);
		setIsStepOwnerLinked(false);
	};

	const handleSearchOwners = (e) => {
		e.preventDefault();
		let value = getValues("searchCriteria").trim();
		if (value !== "") {
			let tempOwners = [...propertyOwnersUnfiltered];
			tempOwners = tempOwners.filter((x) => x.prtName.toLowerCase().includes(value.toLowerCase()));

			// filter a second time
			// filter out "that's not me" as searching for this value or parts of it allows it to be included in
			// the main array as it is originally part of propertyOwnersUnfiltered
			tempOwners = tempOwners.filter((x) => x.prtName.toLowerCase() !== "that's not me");

			// add the "that's not me" option to the end as it is always displayed
			tempOwners.push({
				prtId: -1,
				prtName: "That's not me",
			});

			setPropertyOwners(tempOwners);
		} else {
			setPropertyOwners(propertyOwnersUnfiltered);
		}
	};

	const handleSearchClear = (e) => {
		setValue("searchCriteria", "");
		setPropertyOwners(propertyOwnersUnfiltered);
	};

	return (
		<React.Fragment>
			{hideAddButton === undefined || hideAddButton === false ? (
				<>
					<Box
						textAlign={"right"}
						mb={3}>
						<Button
							onClick={(e) => {
								handleOnOpenAddProperty(e);
							}}
							colorScheme='blue'
							type={"submit"}
							fontSize={"0.95em"}
							className={"add-property-button-lg"}>
							<FontAwesomeIcon icon='plus' />
							&nbsp;&nbsp;{"ADD A PROPERTY"}
						</Button>
						<Button
							onClick={(e) => {
								handleOnOpenAddProperty(e);
							}}
							colorScheme='blue'
							type={"submit"}
							fontSize={"0.95em"}
							className={"add-property-button-sm"}>
							<FontAwesomeIcon icon='plus' />
						</Button>
					</Box>
				</>
			) : (
				<></>
			)}
			<Modal
				closeOnOverlayClick={false}
				isOpen={isOpen}
				onClose={handleModalClose}>
				<ModalOverlay />
				<ModalContent maxW={"53rem"}>
					<ModalHeader className={"modal-header"}>
						{addPropertyType !== CommonFns.AddPropertyTypeEnum.Verify ? (
							<>{`Add Your Property: Step ${currentStep} of 2`}</>
						) : (
							<>Ownership Verification</>
						)}
					</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					<ModalBody>
						{isStepOne ? (
							<React.Fragment>
								<Box
									mt={5}
									mb={4}>
									<FormControl
										isInvalid={errorsAccount.accId}
										mt={2}>
										<Flex direction={["column", "column", "row"]}>
											<Box minW={formLabelMinWidth}>
												<FormLabel
													htmlFor='accId'
													textAlign={isSmallerScreen ? "" : "end"}
													className='form-label-fixed'>
													Account Number
												</FormLabel>
											</Box>
											<Box minW={formControlMinWidth}>
												<Input
													id='accId'
													type='number'
													autoComplete='off'
													className={"text-upper"}
													{...registerAccount("accId", {
														required: "Account Number is required",
													})}
												/>
											</Box>
											<Box ml={isSmallerScreen ? 0 : 3}>
												<FormErrorMessage
													colorScheme={"red"}
													className={"text-error pos"}>
													{errorsAccount.accId && errorsAccount.accId.message}
												</FormErrorMessage>
											</Box>
										</Flex>
									</FormControl>

									<FormControl
										isInvalid={errorsAccount.password}
										mt={2}>
										<Flex direction={["column", "column", "row"]}>
											<Box minW={formLabelMinWidth}>
												<FormLabel
													htmlFor='password'
													textAlign={isSmallerScreen ? "" : "end"}
													className='form-label-fixed'>
													Access Code
												</FormLabel>
											</Box>
											<Box minW={formControlMinWidth}>
												<Input
													id='password'
													type='text'
													placeholder={isAdmin ? "NOT REQUIRED" : "REFER TO LATEST NOTICE"}
													readOnly={isAdmin}
													className={"text-upper"}
													autoComplete='off'
													{...registerAccount("password", {
														required: !isAdmin ? "Access Code is required" : "",
													})}
												/>
											</Box>
											<Box ml={isSmallerScreen ? 0 : 3}>
												<FormErrorMessage
													colorScheme={"red"}
													className={"text-error"}>
													{errorsAccount.password && errorsAccount.password.message}
												</FormErrorMessage>
											</Box>
										</Flex>
									</FormControl>

									{/* <FormControl mt={isSmallerScreen ? 6 : 3}>
										<Flex direction={["column", "column", "row"]}>
											<Box minW={formLabelMinWidth} display={isSmallerScreen ? "none" : "normal"}>
												<FormLabel>&nbsp;</FormLabel>
											</Box>
											<Box>
												<Flex direction={["column", "column", "row"]}>
													<Button
														colorScheme='lightBlue'
														mr={isSmallerScreen ? 0 : 2}
														width={isSmallerScreen ? "100%" : 100}
														onClick={handleSubmitAccount((data) => handleAccount(data))}>
														NEXT
													</Button>
													<Button
														type='reset'
														colorScheme='grey'
														variant={"outline"}
														mt={isSmallerScreen ? 4 : 0}
														width={isSmallerScreen ? "100%" : 100}
														onClick={(e) => handleClearAccountForm(e)}>
														CLEAR
													</Button>
												</Flex>
											</Box>
										</Flex>
									</FormControl> */}
								</Box>
							</React.Fragment>
						) : null}
						{isStepTwo ? (
							<React.Fragment>
								<Box mt={isSmallerScreen ? 6 : 3}>
									<Flex direction={["column", "column", "row"]}>
										<Box
											minW={formLabelMinWidth}
											display={isSmallerScreen ? "none" : "normal"}>
											<Box>&nbsp;</Box>
										</Box>
										<Box width={isSmallerScreen ? "100%" : 500}>
											<Flex direction={["column"]}>
												<Text mb={5}>
													Property assessment and taxation information you are about to access
													is
													<chakra.span fontWeight={700}>
														{" "}
														for property owners only
													</chakra.span>
													.
												</Text>
												<Text mb={5}>
													By completing this step, you are confirming that you are a
													registered owner of a property located at
												</Text>
												<Text fontWeight={700}>{property.address}</Text>
												<Text
													fontWeight={700}
													mb={5}>
													Account {property.accId}
												</Text>

												{propertyOwnersUnfiltered.length > propertyOwnerSearchThreshold ? (
													<>
														<Box mb={3}>
															<form
																onSubmit={(e) => {
																	handleSearchOwners(e);
																}}>
																<Flex direction={["column", "column", "row"]}>
																	<InputGroup>
																		<InputLeftAddon>
																			<chakra.span>
																				<FontAwesomeIcon icon='fas fa-search' />
																			</chakra.span>
																		</InputLeftAddon>

																		<Input
																			id='searchCriteria'
																			type='text'
																			className='add-property-search'
																			autoComplete='off'
																			width={"100%"}
																			{...registerAccount("searchCriteria")}
																		/>
																		<InputRightElement className='add-property-input-addon-right'>
																			<Button
																				className='add-property-search-btn'
																				colorScheme='lightBlue'
																				fontSize={"0.95em"}
																				type='submit'>
																				<chakra.span>
																					<FontAwesomeIcon icon='fas fa-filter' />
																				</chakra.span>
																			</Button>
																		</InputRightElement>
																		<InputRightElement className='add-property-input-addon-right'>
																			<Button
																				className='add-property-search-btn add-property-search-btn-gray'
																				fontSize={"0.95em"}
																				onClick={(e) => {
																					handleSearchClear(e);
																				}}>
																				<chakra.span>
																					<FontAwesomeIcon icon='fas fa-sync-alt' />
																				</chakra.span>
																			</Button>
																		</InputRightElement>
																	</InputGroup>
																</Flex>
															</form>
														</Box>
													</>
												) : (
													<></>
												)}
												<Divider mb={2}></Divider>
												<RadioGroup className='add-property-owners-radio'>
													<Box overflow={"auto"}>
														<Stack>
															{propertyOwners?.length === 1 ? (
																<Flex>
																	<Center w='100%'>
																		<Box
																			fontStyle={"italic"}
																			color='#999'>
																			No owners matching the search criteria
																		</Box>
																	</Center>
																</Flex>
															) : (
																<></>
															)}
															{propertyOwners
																.filter((x) => x.prtId !== -1)
																.map((propertyOwner, index) => (
																	<React.Fragment key={index}>
																		<Radio
																			key={index}
																			name={`propertyOwner.${index}`}
																			value={`${propertyOwner.prtId}`}
																			onChange={() => {
																				handleSelectedPropertyOwner(
																					propertyOwner.prtId
																				);
																			}}>
																			<chakra.span fontWeight={700}>
																				{propertyOwner.prtName}
																			</chakra.span>
																		</Radio>
																	</React.Fragment>
																))}
														</Stack>
													</Box>
													<Divider
														mt={2}
														mb={1}></Divider>
													<Radio
														key={propertyOwners?.length - 1}
														name={`propertyOwner.${propertyOwners?.length - 1}`}
														value={`${propertyOwners[propertyOwners?.length - 1]?.prtId}`}
														onChange={() => {
															handleSelectedPropertyOwner(
																propertyOwners[propertyOwners?.length - 1]?.prtId
															);
														}}>
														<chakra.span fontWeight={700}>
															{propertyOwners[propertyOwners?.length - 1]?.prtName}
														</chakra.span>
													</Radio>
												</RadioGroup>
											</Flex>
										</Box>
									</Flex>
								</Box>

								{/* <Box mt={isSmallerScreen ? 6 : 4} mb={1}>
									<Flex direction={["column", "column", "row"]}>
										<Box minW={formLabelMinWidth} display={isSmallerScreen ? "none" : "normal"}>
											<Box>&nbsp;</Box>
										</Box>
										<Box>
											<Flex direction={["column", "column", "row"]}>
												<Button
													colorScheme='lightBlue'
													width={
														isSmallerScreen
															? "100%"
															: addPropertyType !== CommonFns.AddPropertyTypeEnum.Verify
															? "190"
															: "120px"
													}
													mr={isSmallerScreen ? 0 : 2}
													onClick={(e) => handleOwnerSubmit(e)}>
													{addPropertyType !== CommonFns.AddPropertyTypeEnum.Verify
														? "ADD MY PROPERTY"
														: "CONFIRM"}
												</Button>

												<Button
													colorScheme='grey'
													variant={"outline"}
													mt={isSmallerScreen ? 4 : 0}
													width={isSmallerScreen ? "100%" : 110}
													onClick={(e) => handleOwnerCancel(e)}>
													CANCEL
												</Button>
											</Flex>
										</Box>
									</Flex>
								</Box> */}

								{displayOwnerError ? (
									<Box mt={2}>
										<Flex direction={["column", "column", "row"]}>
											<Box
												minW={formLabelMinWidth}
												display={isSmallerScreen ? "none" : "normal"}>
												<Box>&nbsp;</Box>
											</Box>
											<Box>
												<Flex direction={["column", "column", "row"]}>
													<chakra.span className={"text-error"}>
														An owner must be selected to add the property to your profile.
													</chakra.span>
												</Flex>
											</Box>
										</Flex>
									</Box>
								) : null}
							</React.Fragment>
						) : null}
						{isStepOwnersOnly ? (
							<React.Fragment>
								<Box mt={isSmallerScreen ? 6 : 3}>
									<Flex direction={["column", "column", "row"]}>
										<Box
											minW={formLabelMinWidth}
											display={isSmallerScreen ? "none" : "normal"}>
											<Box>&nbsp;</Box>
										</Box>
										<Box width={isSmallerScreen ? "100%" : 500}>
											<Box
												fontSize={"1.45em"}
												fontWeight={400}
												mb={4}>
												We are sorry. Further access is for property owners only.
											</Box>
											<Text mb={4}>
												Property assessment and taxation information located within this website
												is <chakra.span fontWeight={700}>for property owners only</chakra.span>.
											</Text>
											<Text mb={4}>
												If you are legally acting on behalf of a property owner or are a
												property or tax agent, use the agent sign in at{" "}
												<Link
													href={"../agentaccess"}
													rel={"noopener"}
													target={"_blank"}>
													MyProperty.edmonton.ca
												</Link>
												.
											</Text>
											<Text mb={4}>
												To receive clarification on this matter,{" "}
												<Link
													as={RouteLink}
													to={"/auth/feedback"}>
													get in touch with us
												</Link>
												.
											</Text>
										</Box>
									</Flex>
								</Box>
							</React.Fragment>
						) : null}
						{isStepOwnerLinked ? (
							<React.Fragment>
								<Box mt={isSmallerScreen ? 6 : 3}>
									<Flex direction={["column", "column", "row"]}>
										<Box
											minW={formLabelMinWidth}
											display={isSmallerScreen ? "none" : "normal"}>
											<Box>&nbsp;</Box>
										</Box>
										<Box width={isSmallerScreen ? "100%" : 500}>
											<Box
												fontSize={"1.45em"}
												fontWeight={400}
												mb={4}>
												{ownerLinked.isLinkedMsg}
											</Box>
											<Text
												mb={8}
												textAlign={"center"}>
												{ownerLinked.linkedEmail}
											</Text>
											<Text mb={8}>
												<chakra.span fontWeight={700}>If this is not your email</chakra.span>
												<br />
												<chakra.span>
													<Link
														as={RouteLink}
														to={"/auth/feedback"}>
														Get in touch with us
													</Link>{" "}
													as soon as possible to disconnect you property from that email.
												</chakra.span>
											</Text>
											<Text mb={4}>
												<chakra.span fontWeight={700}>If this is your email</chakra.span>
												<br />
												<chakra.span>
													For privacy and security reasons, you can link your property to only
													one email.
												</chakra.span>
												<br />
												<br />
												<chakra.span>
													You can access your property's data through the already linked
													email. Or, you can disconnect your property from your old email and
													relink it to your new email.{" "}
													<Link
														href={"https://www.edmonton.ca/mypropertyfaq"}
														rel={"noopener"}
														target={"_blank"}>
														FAQ: How do I remove a property from my profile?
													</Link>
												</chakra.span>
											</Text>
										</Box>
									</Flex>
								</Box>
							</React.Fragment>
						) : null}
					</ModalBody>
					<ModalFooter
						className={"modal-footer"}
						mt={2}>
						<Flex
							direction={["column", "column", "row"]}
							w={["100%", "100%", ""]}
							justifyContent={"flex-end"}>
							{isStepOne ? (
								<>
									<Button
										colorScheme='lightBlue'
										minW={["100%", "100%", 100]}
										mr={[0, 0, 2]}
										onClick={handleSubmitAccount((data) => handleAccount(data))}>
										NEXT
									</Button>
									<Button
										type='reset'
										colorScheme='grey'
										variant={"outline"}
										mt={[2, 2, 0]}
										minW={["100%", "100%", 100]}
										onClick={(e) => handleClearAccountForm(e)}>
										CLEAR
									</Button>
								</>
							) : null}
							{isStepTwo ? (
								<>
									<Button
										colorScheme='lightBlue'
										// width={[
										// 	"100%",
										// 	addPropertyType !== CommonFns.AddPropertyTypeEnum.Verify ? "190" : "120px",
										// ]}
										minW={["100%", "100%", 100]}
										mr={[0, 0, 2]}
										onClick={(e) => handleOwnerSubmit(e)}>
										{addPropertyType !== CommonFns.AddPropertyTypeEnum.Verify
											? "ADD MY PROPERTY"
											: "CONFIRM"}
									</Button>

									<Button
										colorScheme='grey'
										variant={"outline"}
										mt={[2, 2, 0]}
										minW={["100%", "100%", 100]}
										onClick={(e) => handleOwnerCancel(e)}>
										Cancel
									</Button>
								</>
							) : null}
						</Flex>
						{isStepOwnerLinked ? (
							<>
								<Flex w={["100%", "100%", "auto"]}>
									<Button
										colorScheme='blue'
										minW={["100%", "100%", 100]}
										onClick={() => handleModalClose()}>
										OK
									</Button>
								</Flex>
							</>
						) : null}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</React.Fragment>
	);
};

export default AddProperty;
