import "./Application.css";

import * as AdobeLC from "../../../../../../../adobelc";
import * as CommonFns from "../../../../../../../common";
import * as toastMessages from "../../../../../../../toastMessages";

import {
	Box,
	Button,
	Center,
	Flex,
	Hide,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Show,
	SimpleGrid,
	Table,
	Tbody,
	Td,
	Text,
	Tr,
	useDisclosure,
	useMediaQuery,
	useToast,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";

import { AuditContext } from "../../../../../../../contexts/AuditContext";
import CrmRequest from "../CrmRequest/CrmRequest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingSpinner from "../../../../../../Global/LoadingSpinner/LoadingSpinner";
import Markdown from "../../../../../../Global/Markdown/Markdown";
import { axiosMyPropertyAuth } from "../../../../../../../apis";
import { useNavigate } from "react-router-dom";

const Application = ({ accId, emailAddress }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [applicationDetail, setApplicationDetail] = useState(null);
	const [applicationReason, setApplicationReason] = useState(null);
	const [applicationTopicCode, setApplicationTopicCode] = useState(null);
	const [crmDetail, setCrmDetail] = useState(null);
	const [dbRecord, setDbRecord] = useState(null);
	const [leftDetails, setLeftDetails] = useState(null);
	const [leftCount, setLeftCount] = useState(0);
	const [rightDetails, setRightDetails] = useState(null);
	const [rightCount, setRightCount] = useState(0);

	const [isGenerateApplication, setIsGenerateApplication] = useState(false);
	const [isGenerateApplicationWarning, setIsGenerateApplicationWarning] = useState(false);
	const [isRequestApplication, setIsRequestApplication] = useState(false);

	const [modalSize, setModalSize] = useState("5xl");

	const { isOpen: isOpenMpp, onOpen: onOpenMpp, onClose: onCloseMpp } = useDisclosure();
	const { isOpen: isOpenMppLoading, onOpen: onOpenMppLoading, onClose: onCloseMppLoading } = useDisclosure();

	const toast = useToast();
	let navigate = useNavigate();
	const { auditActivity } = useContext(AuditContext);

	const mppApplicationToastErrorId = "tax-mpp-toast-error";
	const [isLargerThan480] = useMediaQuery("(min-width: 480px)");

	const getMppApplicationDetail = async () => {
		setIsLoading(true);

		let url = `taxes/mpp/${accId}`;
		await axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;

				if (returnObj.retVal === "true") {
					setIsError(false);

					setApplicationDetail(returnObj);
					setApplicationReason(returnObj.mppApplReason);
					setApplicationTopicCode(returnObj.mppApplTopicCode);
					setDbRecord(returnObj.dbRecord);

					let generateApplication = returnObj.mppApplStatus === "Y" ? true : false;
					setIsGenerateApplication(generateApplication);

					if (generateApplication) {
						// application can be generated, store the details
						setLeftDetails(returnObj.mppApplDtls.leftDetails);
						setLeftCount(returnObj.mppApplDtls.leftDetails.sectionDtls.length);

						setRightDetails(returnObj.mppApplDtls.rightDetails);
						setRightCount(returnObj.mppApplDtls.rightDetails.sectionDtls.length);

						setIsGenerateApplicationWarning(returnObj.mppApplDtls.warningMsg !== null ? true : false);

						setModalSize("5xl");
					} else {
						setModalSize("2xl");

						let requestApplication = returnObj.mppApplCrmFlag === "Y" ? true : false;
						setIsRequestApplication(requestApplication);
						setCrmDetail(requestApplication ? returnObj.mppApplCrmDtls : null);
					}
				} else {
					setIsError(true);
					resetModalState();
				}

				setIsLoading(false);
			})
			.catch((error) => {
				// error
				showError();
				setIsLoading(false);
				setIsError(true);
			});
	};

	const handleOpenMppModal = async (e) => {
		e.preventDefault();
		await getMppApplicationDetail();
		onOpenMpp();
	};

	const handleMppModalClose = () => {
		//e.preventDefault();
		onCloseMpp();

		resetLoadingState();
		resetErrorState();
		resetModalState();
	};

	const resetErrorState = () => {
		setIsError(false);
	};

	const resetLoadingState = () => {
		setIsLoading(true);
	};

	const resetModalState = () => {
		setApplicationDetail(null);
		setDbRecord(null);

		setLeftDetails(null);
		setLeftCount(0);

		setRightDetails(null);
		setRightCount(0);

		setIsGenerateApplication(false);
		setIsGenerateApplicationWarning(false);

		setIsRequestApplication(false);
	};

	const handleDownloadApplication = async (e) => {
		e.preventDefault();

		//show a loading modal
		onOpenMppLoading();

		auditActivity("MPP - Clicked Download Application");

		// set the application details in TACS
		let appDtls = await mppSetApplication();
		if (appDtls !== null) {
			// get xml
			let xml = await AdobeLC.getMppXml(appDtls.mpfId);

			if (xml !== null) {
				// get pdf
				let pdf = await AdobeLC.getMppPdf(xml, appDtls);

				if (pdf !== null) {
					CommonFns.pdfDownload(pdf.data, pdf.fileName);
				} else {
					showError();
				}
			} else {
				showError();
			}
		} else {
			showError();
		}

		//hide modal
		onCloseMppLoading();
	};

	const showError = () => {
		if (!toast.isActive(mppApplicationToastErrorId)) {
			toast(toastMessages.getToastError(mppApplicationToastErrorId));
		}
	};

	const mppSetApplication = async () => {
		let payload = {
			dbRecord: JSON.stringify(dbRecord),
		};

		// set application
		// return xmlId and correspondance
		let url = `taxes/mpp`;
		return await axiosMyPropertyAuth
			.post(url, payload)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					return returnObj;
				} else {
					return null;
				}
			})
			.catch((error) => {
				// error
				showError();
			});
	};

	const handleContactUs = (e) => {
		e.preventDefault();
		localStorage.setItem("feedbackReason", applicationReason);
		auditActivity("MPP - Clicked Contact Us");
		navigate(`/auth/feedback?accId=${accId}&tc=${applicationTopicCode}`, { replace: true });
	};

	return (
		<React.Fragment>
			<Button
				colorScheme='lightBlue'
				as='button'
				minW={"5rem"}
				size={"sm"}
				onClick={(e) => {
					handleOpenMppModal(e);
				}}>
				Get Application
			</Button>
			<Modal
				size={modalSize}
				closeOnOverlayClick={false}
				isOpen={isOpenMpp}
				onClose={handleMppModalClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className={"modal-header"}>Monthly Payment Plan</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					{isLoading ? (
						<React.Fragment>
							<ModalBody className={"mpp-modal-body"}>
								<LoadingSpinner></LoadingSpinner>
							</ModalBody>
						</React.Fragment>
					) : isError ? (
						<React.Fragment>
							<ModalBody>
								<React.Fragment>Details are unavailable at this time.</React.Fragment>
							</ModalBody>
						</React.Fragment>
					) : isGenerateApplication ? (
						<React.Fragment>
							<ModalBody className={"mpp-modal-body"}>
								<Box
									mt={2}
									mb={2}>
									<Text fontSize={"xl"}>
										<Markdown text={applicationDetail.mppApplDtls.topMsg}></Markdown>
									</Text>
								</Box>
							</ModalBody>
							<ModalBody className={"mpp-modal-body mpp-grey-container"}>
								<Box
									mt={2}
									mb={2}>
									<SimpleGrid columns={{ sm: 1, md: 2 }}>
										<Box w={{ sm: "100%", md: "90%" }}>
											<Text
												className={"mpp-title"}
												fontSize={"xl"}
												mb={2}>
												<Markdown text={leftDetails.heading}></Markdown>
											</Text>
											<Text
												fontSize={"sm"}
												w={{ sm: "100%", md: "90%" }}>
												<Markdown text={leftDetails.headingText}></Markdown>
											</Text>
											<Table
												mt={4}
												className={"mpp-table"}
												variant={"simple"}
												size={"sm"}>
												<Tbody>
													{leftDetails.sectionDtls.map((item, index) => (
														<Tr key={index}>
															<Td
																w={"56%"}
																className={
																	item.lineType === "GRANDTOTAL"
																		? "grand-total-text"
																		: leftCount - 2 === index
																		? "spacer"
																		: "normal"
																}>
																<Markdown text={item.lineText}></Markdown>
															</Td>
															<Td
																isNumeric
																className={
																	item.lineType === "GRANDTOTAL"
																		? "grand-total-value"
																		: leftCount - 2 === index
																		? "spacer"
																		: "normal"
																}>
																<Markdown text={item.lineDtls}></Markdown>
															</Td>
														</Tr>
													))}
												</Tbody>
											</Table>
											<Box
												mb={4}
												mt={4}>
												<Text
													fontSize={"sm"}
													className='mpp-footer-title'>
													<Markdown text={leftDetails.footer}></Markdown>
												</Text>
												<Text fontSize={"sm"}>
													<Markdown text={leftDetails.footerText}></Markdown>
												</Text>
											</Box>
										</Box>
										<Box>
											<Text
												className={"mpp-title"}
												fontSize={"xl"}
												mt={!isLargerThan480 ? 4 : 0}
												mb={2}>
												<Markdown text={rightDetails.heading}></Markdown>
											</Text>
											<Text
												fontSize={"sm"}
												w={{ sm: "100%", md: "90%" }}>
												<Markdown text={rightDetails.headingText}></Markdown>
											</Text>
											<Table
												mt={4}
												className={"mpp-table"}
												variant={"simple"}
												size={"sm"}>
												<Tbody>
													{rightDetails.sectionDtls.map((item, index) => (
														<Tr key={index}>
															<Td
																w={"56%"}
																className={
																	item.lineType === "GRANDTOTAL"
																		? "grand-total-text"
																		: item.lineType === "SUBTOTAL"
																		? rightCount - 2 === index
																			? "sub-total-text spacer"
																			: "sub-total-text"
																		: "normal"
																}>
																<Markdown text={item.lineText}></Markdown>
															</Td>
															<Td
																isNumeric
																className={
																	item.lineType === "GRANDTOTAL"
																		? "grand-total-value"
																		: item.lineType === "SUBTOTAL"
																		? rightCount - 2 === index
																			? "sub-total-value spacer"
																			: "sub-total-value"
																		: "normal"
																}>
																<Markdown text={item.lineDtls}></Markdown>
															</Td>
														</Tr>
													))}
												</Tbody>
											</Table>
										</Box>
									</SimpleGrid>
								</Box>
							</ModalBody>
							<ModalBody className={"mpp-modal-body mpp-get-started-container"}>
								<SimpleGrid columns={{ sm: 1, md: 2 }}>
									<Box
										w={"100%"}
										mt={2}
										mb={2}>
										{isGenerateApplicationWarning ? (
											<Text
												mt={2}
												mb={6}>
												<Markdown text={applicationDetail.mppApplDtls.warningMsg}></Markdown>
											</Text>
										) : null}
										<Text
											fontSize={"lg"}
											mb={2}>
											<Markdown text={applicationDetail.mppApplDtls.footer1Msg}></Markdown>
										</Text>
										<Text>
											<Markdown text={applicationDetail.mppApplDtls.footer2Msg}></Markdown>
										</Text>
									</Box>
									<Box>
										<Show above='md'>
											<Center mt={8}>
												<Button
													colorScheme='lightBlue'
													w={"75%"}
													onClick={(e) => {
														handleDownloadApplication(e);
													}}>
													Download Your Application
												</Button>
											</Center>
										</Show>
										<Hide above='md'>
											<Box
												w={!isLargerThan480 ? "100%" : "60%"}
												mt={4}
												mb={2}>
												<Button
													w={"100%"}
													colorScheme='lightBlue'
													onClick={(e) => {
														handleDownloadApplication(e);
													}}>
													Download Your Application
												</Button>
											</Box>
										</Hide>
									</Box>
								</SimpleGrid>
							</ModalBody>
						</React.Fragment>
					) : (
						<React.Fragment>
							{isRequestApplication ? (
								<React.Fragment>
									<ModalBody className={"mpp-modal-body"}>
										<Box
											mt={2}
											mb={2}>
											<Text fontSize={"xl"}>
												<Markdown text={applicationDetail.mppApplError.topMsg}></Markdown>
											</Text>
										</Box>
									</ModalBody>
									<ModalBody className={"mpp-modal-body mpp-grey-container"}>
										<Center h={"100%"}>
											<CrmRequest
												accId={accId}
												emailAddress={emailAddress}
												crmDetail={crmDetail}
												applicationDetail={applicationDetail}
												applicationReason={applicationReason}></CrmRequest>
										</Center>
									</ModalBody>
									<ModalBody className={"mpp-modal-body"}>
										<Text>
											<Markdown text={applicationDetail.mppApplError.footerMsg}></Markdown>
										</Text>
									</ModalBody>
								</React.Fragment>
							) : (
								<React.Fragment>
									<ModalBody className={"mpp-modal-body"}>
										<Box
											mt={2}
											mb={2}>
											<Text fontSize={"xl"}>
												<Markdown text={applicationDetail.mppApplError.topMsg}></Markdown>
											</Text>
										</Box>
									</ModalBody>
									<ModalBody className={"mpp-modal-body"}>
										<Center h={"100%"}>
											<Button
												mt={4}
												mb={4}
												colorScheme='lightBlue'
												w={!isLargerThan480 ? "100%" : "50%"}
												onClick={(e) => {
													handleContactUs(e);
												}}>
												Contact Us
											</Button>
										</Center>
									</ModalBody>
								</React.Fragment>
							)}
						</React.Fragment>
					)}
					<ModalFooter className={"modal-footer"}>
						{/* <Button colorScheme='blue' size={"sm"} onClick={(e) => handleMppModalClose(e)}>
							Close
						</Button> */}
					</ModalFooter>
				</ModalContent>
			</Modal>

			<Modal
				size={"md"}
				closeOnEsc={false}
				closeOnOverlayClick={false}
				isOpen={isOpenMppLoading}
				onClose={onCloseMppLoading}>
				<ModalOverlay />
				<ModalContent mt={20}>
					<ModalHeader className={"modal-header"}>Retrieving Application</ModalHeader>
					<ModalBody>
						<Flex
							direction={["column"]}
							alignItems='center'>
							<Box>One moment while we retrieve your application.</Box>
							<Box mt={2}>
								<LoadingSpinner></LoadingSpinner>
							</Box>
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
		</React.Fragment>
	);
};

export default Application;
