import '../../History.css';
import 'chart.js/auto';

import * as CommonFns from '../../../../../../../common';

import { Box, HStack, chakra } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const FiveYearAmount = ({ historyDetails }) => {
	const [propertyTaxDetails, setPropertyTaxDetails] = useState([{}]);

	const [actualBgColors] = useState(['#b4c193', '#9dae74', '#879c58', '#738b3d', '#607a19']);
	const [averageBgColors] = useState(['#8994a9', '#6a7a93', '#4d647f', '#2f516e', '#003f60']);

	useEffect(() => {
		if (historyDetails !== undefined) {
			let taxDetails = historyDetails.webTaxHistCol
				.filter((x) => x.baselineRecordFlag !== 'Y' && x.myPropTaxTotal > 0)
				.flatMap((x, index) => [
					{
						categoryPercentage: 0.5,
						label: x.asmtYear ?? 0,
						type: 'bar',
						stack: 'Prop/Actual',
						backgroundColor: actualBgColors[index],
						data: [x.myPropTaxTotal ?? 0],
					},
					{
						categoryPercentage: 0.5,
						label: x.asmtYear ?? 0,
						type: 'bar',
						stack: 'Prop/Avg',
						backgroundColor: averageBgColors[index],
						data: [x.cityTaxTotal ?? 0],
					},
				]);

			setPropertyTaxDetails(taxDetails);
		}
	}, [historyDetails]);

	return (
		<React.Fragment>
			<Bar
				options={{
					responsive: true,
					scales: {
						y: {
							title: {
								display: true,
								text: 'Amount',
								font: {
									size: '16',
								},
							},
							ticks: {
								stepSize: 5000,
								// Include a dollar sign in the ticks
								callback: function (value, index, ticks) {
									return value.toLocaleString('en-CA', {
										style: 'currency',
										currency: 'CAD',
									});
								},
							},
							grid: {
								borderColor: '#949494',
								borderWidth: 2,
							},
						},
						x: {
							offset: true,
							grid: {
								borderColor: '#949494',
								borderWidth: 2,
							},
						},
					},
					plugins: {
						datalabels: {
							color: '#fff',
							formatter: function (value, context) {
								let label = context.dataset.label;
								return label;
							},
						},
						legend: {
							display: false,
						},
						tooltip: {
							callbacks: {
								label: function (tooltipItem) {
									return CommonFns.FormatCurrency(tooltipItem.dataset.data, 2);
								},
							},
						},
					},
				}}
				plugins={[ChartDataLabels]}
				data={{
					labels: [''],
					datasets: propertyTaxDetails,
				}}
			/>
			<Box className="history-legend-container-tax alt">
				<HStack>
					<Box className="history-legend-alt-first"></Box>
					<Box className="history-legend-font">
						<chakra.span>Your property - Actual</chakra.span>
					</Box>
				</HStack>
			</Box>
			<Box className="history-legend-container-tax alt following">
				<HStack>
					<Box className="history-legend-alt-last"></Box>
					<Box className="history-legend-font">
						<chakra.span>Your property - Had it followed the average change</chakra.span>
					</Box>
				</HStack>
			</Box>
		</React.Fragment>
	);
};
export default FiveYearAmount;
