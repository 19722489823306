import * as toastMessages from "../../../../toastMessages";

import {
	Box,
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	useBreakpointValue,
	useToast,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";

import { AuditContext } from "../../../../contexts/AuditContext";
import { AuthContext } from "../../../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { axiosMyPropertyAuth } from "../../../../apis";
import { useForm } from "react-hook-form";

const DeleteProfile = ({ emailAddress, handleLogout, handleDeleteProfileModalClose }) => {
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });
	const { authDetails } = useContext(AuthContext);
	const { auditActivity } = useContext(AuditContext);

	const [passwordShow, setPasswordShow] = useState(false);
	const handleShowHidePassword = () => setPasswordShow(!passwordShow);

	const toast = useToast();
	const deleteProfileToastErrorId = "delete-profile-toast-error";
	const deleteProfileToastPasswordId = "delete-profile-toast-password";

	const formLabelMinWidth = 85;
	const formControlMinWidth = 300;

	const {
		register: registerDeleteProfile,
		handleSubmit: handleSubmitDeleteProfile,
		formState: { errors: errorsDeleteProfile },
	} = useForm({
		defaultValues: {
			password: "",
		},
	});

	const handleDeleteProfile = (data) => {
		let payload = {
			uId: "",
			password: data.password,
		};

		let url = "profile/delete";
		axiosMyPropertyAuth
			.post(url, payload)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					// audit
					auditActivity(`Delete Profile ${authDetails.uId} ${emailAddress}`);

					// logout
					handleLogout("deleted");
				} else {
					// delete failed gracefully
					if (!toast.isActive(deleteProfileToastPasswordId)) {
						toast(
							toastMessages.getToastWarning(
								deleteProfileToastPasswordId,
								returnObj.errorMsg,
								toastMessages.deleteProfileMessages
							)
						);
					}
				}
			})
			.catch((e) => {
				// error
				if (!toast.isActive(deleteProfileToastErrorId)) {
					toast(toastMessages.getToastError(deleteProfileToastErrorId));
				}
			});
	};

	return (
		<React.Fragment>
			<ModalHeader className={"modal-header"}>Delete Profile</ModalHeader>
			<ModalCloseButton className='modal-close-button'>
				<FontAwesomeIcon
					size={"2xl"}
					icon='fa-solid fa-xmark'
				/>
			</ModalCloseButton>
			<ModalBody>
				<Box mb={3}>Are you sure you want to delete your profile?</Box>
				<Box mb={8}>If so, please provide your password as confirmation and click the DELETE button.</Box>
				<Box mb={3}>
					<FormControl
						isInvalid={errorsDeleteProfile.password}
						mt={2}>
						<Flex direction={["column", "column", "row"]}>
							<Box minW={formLabelMinWidth}>
								<FormLabel
									htmlFor='password'
									textAlign={isSmallerScreen ? "" : "end"}
									className='form-label-fixed'>
									Password
								</FormLabel>
							</Box>
							<Box minW={formControlMinWidth}>
								<InputGroup>
									<Input
										id='password'
										type={passwordShow ? "text" : "password"}
										placeholder=''
										autoComplete='off'
										{...registerDeleteProfile("password", {
											required: "Password is required",
										})}
									/>
									<InputRightElement width='4.5rem'>
										<Button
											h='1.75rem'
											w={"56px"}
											size='sm'
											colorScheme={"lightBlue"}
											onClick={handleShowHidePassword}
											tabIndex={-1}>
											{passwordShow ? "Hide" : "Show"}
										</Button>
									</InputRightElement>
								</InputGroup>
							</Box>
							<Box ml={isSmallerScreen ? 0 : 3}>
								<FormErrorMessage
									colorScheme={"red"}
									className={"text-error pos"}>
									{errorsDeleteProfile.password && errorsDeleteProfile.password.message}
								</FormErrorMessage>
							</Box>
						</Flex>
					</FormControl>
					{/* <FormControl mt={isSmallerScreen ? 6 : 3}>
						<Flex direction={["column", "column", "row"]}>
							<Box minW={formLabelMinWidth} display={isSmallerScreen ? "none" : "normal"}>
								<FormLabel>&nbsp;</FormLabel>
							</Box>
							<Box>
								<Flex direction={["column", "column", "row"]}>
									<Button
										colorScheme='red'
										type={"submit"}
										onClick={handleSubmitDeleteProfile((data) => handleDeleteProfile(data))}>
										DELETE
									</Button>
								</Flex>
							</Box>
						</Flex>
					</FormControl> */}
				</Box>
			</ModalBody>
			<ModalFooter className={"modal-footer"}>
				<Flex
					direction={["column", "column", "row"]}
					w={["100%", "100%", ""]}
					justifyContent={"flex-end"}>
					<Button
						colorScheme='red'
						type={"submit"}
						minW={["100%", "100%", 100]}
						mr={[0, 0, 2]}
						onClick={handleSubmitDeleteProfile((data) => handleDeleteProfile(data))}>
						DELETE
					</Button>
					<Button
						colorScheme='grey'
						variant={"outline"}
						minW={["100%", "100%", 100]}
						mt={[2, 2, 0]}
						onClick={(e) => handleDeleteProfileModalClose(e)}>
						Cancel
					</Button>
				</Flex>
			</ModalFooter>
		</React.Fragment>
	);
};

export default DeleteProfile;
