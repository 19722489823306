import {
	Box,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Td,
	Tr,
	VStack,
	chakra,
	useDisclosure,
} from "@chakra-ui/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Markdown from "../../../../../../../Global/Markdown/Markdown";
import React from "react";

const DetailRow = ({ summary }) => {
	const { isOpen, onToggle, onClose } = useDisclosure();

	return (
		<Tr
			className={summary.informationText !== null ? "assessed-value-row-hover" : ""}
			onClick={summary.informationText !== null ? onToggle : null}>
			<Td className='assessed-value-info'>
				{summary.informationText !== null ? (
					<FontAwesomeIcon
						icon='fa-solid fa-circle-info'
						className='assessed-value-info-icon'
					/>
				) : (
					<FontAwesomeIcon
						icon='fa-solid fa-circle-info'
						className='assessed-value-info-icon transparent'
					/>
				)}
			</Td>
			<Td className='assessed-value-label'>{summary.variableLabel}</Td>
			<Td className='assessed-value-unit'>{summary.unitVariableValue}</Td>
			<Td>
				<Popover
					returnFocusOnClose={false}
					isOpen={isOpen}
					onClose={onClose}
					placement='right'
					closeOnBlur={true}>
					<PopoverTrigger>
						<chakra.span></chakra.span>
					</PopoverTrigger>
					<PopoverContent minW={{ base: "100%", lg: 500 }}>
						<PopoverArrow />
						<PopoverBody className='popover-shadow'>
							<VStack align={"left"}>
								<Box fontWeight={700}>
									<Markdown text={summary.variableLabel}></Markdown>
								</Box>
								<Box whiteSpace={"break-spaces"}>
									<Markdown text={summary.informationText}></Markdown>
								</Box>
							</VStack>
						</PopoverBody>
					</PopoverContent>
				</Popover>
			</Td>
			<Td className='assessed-value-value'>{summary.valueVariableValue}</Td>
			<Td className='assessed-value-value'>{summary.variableType}</Td>
		</Tr>
	);
};

export default DetailRow;
