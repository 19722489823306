import "./MunicipalTaxes.css";

import * as CommonFns from "../../../../../../common";
import * as toastMessages from "../../../../../../toastMessages";

import {
	Box,
	Button,
	Flex,
	FormLabel,
	HStack,
	Input,
	InputGroup,
	InputLeftAddon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spacer,
	Text,
	chakra,
	useBreakpointValue,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import { AuditContext } from "../../../../../../contexts/AuditContext";
import Breakdown from "./Breakdown/Breakdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { axiosMyPropertyAuth } from "../../../../../../apis";

const MunicipalTaxes = ({ accId, taxYear }) => {
	const isSmallerScreen = useBreakpointValue({ base: true, sm: false });
	const { isOpen: isOpenBreakdown, onOpen: onOpenBreakdown, onClose: onCloseBreakdown } = useDisclosure();

	const [municipalTaxes, setMunicipalTaxes] = useState();
	const [isAnnual, setIsAnnual] = useState(false);
	const [isSupplementary, setIsSupplementary] = useState(false);
	const [isAnnualSupplementary, setIsAnnualSupplementary] = useState(false);

	const annSuppFormControlWidth = 225;
	const addonAnnSuppFormControlWidth = 194;
	const formControlWidth = 300;
	const addonformControlWidth = 269;

	const { auditActivity } = useContext(AuditContext);

	useEffect(() => {
		getMunicipalTaxes();
	}, []);

	const toast = useToast();
	const taxMunicipalToastErrorId = "tax-municipal-toast-error";

	const getMunicipalTaxes = () => {
		let url = `taxes/municipal/${accId}`;
		axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					setMunicipalTaxes(returnObj);

					if (returnObj.dspAnnualAssessment === "Y" && returnObj.dspSuppAssessment === "Y") {
						setIsAnnualSupplementary(true);
					} else {
						if (returnObj.dspAnnualAssessment === "Y") {
							setIsAnnual(true);
						}
						if (returnObj.dspSuppAssessment === "Y") {
							setIsSupplementary(true);
						}
					}
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(taxMunicipalToastErrorId)) {
					toast(toastMessages.getToastError(taxMunicipalToastErrorId));
				}
			});
	};

	const handleViewBreakdown = () => {
		onOpenBreakdown();
	};

	// const handleBreakdownModalClose = (e) => {
	// 	e.preventDefault();
	// 	onCloseBreakdown();
	// };

	return (
		<React.Fragment>
			{municipalTaxes !== undefined ? (
				<React.Fragment>
					<Box>
						<Box
							maxW={1050}
							ml={3}>
							<Text fontSize={"xl"}>
								Municipal property taxes represent one of the few, but the most significant, funding
								avenues available to the City. Your contribution to the total amount helps sustain
								financial stability of our community and supports the quality of life for which our city
								has become known.
							</Text>
							<Text mt={4}>
								To calculate your share of municipal property taxes, every year the City establishes a
								tax rate by dividing the total operating budget by the total assessed value of all
								properties.
							</Text>
							<Text mt={4}>
								Then, to determine the exact amount you are required to pay, the City applies this tax
								rate to the value of your property.
							</Text>
						</Box>
						<Box
							mt={2}
							overflowX='auto'
							overflowY={"hidden"}>
							<Flex direction={["row"]}>
								<Box>
									<Flex direction={["column"]}>
										{isAnnual | isAnnualSupplementary ? (
											<Box className='municipal-taxes-calc-container'>
												<HStack>
													<Box>
														<Flex direction={["column"]}>
															<Box>
																<FormLabel htmlFor='annualAssessment'>
																	{taxYear} property
																	{isAnnualSupplementary ? <br /> : " "}
																	assessment
																</FormLabel>
															</Box>
															<Box>
																<InputGroup>
																	<InputLeftAddon
																		children={
																			<span>
																				<FontAwesomeIcon icon='fas fa-dollar-sign' />
																			</span>
																		}
																	/>
																	<Input
																		id='annualAssessment'
																		type='text'
																		readOnly
																		className='municipal-taxes-input addon'
																		width={
																			isAnnualSupplementary
																				? addonAnnSuppFormControlWidth
																				: addonformControlWidth
																		}
																		value={CommonFns.FormatCurrency(
																			municipalTaxes.annualAssessment,
																			0,
																			false
																		)}
																	/>
																</InputGroup>
															</Box>
														</Flex>
													</Box>
													<Flex direction={["column"]}>
														<Box>&nbsp;</Box>
														<Box
															className={`municipal-taxes-icon-container ${
																isAnnualSupplementary ? "ann-supp-pos" : "pos"
															} ${
																isAnnualSupplementary ? "ann-supp-margin" : "margin"
															} times`}>
															<Box className='municipal-taxes-icon'>
																<FontAwesomeIcon icon='fa-solid fa-times' />
															</Box>
														</Box>
													</Flex>
													<Box>
														<Flex direction={["column"]}>
															<Box>
																<FormLabel htmlFor='annualTaxRate'>
																	{taxYear} property
																	{isAnnualSupplementary ? <br /> : " "}
																	tax rate
																</FormLabel>
															</Box>
															<Box>
																<Input
																	id='annualTaxRate'
																	type='text'
																	readOnly
																	className='municipal-taxes-input'
																	width={
																		isAnnualSupplementary
																			? annSuppFormControlWidth
																			: formControlWidth
																	}
																	value={municipalTaxes.annualTaxRate}
																/>
															</Box>
														</Flex>
													</Box>
												</HStack>
											</Box>
										) : null}

										{isSupplementary | isAnnualSupplementary ? (
											<Box
												className='municipal-taxes-calc-container'
												mt={isAnnualSupplementary ? 1 : 0}>
												<HStack>
													<Box>
														<Flex direction={["column"]}>
															<Box>
																<FormLabel htmlFor='suppAssessment'>
																	{taxYear} supplementary
																	<br />
																	assessment
																</FormLabel>
															</Box>
															<Box>
																<InputGroup>
																	<InputLeftAddon
																		children={
																			<span>
																				<FontAwesomeIcon icon='fas fa-dollar-sign' />
																			</span>
																		}
																	/>
																	<Input
																		id='suppAssessment'
																		type='text'
																		readOnly
																		className='municipal-taxes-input addon'
																		width={addonAnnSuppFormControlWidth}
																		value={CommonFns.FormatCurrency(
																			municipalTaxes.suppAssessment,
																			0,
																			false
																		)}
																	/>
																</InputGroup>
															</Box>
														</Flex>
													</Box>
													<Flex direction={["column"]}>
														<Box>&nbsp;</Box>
														<Box
															className={`municipal-taxes-icon-container ann-supp-pos
														 ${isAnnualSupplementary ? "ann-supp-margin" : "margin"} times`}>
															<Box className='municipal-taxes-icon'>
																<FontAwesomeIcon icon='fa-solid fa-times' />
															</Box>
														</Box>
													</Flex>
													<Box>
														<Flex direction={["column"]}>
															<Box>
																<FormLabel htmlFor='suppMonths'>
																	Supplementary
																	<br />
																	months
																</FormLabel>
															</Box>
															<Box>
																<Input
																	id='suppMonths'
																	type='text'
																	readOnly
																	className='municipal-taxes-input'
																	width={annSuppFormControlWidth}
																	value={municipalTaxes.suppMonths + " / 12"}
																/>
															</Box>
														</Flex>
													</Box>
													<Flex direction={["column"]}>
														<Box>&nbsp;</Box>
														<Box
															className={`municipal-taxes-icon-container ann-supp-pos
														 ${isAnnualSupplementary ? "ann-supp-margin" : "margin"} times`}>
															<Box className='municipal-taxes-icon'>
																<FontAwesomeIcon icon='fa-solid fa-times' />
															</Box>
														</Box>
													</Flex>
													<Box>
														<Flex direction={["column"]}>
															<Box>
																<FormLabel htmlFor='suppTaxRate'>
																	{taxYear} supplementary
																	<br />
																	tax rate
																</FormLabel>
															</Box>
															<Box>
																<Input
																	id='suppTaxRate'
																	type='text'
																	readOnly
																	className='municipal-taxes-input'
																	width={annSuppFormControlWidth}
																	value={municipalTaxes.suppTaxRate}
																/>
															</Box>
														</Flex>
														<chakra.span></chakra.span>
													</Box>
												</HStack>
											</Box>
										) : null}
									</Flex>
								</Box>
								<Box
									className='municipal-taxes-total-container'
									width='100%'
									minW={isSmallerScreen ? 0 : 280}>
									{isAnnual | isSupplementary | isAnnualSupplementary ? (
										<Flex direction={["row"]}>
											<Flex direction={["column"]}>
												<Box>&nbsp;</Box>
												<Box
													className={`municipal-taxes-icon-container pos ${
														isAnnualSupplementary ? "full" : isSupplementary ? "supp" : ""
													} equal`}>
													<Box className='municipal-taxes-icon'>
														<FontAwesomeIcon icon='fa-solid fa-equals' />
													</Box>
												</Box>
											</Flex>
											<Flex
												direction={["column"]}
												h={isAnnualSupplementary ? 225 : isSupplementary ? 110 : 92}
												position={"relative"}
												left={"20px"}
												top={isAnnualSupplementary ? "2px" : isSupplementary ? "5px" : "2px"}
												justifyContent={"center"}
												alignItems={"center"}>
												<Flex direction={["column"]}>
													<Box>Your {taxYear} municipal taxes</Box>
													<Box
														fontWeight={700}
														fontSize={"1.2em"}>
														{CommonFns.FormatCurrency(municipalTaxes.taxAmount, 2)}
													</Box>
												</Flex>
											</Flex>
										</Flex>
									) : null}
								</Box>
							</Flex>
						</Box>
						<Box
							mt={2}
							className='municipal-taxes-breakdown-container'>
							<Box maxW={1050}>
								<Text>
									By legislation, the City collects just enough property taxes to support its budget
									for programs and services; it cannot collect more and it cannot collect less.
								</Text>
								<Text mt={4}>
									Your municipal property taxes help pay for police and fire protection, summer and
									winter road maintenance, parks, recreation centres, neighbourhood infrastructure—and
									much, much more.
								</Text>
							</Box>
							<Box mt={3}>
								<Flex>
									<Box pt={2}>
										<chakra.span fontWeight={700}>
											Your {taxYear} municipal tax breakdown
										</chakra.span>
									</Box>
									<Spacer />
									<Box>
										<Button
											colorScheme='lightBlue'
											as='button'
											minW={"5rem"}
											size={"sm"}
											onClick={(e) => {
												auditActivity("View Municipal Tax Breakdown", accId);
												handleViewBreakdown(e);
											}}>
											View
										</Button>
									</Box>
								</Flex>
							</Box>
						</Box>
					</Box>
				</React.Fragment>
			) : null}

			<Modal
				size={"3xl"}
				closeOnOverlayClick={true}
				isOpen={isOpenBreakdown}
				onClose={onCloseBreakdown}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className={"modal-header"}>Municipal Tax Breakdown</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					<ModalBody>
						<Breakdown accId={accId}></Breakdown>
					</ModalBody>
					<ModalFooter className={"modal-footer"}>
						{/* <Button colorScheme="blue" size={'sm'} onClick={(e) => handleBreakdownModalClose(e)}>
							Close
						</Button> */}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</React.Fragment>
	);
};

export default MunicipalTaxes;
