import {
	Box,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Td,
	Tr,
	VStack,
	chakra,
	useDisclosure,
} from "@chakra-ui/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const MarketApproachRow = ({ summary }) => {
	const { isOpen, onToggle, onClose } = useDisclosure();
	return (
		<Tr
			className={
				summary.assessmentApproachDef !== null
					? "assessed-value-market-approach assessed-value-row-hover"
					: "assessed-value-market-approach"
			}
			onClick={summary.assessmentApproachDef !== null ? onToggle : null}
		>
			<Td className="assessed-value-info">
				{summary.assessmentApproachDef !== null ? (
					<FontAwesomeIcon icon="fa-solid fa-circle-info" className="assessed-value-info-icon" />
				) : (
					<FontAwesomeIcon icon="fa-solid fa-circle-info" className="assessed-value-info-icon transparent" />
				)}
			</Td>
			<Td className="assessed-value-label">Market Value Approach</Td>
			<Td className="assessed-value-unit">{summary.assessmentApproach}</Td>
			<Td>
				<Popover
					returnFocusOnClose={false}
					isOpen={isOpen}
					onClose={onClose}
					placement="right"
					closeOnBlur={true}
				>
					<PopoverTrigger>
						<chakra.span></chakra.span>
					</PopoverTrigger>
					<PopoverContent minW={{ base: "100%", lg: 500 }}>
						<PopoverArrow />
						<PopoverBody className="popover-shadow">
							<VStack align={"left"}>
								<Box fontWeight={700}>{summary.assessmentApproach}</Box>
								<Box whiteSpace={"break-spaces"}>{summary.assessmentApproachDef}</Box>
							</VStack>
						</PopoverBody>
					</PopoverContent>
				</Popover>
			</Td>
			<Td></Td>
			<Td></Td>
		</Tr>
	);
};

export default MarketApproachRow;
