import "./Banner.css";

import { Box, Button, Flex } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import { BannerContext } from "../../../contexts/BannerContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Markdown from "../../Global/Markdown/Markdown";
import RenewAuthorization from "../Profile/ShareYourInformation/RenewAuthorization/RenewAuthorization";

const Banner = () => {
	const { bannerItems, getBannerItems, closeBannerItem, refreshBannerItem } = useContext(BannerContext);
	const [openRenewAuthModal, setOpenRenewAuthModal] = useState(false);
	const [refreshDataToggle, setRefreshDataToggle] = useState();
	const [renewalMessageType, setRenewalMessageType] = useState()

	const [items, setItems] = useState([]);

	useEffect(() => {
		getBannerItems();
	}, []);

	useEffect(() => {
		if (refreshDataToggle !== undefined) {
			refreshBannerItem(renewalMessageType);
		}
	}, [refreshDataToggle]);

	useEffect(() => {
		setItems(bannerItems);
	}, [bannerItems]);

	const handleBannerClose = (e, id, messageType, closureType) => {
		e.preventDefault();
		closeBannerItem(id, messageType, closureType);
	};

	const handleOwnerRenewalClick = (messageType) => {
		setRenewalMessageType(messageType)
		setRefreshDataToggle(false);
		setOpenRenewAuthModal(true);
	};

	return (
		<React.Fragment>
			{items !== undefined ? (
				items !== null ? (
					items.length > 0 ? (
						<Box
							mb={3}
							className='banner-container'>
							{items?.map((item, index) => (
								<React.Fragment key={index}>
									{item.color !== "invalid" &&
									item.icon !== "invalid" &&
									item.closure !== "invalid" ? (
										<Box className={`banner-item`}>
											<Flex direction={["row"]}>
												<Box className={`banner-icon-container ${item.color}`}>
													<Flex
														className='banner-icon'
														justifyContent={"center"}
														mt={3}>
														{item.icon !== "fa-circle" ? (
															<FontAwesomeIcon icon={`fa-solid ${item.icon}`} />
														) : (
															<FontAwesomeIcon icon={`fa-regular ${item.icon}`} />
														)}
													</Flex>
												</Box>
												<Box className={`banner-text-container ${item.color}`}>
													<Box
														className='banner-text'
														ml={3}
														mt={2}
														mb={2}>
														<Markdown text={item.message}></Markdown>
														{item.messageType === "AGENT_BLUE_BANNER_OWNER_ARA_PERIOD" ||
														 item.messageType === "AGENT_ORANGE_BANNER_OWNER_ARA_GRACE_PERIOD" ? (
															<>
																<Button
																	mt={2}
																	size={"sm"}
																	colorScheme='lightBlue'
																	onClick={(e) => {
																		handleOwnerRenewalClick(item.messageType);
																	}}>
																	Renew Access
																</Button>
															</>
														) : null}
													</Box>
												</Box>
												{item.closureType !== "none" ? (
													<Box className={`banner-close-container ${item.color}`}>
														<Box className={`banner-close ${item.color}`}>
															<Button
																size={"sm"}
																onClick={(e) =>
																	handleBannerClose(
																		e,
																		item.id,
																		item.messageType,
																		item.closureType
																	)
																}>
																<FontAwesomeIcon icon='fa-solid fa-times' />
															</Button>
														</Box>
													</Box>
												) : null}
											</Flex>
										</Box>
									) : null}
								</React.Fragment>
							))}
						</Box>
					) : null
				) : null
			) : null}
			<RenewAuthorization
				openRenewAuthModal={openRenewAuthModal}
				setOpenRenewAuthModal={setOpenRenewAuthModal}
				refreshDataToggle={refreshDataToggle}
				setRefreshDataToggle={setRefreshDataToggle}
			/>
		</React.Fragment>
	);
};

export default Banner;
