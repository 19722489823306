import "./LIPayOut.css";

import * as CommonFns from "../../../../../../../common";

import { Box, Checkbox, Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import Markdown from "../../../../../../Global/Markdown/Markdown";

const LIPayOut = ({ localImprovement }) => {
	const [payOutDetails, setPayOutDetails] = useState();
	const [payOutTotal, setPayOutTotal] = useState(parseFloat(0.0));

	useEffect(() => {
		getPayOutDetail(localImprovement);
	}, [localImprovement]);

	const getPayOutDetail = (localImprovement) => {
		// filter
		// group type LOCIMP
		// has payoutAmount
		let payOutFilter = localImprovement.liGroupCol
			.filter((x) => x.liGroup === "LOCIMP")
			.map((x) => {
				return {
					...x,
					liGroupDtls: x.liGroupDtls.filter((y) => y.payoutAmount !== null),
				};
			});

		setPayOutDetails(payOutFilter[0].liGroupDtls);
	};

	const handlePayOutChange = (e) => {
		let checked = e.target.checked;
		let value = e.target.value;

		checked
			? setPayOutTotal(parseFloat(payOutTotal) + parseFloat(value))
			: setPayOutTotal(parseFloat(payOutTotal) - parseFloat(value));
	};

	return (
		<React.Fragment>
			{localImprovement !== undefined && payOutDetails !== undefined ? (
				<React.Fragment>
					<Box maxW={785}>
						<Box>
							<Text>
								<Markdown text={localImprovement.liPayoutMessage}></Markdown>
							</Text>
						</Box>
						<Box mt={3}>
							<Text>
								<Markdown text={localImprovement.liPayoutForm_step1Msg}></Markdown>
							</Text>
						</Box>
					</Box>
					<Box mt={3}>
						<TableContainer>
							<Box minW={750}>
								<Table className='li-pay-out-table'>
									<Thead>
										<Tr>
											<Th className='border-none'></Th>
											<Th>
												First
												<br />
												year
											</Th>
											<Th>
												Expiry
												<br />
												year
											</Th>
											<Th>
												Interest
												<br />
												Rate
											</Th>
											<Th>
												Payable
												<br />
												balance
											</Th>
											<Th>
												Optional payout
												<br />
												due by
											</Th>
										</Tr>
									</Thead>
									<Tbody>
										{payOutDetails.map((detail, index) => (
											<React.Fragment key={index}>
												<Tr>
													<Td
														w={"30%"}
														className='text-left pad-left'>
														<Checkbox
															value={detail.payoutAmount}
															onChange={(e) => handlePayOutChange(e)}>
															{detail.liType}
														</Checkbox>
													</Td>
													<Td w={"10%"}>{detail.firstYear}</Td>
													<Td w={"10%"}>{detail.expiryYear}</Td>
													<Td w={"10%"}>
														<Flex justifyContent='center'>{detail.interestRate}%</Flex>
													</Td>
													<Td
														w={"15%"}
														fontWeight={700}>
														<Flex justifyContent='center'>
															{CommonFns.FormatCurrency(detail.payoutAmount, 2, true)}
														</Flex>
													</Td>
													<Td
														w={"25%"}
														fontWeight={700}>
														{CommonFns.FormatDate(detail.liPaymentDate)}
													</Td>
												</Tr>
											</React.Fragment>
										))}
									</Tbody>
								</Table>
							</Box>
						</TableContainer>
					</Box>
					<Box mt={3}>
						<Text>
							<Markdown text={localImprovement.liPayoutForm_step2Msg}></Markdown>
						</Text>
					</Box>
					<TableContainer>
						<Box
							mt={3}
							minW={750}>
							<Flex className='li-pay-out-submit-payment'>
								<Box className='due-date'>
									<VStack>
										<Box>Due date</Box>
										<Box
											className='value'
											fontSize={"lg"}
											fontWeight={700}>
											{CommonFns.FormatDate(localImprovement.liPaymentDueDate)}
										</Box>
									</VStack>
								</Box>
								<Box className='account'>
									<VStack>
										<Box>Your local improvement account</Box>
										<Box
											className='value'
											fontSize={"lg"}
											fontWeight={700}>
											{localImprovement.liAccountNum}
										</Box>
									</VStack>
								</Box>
								<Box className='amount'>
									<VStack>
										<Box>Payout amount</Box>
										<Box
											className='value'
											fontSize={"lg"}
											fontWeight={700}>
											{CommonFns.FormatCurrency(payOutTotal, 2, true)}
										</Box>
									</VStack>
								</Box>
							</Flex>
						</Box>
					</TableContainer>
					<Box
						mt={3}
						maxW={785}>
						<Text>
							<Markdown text={localImprovement.liPayoutForm_bottomMsg}></Markdown>
						</Text>
					</Box>
				</React.Fragment>
			) : null}
		</React.Fragment>
	);
};

export default LIPayOut;
