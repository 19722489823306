import * as toastMessages from "../../../../toastMessages";

import {
	Box,
	Button,
	Checkbox,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	ListItem,
	ModalBody,
	ModalCloseButton,
	ModalFooter,
	ModalHeader,
	Text,
	UnorderedList,
	chakra,
	useBreakpointValue,
	useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import React, { useContext, useState } from "react";
import { axiosMyProperty, axiosMyPropertyAuth } from "../../../../apis";

import { AuthContext } from "../../../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ChangeEmail = ({ userProfile, closeChangeEmailModal }) => {
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });
	const { authDetails, setAuthInUpdateEmailProcess } = useContext(AuthContext);

	const toast = useToast();
	const changeEmailToastErrorId = "change-email-toast-error";
	const changeEmailToastWarningId = "change-email-toast-warning";

	const formLabelMinWidth = 150;
	const formControlMinWidth = 300;

	const [currentStep, setCurrentStep] = useState("1");
	const [isStepOne, setIsStepOne] = useState(true);
	const [isStepTwo, setIsStepTwo] = useState(false);
	const [isStepThree, setIsStepThree] = useState(false);
	const [newEmailAddress, setNewEmailAddress] = useState("");
	const [passwordShow, setPasswordShow] = useState(false);
	const handleShowHidePassword = () => setPasswordShow(!passwordShow);

	const {
		register: registerChangeEmailPassword,
		handleSubmit: handleSubmitChangeEmailPassword,
		reset: resetChangeEmailPassword,
		control: controlTerms,
		formState: { errors: errorsChangeEmailPassword },
	} = useForm({
		defaultValues: {
			password: "",
		},
	});

	const {
		register: registerChangeEmail,
		handleSubmit: handleSubmitChangeEmail,
		reset: resetChangeEmail,
		watch: watchChangeEmail,
		formState: { errors: errorsChangeEmail },
	} = useForm({
		defaultValues: {
			email: "",
			emailConfirm: "",
		},
	});

	const watchEmail = watchChangeEmail("email", "");

	const handleChangeEmailModalClose = (e) => {
		closeChangeEmailModal(e);
		resetForm();
	};

	const resetForm = () => {
		resetSteps();
		setNewEmailAddress();
		resetChangeEmailPassword();
		resetChangeEmail();
	};

	const resetSteps = () => {
		setIsStepOne(true);
		setIsStepTwo(false);
		setIsStepThree(false);
		setCurrentStep("1");
	};

	const handleStepOne = (data) => {
		// validate password
		let payload = {
			uId: authDetails.uId,
			password: data.password,
		};

		let url = "profile/password/verify";
		axiosMyProperty
			.put(url, payload)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					setIsStepOne(false);
					setIsStepTwo(true);
					setIsStepThree(false);
					setCurrentStep("2");
				} else {
					if (!toast.isActive(changeEmailToastWarningId)) {
						toast(
							toastMessages.getToastWarning(
								changeEmailToastWarningId,
								returnObj.errorMsg,
								toastMessages.changeEmailMessages
							)
						);
					}
				}
			})
			.catch((e) => {
				// error
				if (!toast.isActive(changeEmailToastErrorId)) {
					toast(toastMessages.getToastError(changeEmailToastErrorId));
				}
			});
	};

	const handleClearChangeEmailPassword = (e) => {
		e.preventDefault();
		resetChangeEmailPassword();
	};

	const handleStepTwo = (data) => {
		let payload = {
			uId: "",
			newEmailAddress: data.email,
			name: userProfile.profileName,
		};

		let url = "profile/email/update/request";
		axiosMyPropertyAuth
			.put(url, payload)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.isSuccess === true) {
					setIsStepOne(false);
					setIsStepTwo(false);
					setIsStepThree(true);
					setNewEmailAddress(payload.newEmailAddress);
					setAuthInUpdateEmailProcess();
					setCurrentStep("3");
				} else {
					if (!toast.isActive(changeEmailToastWarningId)) {
						toast(
							toastMessages.getToastWarning(
								changeEmailToastWarningId,
								returnObj.errorMsg,
								toastMessages.changeEmailMessages
							)
						);
					}
				}
			})
			.catch((e) => {
				// error
				if (!toast.isActive(changeEmailToastErrorId)) {
					toast(toastMessages.getToastError(changeEmailToastErrorId));
				}
			});
	};

	const handleClearChangeEmail = (e) => {
		e.preventDefault();
		resetChangeEmail();
	};

	const emailExists = (value) => {
		let url = `profile/exists/${value}`;
		return axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;
				// special case, retVal is inccorect on return
				// do not check retVal
				if (returnObj.isRegistered === "Y") {
					// email is already registered
					// return false, validation message will be displayed
					return false;
				} else {
					// email is not registered
					// return true, validation message will not be displayed
					return true;
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(changeEmailToastErrorId)) {
					toast(toastMessages.getToastError(changeEmailToastErrorId));
				}
			});
	};

	const emailMatch = (value) => {
		return value === watchEmail ? true : false;
	};

	return (
		<React.Fragment>
			<ModalHeader className={"modal-header"}>Change Email: Step {currentStep} of 3</ModalHeader>
			<ModalCloseButton className='modal-close-button'>
				<FontAwesomeIcon
					size={"2xl"}
					icon='fa-solid fa-xmark'
				/>
			</ModalCloseButton>
			<ModalBody>
				<Box>
					{isStepOne ? (
						// <form
						// 	id='changeEmailPassword'
						// 	onSubmit={handleSubmitChangeEmailPassword((data) => handleStepOne(data))}>
						<Box mb={2}>
							<Box mb={3}>After you change your email address:</Box>
							<Box
								mb={6}
								maxW={550}
								ml={1}>
								<UnorderedList>
									<ListItem>
										You will use the updated email address to log in to the MyProperty website
									</ListItem>
									<ListItem>
										Any correspondence from MyProperty, including electronic notifications, will be
										sent to the updated email address
									</ListItem>
								</UnorderedList>
							</Box>
							<chakra.span fontSize={"2xl"}>To continue, enter your password:</chakra.span>
							<FormControl
								isInvalid={errorsChangeEmailPassword.password}
								mt={2}>
								<Flex direction={["column", "column", "row"]}>
									<Box minW={formLabelMinWidth}>
										<FormLabel
											htmlFor='password'
											textAlign={isSmallerScreen ? "" : "end"}
											className='form-label-fixed'>
											Your Password
										</FormLabel>
									</Box>
									<Box minW={formControlMinWidth}>
										<InputGroup>
											<Input
												id='password'
												type={passwordShow ? "text" : "password"}
												placeholder=''
												autoComplete='off'
												{...registerChangeEmailPassword("password", {
													required: "Your Password is required",
												})}
											/>
											<InputRightElement width='4.5rem'>
												<Button
													h='1.75rem'
													w={"56px"}
													size='sm'
													colorScheme={"lightBlue"}
													onClick={handleShowHidePassword}
													tabIndex={-1}>
													{passwordShow ? "Hide" : "Show"}
												</Button>
											</InputRightElement>
										</InputGroup>
									</Box>
									<Box ml={isSmallerScreen ? 0 : 3}>
										<FormErrorMessage
											colorScheme={"red"}
											className={"text-error pos"}>
											{errorsChangeEmailPassword.password &&
												errorsChangeEmailPassword.password.message}
										</FormErrorMessage>
									</Box>
								</Flex>
							</FormControl>
							<FormControl
								isInvalid={errorsChangeEmailPassword.terms}
								mt={2}>
								<Flex direction={["column", "column", "row"]}>
									<Box
										minW={formLabelMinWidth}
										display={isSmallerScreen ? "none" : "normal"}>
										<FormLabel>&nbsp;</FormLabel>
									</Box>
									<Box>
										<Box mt={isSmallerScreen ? 2 : 0}>
											<Controller
												control={controlTerms}
												name='terms'
												defaultValue={false}
												render={({ field: { onChange, value, ref } }) => (
													<Checkbox
														size={"lg"}
														onChange={onChange}
														ref={ref}
														isChecked={value}
														{...registerChangeEmailPassword("terms", {
															required:
																"You must accept the MyProperty Privacy Terms and MyProperty Use of Information Disclaimer",
														})}>
														<chakra.span
															fontWeight={700}
															fontSize={".85em"}>
															I've read and agree to MyProperty Privacy Terms and
															MyProperty Use of Information Disclaimer.{" "}
															<chakra.span
																color={"#00a5d4"}
																fontStyle={"italic"}>
																(Mandatory)
															</chakra.span>
														</chakra.span>
													</Checkbox>
												)}
											/>
										</Box>
										<Box>
											<FormErrorMessage
												colorScheme={"red"}
												className={"text-error"}>
												{errorsChangeEmailPassword.terms &&
													errorsChangeEmailPassword.terms.message}
											</FormErrorMessage>
										</Box>
									</Box>
									<Box ml={isSmallerScreen ? 0 : 3}></Box>
								</Flex>
							</FormControl>
							{/* <FormControl mt={isSmallerScreen ? 6 : 3}>
									<Flex direction={["column", "column", "row"]}>
										<Box minW={formLabelMinWidth} display={isSmallerScreen ? "none" : "normal"}>
											<FormLabel>&nbsp;</FormLabel>
										</Box>
										<Box>
											<Flex direction={["column", "column", "row"]}>
												<Button
													mr={isSmallerScreen ? 0 : 2}
													w={isSmallerScreen ? "100%" : 100}
													colorScheme='lightBlue'
													type='submit'>
													NEXT
												</Button>

												<Button
													colorScheme='grey'
													type='reset'
													variant={"outline"}
													w={isSmallerScreen ? "100%" : 100}
													mt={isSmallerScreen ? 4 : 0}
													onClick={(e) => handleClearChangeEmailPassword(e)}>
													CLEAR
												</Button>
											</Flex>
										</Box>
									</Flex>
								</FormControl> */}
						</Box>
					) : // </form>
					null}

					{isStepTwo ? (
						// <form
						// 	id='changeEmailEmailAddress'
						// 	onSubmit={handleSubmitChangeEmail((data) => handleStepTwo(data))}>
						<Box mb={2}>
							<chakra.span fontSize={"2xl"}>Enter your new email address:</chakra.span>
							<FormControl
								isInvalid={errorsChangeEmail.email}
								mt={2}>
								<Flex direction={["column", "column", "row"]}>
									<Box minW={formLabelMinWidth}>
										<FormLabel
											htmlFor='email'
											textAlign={isSmallerScreen ? "" : "end"}
											className='form-label-fixed'>
											Your new email
										</FormLabel>
									</Box>
									<Box minW={formControlMinWidth}>
										<Input
											id='email'
											type='email'
											placeholder=''
											autoComplete='off'
											{...registerChangeEmail("email", {
												required: "Your new email is required",
												pattern: {
													value: /^[\w.-]+@([\w-]+\.)+[\w-]{2,}$/g,
													message: "Your new email is invalid",
												},
												validate: emailExists,
											})}
										/>
									</Box>
									<Box ml={isSmallerScreen ? 0 : 3}>
										<FormErrorMessage
											colorScheme={"red"}
											className={"text-error pos"}>
											{errorsChangeEmail.email && errorsChangeEmail.email.message}
											{errorsChangeEmail.email?.type === "validate" && (
												<chakra.span>
													The provided email has already been registered.
												</chakra.span>
											)}
										</FormErrorMessage>
									</Box>
								</Flex>
							</FormControl>
							<FormControl
								isInvalid={errorsChangeEmail.emailConfirm}
								mt={2}>
								<Flex direction={["column", "column", "row"]}>
									<Box minW={formLabelMinWidth}>
										<FormLabel
											htmlFor='emailConfirm'
											textAlign={isSmallerScreen ? "" : "end"}
											className='form-label-fixed'>
											Retype your email
										</FormLabel>
									</Box>
									<Box minW={formControlMinWidth}>
										<Input
											id='emailConfirm'
											type='email'
											placeholder=''
											autoComplete='off'
											{...registerChangeEmail("emailConfirm", {
												required: "Retype your email is required",
												validate: emailMatch,
											})}
										/>
									</Box>
									<Box ml={isSmallerScreen ? 0 : 3}>
										<FormErrorMessage
											colorScheme={"red"}
											className={"text-error pos"}>
											{errorsChangeEmail.emailConfirm && errorsChangeEmail.emailConfirm.message}
											{errorsChangeEmail.emailConfirm?.type === "validate" && (
												<chakra.span>Emails do not match</chakra.span>
											)}
										</FormErrorMessage>
									</Box>
								</Flex>
							</FormControl>
							{/* <FormControl mt={isSmallerScreen ? 6 : 3}>
									<Flex direction={["column", "column", "row"]}>
										<Box minW={formLabelMinWidth} display={isSmallerScreen ? "none" : "normal"}>
											<FormLabel>&nbsp;</FormLabel>
										</Box>
										<Box>
											<Flex direction={["column", "column", "row"]}>
												<Button
													mr={isSmallerScreen ? 0 : 2}
													w={isSmallerScreen ? "100%" : 100}
													colorScheme='lightBlue'
													type='submit'>
													NEXT
												</Button>
												<Button
													colorScheme='grey'
													type='reset'
													variant={"outline"}
													w={isSmallerScreen ? "100%" : 100}
													mt={isSmallerScreen ? 4 : 0}
													onClick={(e) => handleClearChangeEmail(e)}>
													CLEAR
												</Button>
											</Flex>
										</Box>
									</Flex>
								</FormControl> */}
						</Box>
					) : // </form>
					null}

					{isStepThree ? (
						<React.Fragment>
							<chakra.span fontSize={"2xl"}>Thank you. Now, check your email.</chakra.span>
							<Flex
								direction={["column", "column", "column"]}
								alignItems={"center"}>
								<Box mt={6}>We’ve just sent a verification email to the following email address:</Box>
								<Box mt={4}>
									<chakra.span fontWeight={700}>{newEmailAddress}</chakra.span>
								</Box>
								<Box
									mt={4}
									mb={2}>
									<Box maxW={550}>
										<Text>
											Please see this email, and click the Verify button to complete the process
											and change the email on your MyProperty profile.
										</Text>
										<Text mt={4}>
											Verification emails can sometimes be directed to your junk mail or spam
											folders.
										</Text>
									</Box>
								</Box>
							</Flex>
						</React.Fragment>
					) : null}
				</Box>
			</ModalBody>
			<ModalFooter className={"modal-footer"}>
				{isStepOne || isStepTwo ? (
					<>
						<Button
							mr={2}
							//w={isSmallerScreen ? "100%" : 100}
							colorScheme='lightBlue'
							type='submit'
							onClick={
								isStepOne
									? handleSubmitChangeEmailPassword((data) => handleStepOne(data))
									: handleSubmitChangeEmail((data) => handleStepTwo(data))
							}>
							NEXT
						</Button>

						<Button
							colorScheme='grey'
							type='reset'
							variant={"outline"}
							//w={isSmallerScreen ? "100%" : 100}
							//mt={isSmallerScreen ? 4 : 0}
							onClick={(e) =>
								isStepOne ? handleClearChangeEmailPassword(e) : handleClearChangeEmail(e)
							}>
							CLEAR
						</Button>
					</>
				) : null}
				{isStepThree ? (
					<Flex w={["100%", "100%", "auto"]}>
						<Button
							colorScheme='blue'
							minW={["100%", "100%", 100]}
							onClick={(e) => handleChangeEmailModalClose(e)}>
							OK
						</Button>
					</Flex>
				) : null}
			</ModalFooter>
		</React.Fragment>
	);
};

export default ChangeEmail;
