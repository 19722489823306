import "./FirstSignIn.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import {
	Box,
	Button,
	Flex,
	Image,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spacer,
	chakra,
	useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";

import { AuditContext } from "../../../../contexts/AuditContext";
import { AuthContext } from "../../../../contexts/AuthContext";
import { Carousel } from "react-responsive-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";

const FirstSignIn = ({ openFirstSignIn }) => {
	const { authDetails, setAuthDetails } = useContext(AuthContext);
	const { auditActivity } = useContext(AuditContext);
	const { isOpen: isOpenFirstSignIn, onOpen: onOpenFirstSignIn, onClose: onCloseFirstSignIn } = useDisclosure();
	const ImageStep4 = require("./Images/step_04.png");
	const ImageAddProperty = require("./Images/add_property.png");
	const ImageNotice = require("./Images/notices.png");
	const handleCarouselOnChange = () => {};

	const carouselMaxIndex = 3;
	const carouselMinIndex = 0;
	const [carouselIndex, setCarouselIndex] = useState(0);

	useEffect(() => {
		if (authDetails?.profileIsNew === "Y") {
			handleCarouselOpen();

			// audit
			// one "Sign In" audit occurs when using the Sign In form
			// a second "Sign In" audit here will set "IsProfileNew = N" during any subsequent sign in
			auditActivity("Sign In");
		}
	}, []);

	const handleCarouselOpen = () => {
		setCarouselIndex(0);
		onOpenFirstSignIn();
	};

	const handleCarouselNext = (e) => {
		e.preventDefault();
		setCarouselIndex(carouselIndex + 1);
	};

	const handleCarouselPrev = (e) => {
		e.preventDefault();
		setCarouselIndex(carouselIndex - 1);
	};

	const handleCarouselClose = (e) => {
		e.preventDefault();
		onCloseFirstSignIn();

		// set context not to open the modal again
		// subsequent calls to TACS should now have profileIsNew set to "N" based on two or more audit logs for "Sign In"
		setAuthDetails({
			...authDetails,
			profileIsNew: "N",
			displayPaperlessPromo: "N",
		});
	};

	return (
		<React.Fragment>
			<Modal
				closeOnOverlayClick={false}
				closeOnEsc={false}
				isOpen={isOpenFirstSignIn}
				onClose={onCloseFirstSignIn}
			>
				<ModalOverlay />
				<ModalContent maxW={"30rem"}>
					<ModalHeader className={"first-sign-in-modal-header"}>
						<Flex direction={["row"]} justifyContent={"right"}>
							<Box className={"first-sign-in-close-container"}>
								<Button
									zIndex={-1}
									h={0}
									w={0}
									_focus={{ border: "none", boxShadow: "none" }}
									backgroundColor={"transparent"}
								></Button>
								<Button onClick={(e) => handleCarouselClose(e)} size={"sm"}>
									<FontAwesomeIcon icon="fa-solid fa-times" />
								</Button>
							</Box>
						</Flex>
					</ModalHeader>
					<ModalBody className="first-sign-in-modal-body">
						<Carousel
							showThumbs={false}
							showArrows={false}
							showIndicators={false}
							selectedItem={carouselIndex}
							onChange={handleCarouselOnChange()}
							showStatus={false}
							swipeable={false}
						>
							<Flex direction={["column"]}>
								<Box className="first-sign-in-bubble-container">
									<Box pt={16}>
										<chakra.span
											className="first-sign-in-orange-text"
											fontSize={"4xl"}
											fontWeight={700}
										>
											Welcome, {authDetails.profileName}!
										</chakra.span>
									</Box>
									<Box pt={7}>
										<chakra.span
											className="first-sign-in-dark-blue-text"
											fontSize={"4xl"}
											fontWeight={700}
											lineHeight={10}
										>
											We are thrilled
											<br />
											to have you on board.
										</chakra.span>
									</Box>
								</Box>
								<Box className="first-sign-in-step-4-container" pt={2}>
									<Image src={ImageStep4}></Image>
								</Box>
								<Box pt={4}>
									<Flex justifyContent={"center"}>
										<Box w={400}>
											<chakra.span className="first-sign-in-dark-blue-text" fontSize={"xl"}>
												To gain full access to your property's data, we now ask you to add your
												property to your MyProperty profile.
											</chakra.span>
										</Box>
									</Flex>
								</Box>
								<Box pt={4} pb={4}>
									<Flex justifyContent={"center"}>
										<chakra.span className="first-sign-in-light-blue-text" fontSize={"xl"}>
											Here is how...
										</chakra.span>
									</Flex>
								</Box>
							</Flex>

							<Flex direction={["column"]}>
								<Box pt={8} pb={6}>
									<Box mb={1}>
										<chakra.span
											className="first-sign-in-light-blue-text"
											fontSize={"xl"}
											fontWeight={700}
										>
											Locate
										</chakra.span>
									</Box>
									<Box>
										<chakra.span
											className="first-sign-in-dark-blue-text"
											fontSize={"xl"}
											fontWeight={700}
										>
											+ ADD A PROPERTY
										</chakra.span>
									</Box>
									<Box>
										<chakra.span
											className="first-sign-in-light-blue-text"
											fontSize={"xl"}
											fontWeight={700}
										>
											on the home page
										</chakra.span>
									</Box>
								</Box>
								<Box>
									<Image src={ImageAddProperty}></Image>
								</Box>
							</Flex>

							<Flex direction={["column"]}>
								<Box pt={8} pb={6}>
									<Flex direction={["column"]} alignItems={"center"}>
										<Box w={220} mb={2}>
											<chakra.span
												className="first-sign-in-light-blue-text"
												fontSize={"xl"}
												fontWeight={700}
											>
												Enter account number and access code
											</chakra.span>
										</Box>
										<Box w={400} mb={4}>
											<chakra.span
												className="first-sign-in-dark-blue-text"
												fontSize={"xl"}
												fontStyle={"italic"}
											>
												both are printed on your most recent assessment or tax notice
											</chakra.span>
										</Box>
									</Flex>
									<Box mb={2}>
										<Image src={ImageNotice}></Image>
									</Box>
									<Box>
										<chakra.span className="first-sign-in-light-blue-text" fontSize={"xl"}>
											Then, verify ownership
										</chakra.span>
									</Box>
								</Box>
							</Flex>

							<Flex direction={["column"]}>
								<Box className="first-sign-in-bubble-container">
									<Box pt={"168px"}>
										<chakra.span
											className="first-sign-in-orange-text"
											fontSize={"4xl"}
											fontWeight={700}
										>
											Voilà!
										</chakra.span>
									</Box>
								</Box>
								<Box>
									<Flex direction={["column"]} alignItems={"center"}>
										<Box w={310} pt={4}>
											<chakra.span
												className="first-sign-in-light-blue-text"
												fontSize={"2xl"}
												fontWeight={700}
											>
												Here are some of the many features you can enjoy right away
											</chakra.span>
										</Box>
									</Flex>
								</Box>
								<Box ml={6} mr={6} mt={4} textAlign="left">
									<Flex direction={["column"]} alignItems={"unset"}>
										<Box>
											<Flex direction={["row"]}>
												<Box className="first-sign-in-green-text" pt={"5px"} pr={2}>
													<FontAwesomeIcon icon="fa-solid fa-check" />
												</Box>
												<Box>
													<chakra.span
														className="first-sign-in-dark-blue-text"
														fontSize={"xl"}
														w={300}
													>
														detailed property information
													</chakra.span>
												</Box>
											</Flex>
										</Box>

										<Box mt={2}>
											<Flex direction={["row"]}>
												<Box className="first-sign-in-green-text" pt={"5px"} pr={2}>
													<FontAwesomeIcon icon="fa-solid fa-check" />
												</Box>
												<Box>
													<chakra.span
														className="first-sign-in-dark-blue-text"
														fontSize={"xl"}
														w={300}
													>
														property tax balance and transaction history
													</chakra.span>
												</Box>
											</Flex>
										</Box>

										<Box mt={2}>
											<Flex direction={["row"]}>
												<Box className="first-sign-in-green-text" pt={"5px"} pr={2}>
													<FontAwesomeIcon icon="fa-solid fa-check" />
												</Box>
												<Box>
													<Box
														className="first-sign-in-dark-blue-text"
														fontSize={"xl"}
														w={300}
													>
														five-year property history and{" "}
														<chakra.span className="first-sign-in-light-blue-text">
															much, much more!
														</chakra.span>
													</Box>
												</Box>
											</Flex>
										</Box>
									</Flex>
								</Box>
							</Flex>
						</Carousel>
					</ModalBody>
					<ModalFooter className={"first-sign-in-modal-footer"}>
						<Flex>
							<Box>
								{carouselIndex > carouselMinIndex ? (
									<Button colorScheme={"lightBlue"} w={125} onClick={(e) => handleCarouselPrev(e)}>
										PREVIOUS
									</Button>
								) : (
									<Box minW={125}></Box>
								)}
							</Box>
							<Spacer />
							<Box className="first-sign-in-indicators-container">
								<Box className={`indicator ${carouselIndex === 0 ? "active" : ""}`} mr={1}></Box>
								<Box className={`indicator ${carouselIndex === 1 ? "active" : ""}`} mr={1}></Box>
								<Box className={`indicator ${carouselIndex === 2 ? "active" : ""}`} mr={1}></Box>
								<Box className={`indicator ${carouselIndex === 3 ? "active" : ""}`}></Box>
							</Box>
							<Spacer />
							<Box>
								{carouselIndex !== carouselMaxIndex ? (
									<Button colorScheme={"lightBlue"} w={125} onClick={(e) => handleCarouselNext(e)}>
										NEXT
									</Button>
								) : (
									<Button colorScheme={"lightBlue"} w={125} onClick={(e) => handleCarouselClose(e)}>
										GOT IT!
									</Button>
								)}
							</Box>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</React.Fragment>
	);
};

export default FirstSignIn;
