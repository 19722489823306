import "./ListMail.css";

import * as AdobeLC from "../../../../adobelc";
import * as CommonFns from "../../../../common";
import * as toastMessages from "../../../../toastMessages";

import {
	Box,
	Button,
	Flex,
	FormControl,
	FormLabel,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Spacer,
	Spinner,
	TableContainer,
	chakra,
	useBreakpointValue,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import { Grid, _ } from "gridjs-react";
import React, { useContext, useEffect, useState } from "react";

import { AuditContext } from "../../../../contexts/AuditContext";
import { AuthContext } from "../../../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Markdown from "../../../Global/Markdown/Markdown";
import Moment from "moment";
import ViewPdf from "../ViewPdf/ViewPdf";
import { axiosMyPropertyAuth } from "../../../../apis";
import { h } from "gridjs";
import { useNavigate } from "react-router-dom";

const ProfileMail = ({
	isPropertyView,
	propertyCount,
	mailMessage,
	items,
	onGetProfileMail,
	onAddPropertyLink,
	ipAddress,
	isOwnerMail,
}) => {
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });
	const { auditActivityAsync } = useContext(AuditContext);

	const { authDetails, setAuthDetails } = useContext(AuthContext);

	const { isOpen: isOpenViewPdf, onOpen: onOpenViewPdf, onClose: onCloseViewPdf } = useDisclosure();
	const {
		isOpen: isOpenLoadingMailItem,
		onOpen: onOpenLoadingMailItem,
		onClose: onCloseLoadingMailItem,
	} = useDisclosure();

	let navigate = useNavigate();

	const [originalMailItems, setOriginalMailItems] = useState();
	const [mailItems, setMailItems] = useState();

	const [pdfBase64, setPdfBase64] = useState(null);
	const [pdfModalTitle, setPdfModalTitle] = useState("My Mail");

	const [pageLimit] = useState(25);
	const [currentPage, setCurrentPage] = useState(0);

	const [mailDateFilterItems, setMailDateFilterItems] = useState();
	const [mailDateFilterValue, setMailDateFilterValue] = useState("All");

	const [corrTypeFilterItems, setCorrTypeFilterItems] = useState();
	const [corrTypeFilterValue, setCorrTypeFilterValue] = useState("All");

	const [accIdFilterItems, setAccIdFilterItems] = useState();
	const [accIdFilterValue, setAccIdFilterValue] = useState("All");

	const [showFilter, setShowFilter] = useState(false);

	useEffect(() => {
		manageProfileMail();
	}, [items]);

	const toast = useToast();
	const listMailErrorId = "list-mail-toast-error";

	const formLabelMinWidth = 170;
	const [formControlMinWidth, setFormControlMinWidth] = useState(270);

	const manageProfileMail = () => {
		// mail items
		if (items?.length > 0) {
			// mail item count
			let newItemCount = 0;
			newItemCount = items.filter((x) => x.status === "New").length;

			if (isOwnerMail) {
				if (Number(newItemCount) !== Number(authDetails.unreadMailCount)) {
					setAuthDetails({
						...authDetails,
						unreadMailCount: Number(newItemCount),
					});
				}
			}

			let mailItems;
			if (isPropertyView) {
				// take top 5
				mailItems = items.slice(0, 5);
			} else {
				mailItems = items;
			}

			setOriginalMailItems(mailItems);
			setMailItems(existingFilters(mailItems));

			// filters
			setItemFilters(mailItems);
			setShowFilter(mailItems.length >= process.env.REACT_APP_MY_MAIL_FILTER_THRESHOLD ? true : false);
		} else {
			setOriginalMailItems();
			setMailItems();
			// authDetails.unreadMailCount is set on App.js based on no properties existing.
			// can only update context in one place
		}
	};

	const setItemFilters = (mailItems) => {
		let mailDateFilter = Array.from(new Set(mailItems.map((item) => Moment(item.mailDate, "DD-MMM-YYYY").year())));
		if (mailDateFilter.length > 0) {
			mailDateFilter = mailDateFilter.sort((a, b) => b - a);
			setMailDateFilterItems(mailDateFilter);
		}

		let corrTypeFilter = Array.from(
			new Set(mailItems.map((item) => item.corrType.substr(4, item.corrType.length).trim()))
		);

		if (corrTypeFilter.length > 0) {
			corrTypeFilter = corrTypeFilter.sort();
			setCorrTypeFilterItems(corrTypeFilter);

			// set the width of the filter dropdowns based on a couple of values
			// otherwise use a default value
			if (corrTypeFilter.includes("Clean Energy Improvement Charges Payout Option")) {
				setFormControlMinWidth(385);
			} else if (corrTypeFilter.includes("Local Improvement Charges Payout")) {
				setFormControlMinWidth(335);
			}
		}

		let accIdFilter = Array.from(new Set(mailItems.map((item) => item.accId)));
		if (accIdFilter.length > 0) {
			accIdFilter = accIdFilter.sort();
			setAccIdFilterItems(accIdFilter);
		}
	};

	const handleFilterChange = (e, type) => {
		e.preventDefault();
		let value = e.target.value;
		setFilterValue(type, value);
		filterMailItems(type, value);
	};

	const setFilterValue = (type, value) => {
		switch (type) {
			case "mailDate":
				setMailDateFilterValue(value);
				break;
			case "corrType":
				setCorrTypeFilterValue(value);
				break;
			case "accId":
				setAccIdFilterValue(value);
				break;
			// no default
		}
	};

	const filterMailItems = (type, value) => {
		let items = originalMailItems;

		switch (type) {
			case "mailDate":
				if (value !== "All") {
					items = items.filter((x) => x.mailDate.includes(value));
				}

				if (corrTypeFilterValue !== "All") {
					items = items.filter((x) => x.corrType.includes(corrTypeFilterValue));
				}

				if (accIdFilterValue !== "All") {
					items = items.filter((x) => x.accId.toString() === accIdFilterValue.toString());
				}
				break;
			case "corrType":
				if (value !== "All") {
					items = items.filter((x) => x.corrType.includes(value));
				}

				if (mailDateFilterValue !== "All") {
					items = items.filter((x) => x.mailDate.includes(mailDateFilterValue));
				}

				if (accIdFilterValue !== "All") {
					items = items.filter((x) => x.accId.toString() === accIdFilterValue.toString());
				}
				break;
			case "accId":
				if (value !== "All") {
					items = items.filter((x) => x.accId.toString() === value.toString());
				}

				if (mailDateFilterValue !== "All") {
					items = items.filter((x) => x.mailDate.includes(mailDateFilterValue));
				}

				if (corrTypeFilterValue !== "All") {
					items = items.filter((x) => x.corrType.includes(corrTypeFilterValue));
				}
				break;
			// no default
		}

		// set the items for the grid to render
		setMailItems(items);
	};

	const existingFilters = (mailItems) => {
		let items = mailItems;

		if (mailDateFilterValue !== "All") {
			items = items.filter((x) => x.mailDate.includes(mailDateFilterValue));
		}

		if (corrTypeFilterValue !== "All") {
			items = items.filter((x) => x.corrType.includes(corrTypeFilterValue));
		}

		if (accIdFilterValue !== "All") {
			items = items.filter((x) => x.accId.toString() === accIdFilterValue.toString());
		}

		return items;
	};

	const handleResetFilter = (e) => {
		e.preventDefault();

		setMailDateFilterValue("All");

		// Year
		document.getElementById(isOwnerMail ? "filter-owner" : "filter-viewer").querySelector("#mailDateFilter").value =
			"All";

		// Corr Type
		document.getElementById(isOwnerMail ? "filter-owner" : "filter-viewer").querySelector("#corrTypeFilter").value =
			"All";

		// Account Number
		document.getElementById(isOwnerMail ? "filter-owner" : "filter-viewer").querySelector("#accIdFilter").value =
			"All";

		setMailItems(originalMailItems);
	};

	const setCurrentGridPage = () => {
		// can only get away with this because paging is not enabled on the isPropertyView
		if (!isPropertyView && mailItems.length > pageLimit) {
			let currentPage = document
				.getElementById(isOwnerMail ? "table-owner-mail" : "table-viewer-mail")
				.getElementsByClassName("gridjs-currentPage")[0].innerHTML;

			setCurrentPage(Number(currentPage) - 1);
		}
	};

	const handleMyMailAction = async (e, mailDate, corrType, accId, clgId, xmlId, pageRange, action) => {
		e.preventDefault();

		// open loading modal
		onOpenLoadingMailItem();

		setCurrentGridPage();

		let pdfDocument = await AdobeLC.getMyMailDocument(mailDate, corrType, accId, xmlId, pageRange, isOwnerMail);

		if (pdfDocument !== null) {
			let activity = "";

			// download or view
			switch (action) {
				case "download":
					//download report
					CommonFns.pdfDownload(pdfDocument.data, pdfDocument.fileName);

					// set activity
					activity = "Downloaded ";
					break;

				case "view":
					// set base64 string
					setPdfBase64(pdfDocument.data.toString());

					setPdfModalTitle(corrType + " - " + accId.toString() + " - " + CommonFns.FormatDate(mailDate));
					handleViewPdf();

					// set activity
					activity = "Viewed ";
					break;

				// no default
			}

			// close loading modal
			onCloseLoadingMailItem();

			// mark read
			let markReadPayload = {
				uId: "",
				clgId: clgId,
				ipAddress: ipAddress,
				accId: accId,
				activity: activity,
				corrType: corrType,
			};

			let markReadResult = markRead(markReadPayload);
			if (markReadResult !== null) {
				// get the updated mail list
				// items marked as read, etc...
				// existing filters are applied in loadMail()

				// audit
				await auditActivityAsync(activity + corrType + " - " + mailDate, accId);

				// re-get mail (after audit to manage read/new)
				await onGetProfileMail();
			}
		} else {
			// close loading modal
			onCloseLoadingMailItem();

			if (!toast.isActive(listMailErrorId)) {
				toast(toastMessages.getToastError(listMailErrorId, e.message));
			}
		}
	};

	function markRead(payload) {
		let url = "mail/markread";
		return axiosMyPropertyAuth
			.put(url, payload)
			.then((resp) => {
				return resp.data;
			})
			.catch((error) => {
				// error
				// close loading modal
				onCloseLoadingMailItem();
				if (!toast.isActive(listMailErrorId)) {
					toast(toastMessages.getToastError(listMailErrorId, error.message));
				}
			});
	}

	const handleViewPdf = () => {
		onOpenViewPdf();
	};

	const handleViewPdfModalClose = () => {
		//e.preventDefault();
		onCloseViewPdf();
		setPdfBase64(null);
		setPdfModalTitle("My Mail");
	};

	const handleAddProperty = () => {
		if (isPropertyView) {
			onAddPropertyLink();
		} else {
			setAuthDetails({
				...authDetails,
				addPropertyModal: true,
			});
			navigate(`/auth/property`, { replace: true });
		}
	};

	// Grid columns
	const gridColumns = [
		{
			id: "status",
			name: "Status",
			formatter: (cell, row) => {
				return h(
					"div",
					{},
					_(
						<chakra.span fontWeight={row.cells[0].data.toLowerCase() === "new" ? 700 : 400}>
							{cell}
						</chakra.span>
					)
				);
			},
			sort: {
				compare: (a, b) => {
					if (a > b) {
						return 1;
					} else if (b > a) {
						return -1;
					} else {
						return 0;
					}
				},
			},
		},
		{
			id: "mailDate",
			name: "Date of Issue",
			formatter: (cell, row) => {
				return h(
					"div",
					{},
					_(
						<chakra.span fontWeight={row.cells[0].data.toLowerCase() === "new" ? 700 : 400}>
							{CommonFns.FormatDate(cell)}
						</chakra.span>
					)
				);
			},
			sort: {
				compare: (a, b) => {
					if (a > b) {
						return 1;
					} else if (b > a) {
						return -1;
					} else {
						return 0;
					}
				},
			},
		},
		{
			id: "corrType",
			name: "Correspondence Item",
			formatter: (cell, row) => {
				return h(
					"div",
					{},
					_(
						<chakra.span fontWeight={row.cells[0].data.toLowerCase() === "new" ? 700 : 400}>
							{cell}
						</chakra.span>
					)
				);
			},
			sort: {
				compare: (a, b) => {
					if (a > b) {
						return 1;
					} else if (b > a) {
						return -1;
					} else {
						return 0;
					}
				},
			},
		},
		{
			id: "accId",
			name: "Account",
			formatter: (cell, row) => {
				return h(
					"div",
					{},
					_(
						<chakra.span fontWeight={row.cells[0].data.toLowerCase() === "new" ? 700 : 400}>
							{cell}
						</chakra.span>
					)
				);
			},
			sort: {
				compare: (a, b) => {
					if (a > b) {
						return 1;
					} else if (b > a) {
						return -1;
					} else {
						return 0;
					}
				},
			},
		},

		{
			id: "view",
			width: "65px",
			formatter: (cell, row) => {
				return h(
					"div",
					{
						onClick: (e) => {
							e.preventDefault();

							// close loading modal
							onCloseLoadingMailItem();

							handleMyMailAction(
								e,
								row.cells[1].data,
								row.cells[2].data,
								row.cells[3].data,
								row.cells[6].data,
								row.cells[7].data,
								row.cells[8].data,
								"view"
							);
						},
					},
					_(
						<React.Fragment>
							<Button
								className='my-mail-grid-button'
								title='View'>
								View
							</Button>
						</React.Fragment>
					)
				);
			},
			sort: false,
		},
		{
			id: "download",
			width: "100px",
			formatter: (cell, row) => {
				return h(
					"div",
					{
						onClick: (e) => {
							e.preventDefault();

							// close loading modal
							onCloseLoadingMailItem();

							handleMyMailAction(
								e,
								row.cells[1].data,
								row.cells[2].data,
								row.cells[3].data,
								row.cells[6].data,
								row.cells[7].data,
								row.cells[8].data,
								"download"
							);
						},
					},
					_(
						<React.Fragment>
							<Button
								mr={"20px"}
								className='my-mail-grid-button'
								title='Download'>
								Download
							</Button>
						</React.Fragment>
					)
				);
			},
			sort: false,
		},
		{
			id: "clgId",
			name: "clgId",
			hidden: true,
			sort: false,
		},
		{
			id: "xmlId",
			name: "xmlId",
			hidden: true,
			sort: false,
		},
		{
			id: "pageRange",
			name: "pageRange",
			hidden: true,
			sort: false,
		},
	];

	// Grid paging
	const gridPagination = {
		enabled: mailItems !== undefined && !isPropertyView ? (mailItems.length > pageLimit ? true : false) : false,
		page: mailItems !== undefined && !isPropertyView ? (mailItems.length > pageLimit ? currentPage : 0) : 0,
		limit: pageLimit,
		resetPageOnUpdate: false,
	};

	return (
		<React.Fragment>
			{items !== undefined ? (
				<React.Fragment>
					<Box>
						<Flex>
							{!isPropertyView ? (
								<Box maxW={770}>
									<chakra.span fontSize={"xl"}>
										<Markdown text={mailMessage}></Markdown>
									</chakra.span>
								</Box>
							) : null}
							<Spacer></Spacer>
							{showFilter && !isPropertyView ? (
								<Box>
									<Flex direction={["column", "column", "row"]}>
										<Box>
											<Flex direction={["column"]}>
												<Box
													id={isOwnerMail ? "filter-owner" : "filter-viewer"}
													className='my-mail-filter-container'
													ml={isSmallerScreen ? 0 : 4}
													mt={isSmallerScreen ? 4 : 0}>
													<Box>
														<FormControl>
															<Flex direction={["column", "column", "row"]}>
																<Box minW={formLabelMinWidth}>
																	<FormLabel
																		htmlFor=''
																		className='form-label-fixed my-mail-form-label'>
																		Year
																	</FormLabel>
																</Box>
																<Box w={formControlMinWidth}>
																	<Select
																		id='mailDateFilter'
																		className='my-mail-select-filter'
																		onChange={(e) => {
																			handleFilterChange(e, "mailDate");
																		}}>
																		<option value={"All"}>All</option>
																		{mailDateFilterItems !== undefined
																			? mailDateFilterItems.map((item, index) => (
																					<option
																						key={index}
																						value={item}>
																						{item}
																					</option>
																			  ))
																			: null}
																	</Select>
																</Box>
															</Flex>
														</FormControl>
													</Box>
													<Box>
														<FormControl>
															<Flex direction={["column", "column", "row"]}>
																<Box minW={formLabelMinWidth}>
																	<FormLabel
																		htmlFor=''
																		className='form-label-fixed my-mail-form-label'>
																		Correspondence Type
																	</FormLabel>
																</Box>
																<Box w={formControlMinWidth}>
																	<Select
																		id='corrTypeFilter'
																		className='my-mail-select-filter'
																		onChange={(e) => {
																			handleFilterChange(e, "corrType");
																		}}>
																		<option value={"All"}>All</option>
																		{corrTypeFilterItems !== undefined
																			? corrTypeFilterItems.map((item, index) => (
																					<option
																						key={index}
																						value={item}>
																						{item}
																					</option>
																			  ))
																			: null}
																	</Select>
																</Box>
															</Flex>
														</FormControl>
													</Box>
													<Box>
														<FormControl>
															<Flex direction={["column", "column", "row"]}>
																<Box minW={formLabelMinWidth}>
																	<FormLabel
																		htmlFor=''
																		className='form-label-fixed my-mail-form-label'>
																		Account
																	</FormLabel>
																</Box>
																<Box w={formControlMinWidth}>
																	<Select
																		id='accIdFilter'
																		className='my-mail-select-filter'
																		onChange={(e) => {
																			handleFilterChange(e, "accId");
																		}}>
																		<option value={"All"}>All</option>
																		{accIdFilterItems !== undefined
																			? accIdFilterItems.map((item, index) => (
																					<option
																						key={index}
																						value={item}>
																						{item}
																					</option>
																			  ))
																			: null}
																	</Select>
																</Box>
															</Flex>
														</FormControl>
													</Box>
													<Box>
														<FormControl>
															<Flex direction={["column", "column", "row"]}>
																<Box minW={formLabelMinWidth}>
																	<FormLabel></FormLabel>
																</Box>
																<Box w={formControlMinWidth}>
																	<Button
																		h={"27px"}
																		size={"sm"}
																		colorScheme={"lightBlue"}
																		onClick={(e) => {
																			handleResetFilter(e);
																		}}>
																		<chakra.span>Reset</chakra.span>
																	</Button>
																</Box>
															</Flex>
														</FormControl>
													</Box>
												</Box>
											</Flex>
										</Box>
									</Flex>
								</Box>
							) : null}
						</Flex>
					</Box>

					{isOwnerMail && propertyCount <= 0 ? (
						<Box
							mt={2}
							className='my-mail-no-records'>
							It looks like you haven't connected any properties to your online profile. To do so, please{" "}
							<Link onClick={handleAddProperty}>Add a Property</Link>.
						</Box>
					) : mailItems?.length > 0 ? (
						<>
							<TableContainer>
								<Box
									id={isOwnerMail ? "table-owner-mail" : "table-viewer-mail"}
									mt={2}
									className={"my-mail-table"}>
									<Grid
										data={mailItems}
										columns={gridColumns}
										pagination={gridPagination}
										search={false}></Grid>
								</Box>
							</TableContainer>

							<Modal
								size={"6xl"}
								closeOnOverlayClick={false}
								isOpen={isOpenViewPdf}
								onClose={handleViewPdfModalClose}>
								<ModalOverlay />
								<ModalContent>
									<ModalHeader className={"modal-header"}>{pdfModalTitle}</ModalHeader>
									<ModalCloseButton className='modal-close-button'>
										<FontAwesomeIcon
											size={"2xl"}
											icon='fa-solid fa-xmark'
										/>
									</ModalCloseButton>
									<ModalBody>
										<ViewPdf pdfBase64={pdfBase64}></ViewPdf>
									</ModalBody>
									<ModalFooter className={"modal-footer"}>
										{/* <Button
											colorScheme='blue'
											size={"sm"}
											onClick={(e) => handleViewPdfModalClose(e)}>
											Close
										</Button> */}
									</ModalFooter>
								</ModalContent>
							</Modal>
						</>
					) : (
						<Box
							mt={2}
							className='my-mail-no-records'>
							No mail items are available at this time.
						</Box>
					)}
				</React.Fragment>
			) : null}

			<Modal
				size={"md"}
				closeOnEsc={false}
				closeOnOverlayClick={false}
				isOpen={isOpenLoadingMailItem}
				onClose={onCloseLoadingMailItem}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className={"modal-header"}>Retrieving Mail</ModalHeader>
					<ModalBody>
						<Flex
							direction={["column"]}
							alignItems='center'>
							<Box>One moment while we retrieve your information.</Box>
							<Box mt={2}>
								<Spinner
									size='sm'
									speed='0.90s'
									emptyColor='gray.200'
									color='blue.500'
								/>
							</Box>
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
		</React.Fragment>
	);
};

export default ProfileMail;
