import * as toastMessages from "../../../../toastMessages";

import {
	Box,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Image,
	Link,
	chakra,
	useBreakpointValue,
	useToast,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";

import { AuthContext } from "../../../../contexts/AuthContext";
import { axiosMyProperty } from "../../../../apis";
import { useNavigate } from "react-router-dom";

const ThankYou = () => {
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });
	let navigate = useNavigate();

	const ImageStep2 = require("../Images/step_02.png");

	const formLabelMinWidth = 210;

	const { authDetails } = useContext(AuthContext);
	const [emailAddress] = useState(authDetails.emailAddress);
	const [isResendEmail, setIsResendEmail] = useState(false);
	const [faqUrl] = useState(process.env.REACT_APP_FAQ_URL);

	const toast = useToast();
	const thankYouToastErrorId = "thank-you-error";
	const thankYouToastWarningId = "thank-you-warning";

	const handleResendVerificationEmail = (e) => {
		e.preventDefault();

		let url = "account/verify";

		let payload = {
			uId: authDetails.uId,
			toAddress: authDetails.emailAddress,
			profileName: authDetails.profileName,
		};

		axiosMyProperty
			.post(url, payload)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.isSuccess) {
					setIsResendEmail(true);
					payload = null;
				} else {
					payload = null;
					if (!toast.isActive(thankYouToastWarningId)) {
						toast(
							toastMessages.getToastWarning(
								thankYouToastWarningId,
								returnObj.errorMsg,
								toastMessages.createProfileMessages
							)
						);
					}
				}
			})
			.catch((e) => {
				// error
				if (!toast.isActive(thankYouToastErrorId)) {
					toast(toastMessages.getToastError(thankYouToastErrorId));
				}
			});
	};

	const handleSignIn = (e) => {
		e.preventDefault();
		navigate(`/sign-in`, { replace: true });
	};

	return (
		<React.Fragment>
			<Box>
				<FormControl>
					<Flex direction={["column", "column", "row"]}>
						<Box minW={formLabelMinWidth} display={isSmallerScreen ? "none" : "normal"}>
							<FormLabel>&nbsp;</FormLabel>
						</Box>
						<Box mb={8}>
							<Image src={ImageStep2} alt="Step 2 - Verification"></Image>
						</Box>
					</Flex>
				</FormControl>
				<FormControl>
					<Flex direction={["column", "column", "row"]}>
						<Box minW={formLabelMinWidth} display={isSmallerScreen ? "none" : "normal"}>
							<FormLabel>&nbsp;</FormLabel>
						</Box>
						<Box>
							<Heading mb={7}>Thank You. Now, Check Your Email.</Heading>
						</Box>
					</Flex>
				</FormControl>

				<FormControl>
					<Flex direction={["column", "column", "row"]}>
						<Box minW={formLabelMinWidth} display={isSmallerScreen ? "none" : "normal"}>
							<FormLabel>&nbsp;</FormLabel>
						</Box>
						<Box maxW={500}>
							<Box mb={3}>We've just sent a verification email to the following address:</Box>
							<Box mb={3} ml={20}>
								<chakra.span fontWeight={700}>{emailAddress}</chakra.span>
							</Box>
							<Box mb={3}>Please see the email to finish setting up your MyProperty profile.</Box>
							{!isResendEmail ? (
								<Box>
									Need to{" "}
									<Link onClick={(e) => handleResendVerificationEmail(e)}>resend an email</Link>?
									Change your email address or get other sign-in help?{" "}
									<Link href={faqUrl} rel={"noopener"} target={"_blank"}>
										Find out how
									</Link>
									. Or, <Link onClick={(e) => handleSignIn(e, true)}>sign in</Link>.
								</Box>
							) : (
								<Box>
									<Box mt={2}>
										{" "}
										A new verification email has been sent to{" "}
										<chakra.span fontWeight={700}>{emailAddress}</chakra.span>.
									</Box>
								</Box>
							)}
						</Box>
					</Flex>
				</FormControl>
			</Box>
		</React.Fragment>
	);
};

export default ThankYou;
