import "./MobilePhoneNumber.css";

import * as CommonFns from "../../../../../common";
import * as toastMessages from "../../../../../toastMessages";

import {
	Box,
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Input,
	InputGroup,
	InputLeftAddon,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spinner,
	VStack,
	chakra,
	useBreakpointValue,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";

import { AuditContext } from "../../../../../contexts/AuditContext";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputMask from "react-input-mask";
import { axiosMyPropertyAuth } from "../../../../../apis";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

const MobilePhoneNumber = ({ smsPhoneNum, onPhoneNumberModified, handleLogout }) => {
	const isSmallerScreen = useBreakpointValue({ base: true, md: false });
	const { authDetails, setAuthDetails } = useContext(AuthContext);
	const { auditActivity } = useContext(AuditContext);

	const formLabelMinWidth = 210;
	const formControlMinWidth = 350;

	useEffect(() => {
		setMobilePhoneNumber(smsPhoneNum);
	}, [smsPhoneNum]);

	useEffect(() => {
		setEmailAddress(authDetails.emailAddress);
	}, [authDetails.emailAddress]);

	const toast = useToast();
	const addMobilePhoneNumberErrorId = "add-mobile-phone-number-toast-error";
	const addMobilePhoneNumberWarningId = "add-mobile-phone-number-toast-warning";

	// ADD / UPDATE PHONE NUMBER - START
	const {
		isOpen: isOpenChangeMobilePhoneNumber,
		onOpen: onOpenChangeMobilePhoneNumber,
		onClose: onCloseChangeMobilePhoneNumber,
	} = useDisclosure();

	const [currentStep, setCurrentStep] = useState("1");
	const [isStepOne, setIsStepOne] = useState(true);
	const [isStepTwo, setIsStepTwo] = useState(false);
	const [isStepThree, setIsStepThree] = useState(false);

	const [stepOneLoading, setStepOneLoading] = useState(true);
	const [stepTwoLoading, setStepTwoLoading] = useState(false);

	const [mobilePhoneNumber, setMobilePhoneNumber] = useState(smsPhoneNum);
	const [modifyPhoneAttemptThreshold] = useState(process.env.REACT_APP_MOBILE_PHONE_NUMBER_ATTEMPT_THRESHOLD);
	const [emailAddress, setEmailAddress] = useState();

	const [mobilePhoneNumberUserProvided, setMobilePhoneNumberUserProvided] = useState();
	const [mobilePhoneNumberServiceProvided, setMobilePhoneNumberServiceProvided] = useState();
	const [verificationCode, setVerificationCode] = useState();

	const {
		register: registerAddMobilePhoneNumber,
		handleSubmit: handleSubmitAddMobilePhoneNumber,
		reset: resetAddMobilePhoneNumber,
		formState: { errors: errorsAddMobilePhoneNumber },
	} = useForm({
		defaultValues: {
			mobilePhoneNumber: "",
		},
	});

	const {
		register: registerVerficationCode,
		handleSubmit: handleSubmitVerficationCode,
		reset: resetVerficationCode,
		formState: { errors: errorsVerficationCode },
	} = useForm({
		defaultValues: {
			verifcationCode: "",
		},
	});

	const handleStepOne = (data) => {
		// audit
		auditActivity("Mobile Number - Clicked Next");

		// too many attemps, user cannot send more sms until re-logging in
		if (Number(authDetails.mobilePhoneNumberAttempts) < Number(modifyPhoneAttemptThreshold)) {
			let payload = {
				mobilePhoneNumber: data.mobilePhoneNumber,
			};

			// set to use for compare in step 2
			setMobilePhoneNumberUserProvided(data.mobilePhoneNumber);

			// send sms with verification code
			let url = "/mobilephonenumber/sendverificationcode";
			axiosMyPropertyAuth
				.post(url, payload)
				.then((resp) => {
					let returnObj = resp.data;
					if (returnObj.retVal === "true") {
						// increase attempts
						setAuthDetails({
							...authDetails,
							mobilePhoneNumberAttempts: Number((authDetails.mobilePhoneNumberAttempts += 1)),
						});

						// store the verification code
						// used for compare in step 2
						setVerificationCode(returnObj.verificationCode);

						// store the phone number provided back from the service
						// used for compare in step 2
						setMobilePhoneNumberServiceProvided(returnObj.mobilePhoneNumber);

						// steps
						setIsStepOne(false);
						setIsStepTwo(true);
						setIsStepThree(false);

						setStepOneLoading(false);
					} else {
						if (!toast.isActive(addMobilePhoneNumberWarningId)) {
							toast(
								toastMessages.getToastWarning(
									addMobilePhoneNumberWarningId,
									returnObj.errorMsg,
									toastMessages.mobilePhoneNumberMessages
								)
							);
						}

						setStepOneLoading(false);
					}
				})
				.catch((error) => {
					// error
					if (!toast.isActive(addMobilePhoneNumberErrorId)) {
						toast(toastMessages.getToastError(addMobilePhoneNumberErrorId));
					}

					setStepOneLoading(false);
				});
		} else {
			// too many attempts to change phone number
			// client message
			if (!toast.isActive(addMobilePhoneNumberWarningId)) {
				toast(
					toastMessages.getToastWarning(
						addMobilePhoneNumberWarningId,
						"too many attempts",
						toastMessages.mobilePhoneNumberMessages
					)
				);
			}

			setStepOneLoading(false);

			// logout
			handleLogout();
		}
	};

	const handleStepTwo = (data) => {
		// audit
		auditActivity("Mobile Number - Clicked Verify");

		// compare phone numbers
		// chance something got swapped between clicks
		let isValidMobilePhoneNumberCompare = phoneNumberMatch();

		// verification code validation
		let isValidVerificationCodeCompare = verficationCodeMatch(data.verifcationCode);

		if (isValidMobilePhoneNumberCompare && isValidVerificationCodeCompare) {
			// update profile with phone number
			let payload = {
				uId: "",
				phoneNumber: mobilePhoneNumberServiceProvided,
			};

			let url = "/mobilephonenumber";
			axiosMyPropertyAuth
				.put(url, payload)
				.then((resp) => {
					let returnObj = resp.data;
					if (returnObj.retVal === "true") {
						// reset attempts
						setAuthDetails({
							...authDetails,
							mobilePhoneNumberAttempts: 0,
						});

						// steps
						setIsStepOne(false);
						setIsStepTwo(false);
						setIsStepThree(true);

						setStepTwoLoading(false);

						// callback for updated phone number
						onPhoneNumberModified();
					} else {
						if (!toast.isActive(addMobilePhoneNumberWarningId)) {
							toast(
								toastMessages.getToastWarning(
									addMobilePhoneNumberWarningId,
									returnObj.errorMsg,
									toastMessages.mobilePhoneNumberMessages
								)
							);
						}

						setStepTwoLoading(false);
					}
				})
				.catch((error) => {
					// error
					if (!toast.isActive(addMobilePhoneNumberErrorId)) {
						toast(toastMessages.getToastError(addMobilePhoneNumberErrorId));
					}

					setStepTwoLoading(false);
				});
		} else {
			if (!isValidMobilePhoneNumberCompare) {
				// phone numbers do not match
				// client message
				if (!toast.isActive(addMobilePhoneNumberWarningId)) {
					toast(
						toastMessages.getToastWarning(
							addMobilePhoneNumberWarningId,
							"phone number match fail",
							toastMessages.mobilePhoneNumberMessages
						)
					);
				}
			}

			if (!isValidVerificationCodeCompare) {
				// verification code does not match
				// client message
				if (!toast.isActive(addMobilePhoneNumberWarningId)) {
					toast(
						toastMessages.getToastWarning(
							addMobilePhoneNumberWarningId,
							"verification code match fail",
							toastMessages.mobilePhoneNumberMessages
						)
					);
				}
			}

			setStepTwoLoading(false);
		}
	};

	const handleVerificationCodeNotReceived = (e) => {
		e.preventDefault();

		// audit
		auditActivity("Mobile Number - Clicked Did Not Receive Verification Code");

		resetVerficationCode();
		resetSteps();
	};

	const verficationCodeMatch = (value) => {
		if (value.toString() === verificationCode.toString()) {
			return true;
		}

		return false;
	};

	const phoneNumberMatch = () => {
		// format user phone number
		let userPhoneNumber = mobilePhoneNumberUserProvided;
		userPhoneNumber = userPhoneNumber.replaceAll("-", "");

		let servicePhoneNumber = mobilePhoneNumberServiceProvided;

		if (userPhoneNumber.toString() === servicePhoneNumber.toString()) {
			return true;
		}

		return false;
	};

	const handleChangeMobilePhoneNumberModalOpen = (e) => {
		e.preventDefault();
		onOpenChangeMobilePhoneNumber();
	};

	const handleChangeMobilePhoneNumberModalClose = () => {
		//e.preventDefault();
		onCloseChangeMobilePhoneNumber();
		resetForm();
	};

	const resetForm = () => {
		resetSteps();
		resetAddMobilePhoneNumber();
		resetVerficationCode();
		setMobilePhoneNumberUserProvided();
	};

	const resetSteps = () => {
		setIsStepOne(true);
		setIsStepTwo(false);
		setIsStepThree(false);

		setStepOneLoading(false);

		setCurrentStep("1");
	};
	//./ ADD / UPDATE PHONE NUMBER - END

	// REMOVE PHONE NUMBER - START
	const {
		isOpen: isOpenRemoveMobilePhoneNumber,
		onOpen: onOpenRemoveMobilePhoneNumber,
		onClose: onCloseRemoveMobilePhoneNumber,
	} = useDisclosure();

	const removeMobilePhoneNumberErrorId = "remove-mobile-phone-number-toast-error";
	const removeMobilePhoneNumberWarningId = "remove-mobile-phone-number-toast-warning";

	const [isRemoveStepOne, setRemoveIsStepOne] = useState(true);
	const [isRemoveStepTwo, setRemoveIsStepTwo] = useState(false);

	const [removeStepOneLoading, setRemoveStepOneLoading] = useState(false);

	const handleRemoveMobilePhoneNumber = (e) => {
		e.preventDefault();
		let url = `/mobilephonenumber`;
		axiosMyPropertyAuth
			.delete(url)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					// audit
					auditActivity("Mobile Number - Clicked Yes Remove");

					setRemoveIsStepOne(false);
					setRemoveIsStepTwo(true);

					setRemoveStepOneLoading(false);

					// callback for updated phone number
					onPhoneNumberModified();
				} else {
					if (!toast.isActive(removeMobilePhoneNumberWarningId)) {
						toast(
							toastMessages.getToastWarning(
								removeMobilePhoneNumberWarningId,
								returnObj.errorMsg,
								toastMessages.mobilePhoneNumberMessages
							)
						);
					}

					setRemoveStepOneLoading(false);
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(removeMobilePhoneNumberErrorId)) {
					toast(toastMessages.getToastError(removeMobilePhoneNumberErrorId));
				}

				setRemoveStepOneLoading(false);
			});
	};

	const handleRemoveMobilePhoneNumberModalOpen = (e) => {
		e.preventDefault();
		onOpenRemoveMobilePhoneNumber();
	};

	const handleRemoveMobilePhoneNumberModalClose = () => {
		onCloseRemoveMobilePhoneNumber();
		resetRemoveSteps();
	};

	const resetRemoveSteps = () => {
		setRemoveIsStepOne(true);
		setRemoveIsStepTwo(false);

		setRemoveStepOneLoading(false);
	};

	//./REMOVE PHONE NUMBER - END

	return (
		<React.Fragment>
			<Box
				mt={3}
				mb={6}>
				<Box fontSize={"2xl"}>Your contact information</Box>
				<Box>
					<FormControl mt={2}>
						<Flex direction={["column", "column", "row"]}>
							<Box
								minW={formLabelMinWidth}
								mr={3}>
								<FormLabel
									htmlFor='emailAddressNotificationPreferences'
									textAlign={isSmallerScreen ? "" : "end"}
									className='form-label-fixed'>
									Your email
								</FormLabel>
							</Box>
							<Box minW={formControlMinWidth}>
								<Input
									id='emailAddressNotificationPreferences'
									type='email'
									autoComplete='off'
									readOnly={true}
									defaultValue={emailAddress}
								/>
							</Box>
						</Flex>
					</FormControl>
					<FormControl mt={2}>
						<Flex direction={["column", "column", "row"]}>
							<Box
								minW={formLabelMinWidth}
								mr={3}>
								<FormLabel
									htmlFor='phoneNumber'
									textAlign={isSmallerScreen ? "" : "end"}
									className='form-label-fixed'>
									Your mobile phone
								</FormLabel>
							</Box>
							<Box minW={formControlMinWidth}>
								{mobilePhoneNumber !== null ? (
									<Box
										minW={formControlMinWidth}
										mt={2}>
										<Flex direction={["row"]}>
											<Box>
												<chakra.span fontSize={"md"}>
													{CommonFns.FormatPhoneNumber(mobilePhoneNumber)}
												</chakra.span>
											</Box>
											<Box ml={4}>
												<Button
													colorScheme='lightBlue'
													size={"xs"}
													fontSize={"sm"}
													width={20}
													onClick={(e) => {
														auditActivity("Mobile Number - Clicked Add/Change");
														handleChangeMobilePhoneNumberModalOpen(e);
													}}>
													Change
												</Button>
											</Box>
											<Box ml={4}>
												<Link
													colorScheme='grey'
													variant='ghost'
													fontSize={"sm"}
													pl={0}
													onClick={(e) => {
														auditActivity("Mobile Number - Clicked Remove");
														handleRemoveMobilePhoneNumberModalOpen(e);
													}}>
													Remove
												</Link>
											</Box>
										</Flex>
									</Box>
								) : (
									<Box mt={2}>
										<Button
											colorScheme='lightBlue'
											size={"xs"}
											fontSize={"sm"}
											width={20}
											onClick={(e) => {
												auditActivity("Mobile Number - Clicked Add/Change");
												handleChangeMobilePhoneNumberModalOpen(e);
											}}>
											Add
										</Button>
									</Box>
								)}
							</Box>
						</Flex>
					</FormControl>
				</Box>
			</Box>

			{/* ADD / UPDATE PHONE NUMBER */}
			<Modal
				size={"4xl"}
				closeOnOverlayClick={false}
				closeOnEsc={false}
				isOpen={isOpenChangeMobilePhoneNumber}
				onClose={handleChangeMobilePhoneNumberModalClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className={"modal-header"}>
						Add Mobile Phone{!isStepThree ? `: Step ${currentStep} of 2` : null}
					</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					<ModalBody>
						<Box
							mt={1}
							mb={2}>
							{isStepOne ? (
								<React.Fragment>
									<VStack>
										<Flex direction={["column"]}>
											<Box>
												<Box maxW={516}>
													Enter your mobile phone number for receiving property assessment and
													tax mail notifications via text messages.
												</Box>
											</Box>
											<Box mt={2}>
												<Box>
													But first, let's verify your number. (Standard rates may apply.)
												</Box>
											</Box>
											<Box mt={2}>
												<FormControl
													isInvalid={errorsAddMobilePhoneNumber.mobilePhoneNumber}
													mt={2}>
													<Flex direction={["column", "column", "row"]}>
														<Box minW={formLabelMinWidth}>
															<FormLabel
																htmlFor='mobilePhoneNumber'
																textAlign={isSmallerScreen ? "" : "end"}
																className='form-label-fixed'>
																Your mobile phone
															</FormLabel>
														</Box>
														<Box>
															<InputGroup>
																<InputLeftAddon
																	children={
																		<chakra.span fontWeight={700}>+1</chakra.span>
																	}
																/>
																<Input
																	id='mobilePhoneNumber'
																	className='mobile-phone-number'
																	type='tel'
																	width={isSmallerScreen ? "100%" : 175}
																	placeholder=''
																	as={InputMask}
																	mask='999-999-9999'
																	autoComplete='off'
																	{...registerAddMobilePhoneNumber(
																		"mobilePhoneNumber",
																		{
																			required: "Your mobile phone is required",
																			pattern: {
																				value: /^[\d]{3}?-?[\d]{3}-?[\d]{4}$/g,
																				message:
																					"Your mobile phone number is formatted incorrectly",
																			},
																		}
																	)}
																/>
															</InputGroup>
														</Box>
													</Flex>
													<Box>
														<Flex direction={["column", "column", "row"]}>
															<Box minW={formLabelMinWidth}>
																<FormLabel></FormLabel>
															</Box>
															<Box>
																<FormErrorMessage
																	colorScheme={"red"}
																	className={"text-error pos"}>
																	{errorsAddMobilePhoneNumber.mobilePhoneNumber &&
																		errorsAddMobilePhoneNumber.mobilePhoneNumber
																			.message}
																</FormErrorMessage>
															</Box>
														</Flex>
													</Box>
												</FormControl>
												<FormControl mt={isSmallerScreen ? 6 : 3}>
													<Flex direction={["column", "column", "row"]}>
														<Box
															minW={formLabelMinWidth}
															display={isSmallerScreen ? "none" : "normal"}>
															<FormLabel>&nbsp;</FormLabel>
														</Box>
														{/* <Box>
															<Flex direction={["column", "column", "row"]}>
																<Box>
																	<Button
																		mr={isSmallerScreen ? 0 : 2}
																		w={isSmallerScreen ? "100%" : 100}
																		colorScheme='lightBlue'
																		onClick={handleSubmitAddMobilePhoneNumber(
																			(data) => {
																				setStepOneLoading(true);
																				handleStepOne(data);
																			}
																		)}>
																		NEXT
																	</Button>
																</Box>
																{stepOneLoading ? (
																	<Box>
																		<Box mt={2} ml={1}>
																			<Spinner
																				size='xs'
																				speed='0.90s'
																				emptyColor='gray.200'
																				color='blue.500'
																			/>
																		</Box>
																	</Box>
																) : null}
															</Flex>
														</Box> */}
													</Flex>
												</FormControl>
											</Box>
										</Flex>
									</VStack>
								</React.Fragment>
							) : null}
							{isStepTwo ? (
								<React.Fragment>
									<VStack>
										<Flex direction={["column"]}>
											<Box>
												<Box maxW={516}>
													We've sent you a text message with a verification code. Please type
													that code below to verify your mobile phone number.
												</Box>
											</Box>
											<Box mt={2}>
												<FormControl
													isInvalid={errorsVerficationCode.verifcationCode}
													mt={2}>
													<Flex direction={["column", "column", "row"]}>
														<Box minW={formLabelMinWidth}>
															<FormLabel
																htmlFor='verifcationCode'
																textAlign={isSmallerScreen ? "" : "end"}
																className='form-label-fixed'>
																Verification code
															</FormLabel>
														</Box>
														<Box minW={formControlMinWidth}>
															<Input
																id='verifcationCode'
																type='number'
																width={isSmallerScreen ? "100%" : 175}
																placeholder=''
																autoComplete='off'
																{...registerVerficationCode("verifcationCode", {
																	required: "Verification code is required",
																})}
															/>
														</Box>
													</Flex>
													<Box>
														<Flex direction={["column", "column", "row"]}>
															<Box minW={formLabelMinWidth}>
																<FormLabel></FormLabel>
															</Box>
															<Box>
																<FormErrorMessage
																	colorScheme={"red"}
																	className={"text-error pos"}>
																	{errorsVerficationCode.verifcationCode &&
																		errorsVerficationCode.verifcationCode.message}
																</FormErrorMessage>
															</Box>
														</Flex>
													</Box>
												</FormControl>
												<FormControl mt={isSmallerScreen ? 6 : 3}>
													<Flex direction={["column", "column", "row"]}>
														<Box
															minW={formLabelMinWidth}
															display={isSmallerScreen ? "none" : "normal"}>
															<FormLabel>&nbsp;</FormLabel>
														</Box>
														<Box>
															{/*<Flex direction={["column", "column", "row"]}>
																 <Button
																	mr={isSmallerScreen ? 0 : 2}
																	w={isSmallerScreen ? "100%" : 100}
																	colorScheme='lightBlue'
																	onClick={handleSubmitVerficationCode((data) => {
																		setStepTwoLoading(true);
																		handleStepTwo(data);
																	})}>
																	VERIFY
																</Button>
																{stepTwoLoading ? (
																	<Box>
																		<Box mt={2} ml={1} mr={1}>
																			<Spinner
																				size='xs'
																				speed='0.90s'
																				emptyColor='gray.200'
																				color='blue.500'
																			/>
																		</Box>
																	</Box>
																) : null} */}
															<Link
																ml={isSmallerScreen ? 0 : 2}
																//mt={2}
																colorScheme='gray'
																onClick={(e) => {
																	handleVerificationCodeNotReceived(e);
																}}>
																I did not receive a verification code
															</Link>
															{/* </Flex> */}
														</Box>
													</Flex>
												</FormControl>
											</Box>
										</Flex>
									</VStack>
								</React.Fragment>
							) : null}
							{isStepThree ? (
								<React.Fragment>
									<Flex
										direction={["column"]}
										alignItems={"center"}>
										<HStack>
											<Box mr={2}>
												<chakra.span className='mobile-phone-number-check-icon'>
													<FontAwesomeIcon icon='fa-solid fa-circle-check' />
												</chakra.span>
											</Box>
											<Box>
												<VStack>
													<Flex direction={["column"]}>
														<Box fontWeight={700}>Thank You!</Box>
														<Box>
															Your mobile phone number is now verified and added to your
															profile.
														</Box>
														<Box>
															You can now select for which property you would like to
															receive text notifications.
														</Box>
													</Flex>
												</VStack>
											</Box>
										</HStack>
									</Flex>
								</React.Fragment>
							) : null}
						</Box>
					</ModalBody>
					<ModalFooter className={"modal-footer"}>
						{isStepOne ? (
							<>
								<Flex
									direction={["column", "column", "row"]}
									w={["100%", "100%", "auto"]}>
									<HStack>
										<Button
											minW={["100%", "100%", 100]}
											mr={[0, 0, 2]}
											colorScheme='lightBlue'
											onClick={handleSubmitAddMobilePhoneNumber((data) => {
												setStepOneLoading(true);
												handleStepOne(data);
											})}>
											{stepOneLoading ? (
												<Spinner
													mr={2}
													size='sm'
													speed='0.90s'
													emptyColor='gray.200'
													color='blue.500'
												/>
											) : null}
											NEXT
										</Button>
									</HStack>

									<Button
										colorScheme='grey'
										variant={"outline"}
										mt={[2, 2, 0]}
										minW={["100%", "100%", 100]}
										onClick={() => resetForm()}>
										CLEAR
									</Button>
								</Flex>
							</>
						) : null}
						{isStepTwo ? (
							<>
								<Flex
									direction={["column", "column", "row"]}
									w={["100%", "100%", "auto"]}>
									<HStack>
										<Button
											mr={[0, 0, 2]}
											minW={["100%", "100%", 100]}
											colorScheme='lightBlue'
											onClick={handleSubmitVerficationCode((data) => {
												setStepTwoLoading(true);
												handleStepTwo(data);
											})}>
											{stepTwoLoading ? (
												<Spinner
													mr={2}
													size='sm'
													speed='0.90s'
													emptyColor='gray.200'
													color='blue.500'
												/>
											) : null}
											VERIFY
										</Button>
									</HStack>

									<Button
										colorScheme='grey'
										variant={"outline"}
										mt={[2, 2, 0]}
										minW={["100%", "100%", 100]}
										onClick={() => handleChangeMobilePhoneNumberModalClose()}>
										Cancel
									</Button>
								</Flex>
							</>
						) : null}
						{isStepThree ? (
							<>
								<Flex w={["100%", "100%", "auto"]}>
									<Button
										colorScheme='blue'
										minW={["100%", "100%", 100]}
										onClick={() => handleChangeMobilePhoneNumberModalClose()}>
										OK
									</Button>
								</Flex>
							</>
						) : null}
					</ModalFooter>
				</ModalContent>
			</Modal>
			{/* ADD / UPDATE PHONE NUMBER - END */}

			{/* REMOVE PHONE NUMEBR */}
			<Modal
				size={"4xl"}
				closeOnOverlayClick={false}
				closeOnEsc={false}
				isOpen={isOpenRemoveMobilePhoneNumber}
				onClose={handleRemoveMobilePhoneNumberModalClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className={"modal-header"}>Remove Mobile Phone Number</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					<ModalBody>
						<Box
							mt={1}
							mb={2}>
							{isRemoveStepOne ? (
								<VStack>
									<Flex direction={["column"]}>
										<Box>
											<Box maxW={516}>
												Are you sure you want to remove your mobile phone number?
											</Box>
										</Box>
										<Box mt={4}>
											<FormControl>
												<Flex direction={["column", "column", "row"]}>
													<Box minW={formLabelMinWidth}>
														<FormLabel
															htmlFor='mobilePhoneNumber'
															textAlign={isSmallerScreen ? "" : "end"}
															className='form-label-fixed'>
															Mobile phone number
														</FormLabel>
													</Box>
													<Box>
														<Input
															id='mobilePhoneNumber'
															type='tel'
															readOnly={true}
															width={isSmallerScreen ? "100%" : 190}
															placeholder=''
															autoComplete='off'
															defaultValue={CommonFns.FormatPhoneNumber(
																mobilePhoneNumber
															)}
														/>
													</Box>
												</Flex>
											</FormControl>
											<FormControl mt={isSmallerScreen ? 6 : 3}>
												<Flex direction={["column", "column", "row"]}>
													<Box
														minW={formLabelMinWidth}
														display={isSmallerScreen ? "none" : "normal"}>
														<FormLabel>&nbsp;</FormLabel>
													</Box>
													{/* <Box>
														<Flex direction={["column", "column", "row"]}>
															<Box>
																<Button
																	mr={isSmallerScreen ? 0 : 2}
																	w={isSmallerScreen ? "100%" : 110}
																	colorScheme='red'
																	size={isSmallerScreen ? "md" : "sm"}
																	onClick={(e) => {
																		setRemoveStepOneLoading(true);
																		handleRemoveMobilePhoneNumber(e);
																	}}>
																	Yes, remove
																</Button>
															</Box>
															{removeStepOneLoading ? (
																<Box>
																	<Box mt={1} ml={1}>
																		<Spinner
																			size='xs'
																			speed='0.90s'
																			emptyColor='gray.200'
																			color='blue.500'
																		/>
																	</Box>
																</Box>
															) : null}
														</Flex>
													</Box> */}
												</Flex>
											</FormControl>
										</Box>
									</Flex>
								</VStack>
							) : null}
							{isRemoveStepTwo ? (
								<React.Fragment>
									<Flex
										direction={["column"]}
										alignItems={"center"}>
										<HStack>
											<Box mr={2}>
												<chakra.span className='mobile-phone-number-check-icon'>
													<FontAwesomeIcon icon='fa-solid fa-circle-check' />
												</chakra.span>
											</Box>
											<Box>
												<VStack>
													<Flex direction={["column"]}>
														<Box>
															Your mobile phone number has been removed and notification
															preferences have been updated.
														</Box>
														<Box>
															You will no longer receive text message notifications.
														</Box>
													</Flex>
												</VStack>
											</Box>
										</HStack>
									</Flex>
								</React.Fragment>
							) : null}
						</Box>
					</ModalBody>
					<ModalFooter className={"modal-footer"}>
						{isRemoveStepOne ? (
							<>
								<Flex
									direction={["column", "column", "row"]}
									w={["100%", "100%", ""]}
									justifyContent={"flex-end"}>
									<Button
										//mr={isSmallerScreen ? 0 : 2}
										minW={["100%", "100%", 100]}
										colorScheme='red'
										//size={isSmallerScreen ? "md" : "sm"}
										onClick={(e) => {
											setRemoveStepOneLoading(true);
											handleRemoveMobilePhoneNumber(e);
										}}>
										{removeStepOneLoading ? (
											<Spinner
												mr={2}
												size='sm'
												speed='0.90s'
												emptyColor='gray.200'
												color='blue.500'
											/>
										) : null}
										YES, REMOVE
									</Button>
									<Button
										colorScheme='grey'
										variant={"outline"}
										minW={["100%", "100%", 100]}
										ml={[0, 0, 2]}
										mt={[2, 2, 0]}
										onClick={() => handleRemoveMobilePhoneNumberModalClose()}>
										Cancel
									</Button>
								</Flex>
							</>
						) : null}
						{isRemoveStepTwo ? (
							<>
								<Flex w={["100%", "100%", "auto"]}>
									<Button
										colorScheme='blue'
										minW={["100%", "100%", 100]}
										onClick={() => handleRemoveMobilePhoneNumberModalClose()}>
										OK
									</Button>
								</Flex>
							</>
						) : null}
					</ModalFooter>
				</ModalContent>
			</Modal>
			{/* REMOVE PHONE NUMEBR - END
			 */}
		</React.Fragment>
	);
};
export default MobilePhoneNumber;
