import "gridjs/dist/theme/mermaid.css";
import "./ListProperty.css";

import * as CommonFns from "../../../../common";
import * as toastMessages from "../../../../toastMessages";

import {
	Box,
	Button,
	Flex,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	chakra,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import { Grid, _ } from "gridjs-react";
import React, { useContext, useEffect, useState } from "react";

import AddProperty from "../AddProperty/AddProperty";
import { AuditContext } from "../../../../contexts/AuditContext";
import { AuthContext } from "../../../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { axiosMyPropertyAuth } from "../../../../apis";
import { h } from "gridjs";
import { useNavigate } from "react-router-dom";

const ListProperty = ({
	onPropertyRemoved,
	onPropertyVerified,
	onPropertyListed,
	propertyCount,
	addRemovePropertyToggle,
}) => {
	const { authDetails, setAuthAccountNumber, setAuthDetails } = useContext(AuthContext);
	const { auditActivity } = useContext(AuditContext);
	const [addPropertyType, setAddPropertyType] = useState(CommonFns.AddPropertyTypeEnum.None);

	let navigate = useNavigate();

	const toast = useToast();
	const listPropertyToastErrorId = "list-property-toast-error";
	const listPropertyToastWarningId = "list-property-toast-warning";

	const [gridItems, setGridItems] = useState();
	const [pageLimit] = useState(10);
	const [currentPage, setCurrentPage] = useState(0);
	const [isPaging, setIsPaging] = useState(false);

	const { isOpen, onOpen, onClose } = useDisclosure();
	const [deletingAccount, setDeletingAccount] = useState({ accId: "", address: "" });

	useEffect(() => {
		getProperties();
	}, [addRemovePropertyToggle]);

	const getProperties = () => {
		let url = `property/list`;

		axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					if (returnObj.accountsCol !== null) {
						setGridItems(returnObj.accountsCol);

						if (returnObj.accountsCol.length !== propertyCount) {
							onPropertyListed(returnObj.accountsCol.length);
						}

						if (returnObj.accountsCol.length > pageLimit) {
							setIsPaging(true);
						} else {
							setIsPaging(false);
						}
					} else {
						setGridItems([]);
						onPropertyListed(0);
					}
				} else {
					// invalid uId was provided
					setGridItems([]);
					onPropertyListed(0);

					if (!toast.isActive(listPropertyToastWarningId)) {
						toast(
							toastMessages.getToastWarning(
								listPropertyToastWarningId,
								returnObj.errorMsg,
								toastMessages.propertyMessages
							)
						);
					}
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(listPropertyToastErrorId)) {
					toast(toastMessages.getToastError(listPropertyToastErrorId));
				}
			});
	};

	const removeProperty = (accId, address) => {
		setDeletingAccount({
			accId: accId.toString(),
			address: address,
		});

		if (gridItems.length > pageLimit) {
			// keep the user on the current page when showing the delete modal
			let currentPage = document.getElementsByClassName("gridjs-currentPage")[0].innerHTML;
			setCurrentPage(Number(currentPage) - 1);
		}

		onOpen();
	};

	const getDisplayPageNumber = () => {
		// can only get away with this because paging is not enabled on the isPropertyView on ListMail
		if (gridItems.length > pageLimit) {
			let totalPages = Math.ceil(propertyCount / pageLimit); // round up
			let newPage = currentPage; // index
			if (Number(currentPage) === Number(totalPages)) {
				// on the last page
				let modulus = ((propertyCount - 1) / pageLimit) % 1;
				if (modulus === 0) {
					// number does not have a decimal
					// current page is no more
					// move back one extra page
					newPage -= 1;
				}
			}
			return Number(newPage);
		}
	};

	//this is triggered from the owner verification if user selected "that's not me"
	const handleNoOwnerRemoveProperty = () => {
		handleRemoveProperty(authDetails.accId);
	};

	//this is triggered from the delete confirmation modal
	const handleConfirmRemoveProperty = (e) => {
		e.preventDefault();
		handleRemoveProperty(deletingAccount.accId);
	};

	const handleRemoveProperty = (accId) => {
		let url = `property/remove`;
		const payload = { accId: accId, uId: "" };
		axiosMyPropertyAuth
			.post(url, payload)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					// audit
					auditActivity("Account Deleted from Profile", deletingAccount.accId);

					setCurrentPage(getDisplayPageNumber());

					onPropertyRemoved(returnObj.newMyMailOwnerCount);

					resetDeletingAccount();

					// using custom toast here
					// not using toastMessages.js
					// not using id, allows multiple toasts to appear if a user is faster than the 3000ms close timer
					// this prevents console errors
					toast({
						title: "Success!",
						description: "Property has been removed.",
						status: "success",
						position: "top-right",
						duration: 3000,
						isClosable: true,
					});
				} else {
					if (!toast.isActive(listPropertyToastWarningId)) {
						toast(
							toastMessages.getToastWarning(
								listPropertyToastWarningId,
								returnObj.errorMsg,
								toastMessages.propertyMessages
							)
						);
					}
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(listPropertyToastErrorId)) {
					toast(toastMessages.getToastError(listPropertyToastErrorId));
				}
			});

		onClose();
	};

	const handleCancelRemoveProperty = () => {
		resetDeletingAccount();
		onClose();
	};

	const sortProperty = (direction, accId, originalSortOrder) => {
		let sortOrder;
		switch (direction) {
			case "up":
				sortOrder = originalSortOrder - 1;
				break;

			case "down":
				sortOrder = originalSortOrder + 1;
				break;

			// no default
		}

		// can only get away with this because paging is not enabled on the isPropertyView
		if (gridItems.length > pageLimit) {
			let currentPage = document.getElementsByClassName("gridjs-currentPage")[0].innerHTML;
			setCurrentPage(Number(currentPage) - 1);
		}

		//axios call to sort
		let url = `property/updatesortorder`;
		const payload = {
			uId: "",
			accId: accId,
			sortOrder: sortOrder,
		};

		axiosMyPropertyAuth
			.put(url, payload)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					// audit
					auditActivity("Account Sorted", accId);

					getProperties();
				} else {
					if (!toast.isActive(listPropertyToastWarningId)) {
						toast(
							toastMessages.getToastWarning(
								listPropertyToastWarningId,
								returnObj.errorMsg,
								toastMessages.propertyMessages
							)
						);
					}
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(listPropertyToastErrorId)) {
					toast(toastMessages.getToastError(listPropertyToastErrorId));
				}
			});
	};

	const viewProperty = (accId, prtId) => {
		if (prtId === null) {
			//setOpenVerifyOwnerToggle(!openVerifyOwnerToggle);
			setAddPropertyType(CommonFns.AddPropertyTypeEnum.Verify);
			setAuthDetails({
				...authDetails,
				addPropertyModal: true,
				accId: accId, //have to add it directly as opposed to calling setAuthAccountNumber
			});
		} else {
			// set the auth context with the account being viewed
			setAuthAccountNumber(accId);

			// audit
			auditActivity("View account", accId);

			// navigate the user to the property detail
			navigate(`/auth/property-detail`, { replace: true });
		}
	};

	const resetDeletingAccount = () => {
		setDeletingAccount({
			accId: "",
			address: "",
		});
	};

	// Grid columns
	const gridColumns = [
		{
			id: "prtId",
			name: "Id",
			hidden: true,
		},
		{
			id: "sortUp",
			hidden: gridItems !== undefined ? (gridItems.length > 1 ? false : true) : true,
			formatter: (cell, row) => {
				return h(
					"div",
					{
						className: "grid-button light-blue sort-up",
						onClick: (e) => {
							e.preventDefault();
							sortProperty("up", row.cells[5].data, row.cells[3].data);
						},
						title: "Sort property up",
					},
					_(
						<Button>
							<FontAwesomeIcon icon='fa-solid fa-chevron-up' />
						</Button>
					)
				);
			},
			width: "3%",
		},
		{
			id: "sortDown",
			hidden: gridItems !== undefined ? (gridItems.length > 1 ? false : true) : true,
			formatter: (cell, row) => {
				return h(
					"div",
					{
						className: "grid-button light-blue sort-down",
						onClick: (e) => {
							e.preventDefault();
							sortProperty("down", row.cells[5].data, row.cells[3].data);
						},
						title: "Sort property down",
					},
					_(
						<Button>
							<FontAwesomeIcon icon='fa-solid fa-chevron-down' />
						</Button>
					)
				);
			},
			width: "3%",
		},
		{
			id: "sortOrder",
			hidden: gridItems !== undefined ? (gridItems.length > 1 ? false : true) : true,
			formatter: (cell, row) => {
				return h(
					"div",
					{
						title: "Sort order",
					},
					_(<chakra.span fontWeight={700}>{cell}</chakra.span>)
				);
			},
			//width: "6%",
		},
		{
			id: "address",
			name: "Address",
			formatter: (cell, row) => {
				return h(
					"div",
					{
						onClick: (e) => {
							e.preventDefault();
							viewProperty(row.cells[5].data, row.cells[0].data);
						},
					},
					_(
						<Link
							className='grid-link view'
							title='View property details'
							fontWeight={700}>
							{cell}
						</Link>
					)
				);
			},
			sort: {
				compare: (a, b) => {
					if (a > b) {
						return 1;
					} else if (b > a) {
						return -1;
					} else {
						return 0;
					}
				},
			},
			width: "55%",
		},
		{
			id: "accId",
			name: "Account",
			formatter: (cell, row) => {
				return h(
					"div",
					{},
					_(
						<chakra.span
							title='Property account number'
							fontWeight={700}>
							{cell}
						</chakra.span>
					)
				);
			},
			sort: {
				compare: (a, b) => {
					if (a > b) {
						return 1;
					} else if (b > a) {
						return -1;
					} else {
						return 0;
					}
				},
			},
			width: "15%",
		},
		{
			id: "displayAsNewFlag",
			formatter: (cell, row) => {
				return h(
					"div",
					{
						className: cell === "Y" || row.cells[0].data == null ? "grid-badge" : "",
						title: "Property was recently added to your profile",
					},
					_(<chakra.span>{row.cells[0].data == null ? "Verify" : cell === "Y" ? "New" : ""}</chakra.span>)
				);
			},
			//width: "7%",
		},
		{
			id: "delete",
			sort: false,
			formatter: (cell, row) => {
				return h(
					"button",
					{
						className: "grid-button red xl",
						onClick: (e) => {
							e.preventDefault();
							removeProperty(row.cells[5].data, row.cells[4].data);
						},
						title: "Remove property from your profile",
					},
					_(
						<FontAwesomeIcon
							fontSize={"1.15em"}
							icon='fa-solid fa-square-xmark'
						/>
					)
				);
			},
			//width: "2%",
		},
	];

	// Grid paging
	const gridPagination = {
		enabled: gridItems !== undefined ? (isPaging ? true : false) : false,
		page: gridItems !== undefined ? (gridItems.length > pageLimit ? currentPage : 0) : 0,
		limit: pageLimit,
		resetPageOnUpdate: false,
	};

	const handleAddPropertyClose = () => {
		//set apppropertytype back to none so modal is not triggered to open from the other add modal on the myproperty page
		setAddPropertyType(CommonFns.AddPropertyTypeEnum.None);
	};

	const handleOnPropertyVerified = (accId, prtId, newMyMailOwnerCount) => {
		// call property added with verify
		// this will get all of the new mail items and
		// keep the property count the same
		onPropertyVerified(accId, newMyMailOwnerCount);

		//redirect to details page
		viewProperty(accId, prtId);
	};

	return (
		<React.Fragment>
			<Box>
				{gridItems !== undefined ? (
					gridItems.length > 0 ? (
						<Box
							mt={2}
							className={"property-list-table"}>
							<Grid
								data={gridItems}
								columns={gridColumns}
								pagination={gridPagination}
								search={false}
								sort={false}></Grid>
						</Box>
					) : (
						<Box className='list-property-no-records'>
							It looks like you haven't connected any properties to your online profile. To do so, click
							the "Add a Property" button above.
						</Box>
					)
				) : (
					<Box className='list-property-no-records'>
						It looks like you haven't connected any properties to your online profile. To do so, click the
						"Add a Property" button above.
					</Box>
				)}
			</Box>
			<Modal
				closeOnOverlayClick={false}
				isOpen={isOpen}
				onClose={handleCancelRemoveProperty}>
				<ModalOverlay />
				<ModalContent maxW={"35rem"}>
					<ModalHeader className={"modal-header"}>Remove Property - Confirmation</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					<ModalBody>
						<Box
							mt={2}
							mb={4}>
							Are you sure you want to remove this property?
						</Box>
						<Box mb={2}>
							<Box>
								Account: <chakra.span fontWeight={700}>{deletingAccount.accId}</chakra.span>
							</Box>
							<Box>
								Address: <chakra.span fontWeight={700}>{deletingAccount.address}</chakra.span>
							</Box>
						</Box>
					</ModalBody>
					<ModalFooter className={"modal-footer"}>
						<Flex
							direction={["column", "column", "row"]}
							w={["100%", "100%", "auto"]}>
							<Button
								colorScheme={"red"}
								minW={["100%", "100%", 100]}
								mr={[0, 0, 2]}
								onClick={(e) => handleConfirmRemoveProperty(e)}>
								YES, REMOVE
							</Button>
							<Button
								colorScheme='grey'
								variant={"outline"}
								mt={[2, 2, 0]}
								minW={["100%", "100%", 100]}
								onClick={(e) => handleCancelRemoveProperty(e)}>
								Cancel
							</Button>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<AddProperty
				hideAddButton={true}
				onPropertyVerified={handleOnPropertyVerified}
				handleNoOwnerRemoveProperty={handleNoOwnerRemoveProperty}
				addPropertyType={addPropertyType}
				handleAddPropertyClose={handleAddPropertyClose}></AddProperty>
		</React.Fragment>
	);
};

export default ListProperty;
