import "./index.css";

import { ChakraProvider, extendTheme } from "@chakra-ui/react";

import App from "./App";
import AuditContextProvider from "./contexts/AuditContext";
import AuthContextProvider from "./contexts/AuthContext";
import BannerContextProvider from "./contexts/BannerContext";
import React from "react";
import { HashRouter as Router } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { createStandaloneToast } from "@chakra-ui/toast";

const theme = extendTheme({
	colors: {
		blue: {
			500: "#004060",
			600: "#002f47",
			700: "#002f47",
		},
		lightBlue: {
			500: "#00a5d4",
			600: "#0091bb",
			700: "#0091bb",
		},
		red: {
			500: "#c80000",
		},
		nav: {
			500: "#ebecee",
			600: "#e2e2e2",
			700: "#e2e2e2",
		},
	},
});

const { ToastContainer } = createStandaloneToast({ theme: theme });

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
	<Router>
		<ChakraProvider theme={theme}>
			<ToastContainer />
			<AuthContextProvider>
				<AuditContextProvider>
					<BannerContextProvider>
						<App />
					</BannerContextProvider>
				</AuditContextProvider>
			</AuthContextProvider>
		</ChakraProvider>
	</Router>
);
